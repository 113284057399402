<template lang="html">
  <USView class="favourites" :dark="dark" show-back>
    <h1>Your Favourites</h1>
    <p>These are all the posts that you gave a star.</p>
    <label>
      Sort by:
      <USSelectBox v-model="sort" :dark="dark" :options="[{ label: 'Newest First', value: -1 }, { label: 'Oldest First', value: 1 }]" />
    </label>
    <ListTransition>
      <template v-if="posts.length > 0 || nextPageAvailable">
        <USPostCard v-for="post in posts" :dark="dark" :key="post._id" :post="post" @click="openPost($event, { params: { id: post._id }, query: { listType: 'favourites' } })" @mousedown="openPost($event, { params: { id: post._id }, query: { listType: 'favourites' } })" />
        <USButton v-show="nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="postsLoading" @click="fetchPostsPage">Load More</USButton>
      </template>
      <section v-else class="empty-state" key="emptyState">
        <p>You haven’t added any post to your favourites yet.</p>
      </section>
    </ListTransition>
  </USView>
</template>

<script>
import Store from '@/store';
import Feathers from '@/feathersApp';
import ListTransition from '@/transitions/ListTransition.vue';

import openPost from '@/mixins/openPost';

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: posts, total } = await Feathers.service('posts').find({
        query: {
          favourers: Store.getters.userId,
          $limit: 15,
          $sort: { createdAt: -1 },
        },
      });

      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.posts = posts;
        vm.currentPage += 1;
        vm.nextPageAvailable = vm.currentPage < Math.ceil(total / vm.pageSize);
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      else if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchPostsPage();
    next();
  },
  components: {
    ListTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  async created() {
    if (this.$route.name === 'user' && this.$route.query.page === 'favourites') {
      await this.fetchPostsPage();
    }
  },
  data() {
    return {
      currentPage: 0,
      nextPageAvailable: true,
      pageSize: 15,
      posts: [],
      postsLoading: false,
      sort: -1,
    };
  },
  methods: {
    async fetchPostsPage() {
      const timeoutId = window.setTimeout(() => { this.postsLoading = true; }, 200);
      try {
        const { data: posts, total } = await this.$feathers.service('posts').find({
          query: {
            favourers: this.$store.getters.userId,
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage,
            $sort: { createdAt: this.sort },
          },
        });
        this.posts = this.posts.concat(posts);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more posts: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.postsLoading = false;
    },
  },
  mixins: [openPost],
  props: {
    dark: Boolean,
  },
  watch: {
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'user', query: { page: 'favourites' } });
    },
    sort() {
      this.posts = [];
      this.currentPage = 0;
      this.fetchPostsPage();
    },
  },
};
</script>

<style lang="stylus" scoped>
.favourites
  > p
    margin-bottom: 3rem

  .button
    display: flex
    margin: 0 auto

  label
    display: flex
    align-items: center
    margin-bottom: 2rem

    .select-box
      margin-left: auto

  .post-card:not(:last-of-type)
    margin-bottom: 1.5rem

  .empty-state
    margin-bottom: 0

    p
      font-weight: 800
      opacity: 0.6
      text-align: center
</style>

<template lang="html">
  <div class="counter-button" :class="{ dark, disabled: disabled && disabled.left && disabled.right }">
    <USIconButton :dark="dark" :disabled="disabled && disabled.left" :icon="iconLeft" @click="$emit('decrement')" />
    <span class="content">{{$slots.default[0].text}}</span>
    <USIconButton :dark="dark" :disabled="disabled && disabled.right" :icon="iconRight" @click="$emit('increment')" />
  </div>
</template>

<script>

export default {
  props: {
    dark: Boolean,
    disabled: Object,
    iconLeft: String,
    iconRight: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.counter-button
  display: inline-flex
  justify-content: space-between
  align-items: center
  padding: calc(0.375rem - 1px)
  border: 1px solid $interactable
  border-radius: 0.75rem
  background-color: transparent
  color: $text-primary
  line-height: 1.5rem
  box-shadow: $shadow-low
  text-shadow: $text-shadow
  position: relative
  max-width: 100%
  overflow: hidden
  z-index: 0
  transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease, border-color 200ms ease

  &.dark
    color: $text-primary-dark
    border-color: $interactable-dark

  &:hover
    box-shadow: $shadow-high

    &.dark
      background-color: $elevation-primary-dark

  &.disabled
    pointer-events: none
    color: $text-disabled
    border-color: $interactable-disabled
    box-shadow: none

    &.dark
      color: $text-disabled-dark
      border-color: $interactable-disabled-dark

  .icon-button
    width: 2.25rem
    height: @width
    border-radius: 0.375rem
    padding: calc(0.375rem - 1px)

    &:hover,
    &:focus
      box-shadow: none

  .content
    margin: 0 0.375rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
</style>

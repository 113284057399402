<template lang="html">
  <div
    class="editor"
    :class="{
      dark, mobile, prose: mode === 'delta', indented: indentedParagraphs,
    }"
    @click.self="quill.focus()"
    @focusout="handleBlur">
    <div class="editor-element" @focusin="handleFocus" />
    <div v-show="!mobile && !disabled && selectionBounds.width === 0 && focussed" class="fake-caret" :style="{ height: `${selectionBounds.height}px`, transform: `translateX(${currentParagraphType === 'img' ? 0 : selectionBounds.left}px) translateY(${selectionBounds.top}px)` }" />
    <FadeTransition>
      <USActionBar v-show="!scrolling && toolbarItems.length > 0 && (showToolbar || (mobile && focussed))" :actions="toolbarItems" :back-action="() => quill.focus()" class="toolbar" :dark="dark" :mobile="mobile" ref="toolbar" :style="toolbarStyle" @mousedown.native.self.prevent />
    </FadeTransition>
    <template v-if="!disabled">
      <template v-if="formats.includes('link')">
        <FadeTransition>
          <USInput v-show="!scrolling && showLinkInput" v-model="linkUrl" autofocus class="url-input" :class="{ mobile }" :dark="dark" :error="urlInvalid" icon="link" label="Link URL" sendable :style="{ transform: urlInputTransform }" ref="linkInput" @keyup.native.escape="hideUrlInput('linkInput')" @mousedown.native="handleURLInputMousedown" @send="this.insertLink" />
        </FadeTransition>
      </template>
      <template v-if="formats.includes('image')">
        <FadeTransition>
          <USInput v-show="!scrolling && showImageInput" v-model="imageUrl" autofocus class="url-input" :class="{ mobile }" :dark="dark" :error="imageUrlInvalid" icon="image-add" label="Image URL" sendable :style="{ transform: urlInputTransform }" ref="imageInput" @keyup.native.escape="hideUrlInput('imageInput')" @mousedown.native="handleURLInputMousedown" @send="this.insertImage" />
        </FadeTransition>
      </template>
    </template>
  </div>
</template>

<script>
/* eslint-disable max-classes-per-file */
import TurndownService from 'turndown/lib/turndown.browser.es';
import Quill from 'quill';
import { debounce } from 'lodash-es';

import markdown from '@/filters/markdown';

import FadeTransition from '@/transitions/FadeTransition.vue';

const Delta = Quill.import('delta');
const Parchment = Quill.import('parchment');
const BlockEmbed = Quill.import('blots/block/embed');
const Link = Quill.import('formats/link');

export default {
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollStart, true);
    window.removeEventListener('scroll', this.scrollStop, true);
    if (this.disabled) window.removeEventListener('click', this.handleOutsideClick);
  },
  components: {
    FadeTransition,
  },
  computed: {
    currentAlignIcon() {
      if (Array.isArray(this.currentAlign)) return this.currentAlign[0];
      return this.currentAlign;
    },
    currentParagraphTypeIcon() {
      switch (this.currentParagraphType) {
        case 'br':
          return 'line-break';
        case 'blockquote':
          return 'quote';
        case 'h1':
          return 'heading1';
        case 'h2':
          return 'heading2';
        case 'ol':
          return 'ordered-list';
        case 'ul':
          return 'bullet-list';
        default:
          return 'paragraph';
      }
    },
    imageUrlInvalid() {
      if (this.imageUrl) {
        if (!this.imageUrl.trim()) return 'Invalid URL';
        if (this.imageUrl.trim().length > 200) return 'URL is too long';
        if (!/^http[s]?:\/\//.test(this.imageUrl)) return 'Invalid protocol';
      }
      return '';
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
    toolbarItems() {
      if (!this.mobile && !this.showToolbar && this.toolbarActionsCache.length > 0) return this.toolbarActionsCache; // could be enabled for performance optimization, but breaks leave-transition
      const actions = [];

      if (this.disabled) {
        // safe since we're just caching it
        /* eslint-disable vue/no-side-effects-in-computed-properties */
        this.toolbarActionsCache = [
          ...(this.quotable ? [{ action: this.emitQuote, icon: 'quote', tooltip: 'Quote the selected text' }] : []),
          ...(this.bookmarkable ? [{ action: this.emitBookmark, icon: 'add-bookmark', tooltip: 'Set your bookmark at this spot' }] : []),
        ];
        /* eslint-enable vue/no-side-effects-in-computed-properties */

        return this.toolbarActionsCache;
      }

      if (this.currentParagraphType === 'img') {
        if (this.formats.includes('align')) {
          // safe since we're just caching it
          /* eslint-disable vue/no-side-effects-in-computed-properties */
          this.toolbarActionsCache = [
            {
              action: () => this.align('left'), active: this.currentAlignIcon === 'left', icon: 'left', tooltip: 'Align left',
            },
            {
              action: () => this.align('center'), active: this.currentAlignIcon === 'center', icon: 'center', tooltip: 'Align centered',
            },
            {
              action: () => this.align('right'), active: this.currentAlignIcon === 'right', icon: 'right', tooltip: 'Align right',
            },
            {
              action: () => this.align('justify'), active: this.currentAlignIcon === 'justify', icon: 'justify', tooltip: 'Align justified',
            },
          ];
          /* eslint-enable vue/no-side-effects-in-computed-properties */

          return this.toolbarActionsCache;
        }
      }

      const paragraphStyles = [
        {
          action: () => this.handleLineFormat('p'), active: this.currentParagraphType === 'p', icon: 'paragraph', tooltip: 'Paragraph',
        },
      ];
      if (this.formats.includes('linebreak')) {
        paragraphStyles.push({
          action: () => this.handleLineFormat('br'), active: this.currentParagraphType === 'br', icon: 'line-break', tooltip: 'Line break',
        });
      }
      if (this.formats.includes('header')) {
        paragraphStyles.push({
          action: () => this.handleLineFormat('h1'), active: this.currentParagraphType === 'h1', icon: 'heading1', tooltip: 'Heading',
        });
        paragraphStyles.push({
          action: () => this.handleLineFormat('h2'), active: this.currentParagraphType === 'h2', icon: 'heading2', tooltip: 'Subheading',
        });
      }
      if (this.formats.includes('blockquote')) {
        paragraphStyles.push({
          action: () => this.handleLineFormat('blockquote'), active: this.currentParagraphType === 'blockquote', icon: 'quote', tooltip: 'Quote',
        });
      }
      if (this.formats.includes('list')) {
        paragraphStyles.push({
          action: () => this.handleLineFormat('ol'), active: this.currentParagraphType === 'ol', icon: 'ordered-list', tooltip: 'Numbered list',
        });
        paragraphStyles.push({
          action: () => this.handleLineFormat('ul'), active: this.currentParagraphType === 'ul', icon: 'bullet-list', tooltip: 'Bulleted list',
        });
      }
      actions.push({
        action: () => this.quill.focus(),
        icon: this.currentParagraphTypeIcon,
        subactions: paragraphStyles,
        tooltip: 'Paragraph style',
      });

      if (this.formats.includes('align')) {
        actions.push({
          action: () => this.quill.focus(),
          icon: this.currentAlignIcon,
          subactions: [
            {
              action: () => this.align('left'), active: this.currentAlignIcon === 'left', icon: 'left', tooltip: 'Align left',
            },
            {
              action: () => this.align('center'), active: this.currentAlignIcon === 'center', icon: 'center', tooltip: 'Align centered',
            },
            {
              action: () => this.align('right'), active: this.currentAlignIcon === 'right', icon: 'right', tooltip: 'Align right',
            },
            {
              action: () => this.align('justify'), active: this.currentAlignIcon === 'justify', icon: 'justify', tooltip: 'Align justified',
            },
          ],
          tooltip: 'Paragraph alignment',
        });
      }

      if (this.formats.includes('bold')) {
        actions.push({
          action: () => this.format('bold'), active: this.activeFormats.bold, icon: 'bold', tooltip: 'Bold',
        });
      }
      if (this.formats.includes('italic')) {
        actions.push({
          action: () => this.format('italic'), active: this.activeFormats.italic, icon: 'italic', tooltip: 'Italic',
        });
      }
      if (this.formats.includes('underline')) {
        actions.push({
          action: () => this.format('underline'), active: this.activeFormats.underline, icon: 'underline', tooltip: 'Underline',
        });
      }
      if (this.formats.includes('strike')) {
        actions.push({
          action: () => this.format('strike'), active: this.activeFormats.strike, icon: 'strike', tooltip: 'Strikethrough',
        });
      }

      if (this.formats.includes('link')) {
        actions.push({
          // fine because it's just an action called by a button, not an actual assignment
          action: () => { this.showLinkInput = true; }, // eslint-disable-line vue/no-side-effects-in-computed-properties
          active: !!this.activeFormats.link,
          disabled: !this.selectionBounds.width,
          icon: 'link',
          tooltip: this.activeFormats.link ? 'Edit link' : 'Add link',
        });
        if (this.activeFormats.link) actions.push({ action: this.removeLink, icon: 'unlink', tooltip: 'Remove link' });
      }

      if (this.formats.includes('image')) {
        actions.push({
          // fine because it's just an action called by a button, not an actual assignment
          action: () => { this.showImageInput = true; }, // eslint-disable-line vue/no-side-effects-in-computed-properties
          icon: 'image-add',
          tooltip: 'Add image',
        });
      }

      if (this.formats.includes('separator')) actions.push({ action: this.insertSeparator, icon: 'separator-add', tooltip: 'Add separator' });
      // safe since we're just caching it
      this.toolbarActionsCache = actions; // eslint-disable-line vue/no-side-effects-in-computed-properties
      return actions;
    },
    toolbarStyle() {
      if (this.mobile) return null;
      return { transform: this.toolbarTransform };
    },
    toolbarTransform() {
      if (this.scrolling || this.mobile || !this.showToolbar) return this.toolbarTransformCache;
      const marginInPx = 0.5 * this.remBase;
      const editorBounds = this.$el ? this.$el.getBoundingClientRect() : {};
      const offset = this.$el ? this.$el.scrollTop : 0;
      const {
        left, top, width, height,
      } = this.selectionBounds;
      // make bounds relative to viewport
      const fixedLeft = left + editorBounds.left;
      const fixedTop = (top - offset) + (editorBounds.top + (window.visualViewport.offsetTop)); // this doesnt work properly yet as soon as the editor scrolls
      const toolbarItems = (this.$refs.toolbar && this.$refs.toolbar.subactionsActive && this.$refs.toolbar.visibleActions.length + 1) || (this.$refs.toolbar && this.$refs.toolbar.visibleActions.length) || this.toolbarItems.length;
      const toolbarWidthInPx = Math.min((toolbarItems * 2.75 + (toolbarItems + 1) * 0.375) * this.remBase, window.innerWidth - 0.75 * this.remBase);
      const toolbarHeightInPx = 3.5 * this.remBase;

      let translateX = '';
      let translateY = `${fixedTop + height + marginInPx}px`;

      if ((fixedLeft + width / 2) - toolbarWidthInPx / 2 > 0.375 * this.remBase) translateX = `${Math.round((fixedLeft + width / 2) - toolbarWidthInPx / 2)}px`;
      else translateX = '0.375rem';

      if ((fixedLeft + width / 2) + toolbarWidthInPx / 2 > window.innerWidth - 0.375 * this.remBase) translateX = `${window.innerWidth - 0.375 * this.remBase - toolbarWidthInPx}px`;

      if (fixedTop + height > editorBounds.top + editorBounds.height) translateY = `${editorBounds.top + editorBounds.height + marginInPx}px`;
      if (fixedTop < editorBounds.top) translateY = `${editorBounds.top - (toolbarHeightInPx + marginInPx)}px`;

      const transform = `translateX(${translateX}) translateY(${translateY})`;
      // safe since we're just caching it
      this.toolbarTransformCache = transform; // eslint-disable-line vue/no-side-effects-in-computed-properties
      return transform;
    },
    urlInputTransform() {
      if (this.scrolling || (!this.showLinkInput && !this.showImageInput)) return this.urlInputTransformCache;
      const marginInPx = 0.5 * this.remBase;
      const editorBounds = this.$el ? this.$el.getBoundingClientRect() : {};
      const offset = this.$el ? this.$el.scrollTop : 0;
      const {
        left, top, width, height,
      } = this.selectionBounds;
      const fixedLeft = left + editorBounds.left;
      const fixedTop = (top - offset) + (editorBounds.top + window.visualViewport.offsetTop);
      const inputWidthInPx = Math.min(16 * this.remBase, window.innerWidth - 0.75 * this.remBase);
      const inputHeightInPx = 3.5 * this.remBase;

      if (this.mobile) return `translateX(-50%) translateY(${window.visualViewport.height - document.getElementById('layoutViewport').getBoundingClientRect().height + window.visualViewport.offsetTop}px)`;

      let translateX = '';
      let translateY = `${fixedTop + height + marginInPx}px`;

      if ((fixedLeft + width / 2) - inputWidthInPx / 2 > 0.375 * this.remBase) translateX = `${Math.round((fixedLeft + width / 2) - inputWidthInPx / 2)}px`;
      else translateX = '0.375rem';

      if ((fixedLeft + width / 2) + inputWidthInPx / 2 > window.innerWidth - 0.375 * this.remBase) translateX = `${window.innerWidth - 0.375 * this.remBase - inputWidthInPx}px`;

      if (fixedTop + height > editorBounds.top + editorBounds.height) translateY = `${editorBounds.top + editorBounds.height + marginInPx}px`;
      if (fixedTop < editorBounds.top) translateY = `${editorBounds.top - (inputHeightInPx + marginInPx)}px`;

      const transform = `translateX(${translateX}) translateY(${translateY})`;
      // safe since we're just caching it
      this.urlInputTransformCache = transform; // eslint-disable-line vue/no-side-effects-in-computed-properties
      return transform;
    },
    urlInvalid() {
      if (this.linkUrl) {
        if (!this.linkUrl.trim()) return 'Invalid URL';
        if (this.linkUrl.trim().length > 200) return 'URL is too long';
      }
      return '';
    },
  },
  data() {
    return {
      activeFormats: {
        bold: false,
        italic: false,
        link: false,
        strike: false,
        underline: false,
      },
      currentAlign: 'left',
      currentParagraphType: 'p',
      debouncedTypingStart: debounce(() => {
        this.$emit('typing-start');
      }, 500, { leading: true, trailing: false }),
      debouncedValueUpdate: debounce(() => {
        if (this.mode !== 'markdown') {
          this.$emit('input', this.quill.getContents(), this.quill.getText());
          return;
        }
        // otherwise transform to markdown and emit after that
        if (!this.turndownService) {
          this.turndownService = new TurndownService({
            headingStyle: 'atx',
            hr: '---',
            bulletListMarker: '-',
            codeBlockStyle: 'fenced',
            emDelimiter: '*',
            strongDelimiter: '**',
          });

          this.turndownService.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: (content) => `~~${content}~~`,
          });

          this.turndownService.addRule('list-indent', {
            filter: (node) => node.className.includes('ql-indent') && node.nodeName === 'LI',
            replacement(content, node, options) {
              const cleanContent = content
                .replace(/^\n+/, '')
                .replace(/\n+$/, '\n')
                .replace(/\n/gm, '\n    ');
              let prefix = `${options.bulletListMarker}   `;
              const parent = node.parentNode;
              const indentLevel = window.parseInt(node.className.replace('ql-indent-', ''), 10);
              if (parent.nodeName === 'OL') {
                prefix = '1.   ';
              }
              return `${' '.repeat(indentLevel * 4)}${prefix}${cleanContent}${(node.nextSibling && !/\n$/.test(cleanContent) ? '\n' : '')}`;
            },
          });

          this.turndownService.addRule('custom-line-breaks', {
            filter: (node) => node.nodeName === 'P' && ((node.nextElementSibling && node.nextElementSibling.className.includes('linebreak-true')) || node.className.includes('linebreak-true')),
            replacement: (content, node, options) => `${content + options.br}\n`,
          });
        }
        const quillHTML = this.quill.root.innerHTML.replace(/<p><br><\/p>/g, '<p></p>');
        this.$emit('input', this.turndownService.turndown(quillHTML));
      }, 500, { leading: false }),
      focussed: false,
      imageUrl: '',
      keybindings: {
        'markdown header': {
          key: 32,
          collapsed: true,
          format: { header: false, code: false, 'code-block': false },
          prefix: /^#{1,6}$/,
          handler(range, context) {
            switch (context.prefix) {
              case '#':
                this.quill.formatLine(range.index, 1, 'header', '1', 'user');
                this.quill.deleteText(range.index - 1, 1);
                break;
              case '##':
                this.quill.formatLine(range.index, 1, 'header', '2', 'user');
                this.quill.deleteText(range.index - 2, 2);
                break;
              default:
            }
          },
        },
        'markdown separator': {
          key: 'ENTER',
          collapsed: true,
          offset: 1,
          format: { 'code-block': false },
          prefix: /^—$/,
          handler(range, context) {
            this.quill.deleteText(range.index - 1, 1, 'silent');
            this.quill.insertEmbed(range.index - 1, 'separator', true, Quill.sources.USER);
            this.quill.deleteText(range.index, 1, 'silent');
            if (context.format.align) this.quill.format('align', context.format.align);
            return true;
          },
        },
        'markdown quote': {
          key: ' ',
          collapsed: true,
          format: {
            header: false, code: false, 'code-block': false, blockquote: false,
          },
          offset: 1,
          prefix: /^>$/,
          handler(range) {
            this.quill.formatLine(range.index, 1, 'blockquote', true, 'user');
            this.quill.deleteText(range.index - 1, 1);
          },
        },
        'reset align': {
          key: 'backspace',
          collapsed: true,
          format: ['align'],
          empty: true,
          handler(range, context) {
            if (context.format.align === 'center') this.quill.formatLine(range.index, range.length, 'align', false, 'user');
            else if (context.format.align === 'right') this.quill.formatLine(range.index, range.length, 'align', 'center', 'user');
            else return true;
            return false;
          },
        },
        'reset format empty': {
          key: 'backspace',
          format: ['header', 'linebreak'],
          empty: true,
          handler(range, context) {
            if (context.format.header) this.quill.format('header', false, 'user');
            if (context.format.linebreak) this.quill.format('linebreak', false, 'user');
            return true;
          },
        },
        'reset format quote': {
          key: 'ENTER',
          collapsed: true,
          format: ['blockquote'],
          empty: true,
          handler(range) {
            this.quill.formatLine(range.index, range.length, 'blockquote', false, 'user');
          },
        },
        'reset format quote backspace': {
          key: 'BACKSPACE',
          empty: true,
          format: ['blockquote'],
          handler() {
            this.quill.format('blockquote', false, 'user');
          },
        },
        'reset format image': {
          key: 'ENTER',
          format: ['image'],
          handler({ index, length }) {
            if (length > 0) {
              this.quill.deleteText(index, length, 'user');
            }
            this.quill.insertText(index, '\n', 'user');
          },
        },
        'set align': {
          key: 'A',
          altKey: true,
          handler: (range, context) => {
            switch (context.format.align) {
              case undefined:
                this.align('center', range);
                break;
              case 'center':
                this.align('right', range);
                break;
              case 'right':
                this.align('justify', range);
                break;
              case 'justify':
                this.align('left', range);
                break;
              default:
            }
          },
        },
        outdent: {
          key: 'tab',
          shiftKey: true,
          collapsed: true,
          format: ['indent'],
          handler(range, { format }) {
            this.quill.formatLine(range.index, range.length, 'indent', format.indent - 1, 'user');
          },
        },
        'format strike': {
          key: 'S',
          shortKey: true,
          format: { strike: false },
          handler() {
            this.quill.format('strike', true, 'user');
          },
        },
        'remove strike': {
          key: 'S',
          shortKey: true,
          format: { strike: true },
          handler() {
            this.quill.format('strike', false, 'user');
          },
        },
        'prevent double space pre': {
          key: ' ',
          prefix: /\s$/,
          handler(range) {
            this.quill.deleteText(range.index - 1, 1);
            return true;
          },
        },
        'prevent double space suf': {
          key: ' ',
          suffix: /^\s/,
          handler(range) {
            this.quill.deleteText(range.index, 1);
            return true;
          },
        },
        'insert link': {
          key: 'K',
          shortKey: true,
          collapsed: false,
          handler: this.insertLink,
        },
        linebreak: {
          key: 'ENTER',
          shiftKey: true,
          empty: false,
          format: {
            list: false,
            header: false,
          },
          handler(range) {
            this.quill.insertText(range.index, '\n', 'user');
            this.quill.setSelection(range.index + 1, 'silent');
            this.quill.format('linebreak', true, 'user');
          },
        },
        paragraph: {
          key: 'ENTER',
          empty: false,
          format: ['linebreak'],
          handler() {
            this.quill.format('linebreak', false, 'user');
            return true;
          },
        },
        'add image': {
          key: 'I',
          ctrlKey: true,
          shiftKey: true,
          handler: this.insertImage,
        },
        'force toolbar': {
          key: ' ',
          ctrlKey: true,
          handler: () => {
            if (this.showToolbar) this.showToolbar = false;
            else this.toggleToolbar(true);
          },
        },
      },
      urlInputTransformCache: null,
      linkUrl: '',
      quill: null,
      scrolling: false,
      selectionBounds: {},
      showImageInput: false,
      showLinkInput: false,
      showToolbar: false,
      toolbarActionsCache: [],
      toolbarTransformCache: null,
      turndownService: null,
    };
  },
  methods: {
    align(alignment, currentRange) {
      const range = currentRange || this.quill.getSelection();
      if (!range) return;
      if (alignment === 'left') {
        this.quill.formatLine(range.index, range.length, 'align', false, 'user');
        this.currentAlign = 'left';
      } else {
        this.quill.formatLine(range.index, range.length, 'align', alignment, 'user');
        this.currentAlign = alignment;
      }
      this.toggleToolbar(true);
    },
    autoreplacements(range) {
      if (!range) return;
      const [leafStart, offsetStart] = this.quill.getLeaf(range.index);
      const prefixText = leafStart instanceof Parchment.Text ? leafStart.value().slice(0, offsetStart) : '';
      const leafIndex = this.quill.getIndex(leafStart);
      const format = this.quill.getFormat(range);
      const autoreplacements = [
        { pattern: /--/, substitute: '–' }, // en dash
        { pattern: /–-/, substitute: '—' }, // em dash
        { pattern: /\.{3}/, substitute: '…' }, // ellipsis
        // { pattern: /(?<!\S)"/, substitute: '“' }, // double quote open (using lookbehind which isn’t supported everywhere yet
        // { pattern: /(?<=\S)"/, substitute: '”' }, // double quote close (using lookbehind which isn’t supported everywhere yet
        // { pattern: /(?<!\S)'/, substitute: '‘' }, // single quote open (using lookbehind which isn’t supported everywhere yet
        // { pattern: /(?<=\S)'/, substitute: '’' }, // single quote close (using lookbehind which isn’t supported everywhere yet
        { pattern: /\s"/, substitute: '“', lookbehindWorkaround: true }, // double open after space
        { pattern: /^"/, substitute: '“' }, // double open at start
        { pattern: /\s'/, substitute: '‘', lookbehindWorkaround: true }, // single open after space
        { pattern: /^'/, substitute: '‘' }, // single open at start
        { pattern: /\S"/, substitute: '”', lookbehindWorkaround: true }, // double close
        { pattern: /\S'/, substitute: '’', lookbehindWorkaround: true }, // single close
      ];
      let match = null;

      autoreplacements.forEach((replacement) => {
        match = prefixText.match(replacement.pattern);
        if (match) {
          const index = leafIndex + match.index;
          if (!replacement.lookbehindWorkaround) {
            this.quill.updateContents(new Delta().retain(index).delete(match[0].length).insert(replacement.substitute, format), 'user');
            if (replacement.substitute.length < match[0].length) this.$nextTick(() => this.quill.setSelection(index + replacement.substitute.length));
          } else this.quill.updateContents(new Delta().retain(index).delete(match[0].length).insert(`${match[0][0]}${replacement.substitute}`, format), 'user'); // HACK: reinsert the deleted character
        }
      });
    },
    emitBookmark() {
      const { index, length } = this.quill.getSelection();
      if (typeof index !== 'undefined') this.$emit('bookmark', { index, length });
    },
    emitQuote() {
      const { index, length } = this.quill.getSelection();
      if (typeof index !== 'undefined' && length > 0) this.$emit('quote', { text: this.quill.getText(index, length), position: { index, length } });
    },
    format(format) {
      const range = this.quill.getSelection();
      if (!range) return;
      if (!this.quill.getFormat().hasOwnProperty(format)) { // eslint-disable-line no-prototype-builtins
        this.quill.format(format, true, 'user');
        this.activeFormats[format] = true;
      } else {
        this.quill.format(format, false, 'user');
        this.activeFormats[format] = false;
      }
    },
    handleBlur(e) {
      if (e.type === 'focusout' && !this.$el.contains(e.relatedTarget)) {
        if (this.$refs.toolbar && this.$refs.toolbar.$el.contains(e.target)) this.quill.focus();
        else {
          this.debouncedValueUpdate.flush();
          this.$nextTick(() => {
            this.$emit('blur');
            this.focussed = false;
            this.showToolbar = false;
            this.showLinkInput = false;
            this.showImageInput = false;
            window.removeEventListener('scroll', this.scrollStart, true);
            window.visualViewport.removeEventListener('scroll', this.scrollStart, true);
            window.removeEventListener('scroll', this.scrollStop, true);
            window.visualViewport.removeEventListener('scroll', this.scrollStop, true);
          });
        }
      } else if (e.type === 'internal') {
        this.focussed = false;
        this.showToolbar = false;
        window.removeEventListener('scroll', this.scrollStart, true);
        window.removeEventListener('scroll', this.scrollStop, true);
        window.visualViewport.removeEventListener('scroll', this.scrollStart, true);
        window.visualViewport.removeEventListener('scroll', this.scrollStop, true);
        if (this.disabled) window.removeEventListener('click', this.handleOutsideClick);
      }
    },
    handleFocus() {
      if (!this.focussed && !this.disabled) {
        this.focussed = true;
        this.$emit('focus');
      }
      window.addEventListener('scroll', this.scrollStart, true);
      window.addEventListener('scroll', this.scrollStop, true);
      window.visualViewport.addEventListener('scroll', this.scrollStart, true);
      window.visualViewport.addEventListener('scroll', this.scrollStop, true);
      if (this.disabled) window.addEventListener('click', this.handleOutsideClick);
    },
    handleChangePaste(node, delta) {
      const text = node.innerText.replace(/¶/g, '');
      return new Delta().insert(text, {
        ...delta.ops[0].attributes, inserted: false, deleted: false, changed: false,
      });
    },
    handleHeadingPaste(node, delta) {
      return delta.compose(new Delta().retain(delta.length(), { header: '2' }));
    },
    handleOutsideClick(e) {
      if (!this.$el.contains(e.target)) this.quill.setSelection(null);
    },
    handleParagraphPaste(node, delta) {
      if (delta.ops[0] && delta.ops[0].insert && delta.ops[0].insert === '\t') {
        return delta.compose(new Delta().delete(1));
      }
      return delta;
    },
    handleTextPaste(node, delta) {
      if (node.data === '\n') return delta; // otherwise paragraph styles (lists, quotes) get duplicated
      const text = node.data
        .replace(/--/g, '–')
        .replace(/–-/g, '—')
        .replace(/\.{3}/g, '…')
        // .replace(/(?<!\S)"(?=\S)/g, '“') // impossible without lookbehind support
        .replace(/^"|(\s)"/g, '$1“') // workaround
        .replace(/"/g, '”')
        // .replace(/(?<!\S)'(?=\S)/g, '‘') // impossible without lookbehind support
        .replace(/^'|(\s)'/g, '$1‘') // workaround
        .replace(/'/g, '’');
      return new Delta().insert(text);
    },
    handleLineFormat(value) {
      this.currentParagraphType = value;
      const selection = this.quill.getSelection(true);

      switch (value) {
        case 'p':
          this.quill.formatLine(selection.index, selection.length, {
            header: false, list: false, linebreak: false, blockquote: false,
          }, 'user');
          this.currentParagraphType = 'p';
          break;
        case 'br':
          this.quill.formatLine(selection.index, selection.length, 'linebreak', true, 'user');
          this.currentParagraphType = 'br';
          break;
        case 'blockquote':
          this.quill.formatLine(selection.index, selection.length, 'blockquote', true, 'user');
          this.currentParagraphType = 'blockquote';
          break;
        case 'h1':
          this.quill.formatLine(selection.index, selection.length, 'header', 1, 'user');
          this.currentParagraphType = 'h1';
          break;
        case 'h2':
          this.quill.formatLine(selection.index, selection.length, 'header', 2, 'user');
          this.currentParagraphType = 'h2';
          break;
        case 'ol':
          this.quill.formatLine(selection.index, selection.length, 'list', 'ordered', 'user');
          this.currentParagraphType = 'ol';
          break;
        case 'ul':
          this.quill.formatLine(selection.index, selection.length, 'list', 'bullet', 'user');
          this.currentParagraphType = 'ul';
          break;
        default:
      }
      this.toggleToolbar(true);
    },
    handleURLInputMousedown(e) {
      if (e.target.tagName.toLowerCase() !== 'input') e.preventDefault();
    },
    hideUrlInput(which) {
      if (which === 'linkInput') this.showLinkInput = false;
      if (which === 'imageInput') this.showImageInput = false;
      this.quill.focus();
    },
    insertImage() {
      if (!this.showImageInput) this.showImageInput = true;
      else if (!this.imageUrlInvalid) {
        const range = this.quill.getSelection(true);
        const { align } = this.quill.getFormat();
        this.quill.insertText(range.index, '\n', Quill.sources.USER);
        this.quill.insertEmbed(range.index + 1, 'image', {
          alt: 'Image Not Found',
          url: this.imageUrl,
        }, Quill.sources.USER);
        this.quill.formatLine(range.index + 1, 1, 'align', align || false, Quill.sources.USER);
        this.quill.setSelection(range.index + 2, Quill.sources.SILENT);

        this.imageUrl = '';
        this.showImageInput = false;
      }
    },
    insertLink() {
      if (!this.showLinkInput) this.showLinkInput = true;
      else if (!this.urlInvalid) {
        let { linkUrl } = this; // because it gets cleared when we update the selection
        linkUrl = linkUrl.trim();
        if (!linkUrl.startsWith('/') && !linkUrl.startsWith('http') && !linkUrl.startsWith('mailto') && !linkUrl.startsWith('tel') && linkUrl.indexOf('.') !== -1) linkUrl = `http://${linkUrl}`;
        const selection = this.quill.getSelection(true);
        if (selection.length > 0) {
          this.quill.format('link', linkUrl || false);
          if (linkUrl) this.activeFormats.link = true;
        }
        this.linkUrl = '';
        this.showLinkInput = false;
      }
    },
    insertSeparator() {
      const range = this.quill.getSelection();
      if (!range) return;

      const { align } = this.quill.getFormat();
      if (this.quill.getText(range.index, 1) !== '\n') this.quill.insertText(range.index, '\n', Quill.sources.USER);
      this.quill.insertEmbed(range.index + 1, 'separator', true, Quill.sources.USER);
      if (this.quill.getText(range.index + 2) === '') {
        this.quill.insertText(range.index + 2, '\n', Quill.sources.USER);
        this.quill.setSelection(range.index + 3, Quill.sources.SILENT);
      } else this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
      if (align) this.quill.format('align', align);
    },
    markdownToDelta(md) {
      // turn br into custom linebreak, remove paragraphs around images (inserted by markdown it)
      const html = markdown(md).replace(/<br>/g, '</p><p class="linebreak-true">').replace(/<p>\s*(<img .*>)\s*<\/p>/g, '$1').replace(/\n/g, '');

      const delta = this.quill.clipboard.convert(html);

      return delta;
    },
    onSelectionChange(name, range, oldRange) {
      const selection = this.quill.getSelection();
      if (selection) this.selectionBounds = this.quill.getBounds(selection.index, selection.length); // get bounds for caret, ect

      if (name === 'selection-change') {
        if (range && oldRange === null) {
          // focus
          this.handleFocus();
        } else if (range === null && oldRange) {
          // blur
          this.handleBlur({ type: 'internal' });
          return;
        }

        this.$emit('selectionchange', range);

        const activeFormats = this.quill.getFormat();

        if (activeFormats.header) this.currentParagraphType = `h${activeFormats.header}`;
        else if (activeFormats.list === 'ordered') this.currentParagraphType = 'ol';
        else if (activeFormats.list === 'bullet') this.currentParagraphType = 'ul';
        else if (activeFormats.linebreak) this.currentParagraphType = 'br';
        else if (activeFormats.blockquote) this.currentParagraphType = 'blockquote';
        else if (activeFormats.image) this.currentParagraphType = 'img';
        else this.currentParagraphType = 'p';

        // activeFormats.link might be an array if a word in a link got re-formatted with a different link
        if (activeFormats.link) this.linkUrl = Array.isArray(activeFormats.link) ? activeFormats.link[0] : activeFormats.link;
        else this.linkUrl = '';

        const trackedFormats = ['bold', 'italic', 'link', 'strike', 'underline'];
        trackedFormats.forEach((format) => {
          this.activeFormats[format] = activeFormats[format] || false;
        });

        if (activeFormats.align) this.currentAlign = activeFormats.align;
        else this.currentAlign = 'left';

        this.scrollSelectionIntoView(range);
        this.toggleToolbar();

        if (this.showLinkInput) this.showLinkInput = false;
        if (this.showImageInput) this.showImageInput = false;
      }
    },
    onTextChange() {
      const range = this.quill.getSelection();
      if (range) this.autoreplacements(range);
      if (!this.disabled) {
        this.debouncedTypingStart();
        this.debouncedValueUpdate();
      }
    },
    removeLink() {
      const selection = this.quill.getSelection(true);
      if (selection.length > 0) this.quill.format('link', false);
      this.linkUrl = '';
      this.activeFormats.link = false;
    },
    scrollSelectionIntoView(range) {
      if (!range || (this.disabled && range.length === 0)) return;

      const editorBounds = this.$el.getBoundingClientRect();
      const bounds = this.quill.getBounds(range.index, range.length);
      const keyboardHeight = window.visualViewport.height - document.getElementById('layoutViewport').getBoundingClientRect().height + window.visualViewport.offsetTop;

      if (!this.scrollingContainer) {
        const threshold = 2 * this.remBase;
        const offset = this.$el.scrollTop;
        const visibleEditorHeight = editorBounds.top + (editorBounds.height - keyboardHeight);
        const selectionScreenYPosition = bounds.bottom + (editorBounds.top - offset);
        const scrollAmount = this.mode === 'markdown' ? 4.5 * this.remBase : 8 * this.remBase;

        if (selectionScreenYPosition > visibleEditorHeight - threshold) this.$el.scrollTo(0, (selectionScreenYPosition + offset + scrollAmount) - visibleEditorHeight);
      } else {
        const threshold = 4 * this.remBase;
        const scrollingContainer = document.querySelector(this.scrollingContainer);
        const offset = scrollingContainer.scrollTop;
        const visibleEditorHeight = window.visualViewport.height - Math.max(editorBounds.top, 0);

        const relativeBoundsBottom = bounds.bottom + Math.min(editorBounds.top, 0);
        const relativeBoundsTop = bounds.top + Math.min(editorBounds.top, 0);

        if (relativeBoundsBottom > visibleEditorHeight - threshold) scrollingContainer.scrollTo(0, offset + ((relativeBoundsBottom + 8 * this.remBase) - visibleEditorHeight));
        else if (relativeBoundsTop < 0) scrollingContainer.scrollTo(0, offset + (relativeBoundsTop - 8 * this.remBase));
      }
    },
    scrollStart: debounce(function handleScrollStart(e) {
      if (e.target instanceof HTMLElement && this.$refs.toolbar.$el.contains(e.target)) return;
      if (this.showLinkInput || this.showImageInput) this.quill.focus();
      this.scrolling = true;
    }, 150, { leading: true, trailing: false }),
    scrollStop: debounce(function handleScrollStop(e) {
      if (e.target instanceof HTMLElement && e.target === this.$refs.toolbar.$el) return;
      this.scrolling = false;
    }, 150),
    setUpQuill() {
      const editorElement = this.$el.querySelector('.editor-element');
      const scrollingContainer = this.scrollingContainer ? document.querySelector(this.scrollingContainer) || this.$el : this.$el;

      const LineBreakClass = new Parchment.Attributor.Class('linebreak', 'linebreak', {
        scope: Parchment.Scope.BLOCK,
      });

      const InsertedClass = new Parchment.Attributor.Class('inserted', 'inserted', {
        scope: Parchment.Scope.INLINE,
      });

      const DeletedClass = new Parchment.Attributor.Class('deleted', 'deleted', {
        scope: Parchment.Scope.INLINE,
      });

      const ChangedClass = new Parchment.Attributor.Class('changed', 'changed', {
        scope: Parchment.Scope.INLINE,
      });

      class SeaparatorBlot extends BlockEmbed { }
      SeaparatorBlot.blotName = 'separator';
      SeaparatorBlot.tagName = 'hr';

      class ImageBlot extends BlockEmbed {
        static create(value) {
          const node = super.create();
          node.setAttribute('alt', value.alt);
          node.setAttribute('src', value.url);
          return node;
        }

        formats() {
          const formats = this.attributes.values();
          formats.image = true;
          return formats;
        }

        static value(node) {
          return {
            alt: node.getAttribute('alt'),
            url: node.getAttribute('src'),
          };
        }
      }
      ImageBlot.blotName = 'image';
      ImageBlot.tagName = 'img';

      class NoTargetLink extends Link { // based on https://github.com/quilljs/quill/issues/1139
        static create(value) {
          const node = super.create(value);
          if (!/^https?/.test(value)) {
            node.removeAttribute('target');
          }
          return node;
        }

        format(name, value) {
          super.format(name, value);

          if (name !== this.statics.blotName || !value) return;

          if (/^https?/.test(value)) this.domNode.setAttribute('target', '_blank');
          else this.domNode.removeAttribute('target');
        }
      }

      Quill.register('formats/linebreak', LineBreakClass, true);
      Quill.register('formats/inserted', InsertedClass, true);
      Quill.register('formats/deleted', DeletedClass, true);
      Quill.register('formats/changed', ChangedClass, true);
      Quill.register(SeaparatorBlot, true);
      Quill.register(ImageBlot, true);
      Quill.register(NoTargetLink, true);

      if (this.disableTab) {
        this.keybindings.tab = {
          key: 9,
          handler() {
            return true;
          },
        };
      }

      if (this.disableDeletion) {
        this.keybindings.backspace = {
          key: 'backspace',
          handler() {
            return false;
          },
        };
        this.keybindings.delete = {
          key: 'delete',
          handler() {
            return false;
          },
        };
        this.keybindings.ctrlBackspace = {
          key: 'backspace',
          shortKey: true,
          handler() {
            return false;
          },
        };
        this.keybindings.ctrlDelete = {
          key: 'delete',
          shortKey: true,
          handler() {
            return false;
          },
        };
        this.keybindings.cut = {
          key: 'x',
          shortKey: true,
          handler() {
            return false;
          },
        };
        this.keybindings.paste = {
          key: 'v',
          collapsed: false,
          shortKey: true,
          handler() {
            return false;
          },
        };
      }

      this.quill = new Quill(editorElement, {
        formats: this.formats.concat(['inserted', 'deleted', 'changed']),
        modules: {
          clipboard: {
            matchVisual: false,
            matchers: [
              ['h3', this.handleHeadingPaste],
              ['h4', this.handleHeadingPaste],
              ['h5', this.handleHeadingPaste],
              ['h6', this.handleHeadingPaste],
              ['p', this.handleParagraphPaste],
              ['.changed-true', this.handleChangePaste],
              ['.deleted-true', this.handleChangePaste],
              ['.inserted-true', this.handleChangePaste],
              [Node.TEXT_NODE, this.handleTextPaste],
            ],
          },
          history: {
            maxStack: this.disableHistory ? 0 : 100,
            userOnly: true,
          },
          keyboard: {
            bindings: this.keybindings,
          },
        },
        placeholder: this.placeholder,
        readOnly: this.disabled,
        scrollingContainer,
      });

      this.quill.on('editor-change', this.onSelectionChange);
      this.quill.on('text-change', this.onTextChange);
    },
    toggleToolbar(force) {
      const { index, length } = this.quill.getSelection(force);

      if (typeof index !== 'undefined' && (length > 0 || force)) {
        this.showToolbar = true;
        this.scrollSelectionIntoView({ index, length });
      } else this.showToolbar = false;
    },
    visualiseChanges() {
      if (!this.disabled || !this.originalContent) return;
      const currentContent = this.quill.getContents();
      const originalContent = typeof this.originalContent === 'string' ? this.markdownToDelta(this.originalContent) : new Delta(this.originalContent);
      const diff = originalContent.diff(currentContent);

      /* eslint-disable no-param-reassign */
      diff.ops.forEach((change) => {
        if (change.insert) change.attributes = { ...change.attributes, inserted: true };
        if (change.retain && change.attributes) change.attributes = { changed: true };
        if (change.delete) {
          change.retain = change.delete;
          change.attributes = { deleted: true };
          delete change.delete;
        }
      });
      /* eslint-enable no-param-reassign */
      const changes = originalContent.compose(diff);
      this.quill.setContents(changes, 'silent');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setUpQuill();
      if (this.value) {
        if (this.mode === 'markdown') this.quill.setContents(this.markdownToDelta(this.value));
        else this.quill.setContents(this.value);
      }
      this.$emit('mounted');
    });
  },
  props: {
    bookmarkable: Boolean,
    dark: Boolean,
    disabled: Boolean,
    disableTab: Boolean,
    disableDeletion: Boolean,
    disableHistory: Boolean,
    formats: {
      type: Array,
      default() {
        return [
          'align',
          'blockquote',
          'bold',
          'header',
          'image',
          'indent',
          'italic',
          'linebreak',
          'link',
          'list',
          'separator',
          'strike',
          'underline',
        ];
      },
    },
    indentedParagraphs: Boolean,
    mode: {
      type: String,
      default: 'markdown',
      validator(v) {
        return ['markdown', 'delta'].includes(v);
      },
    },
    originalContent: [Array, String], // a Quill ops-array, or a markdown string
    placeholder: {
      type: String,
      default: 'Write something…',
    },
    quotable: Boolean,
    selection: Array,
    showChanges: Boolean,
    scrollingContainer: String,
    value: [String, Object],
  },
  watch: {
    selection(newVal) {
      if (newVal.length > 0) {
        let [index, length = 0] = newVal;
        index = parseInt(index, 10);
        length = parseInt(length, 10);
        if (index >= 0) {
          window.setTimeout(() => this.quill.setSelection(index, length, 'silent'), 0); // required for the selection to actually happen
        }
      } else if (!this.focussed) this.quill.setSelection(null, 'silent');
    },
    showChanges(newVal) {
      if (newVal) this.visualiseChanges();
      else if (this.mode === 'markdown') this.quill.setContents(this.markdownToDelta(this.value), 'silent');
      else this.quill.setContents(this.value, 'silent');
    },
    showLinkInput(newVal) {
      if (newVal) this.$nextTick(this.$refs.linkInput.focus);
    },
    showImageInput(newVal) {
      if (newVal) this.$nextTick(this.$refs.imageInput.focus);
    },
    showToolbar(newVal) {
      if (!this.mobile) return;
      if (newVal) this.$store.commit('setShowActionBars', false);
      else this.$store.commit('setShowActionBars', true);
    },
    value(newVal) {
      if (!this.focussed && this.quill) {
        if (this.mode === 'markdown') this.quill.setContents(this.markdownToDelta(newVal), 'silent');
        else this.quill.setContents(newVal, 'silent');
      }
    },
  },
};
</script>

<style lang="stylus">
@require '../styles/colors'
@require '../styles/shadows'

@require '../styles/quill-core'

.editor
  position: relative
  cursor: text
  overflow-y: auto
  overflow-x: hidden
  // background-color: $bg // should be fine being transparent, unless there’s issues with scrollbars somewhere
  scroll-behavior: smooth

  &.prose
    font-family: 'PT Serif', serif
    padding-bottom: 8rem
    line-height: 1.5
    font-size: 1.125rem

    &.indented
      p
        margin-top: 0
        text-indent: 1.125rem

      hr + p,
      h1 + p,
      h2 + p,
      img + p,
      blockquote + p,
      ul + p,
      ol + p,
      p:first-child,
      p.linebreak-true
        text-indent: 0

    h1,
    h2
      color: inherit
      font-weight: normal

    h1
      font-size: 2rem

    h2 + h1
      margin-top: -1rem

    h2
      font-size: 1.5rem

    .input
      font-family: 'Nunito'

  &:not(.prose)
    img
      margin-left: auto
      margin-right: auto

  &:not(.mobile) .editor-element
    caret-color: transparent

  &.dark
    // background-color: $bg-dark // should be fine being transparent, unless there’s issues with scrollbars somewhere

    .editor-element .ql-editor.ql-blank::before
      color: $text-secondary-dark

  .editor-element
    *
      user-select: text

    .ql-editor
      &.ql-blank::before
        font-style: normal
        left: 0
        color: $text-secondary

      .inserted-true
        color: $positive

      .deleted-true
        color: alpha($negative, 0.60)
        position: relative

        &::after
          content: ''
          position: absolute
          top: 50%
          left: 0
          height: 0.125rem
          width: 100%
          background-color: @color
          transform: translateY(-50%)

      .changed-true
        position: relative

        &::after
          content: ''
          position: absolute
          height: 0.125rem
          width: 100%
          background-color: $warning
          bottom: -0.125rem
          left: 0

  .toolbar
    position: fixed
    top: 0
    left: 0
    z-index: 2
    transform: translateX(-50%) translateY(0.5rem)
    cursor: default

    &:not(.no-transition):not(.mobile)
      transition: transform 200ms ease, box-shadow 200ms ease, background-color 200ms ease

    &.mobile
      top: auto
      transform: translateX(-50%)

  .input.url-input
    position: fixed
    top: 0
    left: 0
    z-index: 2
    transform: translateX(-50%) translateY(0.5rem)
    width: 16rem
    max-width: calc(100% - 0.75rem)
    transition: transform 200ms ease, box-shadow 200ms ease, background-color 200ms ease

    &.mobile
      position: fixed
      top: auto
      bottom: 1rem
      left: 50%
      max-width: calc(100vw - 2rem)
      transform: translateX(-50%)

  .fake-caret
    position: absolute
    top: 0
    left: 0
    z-index: 1
    width: 0.125rem
    min-height: 1rem
    border-radius: 0.0625rem
    background-color: currentColor
    pointer-events: none
    transition: transform 100ms ease-out
    animation: blink 1s ease infinite

    @keyframes blink
      0%
        opacity: 0
      50%
        opacity: 1
      100%
        opacity: 0
</style>

<template lang="html">
  <div class="player-card" :dark="dark">
    <img :src="(player && player.avatar) || '/img/default-avatar.png'" alt="Avatar not found">
    <span class="name">{{firstName(player && player.name || 'former player')}}</span>
    <USChip v-if="status" :content="status" :dark="dark" />
  </div>
</template>

<script>
import firstName from '@/filters/firstName';

export default {
  methods: {
    firstName,
  },
  props: {
    dark: Boolean,
    player: Object,
    status: String,
  },
};
</script>

<style lang="stylus" scoped>
.player-card
  display: flex
  align-items: center

  img
    margin: 0
    margin-right: 1rem
    width: 2.5rem
    height: @width
    border-radius: 50%
    flex-shrink: 0

  .name
    margin-right: 1rem
    text-transform: capitalize
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  .chip
    margin-left: auto
</style>

<template lang="html">
  <div class="color-picker" :class="{ active }" @focusin="activate" @focusout="handleFocusout">
    <div class="padder">{{label || value}}</div>
    <div class="spacer" :class="{ active, dark, 'from-bottom': fromBottom }" :style="{ transform: transform.transform, width: transform.width }" tabindex="0">
      <div class="color-and-label">
        <div class="color" :style="{ backgroundColor: value }" />
        <label>{{ label || value }}</label>
      </div>
      <div class="saturation-picker" ref="saturationPicker" :style="{ backgroundColor: saturationPickerBG }" @mousedown.left="activateSaturationPicker" @pointerdown="activateSaturationPicker" @touchstart.stop>
        <div class="saturation-white" />
        <div class="saturation-black" />
        <div v-show="active" class="picker" :style="{ top: saturationTop, left: saturationLeft }" />
      </div>
      <div class="hue-picker" ref="huePicker" @mousedown.left="activateHuePicker" @pointerdown="activateHuePicker" @touchstart.stop>
        <div class="hue" />
        <div v-show="active" class="picker" :style="{ left: hueLeft }" />
      </div>
      <USInput v-model="colorInput" :dark="dark" :disabled="!active" :error="isColorInvalid" icon="hashtag" label="Color" />
      <div class="presets">
        <span v-for="color in presets" :key="color" :style="{ backgroundColor: color }" @click="$emit('input', color)" />
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash-es';
import tinycolor from 'tinycolor2';

export default {
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, { passive: true, capture: true });
  },
  computed: {
    hueLeft() {
      return `${(this.workingColor.h / 360) * 100}%`;
    },
    isColorInvalid() {
      return tinycolor(this.colorInput).isValid() ? '' : 'Invalid color';
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
    saturationLeft() {
      return `${this.workingColor.s * 100}%`;
    },
    saturationPickerBG() {
      return `hsl(${this.workingColor.h}, 100%, 50%)`;
    },
    saturationTop() {
      return `${-this.workingColor.v * 100 + 100}%`;
    },
    transform() {
      if (!this.active) return {};
      const rect = this.active ? this.$el.getBoundingClientRect() : {};
      const x = Math.round(rect.left);
      const y = this.fromBottom ? Math.round(rect.bottom) : Math.round(rect.top);
      const translate = this.fromBottom ? `translate(${x}px, calc(${y}px - 100%))` : `translate(${x}px, ${y}px)`;
      return { transform: translate, width: `${rect.width}px` };
    },
  },
  data() {
    return {
      active: false,
      colorInput: this.value,
      fromBottom: false,
      workingColor: tinycolor(this.value).toHsv(),
    };
  },
  methods: {
    activate() {
      if (this.$el.getBoundingClientRect().top + this.remBase * (2.75 + 8 + 1.5 + 3.5 + 1.5 + 1.5 + 0.75) > window.innerHeight) this.fromBottom = true;
      else this.fromBottom = false;

      this.workingColor = tinycolor(this.value).toHsv(); // don’t update using the watcher but upon activation to avoid issues with the hue on desaturated colors

      window.addEventListener('scroll', this.handleScroll, { passive: true, capture: true });
      this.active = true;
    },
    activateHuePicker() {
      if (window.PointerEvent) {
        window.addEventListener('pointermove', this.handleHueInput, { passive: true });
        window.addEventListener('pointerup', this.deactivateHuePicker);
      } else {
        window.addEventListener('mousemove', this.handleHueInput, { passive: true });
        window.addEventListener('mouseup', this.deactivateHuePicker);
      }
    },
    activateSaturationPicker() {
      if (window.PointerEvent) {
        window.addEventListener('pointermove', this.handleSaturationInput, { passive: true });
        window.addEventListener('pointerup', this.deactivateSaturationPicker);
      } else {
        window.addEventListener('mousemove', this.handleSaturationInput, { passive: true });
        window.addEventListener('mouseup', this.deactivateSaturationPicker);
      }
    },
    clamp(value, min, max) {
      if (min < max) {
        if (value < min) return min;
        if (value > max) return max;
        return value;
      }
      if (value < max) return max;
      if (value > min) return min;
      return value;
    },
    deactivate() {
      window.removeEventListener('scroll', this.handleScroll, { passive: true, capture: true });
      this.active = false;
    },
    deactivateHuePicker(e) {
      this.handleHueInput(e);
      if (window.PointerEvent) {
        window.removeEventListener('pointermove', this.handleHueInput);
        window.removeEventListener('pointerup', this.deactivateHuePicker);
      } else {
        window.removeEventListener('mousemove', this.handleHueInput);
        window.removeEventListener('mouseup', this.deactivateHuePicker);
      }
    },
    deactivateSaturationPicker(e) {
      this.handleSaturationInput(e);
      if (window.PointerEvent) {
        window.removeEventListener('pointermove', this.handleSaturationInput);
        window.removeEventListener('pointerup', this.deactivateSaturationPicker);
      } else {
        window.removeEventListener('mousemove', this.handleSaturationInput);
        window.removeEventListener('mouseup', this.deactivateSaturationPicker);
      }
    },
    handleFocusout(e) {
      if (!this.$el.contains(e.relatedTarget)) this.deactivate();
    },
    handleHueInput: throttle(function (e) { // eslint-disable-line func-names
      const container = this.$refs.huePicker;
      const containerRect = container.getBoundingClientRect();

      const left = this.clamp(e.clientX - containerRect.left, 0, containerRect.width);
      const h = 360 * this.clamp(left / containerRect.width, 0, 360);
      const { s, v } = this.workingColor;

      const hexString = tinycolor({ h, s, v }).toHexString();

      this.workingColor.h = h;
      this.$emit('input', hexString);
    }, 20),
    handleSaturationInput: throttle(function (e) { // eslint-disable-line func-names
      const container = this.$refs.saturationPicker;
      const containerRect = container.getBoundingClientRect();

      const left = this.clamp(e.clientX - containerRect.left, 0, containerRect.width);
      const top = this.clamp(e.clientY - containerRect.top, 0, containerRect.height);

      const { h } = this.workingColor;
      const s = left / containerRect.width;
      const v = this.clamp(-(top / containerRect.height) + 1, 0, 1);
      const hexString = tinycolor({ h, s, v }).toHexString();

      this.workingColor.s = s;
      this.workingColor.v = v;
      this.$emit('input', hexString);
    }, 20),
    handleScroll() {
      this.deactivate();
      document.activeElement.blur();
    },
  },
  props: {
    dark: Boolean,
    label: String,
    presets: {
      type: Array,
      default: () => ['#C13C32', '#F2C337', '#17CB76', '#20A2F7', '#2980B9', '#B100CE'],
    },
    value: String,
  },
  watch: {
    colorInput(newVal) {
      if (!newVal || (newVal.startsWith('#') && newVal.length < 7)) return;
      const color = tinycolor(newVal);
      if (color.isValid()) {
        this.workingColor = color.toHsv();
        this.$emit('input', color.toHexString());
      }
    },
    workingColor: {
      handler(newVal) {
        this.colorInput = tinycolor(newVal).toHexString();
      },
      deep: true,
    },
    value(newVal) {
      this.colorInput = newVal;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.color-picker
  height: 3.5rem
  display: inline-block
  position: relative
  min-width: 11.625rem
  z-index: 2

  .padder
    padding: 1.0625rem
    padding-left: 4.125rem
    white-space: nowrap

  .spacer
    position: absolute
    width: 100%
    height: 3.5rem
    top: 0
    overflow: hidden
    padding: calc(0.375rem - 1px)
    border: 1px solid $interactable
    border-radius: 0.75rem
    box-shadow: $shadow-low
    background-color: $bg
    cursor: pointer
    transition: box-shadow 200ms ease, border-color 200ms ease, background-color 200ms ease, height 200ms ease

    &.dark
      background-color: $bg-dark
      border-color: $interactable-dark

      &:hover,
      &.active
        background-color: $elevation-primary-dark

    &:hover,
    &.active
      box-shadow: $shadow-high
      border-color: $accent-primary

    &.active
      height: (2.75+8+1.5+3.5+1.5+1.5+0.75)rem
      position: fixed;
      top: 0
      left: 0

    &.from-bottom:not(.active)
      top: auto
      bottom: 0

    .color-and-label
      display: flex
      align-items: center
      margin-bottom: 0.375rem
      padding-right: 0.625rem

      .color
        width: 2.75rem
        height: @width
        border-radius: 0.375rem
        margin-right: 1rem

      label
        cursor: inherit

    .saturation-picker
      position: relative
      height: 8rem
      margin-bottom: 0.375rem
      border-radius: 0.375rem
      touch-action: none

      .saturation-white,
      .saturation-black
        position: absolute
        top: 0
        left: @top
        right: @top
        bottom: @top
        border-radius: 0.375rem
        pointer-events: none

      .saturation-white
        background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));

      .saturation-black
        background-image: linear-gradient(to top, #000, rgba(0,0,0,0));

    .hue-picker
      position: relative
      height: 1.5rem
      margin-bottom: 0.375rem
      touch-action: none

      .hue
        height: 100%
        border-radius: 0.375rem
        background-image: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
        pointer-events: none

    .picker
      border: 0.125rem solid white
      width: 1rem
      height: @width
      border-radius: 0.5rem
      position: absolute
      left: 0
      top: 50%
      transform: translate(-50%, -50%)
      box-shadow: $shadow-low

    .input
      border-radius: 0.375rem
      box-shadow: none
      margin-bottom: 0.375rem
      width: 100%

    .presets
      height: 1.5rem
      display: flex

      span
        display: inline-block
        width: 100%
        min-width: 1.5rem
        border-radius: 0.375rem
        cursor: pointer

        &:not(:last-child)
          margin-right: 0.375rem
</style>

<template lang="html">
  <USView class="settings" :dark="dark" show-back>
    <h1>Settings</h1>
    <h2>Email</h2>
    <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="Email Address" type="email" @blur="validate('email')" />
    <USInput v-model="password.old" :dark="dark" :error="errors.oldPassword" icon="lock-closed" label="Current Password" type="password" @blur="validate('oldPassword')" />
    <USButton :dark="dark" :disabled="Boolean(errors.email || errors.oldPassword)" icon-right="send" :loading="loading.email" @click="changeEmail">Change Email</USButton>
    <h2>Password</h2>
    <USInput v-model="password.old" :dark="dark" :error="errors.oldPassword" icon="lock-closed" label="Current Password" type="password" @blur="validate('oldPassword')" />
    <USInput v-model="password.new" :dark="dark" :error="errors.newPassword" icon="key" label="New Password" type="password" @blur="validate('password')" />
    <USInput v-model="password.confirm" :dark="dark" :error="errors.passwordConfirmation" icon="key" label="Confirm New Password" type="password" @blur="validate('passwordConfirmation')" />
    <USButton :dark="dark" :disabled="Boolean(errors.newPassword || errors.passwordConfirmation || errors.oldPassword)" icon-right="send" :loading="loading.password" @click="changePassword">Change Password</USButton>
    <h2>Theme</h2>
    <USTabBar v-model="theme" :dark="dark" :tabs="[{ label: 'Auto', value: 'auto' }, { label: 'Light', value: 'light' }, { label: 'Dark', value: 'dark' }]" />
    <h2>Interface Scaling</h2>
    <USSlider v-model.number="remBase" :dark="dark" :max="32" :min="16" />
    <h2>Push Notifications</h2>
    <p>This refers to the notifications you can get on your desktop and mobile computing device if you use a supported browser. You will get notifications on the site regardless of these settings.</p>
    <p v-if="pushNotificationsUnsupported" class="unsupported"><strong>Note:</strong> it looks like your browser / device doesn’t support push notifications or you have blocked them permanently.</p>
    <label>
      Enable Push Notifications on this device
      <USSwitch v-model="enableNotifications" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <h2>Receive Push Notifications for:</h2>
    <label>
      Replies to Your Comments
      <USSwitch v-model="pushNotifications.commentReplies" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      Likes on Your Comments
      <USSwitch v-model="pushNotifications.commentLikes" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      Comments on Your Posts
      <USSwitch v-model="pushNotifications.comments" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      Favourites on Your Posts
      <USSwitch v-model="pushNotifications.favourites" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      Likes on Your Posts
      <USSwitch v-model="pushNotifications.likes" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      New Forum Threads
      <USSwitch v-model="pushNotifications.forum" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      Replies in Forum Threads
      <USSwitch v-model="pushNotifications.forumReplies" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      New Events
      <USSwitch v-model="pushNotifications.newEvents" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <label>
      New Writing Prompts
      <USSwitch v-model="pushNotifications.writingPrompts" :dark="dark" :disabled="pushNotificationsUnsupported" />
    </label>
    <h2>Filter Posts</h2>
    <p>If for any reason you would not like to see the posts of a specific author or containing specific tags, you may add their name or those tags in the inputs below. This will make sure their posts don’t appear for you anywhere <strong>except</strong> the <strong>To Be Discussed</strong> tab, and, for filtered authors, their own profile page.</p>
    <USChipInput v-model="filteredAuthors" :dark="dark" field-to-search="name" icon="hide" label="Authors to filter out" :max-len="maxFilteredAuthors" model="users" @input="changeFilteredAuthors" />
    <USChipInput v-model="filteredTags" allow-not-suggested :dark="dark" icon="hide" label="Tags to filter out" :max-len="maxFilteredTags" @input="changeFilteredTags" />
    <h2>See What’s New</h2>
    <p>Whenever the app updates, you will see a detailed log of what changed, but perhaps you didn’t have time to read it in that moment. Here’s your chance to do so whenever you want to.</p>
    <USButton :dark="dark" @click="$emit('show-changelog')">Show Changelog</USButton>
    <template v-if="installationAvailable">
      <h2>Install the app</h2>
      <p>You can install the official Untold Stories app on your device! This will make it open in its own window independent from your browser and add an icon to your homescreen or desktop for easier access.</p>
      <p v-if="onIOS">To do so, simply click the <strong>share icon</strong> in your browser and select <strong>“Add to homescreen”</strong>.</p>
      <USButton v-else :dark="dark" primary @click="installApp">Install Now</USButton>
    </template>
    <h2>Delete Account</h2>
    <USButton color="danger" :dark="dark" icon-left="delete" :loading="loading.accountDelete" primary @click="showAccountDelete = true">Delete Account</USButton>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: deleteAccount, disabled: Boolean(!password.old || errors.oldPassword), icon: 'delete', label: 'delete account',
      }]"
      :dark="dark"
      title="Delete Account"
      :visible="showAccountDelete"
      @close="showAccountDelete = false">
      <p>You’re about to <strong>permanently</strong> delete your account on Untold Stories. This will <strong>remove all your posts</strong> and cause your other interactions on the website to appear as “Deleted User”. We cannot remove those for functionality reasons, but they won’t be able to be traced back to you.</p>
      <p>If you would like to export your posts before you delete your account, please come to one of our meetings or <a href="mailto:info@untoldstoriesmuc.de">contact us</a>.</p>
      <p>We’re sad to see you go, but hope you enjoyed your time here. Please enter your current password to confirm your identity:</p>
      <USInput v-model="password.old" :dark="dark" :error="errors.oldPassword" icon="lock-closed" label="Current Password" type="password" @blur="validate('oldPassword')" />
      <p><strong>Please keep in mind that this step cannot be undone!</strong></p>
    </USModal>
  </USView>
</template>

<script>
import isIOS from '@/scripts/isIOS';
import urlBase64ToUint8Array from '@/scripts/urlBase64ToUint8Array';

export default {
  computed: {
    installationAvailable() {
      return (isIOS() && !window?.navigator?.standalone) || this.$store.state.application.installPrompt;
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    onIOS() {
      return isIOS();
    },
    pushNotificationsUnsupported() {
      return !('serviceWorker' in navigator) || !('PushManager' in window) || (Notification && Notification.permission === 'denied') || !this.$store.state.application.serviceWorker;
    },
    remBase: {
      get() {
        return this.$store.state.application.remBase;
      },
      set(v) {
        this.$store.commit('setRemBase', v);
      },
    },
  },
  async created() {
    const sub = await this.fetchSubscription();

    if (sub) {
      this.enableNotifications = true;
      Object.keys(this.pushNotifications).forEach((key) => {
        if (sub.notificationsFor.includes(key)) this.pushNotifications[key] = true;
      });
    }

    const rawFilteredAuthors = this.$store.state.user.filteredAuthors;

    if (rawFilteredAuthors && rawFilteredAuthors.length) {
      try {
        const { data: users } = await this.$feathers.service('users').find({ query: { _id: { $in: rawFilteredAuthors }, $select: ['_id', 'name'], $limit: this.maxFilteredAuthors } });
        this.filteredAuthors = users.map((user) => ({ label: user.name, value: user._id }));
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch filtered authors: ${err.message}`, type: 'negative' });
      }
    }

    this.filteredTags = [...this.$store.state.user.filteredTags];

    await this.$nextTick();
    this.initialized = true;
  },
  data() {
    return {
      email: this.$store.state.user.email,
      enableNotifications: false,
      errors: {
        email: '',
        newPassword: '',
        oldPassword: '',
        passwordConfirmation: '',
      },
      filteredAuthors: [],
      filteredTags: [],
      initialized: false,
      loading: {
        deleteAccount: false,
        email: false,
      },
      maxFilteredAuthors: 8,
      maxFilteredTags: 16,
      password: {
        old: '',
        new: '',
        confirm: '',
      },
      pushNotifications: {
        commentReplies: false,
        commentLikes: false,
        comments: false,
        favourites: false,
        forum: false,
        forumReplies: false,
        likes: false,
        newEvents: false,
        writingPrompts: false,
      },
      showAccountDelete: false,
      theme: this.$store.state.user.darkThemePreference,
      updateNotificationSettingsTimeout: null,
    };
  },
  methods: {
    async changeEmail() {
      this.validate('email');
      this.validate('oldPassword');

      if (this.errors.email || this.errors.oldPassword) return;

      try {
        await this.$feathers.service('authmanagement').create({
          action: 'change-email',
          email: this.email,
          oldEmail: this.$store.state.user.email,
          password: this.password.old,
        });
        this.$store.commit('addToast', { message: 'Your email was changed. You should have received a link to verify it.', type: 'positive' });
        this.password.old = '';
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not change email: ${err.message}`, type: 'negative' });
      }
    },
    async changeFilteredAuthors() {
      if (this.filteredAuthors.length > this.maxFilteredAuthors) return; // abort, it’s too many

      const ownIndex = this.filteredAuthors.findIndex((chip) => chip.value === this.$store.getters.userId);
      if (ownIndex > -1) {
        this.$store.commit('addToast', { message: 'You cannot filter out your own posts!', type: 'negative' });
        this.filteredAuthors.splice(ownIndex, 1);
        return;
      }

      try {
        await this.$feathers.service('users').patch(this.$store.getters.userId, { filteredAuthors: this.filteredAuthors.map((item) => item.value) });
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not update filtered authors: ${err.message}`, type: 'negative' });
      }
    },
    async changeFilteredTags() {
      if (this.filteredTags.length > this.maxFilteredTags) return; // abort, it’s too many

      try {
        await this.$feathers.service('users').patch(this.$store.getters.userId, { filteredTags: this.filteredTags });
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not update filtered tags: ${err.message}`, type: 'negative' });
      }
    },
    async changePassword() {
      this.validate('oldPassword');
      this.validate('password');
      this.validate('passwordConfirmation');

      if (this.errors.oldPassword || this.errors.newPassword || this.errors.passwordConfirmation) return;

      try {
        await this.$feathers.service('authmanagement').create({
          action: 'change-password',
          password: this.password.new,
          oldPassword: this.password.old,
        });
        this.$store.commit('addToast', { message: 'Your password was changed', type: 'positive' });
        this.password = {
          old: '',
          new: '',
          confirm: '',
        };
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not change password: ${err.message}`, type: 'negative' });
      }
    },
    async deleteAccount() {
      this.validate('oldPassword');

      if (this.errors.oldPassword) {
        this.showAccountDelete = true;
        return;
      }

      try {
        await this.$feathers.service('users').remove(this.$store.getters.userId, { query: { password: this.password.old } });
        window.location.reload(true);
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => { this.showAccountDelete = true; },
          actionLabel: 'Try again',
          message: `Could not delete account: ${err.message}`,
          type: 'negative',
        });
      }
    },
    async fetchSubscription() {
      if (!this.$store.state.application.serviceWorker) return null;

      const subscription = await this.$store.state.application.serviceWorker.pushManager.getSubscription();
      if (!subscription) return null;

      const { data: matchingSubscriptions } = await this.$feathers.service('push-subscriptions').find({ query: { endpoint: subscription.endpoint, $limit: 1 } });
      return matchingSubscriptions[0];
    },
    async installApp() {
      this.$store.state.application.installPrompt.prompt();
      const choice = await this.$store.state.application.installPrompt.userChoice;
      if (choice.outcome === 'accepted') this.$store.commit('addToast', { message: 'Great! Untold Stories should have been added to your homescreen or desktop. You can now launch it from there and close this tab.' });
      else this.$store.commit('addToast', { message: 'That’s okay, if you change your mind, you can always install it from here.' });
    },
    validate(field) {
      let error = '';

      switch (field) {
        case 'email':
          if (this.email === this.$store.state.user.email) error = 'Email has not changed';
          if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(this.email)) error = 'Email is invalid';
          if (!this.email) error = 'Email is required';
          if (this.email.length > 256) error = 'Email is too long';
          this.errors.email = error;
          break;
        case 'oldPassword':
          if (!this.password.old) error = 'Current Password is required';
          this.errors.oldPassword = error;
          break;
        case 'password':
          if (this.password.new.length < 8) error = 'Password is too short';
          if (!this.password.new) error = 'Password is required';
          if (this.password.new.length > 128) error = 'Password is too long';
          if (['        ', 'password', 'password1', 'passwort', 'passwort1', 'qwertzui', 'qwertyui', 'abcdefgh', 'abcd1234', '1234abcd', 'docmagazin'].includes(this.password.new)) error = 'Password is insecure';
          if (/^\d+$/.test(this.password.new)) error = 'Can’t contain only numbers';
          this.errors.newPassword = error;
          break;
        case 'passwordConfirmation':
          if (this.password.new !== this.password.confirm) error = 'Passwords don’t match';
          this.errors.passwordConfirmation = error;
          break;
        default:
      }
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    async enableNotifications(newVal) {
      if (!this.initialized) return;
      if (newVal === true) {
        let permission;
        if (Notification && (Notification.permission === 'default' || !Notification.permission)) permission = await Notification.requestPermission();
        else if (Notification) permission = Notification.permission;

        if (permission === 'granted') {
          try {
            const registration = this.$store.state.application.serviceWorker;
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_PUSH_PUBLIC_KEY),
            });
            await this.$feathers.service('push-subscriptions').create({ notificationsFor: Object.keys(this.pushNotifications).filter((key) => this.pushNotifications[key]), ...JSON.parse(JSON.stringify(subscription)) }); // so it’s an object again
            this.$store.commit('addToast', { message: 'Great! You should now receive push notifications on your device. You can adjust which ones in the settings', type: 'positive' });
          } catch (err) {
            this.$store.commit('addToast', { message: `Could not enable push notifications: ${err.message}`, timeout: -1, type: 'negative' });
          }
        }
        if (permission === 'default') this.$store.commit('addToast', { message: 'If you change your mind at a later date, you can enable push notifications from the settings' });
        if (permission === 'denied') {
          this.$store.commit('addToast', { message: 'You have permanently blocked notifications from this website. If you want to change your mind, you’ll have to unblock Untold Stories from your browser’s settings', timeout: 5000, type: 'negative' });
          this.enableNotifications = false;
        }
        if (window.localStorage) window.localStorage.setItem('notificationsPrompted', true);
      } else if (newVal === false && Notification && Notification.permission === 'granted') {
        try {
          const sub = await this.$store.state.application.serviceWorker.pushManager.getSubscription();
          const { _id: subId } = await this.fetchSubscription();
          await sub.unsubscribe();
          if (subId) await this.$feathers.service('push-subscriptions').remove(subId);
          this.$store.commit('addToast', { message: 'Push notifications have been disabled for this device', type: 'positive' });
        } catch (err) {
          this.$store.commit('addToast', {
            action: () => window.location.reload(true),
            actionLabel: 'Refresh',
            message: `There was an error while disabling push notifications: ${err.message}. Try refreshing the page to see if it was still successful`,
            timeout: -1,
            type: 'negative',
          });
        }
      }
    },
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'user', query: { page: 'settings' } });
    },
    pushNotifications: {
      deep: true,
      handler(newVal) {
        if (!this.initialized || !this.enableNotifications) return; // so it doesn’t get triggered by the initial setting of the values

        const copy = { ...newVal };

        if (this.updateNotificationSettingsTimeout) {
          window.clearTimeout(this.updateNotificationSettingsTimeout);
          this.updateNotificationSettingsTimeout = null;
        }
        this.updateNotificationSettingsTimeout = window.setTimeout(async () => {
          try {
            const { _id: subscriptionId } = await this.fetchSubscription();
            await this.$feathers.service('push-subscriptions').patch(subscriptionId, { notificationsFor: Object.keys(copy).filter((key) => copy[key]) });
            this.$store.commit('addToast', { message: 'Your preferences were saved', type: 'positive' });
          } catch (err) {
            this.$store.commit('addToast', { message: `Could not save preferences: ${err.message}`, timeout: 5000, type: 'negative' });
          }
        }, 1000);
      },
    },
    async theme(newVal, oldVal) {
      try {
        await this.$feathers.service('users').patch(this.$store.getters.userId, { darkThemePreference: newVal });
      } catch (err) {
        this.theme = oldVal;
        this.$store.commit('addToast', { message: `Could not change theme preference: ${err.message}`, type: 'negative' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require('../styles/colors')

.settings
  > h2:not(:first-of-type)
    margin-top: 3rem

  > .button,
  > .input,
  > .input.icon,
  > .chip-input.icon
    width: 100%

  > .chip-input.icon + .chip-input.icon
    margin-top: 1rem

  > .input
    margin-bottom: 1rem

  > p
    margin-bottom: 1.5rem

    &.unsupported strong
      color: $negative

  > label
    display: flex
    align-items: center
    margin-bottom: 1rem

    .switch
      margin-left: auto
      flex-shrink: 0

  .modal .input
    width: 100%
</style>

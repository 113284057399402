<template lang="html">
  <span class="chip" :class="{ dark, prominent, removable }" @click="handleClick">
    <CountTransition :direction="direction">
      <span class="content" :key="noTransition ? 'content' : content">{{content}}</span>
    </CountTransition>
    <USIcon v-if="removable" icon="cross" @click.native="$emit('remove', $event)" />
  </span>
</template>

<script>
import CountTransition from '@/transitions/CountTransition.vue';

export default {
  components: {
    CountTransition,
  },
  data() {
    return {
      direction: 'down',
    };
  },
  methods: {
    handleClick(e) {
      if (!this.removable) this.$emit('click', e);
    },
  },
  props: {
    content: [String, Number],
    dark: Boolean,
    noTransition: Boolean,
    prominent: Boolean,
    removable: Boolean,
  },
  watch: {
    content(newVal, oldVal) {
      if (typeof newVal === 'number' && typeof oldVal === 'number' && newVal > oldVal) this.direction = 'up';
      else this.direction = 'down';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.chip
  display: inline-flex
  justify-content: space-between
  align-items: center
  padding: 0.125rem 0.75rem
  height: 1.5rem
  background-color: mix($accent-primary, $bg, 10)
  color: $accent-primary
  border-radius: 0.75rem
  font-size: 0.875rem
  font-weight: 800
  max-width: 100%
  white-space: nowrap

  &.dark
    background-color: mix($accent-primary, $bg-dark, 40)
    color: $text-primary-dark

  &.prominent
    background-color: $accent-secondary
    color: $text-primary-dark

    &.dark
      background-color: mix($accent-secondary, $bg-dark, 70%)

  &.removable
    padding: 0.25rem 0.25rem 0.25rem 0.75rem

    .icon
      border-radius: 50%
      width: 1rem
      height: @width
      margin-left: 0.25rem
      cursor: pointer

      >>> path
        vector-effect: non-scaling-stroke

  .content
    overflow: hidden
    text-overflow: ellipsis
    text-transform: capitalize
</style>

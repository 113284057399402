import auth from '@feathersjs/client/authentication'; // using @feathersjs/client here because it’s pre-transpiled and trying to transpile the standard modules throws errors
import feathers from '@feathersjs/client/core'; // using @feathersjs/client here because it’s pre-transpiled and trying to transpile the standard modules throws errors
import io from 'socket.io-client';
import socketio from '@feathersjs/client/socketio'; // using @feathersjs/client here because it’s pre-transpiled and trying to transpile the standard modules throws errors

const socket = io(process.env.VUE_APP_API_SERVER);
const app = feathers();

app.configure(socketio(socket));
app.configure(auth({ storage: window.localStorage }));

// if I ever need to clear the buffer of requests that happened during a disconnect
// socket.on('reconnect', () => {
//   socket.sendBuffer = [];
// });

export default app;

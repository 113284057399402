function isMac() {
  const platform = (window.navigator.userAgentData && window.navigator.userAgentData.platform) || window.navigator.platform;

  return platform.includes('Mac');
}

export default {
  methods: {
    openPost(event, details = {}) {
      const { params, query } = details;

      if (event.type === 'mousedown' && event.button !== 1) return; // do nothing on mousedown if it is not a middle click

      // open in new window if we’re middle clicking or CMD / Ctrl clicking
      if (event.button === 1 || ((isMac() && event.metaKey) || (!isMac() && event.ctrlKey))) {
        if (event.button === 1) event.preventDefault();
        const routeData = this.$router.resolve({ name: 'read', params, query });
        window.open(routeData.href, '_blank');
      } else this.$router.push({ name: 'read', params, query });
    },
  },
};

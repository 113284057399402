<template lang="html">
  <div class="home-page" :class="{ mobile }">
    <transition :name="pageTransitionName" mode="out-in" @before-enter="triggerScroll">
      <USView v-if="page === 'home' || !mobile" class="home" :dark="dark" key="home">
        <img class="bg" src="../assets/headerBG.png" alt="Overlapping wavy background-images in the Untold Stories gradient">
        <img src="../assets/logo.svg" alt="Untold Stories—English Language Writers’ Club">
        <USSidebar v-if="!mobile" :dark="dark" :back-button="showSidebarBack" @back="handleBack">
          <transition :name="pageTransitionName" mode="out-in" @before-enter="$el.querySelector('.sidebar').scrollTop = 0">
            <section v-if="page === 'terms'" class="terms" key="terms">
              <h1>Please Note</h1>
              <article v-html="$options.filters.markdown(textContent['please-note'])" />
              <USButton class="terms-button" :dark="dark" icon-right="check" primary @click="goTo('accountCreation')">I Understand</USButton>
            </section>
            <section v-else-if="page === 'accountCreation'" key="accountCreation">
              <h2>Account Information</h2>
              <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="E-Mail" type="email" @blur="validate('email')" />
              <USInput v-model="password" :dark="dark" :error="errors.password" icon="key" label="Password" type="password" @blur="validate('password')" />
              <USInput v-model="passwordConfirmation" :dark="dark" :error="errors.passwordConfirmation" icon="key" label="Confirm Password" type="password" @blur="validate('passwordConfirmation')" />
              <h2>User Information</h2>
              <USInput v-model="name" :dark="dark" :error="errors.name" icon="user" label="Name or Pseudonym" @blur="validate('name')" />
              <USInput v-model="requestReason" :dark="dark" :error="errors.requestReason" :formats="['bold', 'link']" icon="description" label="Tell us about yourself" :max-len="512" multiline @blur="validate('requestReason')" />
              <USButton :dark="dark" :disabled="formErrors" icon-right="send" :loading="sendButtonLoading" primary @click="signUp">Send Request</USButton>
            </section>
            <section v-else-if="page === 'welcome'" class="welcome" key="welcome">
              <svg class="user-splat" viewBox="0 0 360 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H360V42.1255C356.777 30.5188 346.133 22 333.5 22C318.312 22 306 34.3122 306 49.5V57V73.5C306 82.6127 298.613 90 289.5 90H288.5C279.387 90 272 82.6127 272 73.5V57V56.5C272 42.4167 260.583 31 246.5 31C232.417 31 221 42.4167 221 56.5V57V65C221 87.0914 203.091 105 181 105H180C157.909 105 140 87.0914 140 65V57V56V55.5C140 40.8645 128.135 29 113.5 29C98.8645 29 87 40.8645 87 55.5V57V104C87 113.941 78.9412 122 69 122C59.0588 122 51 113.941 51 104V57V55V47.5C51 33.4167 39.5833 22 25.5 22C11.4167 22 0 33.4167 0 47.5V0ZM289 138C298.389 138 306 130.613 306 121.5C306 112.387 298.389 105 289 105C279.611 105 272 112.387 272 121.5C272 130.613 279.611 138 289 138Z" :fill="$store.state.user.color" />
                </g>
                <defs>
                  <filter id="filter0_d" x="-4" y="-3" width="368" height="146" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                  </filter>
                </defs>
              </svg>
              <img :src="$store.state.user.avatar || '/img/default-avatar.png'" alt="Your avatar image">
              <h2>Welcome to Untold Stories, {{$store.state.user.name | firstName }}!</h2>
              <article v-html="$options.filters.markdown(textContent['welcome-to-untold-stories'])" />
              <p>In the meantime, here’s a bunch of things you can already check out:</p>
              <USButton v-if="communityGuidelinesThreadId" :dark="dark" icon-right="chevron-right" primary @click="$router.push({ name: 'thread', params: { id: communityGuidelinesThreadId } })">Community Guidelines</USButton>
              <USButton :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'profile', params: { id: $store.getters.userId } })">Profile Settings</USButton>
              <USButton :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'forum' })">Discussion Forum</USButton>
              <USButton v-if="events.length > 0" :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'events' })">Come to a Meeting</USButton>
              <USButton :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'read', query: { list: 'staff-picks', listType: 'collection' } })">Current Staff Picks</USButton>
            </section>
            <section v-else-if="page === 'verify'" key="verify">
              <h1>Verfiy Email Address</h1>
              <FadeTransition>
                <section v-if="$route.query.token">
                  <USLoader />
                </section>
                <section v-if="$route.query.newToken">
                  <p>You can request a new verification email by entering your address below. Click on the link to verify your email address.</p>
                  <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="E-Mail" type="email" @blur="validate('email')" />
                  <USButton :dark="dark" :disabled="!email || !!errors.email" icon-right="send" :loading="sendButtonLoading" primary @click="requestVerify">Send Verification Link</USButton>
                </section>
              </FadeTransition>
            </section>
            <section v-else-if="page === 'resetPassword'" key="resetPassword">
              <h1>Reset Password</h1>
              <FadeTransition>
                <section v-if="!passwordResetStatus && !$route.query.token">
                  <p>If you have verified your email address, you can enter it below and have your password reset. You will receive an email with a link that will allow you to set a new password.</p>
                  <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="E-Mail" type="email" @blur="validate('email')" />
                  <USButton :dark="dark" :disabled="!email || !!errors.email" icon-right="send" :loading="sendButtonLoading" primary @click="requestPasswordReset">Send Reset Link</USButton>
                </section>
                <section v-if="!passwordResetStatus && $route.query.token">
                  <p>Please enter a new password and confirm it to change it.</p>
                  <USInput v-model="password" :dark="dark" :error="errors.password" icon="key" label="Password" type="password" @blur="validate('password')" />
                  <USInput v-model="passwordConfirmation" :dark="dark" :error="errors.passwordConfirmation" icon="key" label="Confirm Password" type="password" @blur="validate('passwordConfirmation')" />
                  <USButton :dark="dark" :disabled="!password || !passwordConfirmation || !!errors.password || !!errors.passwordConfirmation" icon-right="send" :loading="sendButtonLoading" primary @click="changePassword">Set New Password</USButton>
                </section>
                <section v-if="passwordResetStatus == 'sendSuccess'">
                  <p>You have been sent an email with instructions on how to reset your password.</p>
                </section>
                <section v-if="passwordResetStatus === 'success'">
                  <p>Your password was changed successfully. You can now sign in with it.</p>
                  <USButton :dark="dark" primary @click="goTo('login')">Sign In</USButton>
                </section>
              </FadeTransition>
            </section>
            <section v-else class="login" key="login">
              <template v-if="events.length > 0">
                <h1>Next Event</h1>
                <USEventCard compact :dark="dark" :event="events[0]" interactable @click="$router.push({ name: 'events' })" />
                <USButton v-if="events.length > 1" :dark="dark" @click="$router.push({ name: 'events' })">Show All Events</USButton>
              </template>
              <h1>Sign In</h1>
              <USInput v-model="email" autofocus :dark="dark" icon="user" label="E-Mail" type="email" />
              <USInput v-model="password" :dark="dark" icon="key" label="Password" type="password" @keyup.native.enter="login" />
              <USButton :dark="dark" :disabled="!email || !password" icon-right="send" :loading="sendButtonLoading" primary @click="login">Sign In</USButton>
              <USButton :dark="dark" @click="goTo('resetPassword')">Reset Password</USButton>
              <h2>Don’t have an account yet?</h2>
              <USButton :dark="dark" icon-left="add-user" @click="goTo('terms')">Request Membership</USButton>
            </section>
          </transition>
        </USSidebar>
        <div class="content-wrapper">
          <template v-if="mobile">
            <section class="top">
              <template v-if="events.length > 0">
                <USEventCard compact :dark="dark" :event="events[0]" interactable @click="$router.push({ name: 'events' })" />
                <USButton v-if="events.length > 1" :dark="dark" @click="$router.push({ name: 'events' })">See All Events</USButton>
              </template>
              <template v-else>
                <h1>No Upcoming Events</h1>
                <p>There are currently no upcoming events. Check back another time if you’re only interested in those.</p>
                <USButton :dark="dark" @click="$el.querySelector('#events').scrollIntoView({ block: 'center' })">Learn More About Events</USButton>
              </template>
              <USButton :dark="dark" icon-right="chevron-right" primary @click="goTo('login')">Sign In</USButton>
            </section>
            <div class="rounder" :class="{ dark }">
              <div />
            </div>
            <h2 class="what-card">What is Untold Stories?</h2>
          </template>
          <div class="article-grid" :class="{ desktop: !mobile }">
            <article>
              <h1>About Us</h1>
              <section v-html="$options.filters.markdown(textContent['about-us'])" />
            </article>
            <article id="events">
              <h1>Meetings &amp; Events</h1>
              <section v-html="$options.filters.markdown(textContent.meetings)" />
              <USButton v-if="events.length > 0" :dark="dark" icon-left="calendar" @click="$router.push({ name: 'events' })">Upcoming Events</USButton>
            </article>
            <article class="platform">
              <h1>This Platform</h1>
              <section v-html="$options.filters.markdown(textContent['this-platform'])" />
              <USButton v-if="mobile" :dark="dark" icon-right="chevron-right" primary @click="goTo('terms')">Request Membership</USButton>
            </article>
            <article>
              <h1>Content</h1>
              <section v-html="$options.filters.markdown(textContent.content)" />
              <USButton v-if="publicStoryId" :dark="dark" icon-left="show" @click="$router.push({ name: 'read', params: { id: publicStoryId } })">Read Some Stories</USButton>
            </article>
            <article>
              <h1>Contact</h1>
              <section v-html="$options.filters.markdown(textContent.contact)" />
              <USButton :dark="dark" icon-left="add-mail" @click="triggerMailto">Send Us an Email</USButton>
            </article>
            <img class="logo" src="../assets/icon.svg" alt="The Untold Stories logo">
          </div>
        </div>
        <footer>
          <div class="drip" :class="{ dark }" />
          <nav>
            <span>Built with 💚 by </span>
            <a href="https://amxmln.com" target="_blank" rel="noreferrer nofollow noopener">Amadeus Maximilan</a>
            <p>We store no personally identifiable data until you create an account (request membership).</p>
          </nav>
        </footer>
      </USView>
      <USView v-if="page === 'login' && mobile" class="login" :dark="dark" key="login" show-back @back="handleBack">
        <h1>Sign In</h1>
        <USInput v-model="email" autofocus :dark="dark" icon="user" label="E-Mail" type="email" />
        <USInput v-model="password" :dark="dark" icon="key" label="Password" type="password" @keyup.native.enter="login" />
        <USButton :dark="dark" :disabled="!email || !password" icon-right="send" :loading="sendButtonLoading" primary @click="login">Sign In</USButton>
        <USButton :dark="dark" @click="goTo('resetPassword')">Reset Password</USButton>
        <h2>Don’t have an account yet?</h2>
        <USButton :dark="dark" icon-left="add-user" @click="goTo('terms')">Request Membership</USButton>
      </USView>
      <USView v-if="page === 'terms' && mobile" class="terms" :dark="dark" key="terms" show-back @back="handleBack">
        <h1>Please Note</h1>
        <article v-html="$options.filters.markdown(textContent['please-note'])" />
        <USButton :dark="dark" icon-right="check" primary @click="goTo('accountCreation')">I Understand</USButton>
      </USView>
      <USView v-if="page === 'accountCreation' && mobile" class="account-creation" :dark="dark" key="accountCreation" show-back @back="handleBack">
        <h2>Account Information</h2>
        <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="E-Mail" type="email" @blur="validate('email')" />
        <USInput v-model="password" :dark="dark" :error="errors.password" icon="key" label="Password" type="password" @blur="validate('password')" />
        <USInput v-model="passwordConfirmation" :dark="dark" :error="errors.passwordConfirmation" icon="key" label="Confirm Password" type="password" @blur="validate('passwordConfirmation')" />
        <h2>User Information</h2>
        <USInput v-model="name" :dark="dark" :error="errors.name" icon="user" label="Name or Pseudonym" @blur="validate('name')" />
        <USInput v-model="requestReason" :dark="dark" :error="errors.requestReason" :formats="['link', 'bold']" icon="description" label="Tell us about yourself" :max-len="512" multiline @blur="validate('requestReason')" />
        <USButton :dark="dark" :disabled="formErrors" icon-right="send" :loading="sendButtonLoading" primary @click="signUp">Send Request</USButton>
      </USView>
      <USView v-if="page === 'welcome' && mobile" class="welcome" :dark="dark" key="welcome">
        <svg class="user-splat" viewBox="0 0 360 143" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H360V42.1255C356.777 30.5188 346.133 22 333.5 22C318.312 22 306 34.3122 306 49.5V57V73.5C306 82.6127 298.613 90 289.5 90H288.5C279.387 90 272 82.6127 272 73.5V57V56.5C272 42.4167 260.583 31 246.5 31C232.417 31 221 42.4167 221 56.5V57V65C221 87.0914 203.091 105 181 105H180C157.909 105 140 87.0914 140 65V57V56V55.5C140 40.8645 128.135 29 113.5 29C98.8645 29 87 40.8645 87 55.5V57V104C87 113.941 78.9412 122 69 122C59.0588 122 51 113.941 51 104V57V55V47.5C51 33.4167 39.5833 22 25.5 22C11.4167 22 0 33.4167 0 47.5V0ZM289 138C298.389 138 306 130.613 306 121.5C306 112.387 298.389 105 289 105C279.611 105 272 112.387 272 121.5C272 130.613 279.611 138 289 138Z" :fill="$store.state.user.color" />
          </g>
          <defs>
            <filter id="filter0_d" x="-4" y="-3" width="368" height="146" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </svg>
        <img :src="$store.state.user.avatar || '/img/default-avatar.png'" alt="Your avatar image">
        <h2>Welcome to Untold Stories, {{$store.state.user.name | firstName }}!</h2>
        <article v-html="$options.filters.markdown(textContent['welcome-to-untold-stories'])" />
        <p>In the meantime, here’s a bunch of things you can already check out:</p>
        <USButton v-if="communityGuidelinesThreadId" :dark="dark" icon-right="chevron-right" primary @click="$router.push({ name: 'thread', params: { id: communityGuidelinesThreadId } })">Community Guidelines</USButton>
        <USButton :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'profile', params: { id: $store.getters.userId } })">Profile Settings</USButton>
        <USButton :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'forum' })">Discussion Forum</USButton>
        <USButton v-if="events.length > 0" :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'events' })">Come to a Meeting</USButton>
        <USButton :dark="dark" icon-right="chevron-right" @click="$router.push({ name: 'read', query: { list: 'staff-picks', listType: 'collection' } })">Current Staff Picks</USButton>
      </USView>
      <USView v-if="page === 'verify' && mobile" class="verify" :dark="dark" key="verify" show-back @back="handleBack">
        <h1>Verfiy Email Address</h1>
        <FadeTransition>
          <section v-if="$route.query.token">
            <USLoader />
          </section>
          <section v-if="$route.query.newToken">
            <p>You can request a new verification email by entering your address below. Click on the link to verify your email address.</p>
            <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="E-Mail" type="email" @blur="validate('email')" />
            <USButton :dark="dark" :disabled="!email || !!errors.email" icon-right="send" :loading="sendButtonLoading" primary @click="requestVerify">Send Verification Link</USButton>
          </section>
        </FadeTransition>
      </USView>
      <USView v-if="page === 'resetPassword' && mobile" class="reset-password" :dark="dark" key="resetPassword" show-back @back="handleBack">
        <h1>Reset Password</h1>
        <FadeTransition>
          <section v-if="!passwordResetStatus && !$route.query.token">
            <p>If you have verified your email address, you can enter it below and have your password reset. You will receive an email with a link that will allow you to set a new password.</p>
            <USInput v-model="email" :dark="dark" :error="errors.email" icon="mail" label="E-Mail" type="email" @blur="validate('email')" />
            <USButton :dark="dark" :disabled="!email || !!errors.email" icon-right="send" :loading="sendButtonLoading" primary @click="requestPasswordReset">Send Reset Link</USButton>
          </section>
          <section v-if="!passwordResetStatus && $route.query.token">
            <p>Please enter a new password and confirm it to change it.</p>
            <USInput v-model="password" :dark="dark" :error="errors.password" icon="key" label="Password" type="password" @blur="validate('password')" />
            <USInput v-model="passwordConfirmation" :dark="dark" :error="errors.passwordConfirmation" icon="key" label="Confirm Password" type="password" @blur="validate('passwordConfirmation')" />
            <USButton :dark="dark" :disabled="!password || !passwordConfirmation || !!errors.password || !!errors.passwordConfirmation" icon-right="send" :loading="sendButtonLoading" primary @click="changePassword">Set New Password</USButton>
          </section>
          <section v-if="passwordResetStatus == 'sendSuccess'">
            <p>You have been sent an email with instructions on how to reset your password.</p>
          </section>
          <section v-if="passwordResetStatus === 'success'">
            <p>Your password was changed successfully. You can now sign in with it.</p>
            <USButton :dark="dark" primary @click="goTo('login')">Sign In</USButton>
          </section>
        </FadeTransition>
      </USView>
    </transition>
  </div>
</template>

<script>
import Feathers from '@/feathersApp';
import generateAvatar from '@/scripts/generateAvatar';
import firstName from '@/filters/firstName';
import markdown from '@/filters/markdown';

import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  beforeDestroy() {
    this.$store.commit('setShowSidebar', false);
    if (this.dark) document.querySelector('meta[name=theme-color]').setAttribute('content', '#343C41');
    else document.querySelector('meta[name=theme-color]').setAttribute('content', '#fefefe');
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: textContent } = await Feathers.service('info').find({ query: { _id: { $in: ['about-us', 'contact', 'content', 'meetings', 'please-note', 'this-platform', 'welcome-to-untold-stories'] } } });
      const transformedContent = {};
      textContent.forEach((content) => {
        transformedContent[content._id] = content.content;
      });

      const { data: publicStory } = await Feathers.service('posts').find({ query: { public: true, $limit: 1, $sort: { createdAt: -1 } } });

      const auth = Feathers.get('authentication');
      let communityGuidelines;
      if (auth) {
        ({ data: communityGuidelines } = await Feathers.service('threads').find({
          query: {
            title: { $search: 'community guidelines' }, $limit: 1, $sort: { createdAt: -1 }, $select: ['_id'],
          },
        }));
      }
      next((vm) => {
        vm.textContent = transformedContent; // eslint-disable-line no-param-reassign
        vm.publicStoryId = publicStory[0] && publicStory[0]._id; // eslint-disable-line no-param-reassign
        if (communityGuidelines) vm.communityGuidelinesThreadId = communityGuidelines[0]._id; // eslint-disable-line no-param-reassign
      });
    } catch (err) {
      if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  // unnecessary
  // async beforeRouteUpdate(to, from, next) {
  //   const { data: textContent } = await Feathers.service('info').find({ query: { _id: { $in: ['about-us', 'contact', 'content', 'meetings', 'please-note', 'this-platform', 'welcome-to-untold-stories'] } } });
  //   const transformedContent = {};
  //   textContent.forEach((content) => {
  //     transformedContent[content._id] = content.content;
  //   });
  //   this.textContent = transformedContent;
  //   next();
  // },
  components: {
    FadeTransition,
  },
  computed: {
    formErrors() {
      const errors = Object.values(this.errors);
      return errors.some((err) => err !== '');
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    page() {
      if (this.$route.query.page === 'welcome' && !this.$store.state.user._id && this.mobile) return 'login';
      if (this.$route.query.page === 'welcome' && !this.$store.state.user._id && !this.mobile) return 'home';
      return this.$route.query.page || 'home';
    },
    showSidebarBack() {
      const { page } = this;
      return ['terms', 'accountCreation', 'verify', 'resetPassword'].includes(page);
    },
  },
  created() {
    document.querySelector('meta[name=theme-color]').setAttribute('content', '#20A2F7');
  },
  data() {
    return {
      communityGuidelinesThreadId: '',
      email: '',
      errors: {
        email: '',
        field: '',
        requestReason: '',
        name: '',
        password: '',
        passwordConfirmation: '',
        semester: '',
      },
      events: [],
      field: '',
      requestReason: '',
      name: '',
      pageTransitionName: 'forwards',
      password: '',
      passwordConfirmation: '',
      passwordResetStatus: '',
      publicStoryId: '',
      semester: '',
      sendButtonLoading: false,
      textContent: {
        'about-us': '**Untold Stories** is a group of young people who love to write creatively in English and want to exchange ideas and get feedback on their work from like-minded peers. The group was founded at the end of 2014 by lector Anne Pincus and generally meets fortnightly at the LMU Munich.',
        contact: 'Feel free to send us an email if you have any questions or suggestions, or simply come to one of our meetings. We’re looking forward to meeting you!',
        content: 'There is no limitation to the kind of writing you can contribute. We already have a collection of over 200 works ready for you to read, including a variety of short story genres as well as poetry and poetry prose pieces, journalistic style writing and essays.',
        meetings: 'During these fortnightly meetings, which typically last for two hours, we discuss the works of those members who are present and would like to hear some feedback or thoughts. Additionally we talk about events and administrative details, and, if there’s time for it, do some creative writing excercises.\n\nWe also periodically host larger events such as reading nights and launches for our publications.',
        'please-note': 'This is a platfrom for the members of the *Untold Stories Writers’ Club* to share their creative work.\n\nFor now, membership is limited to **Students of the LMU** and **certain exceptions**. To verify this we ask you to input your *course of study* and *semester*, or the *name of the member who invited you* in case you’re not a student during the sign-up process. This information will be visible **only to the person approving your request** and **deleted** once you become a fully fledged member.\n\nIf you are not currently a student, but still interested in joining, please come to one of our [meetings](https://untoldstoriesmuc.de/events) and we will see if we can make an exception for you.\n\n## Privacy Policy\n\nTo make everything work on our end, we need to collect the following personally identifiable information about you. This information is completely under your control and will never be shared with a third party unless you allow us to:\n\n- Your first and last name (can be a pseudonym)\n- Your email address and a secret password (for identification)',
        'this-platform': 'This is a platform for the members to share their work with others and receive feedback from those who can’t make it to one of the meetings. It’s also a central place for all essential information like the time and place of the meetings and other events.\n\nMembers are free, however, to just read and perhaps leave a like or a comment on the works published without contributing themselves.',
        'welcome-to-untold-stories': 'Thank you for taking the time to sign up. We’re currently reviewing your request to become a full member and will let you know as soon as we’ve approved it, so you’ll be able to start posting content.',
      },
      verifyStatus: '',
    };
  },
  filters: {
    firstName,
    markdown,
  },
  methods: {
    async changePassword() {
      this.sendButtonLoading = true;

      this.validate('password');
      this.validate('passwordConfirmation');

      if (this.formErrors) {
        this.sendButtonLoading = false;
        return;
      }

      try {
        await this.$feathers.service('authmanagement').create({
          action: 'change-password',
          password: this.password,
          token: this.$route.query.token,
        });
        this.passwordResetStatus = 'success';
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not change password: ${err.message}`, type: 'negative' });
      } finally {
        this.sendButtonLoading = false;
      }
    },
    goTo(page) {
      this.$router.push({ query: { ...this.$router.query, page } });
    },
    handleBack() {
      this.$router.back();
    },
    async login() {
      this.sendButtonLoading = true;
      try {
        const auth = await this.$feathers.authenticate({
          strategy: 'local',
          email: this.email,
          password: this.password,
        });
        this.$store.commit('setUser', auth.user);
        if (!auth.user.isVerified) this.$store.commit('addToast', { message: 'Looks like you haven’t yet verified your email address with the link we sent you. Please do so in order to be able to reset your password in case you forget it.', timeout: -1, type: 'warning' });
        this.$store.commit('setForceHiddenMenu', false);
        if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect);
        else this.$router.replace({ name: 'feed' });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while logging in: ${err.message}`, type: 'negative' });
      } finally {
        this.sendButtonLoading = false;
      }
    },
    async requestPasswordReset() {
      this.sendButtonLoading = true;

      this.validate('email');

      if (this.formErrors) {
        this.sendButtonLoading = false;
        return;
      }

      try {
        await this.$feathers.service('authmanagement').create({
          action: 'send-reset-password',
          email: this.email,
        });
        this.passwordResetStatus = 'sendSuccess';
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not reset password: ${err.message}`, type: 'negative' });
      } finally {
        this.sendButtonLoading = false;
      }
    },
    async requestVerify() {
      this.sendButtonLoading = true;

      this.validate('email');

      if (this.formErrors) {
        this.sendButtonLoading = false;
        return;
      }

      try {
        await this.$feathers.service('authmanagement').create({
          action: 'send-verify',
          email: this.email,
          id: this.$store.getters.userId,
        });
        this.$store.commit('addToast', { message: 'We have sent you an email with a new verification link. It should arrive soon.', type: 'positive' });
        this.$nextTick(() => this.$router.replace({ name: 'feed' }));
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not resend verification: ${err.message}`, type: 'negative' });
      } finally {
        this.sendButtonLoading = false;
      }
    },
    async signUp() {
      this.sendButtonLoading = true;

      this.validate('email');
      this.validate('field');
      this.validate('requestReason');
      this.validate('name');
      this.validate('password');
      this.validate('passwordConfirmation');
      this.validate('semester');

      if (this.formErrors) {
        this.sendButtonLoading = false;
        return;
      }

      const colors = ['#C13C32', '#F2C337', '#17CB76', '#20A2F7', '#2980B9', '#B100CE'];
      const userColor = colors[Math.floor(Math.random() * colors.length)];
      let initials = this.name.trim().slice(0, 2).toUpperCase();
      if (this.name.trim().includes(' ')) initials = this.name.split(' ')[0][0].toUpperCase() + this.name.split(' ')[1][0].toUpperCase();
      const avatar = generateAvatar(initials, userColor);

      try {
        await this.$feathers.service('users').create({
          avatar,
          color: userColor,
          email: this.email.trim(),
          name: this.name.trim(),
          password: this.password,
          requestReason: this.requestReason.trim(),
        });
        const { user } = await this.$feathers.authenticate({
          strategy: 'local',
          email: this.email.trim(),
          password: this.password,
        });
        this.$store.commit('setUser', user);
        this.password = '';
        this.requestReason = '';
        this.sendButtonLoading = false;
        this.$router.push({ query: { page: 'welcome' } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Sorry, something went wrong: ${err.message}`, timeout: 5000, type: 'negative' });
        this.sendButtonLoading = false;
      }
    },
    triggerMailto() {
      window.open('mailto:info@untoldstoriesmuc.de', '_blank');
    },
    triggerScroll() {
      this.$root.$emit('triggerScroll');
    },
    validate(field) {
      let error = '';

      switch (field) {
        case 'email':
          if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(this.email)) error = 'Email is invalid';
          if (!this.email) error = 'Email is required';
          if (this.email.length > 256) error = 'Email is too long';
          this.errors.email = error;
          break;
        case 'requestReason':
          if (!this.requestReason.trim()) error = 'Application is required';
          if (this.name.length > 512) error = 'Application is too long';
          this.errors.requestReason = error;
          break;
        case 'name':
          if (this.name.length < 2) error = 'Name is too short';
          if (!this.name.trim()) error = 'Name is required';
          if (this.name.length > 256) error = 'Name is too long';
          this.errors.name = error;
          break;
        case 'password':
          if (this.password.length < 8) error = 'Password is too short';
          if (!this.password) error = 'Password is required';
          if (this.password.length > 128) error = 'Password is too long';
          if (['        ', 'password', 'password1', 'passwort', 'passwort1', 'qwertzui', 'qwertyui', 'abcdefgh', 'abcd1234', '1234abcd', 'docmagazin'].includes(this.password)) error = 'Password is insecure';
          if (/^\d+$/.test(this.password)) error = 'Can’t contain only numbers';
          this.errors.password = error;
          break;
        case 'passwordConfirmation':
          if (this.password !== this.passwordConfirmation) error = 'Passwords don’t match';
          this.errors.passwordConfirmation = error;
          break;
        default:
      }
    },
    async verify() {
      const { token } = this.$route.query;

      try {
        await this.$feathers.service('authmanagement').create({
          action: 'verify',
          token,
        });
        this.$store.commit('addToast', { message: 'Your email address has been verified!', type: 'positive' });
      } catch (err) {
        if (err.message === 'Invalid token') {
          this.$store.commit('addToast', {
            action: () => this.$router.push({ name: 'home', query: { page: 'verify', newToken: true } }), actionLabel: 'Request Another', message: 'The provided token is invalid', type: 'negative', timeout: 5000,
          });
        } else this.$store.commit('addToast', { message: `Could not verify email: ${err.message}`, type: 'negative' });
      } finally {
        if (this.$store.getters.userId) this.$router.replace({ name: 'feed' });
        else this.$router.replace({ name: 'home', query: { page: 'login' } });
      }
    },
  },
  async mounted() {
    if (this.mobile) this.$store.commit('setShowSidebar', false);
    else this.$store.commit('setShowSidebar', true);

    if (this.$route.query.page === 'verify' && this.$route.query.token) this.verify();

    const { data: events } = await this.$feathers.service('events').find({ query: { $sort: { date: 1 }, $limit: 2 } });
    this.events = events;
  },
  props: {
    dark: Boolean,
  },
  watch: {
    async page(newVal, oldVal) {
      const pageWeights = {
        home: 0,
        login: 1,
        resetPassword: 2,
        terms: 2,
        verify: 2,
        accountCreation: 3,
        welcome: 4,
      };

      if (pageWeights[newVal] > pageWeights[oldVal]) this.pageTransitionName = 'forwards';
      else this.pageTransitionName = 'backwards';

      if (newVal === 'welcome' && this.$store.state.user._id) {
        const { data: communityGuidelines } = await Feathers.service('threads').find({
          query: {
            title: { $search: 'community guidelines' }, $limit: 1, $sort: { createdAt: -1 }, $select: ['_id'],
          },
        });
        this.communityGuidelinesThreadId = communityGuidelines[0]._id;
      }
    },
    mobile(newVal) {
      if (!newVal) this.$store.commit('setShowSidebar', true);
      else this.$store.commit('setShowSidebar', false);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.home-page.mobile
  overflow-x: hidden

.view
  &.forwards-enter-active,
  &.forwards-leave-active,
  &.backwards-enter-active,
  &.backwards-leave-active,
  .sidebar section.forwards-enter-active,
  .sidebar section.forwards-leave-active,
  .sidebar section.backwards-enter-active,
  .sidebar section.backwards-leave-active
    transition: transform 250ms ease, opacity 200ms ease

    &.forwards-enter,
    &.backwards-leave-to
      transform: translateX(4rem)
      opacity: 0

    &.forwards-leave-to,
    &.backwards-enter
      transform: translateX(-4rem)
      opacity: 0

  &.home
    max-width: 100%
    min-height: 100vh
    padding-top: (294/16)rem

    .bg
      position: absolute
      top: 0
      left: -1rem
      width: calc(100% + 1rem)
      max-width: unset
      height: (294/16)rem
      margin: 0

    > img:not(.bg)
      margin: 0
      position: absolute
      left: 50%
      top: 3.375rem
      transform: translateX(-50%)

    .sidebar
      position: fixed
      left: 0
      top: 0
      bottom: 0
      z-index: 1

      section
        &.login
          display: flex
          flex-direction: column
          min-height: 100%

          h2
            margin-top: auto

          *
            flex-shrink: 0

        /* Hack to fix the bottom padding on overflow in firefox */
        &.terms
          margin-bottom: -2rem

          article
            margin-bottom: 2rem

          .terms-button
            margin-bottom: 2rem

        &.welcome
          margin-top: -6rem

          .button
            width: 100%

            &:not(:last-child)
              margin-bottom: 1rem

          .user-splat
            margin: 0 -2rem
            margin-bottom: -1.5rem

          img
            border-radius: 50%
            width: 5rem
            height: @width
            margin: 0 auto
            box-shadow: $shadow-low
            margin-bottom: 1.5rem

          h2
            color: currentColor
            margin-bottom: 2rem
            text-align: center

          > p
            margin-bottom: 1.5rem

      .input
        width: 100%
        margin-bottom: 1rem

        &:last-of-type
          margin-bottom: 2rem

      .tab-bar,
      .event-card
        margin-bottom: 1rem

      .button
        width: 100%

        &:not(:last-child)
          margin-bottom: 1rem

      .button + h1,
      .event-card + h1
      h2:last-of-type
        margin-top: 2rem

    .content-wrapper
      max-width: 720px
      margin: 0 auto

      .button
        width: 100%

      .top
        display: flex
        flex-direction: column
        padding-bottom: 0.25rem

        p
          margin-top: 0

        .button.primary
          margin: 2rem 0
          margin-bottom: 1.25rem
          z-index: 1

        .event-card
          margin-bottom: 1rem

      .rounder
        background-color: $accent-secondary
        position: relative
        margin: 0 -2rem
        margin-bottom: -1.5rem
        padding-bottom: 1.5rem
        overflow: hidden

        &.dark div
          background-color: $bg-dark

        div
          height: 0.75rem
          background-color: $bg
          border-bottom-left-radius: 0.75rem
          border-bottom-right-radius: 0.75rem
          box-shadow: $shadow-high

      .what-card
        height: 4.5rem
        color: $text-primary-dark
        background-color: $accent-secondary
        margin: 0 -2rem
        margin-bottom: 4rem
        text-align: center
        padding-top: 1.5rem
        border-bottom-left-radius: 0.75rem
        border-bottom-right-radius: 0.75rem
        box-shadow: $shadow-high

      .article-grid
        display: grid
        grid-template-columns: 1fr
        grid-gap: 4rem 2rem
        margin-bottom: 6rem

        @media screen and (min-width: 65.5rem)
          grid-gap: 4rem 8rem

        &.desktop
          @media screen and (min-width: 59.25rem)
            margin-top: 8rem
            grid-template-columns: repeat(2, 1fr)

            article.platform
              grid-row: 2
              grid-column: 2

        .logo
          align-self: center
          justify-self: center
          width: 6rem
          height: @width

        article:nth-child(odd)
          h1
            color: $accent-primary

    footer
      background-image: $default-gradient
      margin: 0 -2rem
      margin-bottom: -6rem
      margin-left: -3rem
      color: $text-primary-dark

      .drip
        height: 8.5rem
        background-image: url(../assets/drip.svg)
        background-repeat: repeat-x
        background-size: 23rem
        position: relative
        top: -1px
        border-top: 1px solid $bg

        &.dark
          border-top-color: $bg-dark
          background-image: url(../assets/drip-dark.svg);

      nav
        padding: 2rem
        text-align: center
        font-weight: 800

        a
          white-space: nowrap

        p
          font-size: (14/16)rem
          font-weight: bold
          margin-top: 0.25rem
          margin-bottom: 0

  &.login
    display: flex
    flex-direction: column
    min-height: 100vh
    padding-bottom: 2rem

    .input
      width: 100%
      margin-bottom: 1rem

      &:last-of-type
        margin-bottom: 2rem

    .button
      width: 100%

      &:not(:last-child)
        margin-bottom: 1rem

    h2
      margin-top: auto

  &.terms
    padding-bottom: 2rem

    .button
      width: 100%
      margin-top: 1rem

  &.account-creation
    padding-bottom: 2rem

    .input,
    .button
      width: 100%

    .input,
    .tab-bar
      margin-bottom: 1rem

    .button
      margin-top: 1rem

    h2:last-of-type
      margin-top: 2rem

  &.welcome
    padding-top: 0
    padding-bottom: 2rem

    .button
      width: 100%

      &:not(:last-child)
        margin-bottom: 1rem

    .user-splat
      margin: 0 -2rem
      margin-bottom: -1.5rem

    img
      border-radius: 50%
      width: 5rem
      height: @width
      margin: 0 auto
      box-shadow: $shadow-low
      margin-bottom: 1.5rem

    h2
      color: currentColor
      margin-bottom: 2rem
      text-align: center

    > p
      margin-bottom: 1.5rem

  &.reset-password,
  &.verify
    .button,
    .input
      width: 100%

    .input
      margin-bottom: 1rem

    .loader
      position: static
      height: 16rem

</style>

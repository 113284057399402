<template lang="html">
  <nav class="menu" :class="{ dark }">
    <div class="top">
      <router-link :to="{ name: 'feed' }" ref="feedLink" @blur="hideTooltip" @focus.native="showTooltip($refs.feedLink.$el, 'Feed', 500, true)" @mouseenter.native="showTooltip($refs.feedLink.$el, 'Feed', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="home" />
        <transition name="pop">
          <div v-show="showFeedNotificationDot" class="notification-dot" />
        </transition>
      </router-link>
      <router-link :to="{ name: 'write' }" :class="{ disabled: writeDisabled }" :tabindex="writeDisabled ? -1 : 0" ref="writeLink" @blur.native="hideTooltip" @focus.native="showTooltip($refs.writeLink.$el, 'Write', 500, true)" @mouseenter.native="showTooltip($refs.writeLink.$el, 'Write', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="pencil" />
      </router-link>
      <router-link :to="{ name: 'explore' }" ref="exploreLink" @blur.native="hideTooltip" @focus.native="showTooltip($refs.exploreLink.$el, 'Explore', 500, true)" @mouseenter.native="showTooltip($refs.exploreLink.$el, 'Explore', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="search" />
      </router-link>
      <router-link :to="{ name: 'forum' }" ref="forumLink" @blur.native="hideTooltip" @focus.native="showTooltip($refs.forumLink.$el, 'Forum', 500, true)" @mouseenter.native="showTooltip($refs.forumLink.$el, 'Forum', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="forum" />
        <transition name="pop">
          <div v-show="showForumNotificationDot" class="notification-dot" />
        </transition>
      </router-link>
      <router-link :to="{ name: 'inspiration', query: { page: 'writing-prompts' } }" ref="inspirationLink" @blur.native="hideTooltip" @focus.native="showTooltip($refs.inspirationLink.$el, 'Inspiration', 500, true)" @mouseenter.native="showTooltip($refs.inspirationLink.$el, 'Inspiration', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="writing-prompt" />
      </router-link>
    </div>
    <div class="bottom">
      <router-link v-if="privileged" :to="{ name: 'advanced-options' }" ref="moderatorLink" @blur.native="hideTooltip" @focus.native="showTooltip($refs.moderatorLink.$el, 'Advanced Options', 500, true)" @mouseenter.native="showTooltip($refs.moderatorLink.$el, 'Advanced Options', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="lock-open" />
      </router-link>
      <router-link :to="{ name: 'user', query: { page: 'drafts' } }" ref="userLink" @blur.native="hideTooltip" @focus.native="showTooltip($refs.userLink.$el, 'User', 500, true)" @mouseenter.native="showTooltip($refs.userLink.$el, 'User', 500, true)" @mouseleave.native="hideTooltip">
        <div class="ink" />
        <USIcon icon="user" />
      </router-link>
    </div>
  </nav>
</template>

<script>
import tooltipFunctions from '@/mixins/tooltipFunctions';

export default {
  computed: {
    privileged() {
      const allowed = ['councilmembers', 'moderators', 'admins'];
      return this.$store.state.user.groups.some((group) => allowed.includes(group));
    },
    showForumNotificationDot() {
      return this.$store.state.application.showForumNotificationDot;
    },
    writeDisabled() {
      return this.$store.state.user.groups.includes('applicants');
    },
  },
  data() {
    return {
      feedLink: null,
      writeLink: null,
      exploreLink: null,
      forumLink: null,
      moderatorLink: null,
      userLink: null,
      showFeedNotificationDot: false,
    };
  },
  mixins: [tooltipFunctions],
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.menu
  border-top-right-radius: 0.75rem
  border-bottom-right-radius: 0.75rem
  box-shadow: 0 0 0 1px $interactable, $shadow-high-right
  background-color: $bg
  padding: 0.375rem
  display: flex
  flex-direction: column
  width: 3.5rem
  height: 100vh

  &.dark
    background-color: $bg-dark
    box-shadow: 0 0 0 1px $interactable-dark, $shadow-high-right

    a
      color: $text-secondary-dark

      .notification-dot
        border-color: $bg-dark

  .bottom
    margin-top: auto

  a
    display: block
    text-align: center
    border-radius: 0.375rem
    padding: calc(0.625rem - 1px)
    text-decoration: none
    overflow: hidden
    position: relative
    color: $text-secondary
    border: 1px solid transparent
    transition: color 200ms ease, border-color 200ms ease

    &:not(:last-child)
      margin-bottom: 0.375rem

    &:hover,
    &:focus
      border-color: $accent-primary
      color: @border-color

    &.disabled
      pointer-events: none
      color: $text-disabled

    &.active
      color: $text-primary-dark
      box-shadow: $shadow-low
      border-color: $accent-primary

      .ink
        transform: translate(-50%, -50%) scale(1.4142135624)

      .notification-dot
        border-color: $accent-primary
        background-color: alpha(mix($text-primary-dark, $accent-primary, 87), 1)

    .ink
      position: absolute
      width: 100%
      top: 50%
      left: 50%
      transform: translate(-50%, -50%) scale(0)
      background-color: $accent-primary
      border-radius: 50%
      transition: transform 200ms ease

      &::before
        content: ''
        display: block
        padding-bottom: 100%

    .icon
      display: block
      position: relative

    .notification-dot
      border: 2px solid $bg
      width: calc(0.5rem + 4px)
      height: @width
      border-radius: 50%
      position: absolute
      top: calc(0.375rem - 0.125rem)
      left: calc(50% + 0.125rem)
      background-color: $accent-secondary
      transition: border-color 200ms ease, background-color 200ms ease

      &.pop-enter-active,
      &.pop-leave-active
        transition: transform 200ms cubic-bezier(0.175, 500.885, 500.32, 1.275)

        &.pop-enter,
        &.pop-leave-to
          transform: size(0)
</style>

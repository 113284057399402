<template lang="html">
  <USView class="inspiration" :dark="dark" show-back show-notifications>
    <template v-if="mobile">
      <h1>Inspiration</h1>
      <p class="description">Get your creative juices flowing with our writing games or one of our many writing prompts!</p>
      <USButton v-for="option in options" :dark="dark" :icon-left="option.icon" :key="option.value" @click="goTo(option.value)">{{ option.label }}</USButton>
    </template>
    <template v-else>
      <USSidebar :dark="dark" collapse-button>
        <h1>Inspiration</h1>
        <p class="description">Get your creative juices flowing with our writing games or one of our many writing prompts!</p>
        <USOptionList v-model="currentPage" :dark="dark" :options="options" />
      </USSidebar>
      <FadeTransition @before-enter="$root.$emit('triggerScroll')">
        <component v-if="viewComponent" :is="viewComponent" :dark="dark" @update-unsaved-changes="unsavedChanges = $event" />
      </FadeTransition>
    </template>
  </USView>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';

import EmojiGame from './games/EmojiGame.vue';
import Freewriting from './games/Freewriting.vue';
import SentenceGameLobby from './games/SentenceGameLobby.vue';
import WritingPrompts from './WritingPrompts.vue';

export default {
  beforeDestroy() {
    this.$store.commit('setShowSidebar', false);
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.$store.commit('addToast', {
        action: () => {
          this.unsavedChanges = false;
          next();
        },
        actionLabel: 'Discard Changes',
        message: 'You have unsaved changes, do you want to discard them?',
        type: 'warning',
      });
    } else next();
  },
  beforeRouteUpdate(to, from, next) {
    if (this.unsavedChanges) {
      this.$store.commit('addToast', {
        action: () => {
          this.unsavedChanges = false;
          next();
        },
        actionLabel: 'Discard Changes',
        message: 'You have unsaved changes, do you want to discard them?',
        type: 'warning',
      });
      this.$store.dispatch('stopApplicationLoading'); // because global beforeLeave gets triggered before we abort
      this.currentPage = from.query.page;
    } else next();
  },
  components: {
    FadeTransition,
    EmojiGame,
    Freewriting,
    SentenceGameLobby,
    WritingPrompts,
  },
  computed: {
    viewComponent() {
      if (!this.$route.query) return '';
      const { page } = this.$route.query;
      switch (page) {
        case 'emoji-game':
          return 'EmojiGame';
        case 'freewriting':
          return 'Freewriting';
        case 'sentence-game-lobby':
          return 'SentenceGameLobby';
        case 'writing-prompts':
          return 'WritingPrompts';
        default:
          return '';
      }
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    page() {
      return this.$route.query.page;
    },
  },
  created() {
    if (!this.mobile) this.$store.commit('setShowSidebar', true);
    if (!this.mobile && !this.currentPage) {
      this.$router.replace({ name: 'inspiration', query: { page: this.options[0].value } });
      this.$nextTick(() => { this.currentPage = this.options[0].value; });
    }
  },
  data() {
    return {
      currentPage: this.$route.query.page || '',
      options: [
        { icon: 'writing-prompt', label: 'Writing Prompts', value: 'writing-prompts' },
        { icon: 'description', label: 'Sentence Game', value: 'sentence-game-lobby' },
        { icon: 'derpy', label: 'What the ’moji?', value: 'emoji-game' },
        { icon: 'stopwatch', label: 'Free Writing', value: 'freewriting' },
      ],
      unsavedChanges: false,
    };
  },
  methods: {
    async goTo(name) {
      try {
        await this.$router.push({ name });
      } catch (err) {
        // swallow it, it’s a permission denied or duplicated navigation
      }
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    currentPage(newVal) {
      if (this.mobile) return;
      if (this.page !== newVal) this.$router.push({ query: { page: newVal } });
    },
    page(newVal) {
      if (this.currentPage !== newVal) this.currentPage = newVal;
    },
  },
};
</script>

<style lang="stylus" scoped>
.inspiration
  > .button
    width: 100%
    margin-bottom: 1rem

  .sidebar
    position: fixed
    left: 3.5rem
    top: 0
    z-index: 3

    .option-list
      margin: 0 -2rem

  p.description
    margin-bottom: 2rem

  .view
    padding: 0
</style>

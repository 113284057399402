<template lang="html">
  <FadeTransition>
    <div v-show="visible" class="notification-bar" :class="{ dark }" @click.self="$emit('close')">
      <div class="card">
        <header :class="{ scrolled }">
          <USCounterIconButton :amount="notificationAmount" :dark="dark" :icon="notificationAmount > 0 ? 'bell-ringing' : 'cross'" :show-counter="false" @click="$emit('close')" />
          <USIconButton v-if="notifications.length > 0 || nextPageAvailable" :dark="dark" icon="clear" tooltip="Clear all" @click="clearAll" />
          <USIconButton v-else :dark="dark" icon="refresh" :loading="notificationsLoading" tooltip="Check for new notifications" @click="fetchFirstNotificationPage" />
        </header>
        <FadeTransition>
          <transition-group v-if="notifications.length > 0 || nextPageAvailable" class="notifications" name="notification-group" tag="div" @scroll.native.passive="handleScroll">
            <section v-for="(group, key) in groupedNotifications" class="notification-group" :key="`h_${key}`">
              <h2>{{key}}</h2>
              <transition-group name="notification" tag="div">
                <USNotification v-for="notification in group" :id="notification._id" :author="notification.author" :content="notification.content" :created="notification.createdAt" :dark="dark" :key="notification._id" :type="notification.type" @accept="acceptApplicant" @decline="declineApplicant" @going="joinEvent($event, 'going')" @maybe="joinEvent($event, 'maybe')" @remove="removeNotificationFromServer" />
              </transition-group>
            </section>
            <USButton v-show="nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="notificationsLoading" @click="fetchNotificationPage">Load more</USButton>
          </transition-group>
          <section v-else class="empty-state">
            <USIcon icon="check" />
            <h2>All done! You have no notifications.</h2>
          </section>
        </FadeTransition>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import { Date as SugarDate } from 'sugar-date';
import FadeTransition from '@/transitions/FadeTransition.vue';
import firstName from '@/filters/firstName';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    groupedNotifications() {
      return this.notifications.reduce((accumulator, notification) => {
        const groups = accumulator;
        const creation = new Date(notification.createdAt);
        let date;

        if (SugarDate.isToday(creation)) date = 'Today';
        else if (SugarDate.isYesterday(creation)) date = 'Yesterday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isMonday(creation)) date = 'Monday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isTuesday(creation)) date = 'Tuesday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isWednesday(creation)) date = 'Wednesday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isThursday(creation)) date = 'Thursday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isFriday(creation)) date = 'Friday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isSaturday(creation)) date = 'Saturday';
        else if (SugarDate.isThisWeek(creation) && SugarDate.isSunday(creation)) date = 'Sunday';
        else if (SugarDate.isLastWeek(creation)) date = 'Last Week';
        else if (SugarDate.isLastMonth(creation)) date = 'Last Month';
        else date = 'Some Time Ago';

        if (!groups[date]) groups[date] = [];
        groups[date].push(notification);

        return groups;
      }, {});
    },
    notificationAmount: {
      get() {
        return this.$store.state.application.notificationAmount;
      },
      set(v) {
        this.$store.commit('setNotificationAmount', v);
      },
    },
  },
  async created() {
    this.$feathers.service('notifications').on('created', this.addNotification);
    this.$feathers.service('notifications').on('removed', this.removeNotification);
    this.$feathers.service('notifications').on('targetremoved', this.removeNotification);
    const { total } = await this.$feathers.service('notifications').find({ query: { $limit: 0 } });
    this.notificationAmount = total;
    this.fetchNotificationPage();
  },
  data() {
    return {
      currentPage: 0,
      notificationsLoading: false,
      nextPageAvailable: true,
      pageSize: 15,
      skipCounter: 0,
      notifications: [],
      scrolled: false,
    };
  },
  methods: {
    async acceptApplicant(applicantId) {
      try {
        const { name } = await this.$feathers.service('authmanagement').create({ action: 'accept-applicant', applicantId });
        this.$store.commit('addToast', { message: `${firstName(name)} is now a Member`, type: 'positive' });
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => this.acceptApplicant(applicantId),
          actionLabel: 'Try Again',
          message: `Could not accept applicant: ${err.message}`,
          timeout: 5000,
          type: 'negative',
        });
      }
    },
    addNotification(notification, context, index) { // context comes from feathers
      if (index > -1) this.notifications.splice(index, 0, notification);
      else this.notifications.unshift(notification);
      this.skipCounter += 1;
      this.notificationAmount += 1;
    },
    async clearAll() {
      try {
        await this.$feathers.service('notifications').patch(null, { $pull: { targets: this.$store.getters.userId } });
        this.notifications = [];
        this.$store.commit('setTooltip', null);
        this.notificationAmount = 0;
        this.nextPageAvailable = false;
        this.skipCounter = 0;
        this.currentPage = 0;
        this.scrolled = false;
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while clearning the notifications: ${err.message}`, type: 'negative' });
      }
    },
    async declineApplicant(applicantId) {
      const index = this.notifications.findIndex((notification) => notification.author._id === applicantId);
      const oldNotification = this.notifications[index];
      const timeout = 5000;
      const timeoutId = window.setTimeout(async () => {
        try {
          await this.$feathers.service('users').remove(applicantId);
        } catch (err) {
          this.$store.commit('addToast', { message: `Something went wrong while deleting the applicant: ${err.message}`, type: 'negative' });
        }
      }, timeout);

      this.removeNotification(oldNotification);
      this.$store.commit('addToast', {
        action: () => {
          window.clearTimeout(timeoutId);
          this.addNotification(oldNotification, null, index);
        },
        actionLabel: 'Undo',
        message: 'The application was rejected and the applicant’s account deleted.',
        timeout: timeout - 200, // for safety
        type: 'warning',
      });
    },
    async fetchNotificationPage() {
      const timeoutId = window.setTimeout(() => { this.notificationsLoading = true; }, 200);

      try {
        const { data: fetchedNotifications, total } = await this.$feathers.service('notifications').find({
          query: {
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage + this.skipCounter,
            $sort: { createdAt: -1 },
            $populate: 'author',
          },
        });

        this.notifications = this.notifications.concat(fetchedNotifications);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while fetching notifications: ${err.message}`, type: 'negative' });
      } finally {
        window.clearTimeout(timeoutId);
        this.notificationsLoading = false;
      }
    },
    fetchFirstNotificationPage() {
      this.currentPage = 0;
      this.skipCounter = 0;
      this.fetchNotificationPage();
    },
    handleScroll(e) {
      if (e.target.scrollTop > 0) this.scrolled = true;
      else this.scrolled = false;
    },
    async joinEvent(eventId, commitment) {
      const { userId } = this.$store.getters;
      try {
        if (commitment === 'maybe') await this.$feathers.service('events').patch(eventId, { $pull: { attendees: userId }, $addToSet: { maybeers: userId } });
        else if (commitment === 'going') await this.$feathers.service('events').patch(eventId, { $pull: { maybeers: userId }, $addToSet: { attendees: userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while sending RSVP: ${err.message}`, type: 'negative' });
      }
    },
    removeNotification(notificationArray) {
      if (!Array.isArray(notificationArray)) notificationArray = [notificationArray]; // eslint-disable-line no-param-reassign
      notificationArray.forEach((notification) => {
        const index = this.notifications.findIndex((existingNotification) => existingNotification._id === notification._id);
        if (index > -1) {
          this.notifications.splice(index, 1);
          this.notificationAmount -= 1;
          this.skipCounter -= 1;
        }
      });
    },
    async removeNotificationFromServer(id) {
      try {
        const notification = await this.$feathers.service('notifications').patch(id, { $pull: { targets: this.$store.getters.userId } });
        this.removeNotification(notification);
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while removing the notification: ${err.message}`, type: 'negative' });
      }
    },
  },
  props: {
    dark: Boolean,
    visible: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.notification-bar
  width: 100%
  height: 100%
  background-color: alpha(black, 0.6)
  position: fixed
  top: 0
  left: 0
  z-index: 8

  &.dark .card
    background-color: $bg-dark

  &.fade-enter-active,
  &.fade-leave-active
    .card
      transition: transform 200ms ease-out

    &.fade-enter,
    &.fade-leave-to
      .card
        transform: translateX(100%)

  .card
    background-color: $bg
    border-top-left-radius: 0.75rem
    border-bottom-left-radius: 0.75rem
    position: absolute
    right: 0
    width: 90%
    height: 100%
    max-width: 22.5rem
    box-shadow: $shadow-high-left
    overflow: hidden

    header
      padding: 1rem
      display: flex
      border-bottom-left-radius: 0.75rem
      border-bottom-right-radius: 0.75rem
      transition: box-shadow 200ms ease

      &.scrolled
        box-shadow: $shadow-high

      .icon-button
        margin-left: auto

    .notifications
      padding: 1rem
      overflow-x: hidden
      overflow-y: auto
      height: calc(100% - 5rem)
      overscroll-behavior: contain

      .notification
        margin-bottom: 1rem

        &.notification-enter-active,
        &.notification-move
          transition: transform 200ms ease, opacity 200ms ease

          &.notification-enter,
          &.notification-leave-to
            opacity: 0

        &.notification-leave-active
          position: absolute

          &.notification-leave-to
            transform: translateX(100%) !important

      .button
        display: flex
        margin: 0 auto

        &.notification-group-move,
        &.notification-group-leave-active
          transition: transform 200ms ease, opacity 200ms ease

          &.notification-leave-to
            opacity: 0

      h2
        text-align: center

      .notification-group,
        &.notification-group-enter-active,
        &.notification-group-leave-active
          transition: opacity 200ms ease

          &.notification-group-enter,
          &.notification-group-leave
            opacity: 0

        &.notification-group-move
          transition: transform 200ms ease

        &.notification-leave-group-active
          position: absolute
          width: calc(100% - 2rem)

      section:not(:first-child) h2
        margin-top: 3rem

    .empty-state
      padding: 0 2rem
      height: calc(100% - 10rem)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

      .icon
        width: 4rem
        margin-bottom: 1rem
        height: @width
        color: $accent-primary

      h2
        text-align: center
</style>

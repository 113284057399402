<template lang="html">
  <div
    class="action-bar"
    :class="{
      dark, 'fader-left': showFaderLeft, 'fader-right': showFaderRight, mobile, vertical,
    }"
    :style="{ transform }">
    <div class="scroll" ref="scroll" @scroll.passive="updateFaders">
      <CountTransition>
        <div class="wrapper" :key="subactionsActive ? 'sub' : 'main'">
          <USIconButton v-show="subactionsActive" :dark="dark" :data-index="0" icon="chevron-left" key="back" ref="backButton" @click="closeSubmenu" @mousedown.native="$event.currentTarget.focus()" />
          <USIconButton v-for="(action, index) in visibleActions" :color="action.color" :active="action.active" :dark="dark" :disabled="action.disabled" :icon="action.icon" :key="`${subactionsActive ? 'sub-' : ''}${index}`" :tooltip="action.tooltip" @click="handleClick(index)" @mousedown.native="$event.currentTarget.focus()" />
        </div>
      </CountTransition>
    </div>
  </div>
</template>

<script>
import CountTransition from '@/transitions/CountTransition.vue';

export default {
  beforeDestroy() {
    window.visualViewport.removeEventListener('resize', this.updateTransform);
    window.visualViewport.removeEventListener('scroll', this.updateTransform);
  },
  components: {
    CountTransition,
  },
  computed: {
    faderThreshold() {
      const actionsLength = this.subactionsActive ? this.visibleActions.length + 1 : this.visibleActions.length;
      const scrollWidth = (actionsLength * 2.75 + (actionsLength + 1) * 0.375) * this.remBase;
      const offsetWidth = Math.min(scrollWidth, window.innerWidth - 0.75 * this.remBase);
      const faderThreshold = scrollWidth - offsetWidth;
      return faderThreshold;
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
  },
  data() {
    return {
      pendingUpdate: false,
      showFaderLeft: false,
      showFaderRight: false,
      subactionsActive: false,
      transform: null,
      visibleActions: this.actions,
    };
  },
  methods: {
    closeSubmenu() {
      if (this.backAction) this.backAction();
      this.visibleActions = this.actions;
      this.updateFaders();
      this.subactionsActive = false;
    },
    async handleClick(index) {
      const action = this.visibleActions[index];
      if (action.action) action.action();
      if (action.subactions) this.openSubmenu(index);
    },
    openSubmenu(index) {
      this.visibleActions = this.actions[index].subactions;
      this.updateFaders();
      this.subactionsActive = true;
    },
    updateFaders(e) {
      const target = (e && e.target) || this.$refs.scroll;
      if (target.scrollLeft <= 0) this.showFaderLeft = false;
      else this.showFaderLeft = true;
      if (target.scrollLeft >= (this.faderThreshold - 8)) this.showFaderRight = false; // allow for a little give
      else this.showFaderRight = true;
    },
    updateTransform(e) {
      if (this.pendingUpdate) return;
      this.pendingUpdate = true;

      window.requestAnimationFrame(() => {
        this.pendingUpdate = false;

        const visualViewport = e.target;
        const keyboardVisible = document.getElementById('layoutViewport').getBoundingClientRect().height !== visualViewport.height;
        if (!keyboardVisible) this.transform = null;
        else this.transform = `translateX(${this.mobile ? '-50%' : 0}) translateY(${visualViewport.height - document.getElementById('layoutViewport').getBoundingClientRect().height + visualViewport.offsetTop}px)`;
      });
    },
  },
  mounted() {
    this.updateFaders();
    window.visualViewport.addEventListener('resize', this.updateTransform);
    window.visualViewport.addEventListener('scroll', this.updateTransform);
    this.updateTransform({ target: window.visualViewport });
  },
  props: {
    actions: Array,
    backAction: Function,
    dark: Boolean,
    vertical: Boolean,
  },
  watch: {
    actions(newVal) {
      this.subactionsActive = false;
      this.visibleActions = newVal;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.action-bar
  display: inline-block
  background-color: $bg
  border-radius: 0.75rem
  border: 1px solid $interactable
  // padding: calc(0.375rem - 1px) disabled because of collapsing right padding
  box-shadow: $shadow-low
  max-width: calc(100% - 0.75rem)
  overflow: hidden
  white-space: nowrap
  position: relative
  transition: box-shadow 200ms ease, background-color 200ms ease

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark

    &::before
      background-image: linear-gradient(90deg, $bg-dark 20%, transparent);

    &::after
      background-image: linear-gradient(-90deg, $bg-dark 20%, transparent);

  &:hover
    box-shadow: $shadow-high

    &.dark
      background-color: $elevation-primary-dark

  &.vertical:not(.mobile)
    max-height: calc(100% - 0.75rem)
    overflow-y: auto

    .icon-button
      display: block

      &:not(:last-child)
        margin-right: 0
        margin-bottom: 0.375rem

  &.mobile
    position: fixed
    bottom: 1rem
    left: 50%
    z-index: 4
    transform: translateX(-50%)
    max-width: calc(100vw - 2rem)

  &.fader-left::before,
  &.fader-right::after
    opacity: 1

  &::before,
  &::after
    content: ''
    position: absolute
    top: 0
    bottom: @top
    width: 3rem
    z-index: 1
    pointer-events: none
    opacity: 0
    transition: opacity 250ms ease

  &::before
    left: 0
    background-image: linear-gradient(90deg, $bg 20%, transparent);

  &::after
    right: 0
    background-image: linear-gradient(-90deg, $bg 20%, transparent);

  .scroll
    overflow-x: auto
    overflow-y: hidden
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

  .wrapper
    width: min-content
    padding: calc(0.375rem - 1px)

  .icon-button
    width: 2.75rem
    height: @width
    border-radius: 0.375rem
    padding: calc(0.375rem - 1px)

    &:hover,
    &:focus
      box-shadow: none

    &:not(:last-child)
      margin-right: 0.375rem
</style>

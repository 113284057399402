export default {
  methods: {
    hideTooltip() {
      this.$store.commit('setTooltip', null);
    },
    showTooltip(target, content, timeout, forceSide) {
      this.$store.dispatch('setTooltip', {
        target, content, timeout, forceSide,
      });
    },
  },
};

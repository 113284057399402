<template lang="html">
  <div class="prompt-card" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <button class="prompt-card-button" :class="{ dark }" type="button" @click="handleClick">
      <div class="ink" />
      <div class="content">
        <h2>{{title}}</h2>
        <span class="time">{{createdAt | relativeTime}}</span>
        <article class="description" v-html="$options.filters.markdown(description)" @click="handleLinkClick" />
        <span>Add the prompt tag: <strong>{{tag}}</strong></span>
      </div>
    </button>
    <FadeTransition>
      <USActionBar v-show="!mobile && showActions" :actions="promptActions" :dark="dark" vertical @mouseenter.native="clearTimeout" />
    </FadeTransition>
    <USModal :actions="null" :dark="dark" :visible="mobile && showActions" @close="showActions = false">
      <USOptionList :dark="dark" :options="promptActions" />
    </USModal>
  </div>
</template>

<script>
import markdown from '@/filters/markdown';
import relativeTime from '@/filters/relativeTime';

import handleLinkClick from '@/mixins/handleLinkClick';

import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
    promptActions() {
      const actions = [
        {
          action: this.handleEdit, icon: 'pencil', label: 'Edit prompt', tooltip: 'Edit this prompt', value: '',
        },
        {
          action: this.handleDelete, icon: 'delete', label: 'Delete prompt', tooltip: 'Delete this prompt', value: '',
        },
      ];
      if (this.mobile) {
        actions.unshift({
          action: this.handleUsePrompt, icon: 'check', label: 'Use prompt', value: '',
        });
      }
      return actions;
    },
  },
  data() {
    return {
      showActions: false,
      timeout: null,
    };
  },
  filters: {
    markdown,
    relativeTime,
  },
  methods: {
    clearTimeout() {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    },
    handleClick(e) {
      if (e.target.tagName.toLowerCase() === 'a') return;
      if (!this.mobile || !this.enableActions) this.handleUsePrompt();
      else this.showActions = true;
    },
    handleEdit() {
      this.showActions = false;
      this.$nextTick(() => this.$emit('edit'));
    },
    handleDelete() {
      this.showActions = false;
      this.$emit('delete');
    },
    handleMouseEnter() {
      if (this.mobile || !this.enableActions) return;
      if (this.timeout) this.clearTimeout();
      this.showActions = true;
    },
    handleMouseLeave() {
      if (this.mobile || !this.enableActions) return;
      if (!this.timeout) {
        this.timeout = window.setTimeout(() => {
          this.showActions = false;
        }, 200);
      }
    },
    handleUsePrompt() {
      this.showActions = false;
      this.$nextTick(() => this.$emit('prompt-click'));
    },
  },
  mixins: [handleLinkClick],
  props: {
    createdAt: Number,
    description: String,
    dark: Boolean,
    enableActions: Boolean,
    tag: String,
    title: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.prompt-card
  position: relative

  .prompt-card-button
    width: 100%
    display: flex
    align-items: center
    padding: calc(1rem - 1px)
    background-color: $bg
    border: 1px solid $interactable
    border-radius: 0.75rem
    box-shadow: $shadow-low
    cursor: pointer
    position: relative
    overflow: hidden
    z-index: 0
    transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, color 200ms ease

    &.dark
      background-color: $bg-dark
      border-color: $interactable-dark
      color: $text-primary-dark

      span:not(.description)
        color: $text-secondary-dark

    &:hover,
    &:focus
      border-color: $accent-primary
      box-shadow: $shadow-high

      &.dark
        background-color: $elevation-primary-dark

    &:focus .ink
        transform: scale(1.4142135624)

    &:active
      transform: translateY(2px)

    .ink
      width: 100%
      border-radius: 50%
      background-color: alpha($accent-primary, 0.1)
      position: absolute
      left: 0
      transform: scale(0)
      z-index: -1
      transition: transform 200ms ease, background-color 200ms ease

      &::before
        content: ''
        display: block
        padding-bottom: 100%

    .icon
      margin-right: 1rem

    .content
      width: 100%
      text-align: left

      h2
        margin-bottom: 0.25rem
        color: inherit
        width: 100%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

      span
        display: inline-block
        font-size: 0.875rem
        line-height: @font-size
        width: 100%
        color: $text-secondary

        &:not(:last-child)
          margin-bottom: 0.5rem

        &.time
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis

        strong
          text-transform: capitalize

      .description
        margin-bottom: 0.5rem

        :first-child
          margin-top: 0

        :last-child
          margin-bottom: 0

  .action-bar
    position: absolute
    left: -1rem
    transform: translateX(-100%)
    top: 0
</style>

import { render, staticRenderFns } from "./Favourites.vue?vue&type=template&id=326e805a&scoped=true&lang=html"
import script from "./Favourites.vue?vue&type=script&lang=js"
export * from "./Favourites.vue?vue&type=script&lang=js"
import style0 from "./Favourites.vue?vue&type=style&index=0&id=326e805a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326e805a",
  null
  
)

export default component.exports
<template lang="html">
  <ul class="option-list" :class="{ dark, mobile }">
    <li v-for="(option, index) in options" :class="{ active: option.value === value }" :key="index" tabindex="0" @click="executeAction(option)" @keyup.enter="executeAction(option)" @keyup.space.prevent="executeAction(option)">
      <div class="ink" />
      <USIcon v-if="option.icon" :icon="option.icon" />
      <span class="content">{{ option.label || option.value }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  methods: {
    executeAction(option) {
      if (option.action) option.action();
      this.$emit('input', option.value);
    },
  },
  props: {
    dark: Boolean,
    options: {
      type: Array,
      required: true,
    },
    value: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.option-list
  margin: 0
  padding: 0
  padding-left: 2rem
  list-style: none

  &.dark li
    color: $text-secondary-dark

  &.mobile
    padding-left: 0

    li
      padding: 0.625rem
      border-radius: 0.375rem
      margin-bottom: 0.375rem
      color: currentColor

      &:hover,
      &:focus
        color: currentColor

      &.active
        color: $text-primary-dark

      .ink
        width: 100%
        background-color: $accent-primary

  li
    display: flex
    align-items: center
    margin-bottom: 1rem
    padding: 0.75rem 2rem
    padding-left: 2rem
    border-top-left-radius: 1.5rem
    border-bottom-left-radius: 1.5rem
    color: $text-secondary
    cursor: pointer
    overflow: hidden
    position: relative
    transition: color 200ms ease

    &:hover,
    &:focus,
    &.active
      color: $accent-primary
      font-weight: 800

    &.active .ink
      transform: scale(1.4142135624)

    .ink
      width: 200%
      border-radius: 50%
      background-color: alpha($accent-primary, 0.1)
      position: absolute
      left: 0
      transform: scale(0)
      transition: transform 200ms ease

      &::before
        content: ''
        display: block
        padding-bottom: 100%

    .icon
      margin-right: 1rem
      position: relative

    .content
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      position: relative
</style>

<template lang="html">
  <USView class="general-error" :class="{ dark }" :dark="dark" show-back>
    <svg viewBox="0 0 448 448" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M248 424C248 437.255 237.255 448 224 448C210.745 448 200 437.255 200 424V296C200 282.745 189.255 272 176 272C162.745 272 152 282.745 152 296V352C152 365.255 141.255 376 128 376C114.745 376 104 365.255 104 352V310C104 296.745 93.2548 286 80 286C66.7452 286 56 296.745 56 310V327.166L55.9123 327.067C54.8643 339.354 44.5586 349 32 349C18.7452 349 8 338.255 8 325V283.543C2.78525 264.583 0 244.617 0 224C0 100.288 100.288 0 224 0C347.712 0 448 100.288 448 224C448 244.617 445.215 264.583 440 283.543V374C440 387.255 429.255 398 416 398C402.745 398 392 387.255 392 374V312C392 298.745 381.255 288 368 288C354.745 288 344 298.745 344 312V353C344 366.255 333.255 377 320 377C306.745 377 296 366.255 296 353V323C296 309.745 285.255 299 272 299C258.745 299 248 309.745 248 323V424Z" fill="#20A2F7" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M271.213 241.95C284.016 245.381 297.177 237.783 300.607 224.979L338.912 82.0224C342.343 69.2192 334.745 56.0591 321.942 52.6285L295.096 45.4352L285.7 58.9813C286.58 58.8551 287.431 58.5334 288.187 58.0272L292.495 55.1418C293.933 54.1789 295.716 53.8835 297.387 54.3313L319.871 60.3559C328.407 62.643 333.472 71.4164 331.185 79.9518L292.88 222.909C290.593 231.444 281.819 236.51 273.284 234.223L243.057 226.123L244.367 234.757L271.213 241.95Z" class="filled" />
      <path d="M285.7 58.9813L295.096 45.4352L321.942 52.6285C334.745 56.0591 342.343 69.2192 338.912 82.0224L300.607 224.979C297.177 237.783 284.016 245.381 271.213 241.95L244.367 234.757L243.057 226.123M285.7 58.9813L259.671 96.5067L268.826 143.477L236.506 182.957L243.057 226.123M285.7 58.9813C286.58 58.8551 287.431 58.5334 288.187 58.0272L292.495 55.1418C293.933 54.1789 295.716 53.8835 297.387 54.3313L319.871 60.3559C328.407 62.643 333.472 71.4164 331.185 79.9518L292.88 222.909C290.593 231.444 281.819 236.51 273.284 234.223L243.057 226.123" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M118.769 112.237C116.636 112.809 115.369 115.002 115.941 117.136L119.047 128.727C119.619 130.861 121.812 132.128 123.946 131.556L118.769 112.237ZM167.338 57.8123L173.134 56.2594C174.201 55.9735 175.297 56.6067 175.583 57.6736C175.869 58.7405 175.236 59.8372 174.169 60.1231L168.373 61.676C167.306 61.9619 166.21 61.3287 165.924 60.2618C165.638 59.1948 166.271 58.0982 167.338 57.8123Z" class="filled" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M130.046 61.593C117.243 65.0236 109.645 78.1837 113.075 90.9869L118.769 112.237L123.946 131.556L151.381 233.944C154.811 246.747 167.971 254.345 180.774 250.914L231.203 237.402L225.751 230.581L178.704 243.187C170.168 245.474 161.395 240.409 159.108 231.873L120.803 88.9164C118.516 80.3809 123.581 71.6075 132.116 69.3204L154.601 63.2958C156.272 62.848 158.055 63.1434 159.493 64.1063L163.801 66.9917C165.238 67.9545 167.021 68.25 168.692 67.8022L176.956 65.588C177.748 65.3756 178.479 65.0059 179.11 64.5102L180.474 48.0808L130.046 61.593ZM173.134 56.2594L167.338 57.8123C166.271 58.0982 165.638 59.1948 165.924 60.2618C166.21 61.3287 167.306 61.9619 168.373 61.676L174.169 60.1231C175.236 59.8372 175.869 58.7405 175.583 57.6736C175.297 56.6067 174.201 55.9735 173.134 56.2594Z" class="filled" />
      <path d="M118.769 112.237L113.075 90.9869C109.645 78.1837 117.243 65.0236 130.046 61.593L180.474 48.0808L179.11 64.5102M118.769 112.237L123.946 131.556M118.769 112.237C116.636 112.809 115.369 115.002 115.941 117.136L119.047 128.727C119.619 130.861 121.812 132.128 123.946 131.556M123.946 131.556L151.381 233.944C154.811 246.747 167.971 254.345 180.774 250.914L231.203 237.402L225.751 230.581M179.11 64.5102L175.331 110.023L206.744 146.122L198.495 196.473L225.751 230.581M179.11 64.5102C178.479 65.0059 177.748 65.3756 176.956 65.588L168.692 67.8022C167.021 68.25 165.238 67.9545 163.801 66.9917L159.493 64.1063C158.055 63.1434 156.272 62.848 154.601 63.2958L132.116 69.3204C123.581 71.6075 118.516 80.3809 120.803 88.9164L159.108 231.873C161.395 240.409 170.168 245.474 178.704 243.187L225.751 230.581M168.373 61.676L174.169 60.1231C175.236 59.8372 175.869 58.7405 175.583 57.6736C175.297 56.6067 174.201 55.9735 173.134 56.2594L167.338 57.8123C166.271 58.0982 165.638 59.1948 165.924 60.2618C166.21 61.3287 167.306 61.9619 168.373 61.676Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path class="blink" d="M236.464 20L228.464 36H236.464L228.464 52" stroke="#B100CE" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      <path class="blink" d="M275.928 32.1436L261 42L267.928 46L253 55.8564" stroke="#B100CE" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      <path class="blink" d="M189 32.1436L203.928 42L197 46L211.928 55.8564" stroke="#B100CE" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <section>
      <h1>Oops, that doesn’t look right…</h1>
      <p>Something went terribly wrong, we’re sorry. If this keeps happening, contact an administrator.</p>
    </section>
    <USButton :dark="dark" primary @click="previousPath ? $router.replace(previousPath) : { name: 'feed' }">Go back to your comfort zone</USButton>
  </USView>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPath = from.fullPath; // eslint-disable-line no-param-reassign
    });
  },
  data() {
    return {
      previousPath: null,
    };
  },
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'

.general-error
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  min-height: 100vh

  &.dark svg
    .filled,
    .filled-circle
      fill: $bg-dark

  svg
    width: 100%
    max-width: 28rem

    .filled
      fill: $bg

    .filled-circle
      fill: #E9F6FF

    .blink
      animation: blink 500ms infinite alternate
      stroke-dashoffset: 6

      @keyframes blink
        0%,
        50%
          opacity: 1
        100%
          opacity: 0

  section
    margin: 2rem 0
</style>

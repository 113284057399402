<template lang="html">
  <div class="slider" :class="{ dark }" style="">
    <input :max="max" :min="min" type="range" :value="value" @blur="focussed = false" @focus="focussed = true" @input="handleInput">
    <FadeTransition>
      <output v-show="focussed" :style="{ transform: `translate(calc( (${value} - ${min}) / (${max} - ${min}) * ${trackWidth}rem - 50%), -2.75rem)` }">{{value}}</output>
    </FadeTransition>
  </div>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    trackWidth() {
      const { remBase } = this.$store.state.application;
      if (!this.$el) return 0;
      return (this.$el.offsetWidth / remBase) - 2.25;
    },
  },
  data() {
    return {
      focussed: false,
    };
  },
  methods: {
    handleInput(e) {
      window.requestAnimationFrame(() => this.$emit('input', e.target.value));
    },
  },
  props: {
    dark: Boolean,
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    value: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

$track-width = 100%
$track-height = 0.5rem
$thumb-diameter = 2.25rem

track()
  box-sizing: border-box
  border: none
  width: $track-width
  height: $track-height
  background-color: $interactable-disabled
  border-radius: 0.5 * $track-height

trackDark()
  background-color: $interactable-disabled-dark

thumb()
  box-sizing: border-box
  border: 1px solid $interactable
  width: $thumb-diameter
  height: @width
  border-radius: 0.375rem
  background-color: $bg
  box-shadow: $shadow-low
  transition: border-color 200ms ease, box-shadow 200ms ease

  &:hover
    border-color: $accent-primary
    box-shadow: $shadow-high

thumbDark()
  background-color: $bg-dark
  border-color: $interactable-dark
  transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease

  &:hover
    border-color: $accent-primary
    box-shadow: $shadow-high
    background-color: $elevation-primary-dark

thumbFocus()
  border-color: $accent-primary
  box-shadow: $shadow-high

thumbFocusDark()
  background-color: $elevation-primary-dark

.slider
  display: inline-flex
  align-items: center
  position: relative
  width: $track-width
  height: $thumb-diameter

  &.dark input
    &::-webkit-slider-runnable-track
      trackDark()

    &::-moz-range-track
      trackDark()

    &::-ms-track
      trackDark()

    &::-webkit-slider-thumb
      thumbDark()

    &::-moz-range-thumb
      thumbDark()

    &::-ms-thumb
      thumbDark()

    &:focus
      &::-webkit-slider-runnable-track
        trackFocusDark()

      &::-moz-range-track
        trackFocusDark()

      &::-ms-track
        trackFocusDark()

      &::-webkit-slider-thumb
        thumbFocusDark()

      &::-moz-range-thumb
        thumbFocusDark()

      &::-ms-thumb
        thumbFocusDark()

  input
    -webkit-appearance: none
    flex: 1
    margin: 0
    padding: 0
    min-width: 0
    min-height: $thumb-diameter
    background-color: transparent
    font: inherit
    cursor: pointer

    &::-webkit-slider-thumb
      -webkit-appearance: none

    &::-webkit-slider-runnable-track
      track()

    &::-moz-range-track
      track()

    &::-ms-track
      track()

    &::-webkit-slider-thumb
      margin-top: 0.5 * ($track-height - $thumb-diameter)
      thumb()

    &::-moz-range-thumb
      thumb()

    &::-ms-thumb
      margin-top: 0
      thumb()

    &::-ms-tooltip
      display: none

    &:focus
      &::-webkit-slider-runnable-track
        trackFocus()

      &::-moz-range-track
        trackFocus()

      &::-ms-track
        trackFocus()

      &::-webkit-slider-thumb
        thumbFocus()

      &::-moz-range-thumb
        thumbFocus()

      &::-ms-thumb
        thumbFocus()

  output
    display: block
    position: absolute
    top: 0
    left: 0.5 * $thumb-diameter
    padding: 0.5rem 1rem
    border-radius: 0.75rem
    background-color: $elevation-primary-dark
    color: $text-primary-dark
    box-shadow: $shadow-low
</style>

<template lang="html">
  <transition-group class="list-transition-wrapper" name="list" :tag="tag" v-bind="$attrs" v-on="$listeners" @before-leave="setPositions" @after-leave="cleanup" @leave-cancelled="cleanup">
    <slot />
  </transition-group>
</template>

<script>
export default {
  methods: {
    cleanup(el) {
      el.style.removeProperty('height');
      el.style.removeProperty('left');
      el.style.removeProperty('top');
      el.style.removeProperty('width');
      el.style.removeProperty('absolute');
    },
    setPositions(el) {
      const {
        offsetHeight: height,
        offsetLeft: left,
        offsetTop: top,
        offsetWidth: width,
      } = el;

      /* eslint-disable no-param-reassign */
      el.style.height = `${height}px`;
      el.style.left = `${left}px`;
      el.style.top = `${top}px`;
      el.style.width = `${width}px`;
      this.$nextTick(() => { el.style.position = 'absolute'; });
      /* eslint-enable no-param-reassign */
    },
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="stylus">
.list-transition-wrapper
  position: relative

  .list-enter-active,
  .list-leave-active,
  .list-move
    transition: opacity 200ms ease, transform 200ms ease !important

    &.list-enter,
    &.list-leave-to
      opacity: 0

    &.list-enter
      transform: translateY(-1rem)

    &.list-leave-to
      transform: translateY(1rem)

  .list-leave-active
    position: absolute
    z-index: 2

</style>

<template lang="html">
  <USSidebar class="reading-list" :class="{ mobile }" :dark="dark">
    <transition>
      <div v-if="mobile" v-show="$store.state.application.showSidebar" class="mask" @click="hideMobileReadingList" />
    </transition>
    <section class="info" :class="{ shaded }">
      <header v-if="!mobile">
        <USIconButton :dark="dark" icon="arrow-left" @click="$emit('back')" />
        <USIconButton :dark="dark" icon="sidebar-collapse" @click="$store.commit('setShowSidebar', false)" />
      </header>
      <h1>{{name}}</h1>
      <p v-if="description" v-html="$options.filters.markdown(description)" @click="handleLinkClick($event, true)" />
      <section v-if="sortable">
        <span>Sort by:</span>
        <USSelectBox v-model="sort" :dark="dark" :options="[{ label: 'Newest', value: 'newest' }, { label: 'Oldest', value: 'oldest' }, { label: 'Most Loved', value: 'hearts' }]" />
      </section>
    </section>
    <ul @scroll.passive="checkShading">
      <transition-group name="list" tag="div" @after-enter="transitioning = false" @before-leave="transitioning = true">
        <li v-for="post in posts" :key="post._id">
          <router-link :to="{ name: 'read', params: { id: post._id }, query: { ...$route.query } }" replace>
            <div class="ink" />
            <h2>{{post.title || 'Untitled'}}</h2>
            <span class="blurb">{{post.blurb}}</span>
            <span class="author">{{post.author.name | authorShortForm }}, {{post.createdAt | relativeTime}}</span>
          </router-link>
        </li>
        <li v-if="loading" class="loader" key="loader">
          <USSpinner />
          <span>Loading more…</span>
        </li>
        <li class="sentinel" key="sentinel" ref="sentinel">
          <USButton v-show="moreAvailable && !loading" :dark="dark" @click="$emit('bottom-reached')">Load More</USButton>
        </li>
      </transition-group>
    </ul>
  </USSidebar>
</template>

<script>
import authorShortForm from '@/filters/authorShortForm';
import markdown from '@/filters/markdown';
import relativeTime from '@/filters/relativeTime';

import handleLinkClick from '@/mixins/handleLinkClick';

export default {
  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  data() {
    return {
      observer: null,
      shaded: false,
      sort: this.initialSort || 'newest',
      transitioning: false,
    };
  },
  filters: {
    authorShortForm,
    markdown,
    relativeTime,
  },
  methods: {
    checkShading(e) {
      if (e.target.scrollTop > 0) this.shaded = true;
      else this.shaded = false;
    },
    hideMobileReadingList() {
      this.$store.commit('setShowSidebar', false);
      this.$store.commit('setBodyLock', false);
    },
  },
  mixins: [handleLinkClick],
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting && !this.transitioning) this.$emit('bottom-reached');
    }, {
      root: this.$el,
    });
    this.observer.observe(this.$refs.sentinel);
  },
  props: {
    dark: Boolean,
    description: String,
    initialSort: {
      type: String,
      validate: (v) => ['newest', 'oldest', 'hearts'].includes(v),
    },
    loading: Boolean,
    moreAvailable: Boolean,
    name: String,
    posts: Array,
    sortable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    sort(newVal) {
      this.$emit('sortchange', newVal);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.reading-list.sidebar.padded
  display: flex
  flex-direction: column
  overflow: hidden
  padding: 0
  padding-left: 1rem

  &.dark
    li
      a
        color: $text-secondary-dark

        &:focus:not(:hover)
          color: $text-primary-dark

        .author
          color: $text-secondary-dark

  &.mobile
    padding-left: 0
    max-height: 90vh
    position: fixed
    top: auto
    bottom: 0
    left: 0
    margin: 0
    width: 100%
    border-bottom-left-radius: 0
    border-top-left-radius: 0.75rem
    box-shadow: $shadow-high-reverse
    overflow: visible

    &.dark ul li a
      color: $text-primary-dark

    &.slide-leave-active
      transition-delay: 100ms

    &.slide-enter,
    &.slide-leave-to
      transform: translateY(100%)

    .mask // massive hack to get it to look like it’s behind the card
      position: absolute
      top: -10vh
      left: 0
      width: 100%
      height: 10vh
      background-color: alpha(black, 0.6)

      &::after,
      &::before
        content: ''
        display: block
        position: absolute
        height: 0.75rem
        width: @height * 2
        bottom: -@height
        border-top-left-radius: @height
        box-shadow: (-@height) 0 0 0 @background-color
        background-color: transparent

      &::after
        right: 0
        border-top-left-radius: 0
        border-top-right-radius: @height
        box-shadow: 0.75rem 0 0 0 alpha(black, 0.6)

      &.v-enter-active,
      &.v-leave-active
        transition: opacity 100ms ease

        &.v-enter,
        &.v-leave-to
          opacity: 0

      &.v-enter-active
        transition-delay: 200ms

    .info
      padding: 2rem

      > section
        margin-top: 1rem

    ul
      padding-left: 0.5rem
      padding-right: 0.5rem

      li a
        border-radius: 0.75rem
        padding: 1rem 2rem
        color: $text-primary

        .ink
          width: 100%

  .info
    padding: 1rem 2rem 4rem 2rem
    position: relative
    z-index: 1

    &.shaded
      box-shadow: $shadow-high
      border-bottom-left-radius: 0.75rem
      border-bottom-right-radius: 0.75rem

    > header
      margin: -1rem -2rem
      margin-bottom: 0
      padding: 1rem
      display: flex
      justify-content: space-between

    > section
      margin-top: 3rem
      display: flex
      align-items: center

      span
        margin-right: auto

  ul
    list-style: none
    padding: 0
    padding-left: 2rem
    margin: 0
    position: relative
    overflow-x: hidden
    overflow-y: auto
    height: 100%
    background-color: inherit
    overscroll-behavior: contain

    li
      &:not(:last-child)
        margin-bottom: 1rem

      &.loader
        margin-left: -2rem
        display: flex
        align-items: center
        justify-content: center

        .spinner
          margin-right: 1rem

      &.sentinel
        margin-left: -2rem
        display: flex
        justify-content: center

      &.list-leave-active
        position: absolute
        width: calc(100% - 2rem)

      &.list-move
        transition: transform 200ms ease

      &.list-enter-active,
      &.list-leave-active
        transition: transform 200ms ease

        &.list-enter,
        &.list-leave-to
          transform: translateX(100%)

      a
        padding: 1rem 2rem 1rem 3.75rem
        border-top-left-radius: 3rem
        border-bottom-left-radius: 3rem
        color: $text-secondary
        position: relative
        overflow: hidden
        display: block
        text-decoration: none

        &:hover
          color: $accent-primary

          .author
            color: @color

        &:focus:not(:hover)
          color: $text-primary

        &:focus,
        &.active
          .ink
            transform: translateY(-50%) scale(1.4142135624)

        .ink
          width: 200%
          border-radius: 50%
          background-color: alpha($accent-primary, 0.1)
          position: absolute
          top: 50%
          left: 0
          transform: translateY(-50%) scale(0)
          transition: transform 200ms ease

          &::before
            content: ''
            display: block
            padding-bottom: 100%

        h2, span
          color: inherit
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

        h2
          margin-bottom: 0

        span
          display: block

          &.author
            font-size: 0.875rem
            color: $text-secondary
</style>

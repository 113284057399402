<template lang="html">
  <div class="loader">
    <USSpinner type="squiggle" />
    <USSpinner type="squiggle" />
    <USSpinner type="squiggle" />
    <span>{{message}}…</span>
  </div>
</template>

<script>
export default {
  computed: {
    message() {
      const messages = [
        'Adjusting lines',
        'Creating rhymes',
        'Folding pages',
        'Refilling ink',
        'Feeding M.',
        'Working in retail',
        'Calming down Missiles',
      ];
      return messages[Math.floor(Math.random() * messages.length)];
    },
  },
};
</script>

<style lang="stylus" scoped>

.loader
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  .spinner.squiggle
    display: block
    height: 2.25rem
    width: 6rem
    margin: 0 auto

    &:not(:last-child)
      margin-bottom: -1rem

    &:nth-child(2)
      animation-delay: 250ms

    &:nth-child(3)
      animation-delay: 500ms

  span
    display: inline-block
    margin: 0 auto
    margin-top: 1rem
    opacity: 0.6
</style>

<template lang="html">
  <div class="select-box" :class="{ disabled }">
    <span class="spacer">{{value ? labelOrValue : placeholder}}</span>
    <div
      class="select-box-content"
      :class="{ active, dark }"
      ref="content"
      :style="{
        bottom: fromBottom ? 0 : '', height, overflowY: activateOverflow || null, top: fromBottom ? '' : 0,
      }"
      :tabindex="!disabled ? 0 : null"
      @focus="activate"
      @focusout="checkDeactivation"
      @keyup.self.space.prevent="deactivate"
      @keyup.self.enter="deactivate">
      <section class="current-selection" @click="deactivate">
        <div class="ink" />
        <span v-if="!value" class="placeholder">{{placeholder}}</span>
        <span v-if="value" class="content">{{labelOrValue}}</span>
        <USIcon icon="chevron-down" :style="{ transform: active ? 'rotate(180deg)' : '' }" />
      </section>
      <ul v-show="active">
        <li v-for="(option, index) in otherOptions" :class="{ disabled: option.disabled }" :key="index" :tabindex="active && !option.disabled ? 0 : -1" @click="handleClick(option)" @keyup.space.prevent="handleClick(option)" @keyup.enter="handleClick(option)">
          <div class="ink" />
          {{ option.label || option.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    height() {
      if (this.active) return `calc(0.375rem + ${this.otherOptions.length + 1} * 2.625rem)`;
      return '';
    },
    labelOrValue() {
      const selectedOption = this.options.find((option) => option.value === this.value);
      if (!selectedOption) return 'Invalid Value';
      if (selectedOption.label) return selectedOption.label;
      return selectedOption.value;
    },
    otherOptions() {
      return this.options.filter((option) => option.value !== this.value);
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
  },
  data() {
    return {
      active: false,
      activateOverflow: false,
      fromBottom: false,
      selection: this.value,
    };
  },
  methods: {
    activate() {
      if (this.$el.getBoundingClientRect().top + this.remBase * 0.375 + (this.otherOptions.length + 1) * (this.remBase * 2.625) > window.innerHeight) this.fromBottom = true;
      else this.fromBottom = false;
      this.active = true;
    },
    checkDeactivation(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.active = false;
      }
    },
    deactivate() {
      this.$refs.content.focus();
      this.$refs.content.blur();
    },
    handleClick(option) {
      if (!option.disabled) {
        this.deactivate();
        this.$emit('input', option.value);
      }
    },
  },
  props: {
    dark: Boolean,
    disabled: Boolean,
    options: Array,
    placeholder: {
      type: String,
      default: 'Select something',
    },
    value: [String, Number],
  },
  watch: {
    active(newVal) {
      if (newVal) {
        window.setTimeout(() => {
          this.activateOverflow = 'auto';
        }, 200);
      } else this.activateOverflow = false;
    },
    value(newValue) {
      this.selection = newValue;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.select-box
  display: inline-block
  height: 3rem
  position: relative
  min-width: 8rem

  &.disabled
    pointer-events: none

    .select-box-content
      border-color: $interactable-disabled
      box-shadow: none
      color: $text-disabled

      &.dark
        border-color: $interactable-disabled-dark
        color: $text-disabled-dark

        .current-selection .placeholder
          color: $text-disabled-dark

      .current-selection .placeholder
        color: $text-disabled

  .spacer
    padding: 0.75rem
    padding-left: 1.125rem
    padding-right: 3rem
    white-space: nowrap

  .select-box-content
    cursor: pointer
    padding: calc(0.375rem - 1px)
    border: 1px solid $interactable
    border-radius: 0.75rem
    background-color: transparent
    color: $text-primary
    line-height: 1.5rem
    box-shadow: $shadow-low
    text-shadow: $text-shadow
    height: 3rem
    width: 100%
    max-height: 50vh
    overflow: hidden
    position: absolute
    background-color: $bg
    transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease, border-color 200ms ease, height 200ms ease

    &.dark
      background-color: $bg-dark
      color: $text-primary-dark
      border-color: $interactable-dark

      .current-selection .placeholder
        color: $text-secondary-dark

      li.disabled
        color: $text-disabled-dark

    &.active
      cursor: default
      // overflow-y: auto
      z-index: 2

      .current-selection
        pointer-events: unset

    &:hover,
    &:focus-within,
    &:focus
      box-shadow: $shadow-high

      &.dark
        background-color: $elevation-primary-dark

    &:focus .current-selection
      border-color: $accent-primary
      color: @border-color

      .placeholder
        color: @border-color

      .ink
        transform: scale(1.4142135624)

    .current-selection
      pointer-events: none
      cursor: pointer
      display: flex
      justify-content: space-between
      align-items: center
      padding: calc(0.375rem - 1px) calc(0.75rem - 1px)
      padding-right: calc(0.375rem - 1px)
      margin-bottom: 0.375rem
      white-space: nowrap
      border: 1px solid transparent
      border-radius: 0.375rem
      position: relative
      overflow: hidden
      transition: border-color 200ms ease, color 200ms ease

      .placeholder,
      .content
        overflow: hidden
        text-overflow: ellipsis

      .placeholder
        color: $text-secondary

      .icon
        margin-left: 0.375rem
        transition: transform 200ms ease

    ul
      list-style: none
      padding: 0
      margin: 0

      li
        cursor: pointer
        display: flex
        align-items: center
        text-align: left
        padding: calc(0.375rem - 1px) calc(0.75rem - 1px)
        border-radius: 0.375rem
        border: 1px solid transparent
        position: relative
        overflow: hidden
        transition: border-color 200ms ease, color 200ms ease

        &:not(:last-child)
          margin-bottom: 0.375rem

        &:hover,
        &:focus
          border-color: $accent-primary
          color: @border-color

        &:focus .ink
          transform: scale(1.4142135624)

        &.disabled
          pointer-events: none
          cursor: default
          color: $text-disabled

    .ink
      width: 100%
      border-radius: 50%
      background-color: alpha($accent-primary, 0.1)
      position: absolute
      left: 0
      transform: scale(0)
      z-index: -1
      transition: transform 200ms ease

      &::before
        content: ''
        display: block
        padding-bottom: 100%
</style>

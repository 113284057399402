import Vue from 'vue';
import App from './App.vue';
import Feathers from './feathersApp';
import router from './router';
import store from './store';
import './registerServiceWorker';

// import used fonts
import 'typeface-nunito'; // eslint-disable-line import/extensions
import 'typeface-pt-serif'; // eslint-disable-line import/extensions

// import base components globally for convenience
const requireComponent = require.context('./components', false, /US[A-Z]\w+\.(vue|js)$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.prototype.$feathers = Feathers; // make the feathers app available to all components for convenience

Vue.config.productionTip = false;

new Vue({
  async created() {
    window.addEventListener('beforeinstallprompt', (prompt) => {
      prompt.preventDefault();
      this.$store.commit('setInstallPrompt', prompt);
    });
  },
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

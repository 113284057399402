<template lang="html">
  <FadeTransition>
    <div v-show="visible" class="modal" :class="{ dark }" @click.self="$emit('close')">
      <div class="card" :class="{ actions, mobile, title }" ref="card" :style="{ transform: transformY !== 0 ? `translateY(${transformY}%)` : '', transition: transitionEnabled ? 'transform 200ms ease-out' : '' }" @touchstart="swipeStart" @touchend="swipeEnd" @touchmove="swipeUpdate">
        <h2 v-if="title">{{title}}</h2>
        <slot />
        <footer v-if="actions && title">
          <USButton color="danger" :dark="dark" :disabled="actions[0].disabled" :icon-left="actions[0].icon" :loading="loading.left" @click="handleAction(actions[0].action, 'left')">{{actions[0].label}}</USButton>
          <span class="separator" />
          <USButton color="positive" :dark="dark" :disabled="actions[1].disabled" :icon-right="actions[1].icon" :loading="loading.right" @click="handleAction(actions[1].action, 'right')">{{actions[1].label}}</USButton>
        </footer>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
    modalVisible() {
      return this.$store.state.application.modalVisible;
    },
  },
  data() {
    return {
      loading: {
        left: false,
        right: false,
      },
      maxSwipeDistance: null,
      swiping: false,
      touchStartY: null,
      transformY: 0,
      transitionEnabled: false,
    };
  },
  methods: {
    getScrollParent(node) {
      const isElement = node instanceof HTMLElement;
      const computedStyle = isElement && window.getComputedStyle(node);
      const overflowY = computedStyle && computedStyle.overflowY;
      const overflowX = computedStyle && computedStyle.overflowX;
      const isScrollable = (overflowY && !(overflowY.includes('hidden') || overflowY.includes('visible'))) || (overflowX && !(overflowX.includes('hidden') || overflowX.includes('visible')));

      if (!node) {
        return null;
      }
      if (isScrollable && (node.scrollHeight >= node.clientHeight || node.scrollWidth >= node.clientWidth)) {
        return node;
      }

      return this.getScrollParent(node.parentNode) || this.$refs.card;
    },
    async handleAction(action, side) {
      if (action) {
        this.loading[side] = true;
        await action();
        this.loading[side] = false;
      }
      this.$emit('close');
    },
    handleSwipeEnd() {
      this.transitionEnabled = true;
      if (this.transformY >= 50) {
        this.transformY = 100;
        this.$emit('close');
      } else this.transformY = 0;
    },
    swipeEnd(e) {
      if (this.swiping) this.swiping = false;
      if (e.type === 'mouseup') {
        window.removeEventListener('mousemove', this.swipeUpdate, false);
        window.removeEventListener('mouseup', this.swipeEnd, false);
      }
      this.handleSwipeEnd();
    },
    swipeStart(e) {
      const scrollParent = this.getScrollParent(e.target);
      if (scrollParent.scrollTop !== 0) return; // we're scrolling
      if (scrollParent.scrollWidth !== scrollParent.clientWidth) return; // we're in an actionBar

      if (this.transitionEnabled) this.transitionEnabled = false;
      this.swiping = true;
      this.touchStartY = e.type === 'touchstart' ? e.changedTouches[0].clientY : e.clientY;
      this.maxSwipeDistance = window.innerHeight - this.touchStartY;
      if (e.type === 'mousedown') {
        window.addEventListener('mousemove', this.swipeUpdate, false);
        window.addEventListener('mouseup', this.swipeEnd, false);
      }
    },
    swipeUpdate(e) {
      if (!this.swiping) return; // we’re scrolling

      const currentY = e.type === 'touchmove' ? e.changedTouches[0].clientY : e.clientY;
      const distance = currentY - this.touchStartY;
      if (distance > 0 && e.cancelable !== false) e.preventDefault();
      if (distance > 0) this.transformY = Math.min((distance / this.maxSwipeDistance) * 100, 100);
      else this.transformY = 0;
    },
  },
  props: {
    actions: {
      type: Array,
      default: () => [{ icon: 'cross', label: 'cancel' }, { icon: 'check', label: 'confirm' }],
      validator: (v) => v.length === 2,
    },
    dark: Boolean,
    title: String,
    visible: Boolean,
  },
  watch: {
    modalVisible(newVal) {
      if (!newVal && this.visible) this.$emit('close');
    },
    visible(newValue) {
      if (newValue) this.$store.commit('setModalVisible', true);
      if (!newValue) this.$store.commit('setModalVisible', false);
      if (!newValue && this.transformY !== 0) this.transformY = 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.modal
  display: flex
  justify-content: center
  align-items: flex-end
  width: 100%
  height: 100%
  background-color: alpha(black, 0.6)
  position: fixed
  top: 0
  left: 0
  z-index: 8

  &.dark .card
    background-color: $bg-dark

    footer
      background-color: $elevation-primary-dark

      .separator
        background-color: $interactable-disabled-dark

  &.fade-enter-active,
  &.fade-leave-active
    .card
      transition: transform 200ms ease-out

    &.fade-enter,
    &.fade-leave-to
      .card
        transform: translateY(4rem)

        &.mobile
          transform: translateY(100%) !important

  .card
    align-self: center
    background-color: $bg
    color: $text
    border-radius: 0.75rem
    width: 100%
    max-width: 40rem
    max-height: 90%
    padding: 2rem
    overflow-x: hidden
    overflow-y: auto
    box-shadow: $shadow-high
    position: relative
    overscroll-behavior: contain

    &:not(.title)
      padding: 0.375rem

    &.actions
      padding-bottom: 3.5rem

    &.mobile
      touch-action: pan-y
      align-self: auto
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      box-shadow: $shadow-high-reverse

    h2
      margin-bottom: 2rem

    footer
      display: flex
      // position: absolute
      // bottom: 0
      // left: 0
      // right: 0
      margin: 2rem -2rem -3.5rem -2rem
      box-shadow: $shadow-low-reverse
      background-color: $bg
      border-top-left-radius: 0.75rem
      border-top-right-radius: 0.75rem
      padding: 0.375rem

      .button
        box-shadow: none
        text-transform: capitalize
        width: 100%
        padding-top: calc(0.625rem - 1px)
        padding-bottom: @padding-top
        border-radius: 0.375rem

        &:not(:hover):not(:focus)
          border-color: transparent

      .separator
        display: block
        width: 1px
        flex-shrink: 0
        margin: -0.375rem 0.375rem
        background-color: $interactable-disabled
</style>

<template lang="html">
  <div class="badge" :class="{ dark }" tabindex="0" @click="$emit('click', $event)">
    <div class="ink" />
    <div class="badge-image">
      <svg width="51" height="74" viewBox="0 0 51 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8057 38.8049H38.8057V71.9764L27.6341 60.8049C26.072 59.2428 23.5393 59.2428 21.9772 60.8049L10.8057 71.9764V38.8049Z" stroke="url(#paint0_linear)" stroke-width="4" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.8049 3.001C34.148 0.131247 30.4785 -0.852005 27.6087 0.804849L25.3048 2.13498L23.001 0.80485C20.1312 -0.852004 16.4617 0.131246 14.8048 3.001L13.7634 4.80485H10.8048C7.49114 4.80485 4.80485 7.49114 4.80485 10.8048V13.9707L3.35999 14.8048C0.490233 16.4617 -0.493019 20.1312 1.16384 23.001L2.21084 24.8145L0.80485 27.2497C-0.852005 30.1195 0.131245 33.789 3.001 35.4459L4.80485 36.4873V38.8048C4.80485 42.1186 7.49114 44.8048 10.8048 44.8048H13.7523L15.1638 47.2497C16.8207 50.1195 20.4902 51.1027 23.36 49.4459L25.3048 48.323L27.2497 49.4459C30.1195 51.1027 33.789 50.1195 35.4459 47.2497L36.8574 44.8048H38.8048C42.1186 44.8048 44.8048 42.1186 44.8048 38.8049V37.0647L47.6087 35.4459C50.4785 33.789 51.4617 30.1195 49.8049 27.2497L48.3989 24.8145L49.4459 23.001C51.1027 20.1312 50.1195 16.4617 47.2497 14.8048L44.8048 13.3933V10.8048C44.8048 7.49114 42.1186 4.80485 38.8049 4.80485H36.8463L35.8049 3.001Z" fill="url(#paint1_linear)" />
        <defs>
          <linearGradient id="paint0_linear" x1="8.80566" y1="36.8049" x2="45.5674" y2="68.4486" gradientUnits="userSpaceOnUse">
            <stop offset="0.18232" stop-color="#20A2F7" />
            <stop offset="1" stop-color="#B100CE" />
          </linearGradient>
          <linearGradient id="paint1_linear" x1="0" y1="0" x2="50.2494" y2="50.6084" gradientUnits="userSpaceOnUse">
            <stop offset="0.18232" stop-color="#20A2F7" />
            <stop offset="1" stop-color="#B100CE" />
          </linearGradient>
        </defs>
      </svg>
      <USIcon :icon="icon" />
    </div>
    <section>
      <h1>{{title}}</h1>
      <span class="author">by {{author | capitalize}}</span>
      <span class="award">{{award}}</span>
    </section>
  </div>
</template>

<script>
import capitalize from '@/filters/capitalize';

export default {
  filters: {
    capitalize,
  },
  props: {
    author: String,
    award: String,
    dark: Boolean,
    icon: String,
    title: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.badge
  border: 1px solid $interactable
  border-radius: 0.75rem
  padding: 1rem
  box-shadow: $shadow-low
  position: relative
  overflow: hidden
  display: flex
  cursor: pointer
  transition: border-color 200ms ease, box-shadow 200ms ease, color 200ms ease

  &.dark
    border-color: $interactable-dark

  &:hover,
  &:focus
    box-shadow: $shadow-high
    border-color: $accent-primary
    color: @border-color

  &:focus .ink
    transform: scale(1.4142135624)

  .badge-image
    margin-right: 1rem
    position: relative
    height: 4.625rem
    flex-shrink: 0

    .icon
      position: absolute
      top: 0.8125rem
      left: @top
      color: $text-primary-dark

  > section
    width: calc(100% - 4.1875rem)

    h1,
    .author,
    .award
      display: block
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis;

    h1,
    .author
      font-family: 'PT Serif', serif
      margin-bottom: 0
      font-weight: normal
      color: inherit

    .author
      font-style: italic
      margin-bottom: 0.375rem

    .award
      font-size: 0.75rem

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    align-self: center
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%
</style>

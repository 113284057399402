<template lang="html">
  <USView class="not-found" :class="{ dark }" :dark="dark" show-back>
    <svg viewBox="0 0 448 448" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M248 424C248 437.255 237.255 448 224 448C210.745 448 200 437.255 200 424V296C200 282.745 189.255 272 176 272C162.745 272 152 282.745 152 296V352C152 365.255 141.255 376 128 376C114.745 376 104 365.255 104 352V310C104 296.745 93.2548 286 80 286C66.7452 286 56 296.745 56 310V327.166L55.9123 327.067C54.8643 339.354 44.5586 349 32 349C18.7452 349 8 338.255 8 325V283.543C2.78525 264.583 0 244.617 0 224C0 100.288 100.288 0 224 0C347.712 0 448 100.288 448 224C448 244.617 445.215 264.583 440 283.543V374C440 387.255 429.255 398 416 398C402.745 398 392 387.255 392 374V312C392 298.745 381.255 288 368 288C354.745 288 344 298.745 344 312V353C344 366.255 333.255 377 320 377C306.745 377 296 366.255 296 353V323C296 309.745 285.255 299 272 299C258.745 299 248 309.745 248 323V424Z" fill="#20A2F7" />
      <rect x="64" y="182" width="320" height="12" rx="6" class="filled" stroke="#20A2F7" stroke-width="2" />
      <path d="M100 200H124V212C124 218.627 118.627 224 112 224V224C105.373 224 100 218.627 100 212V200Z" class="filled" stroke="#20A2F7" stroke-width="2" />
      <rect x="96" y="194" width="32" height="6" rx="3" class="filled" stroke="#20A2F7" stroke-width="2" />
      <circle cx="112" cy="214" r="4" class="filled-circle" stroke="#20A2F7" stroke-width="2" />
      <path d="M324 200H348V212C348 218.627 342.627 224 336 224V224C329.373 224 324 218.627 324 212V200Z" class="filled" stroke="#20A2F7" stroke-width="2" />
      <rect x="320" y="194" width="32" height="6" rx="3" class="filled" stroke="#20A2F7" stroke-width="2" />
      <circle cx="336" cy="214" r="4" class="filled-circle" stroke="#20A2F7" stroke-width="2" />
      <path d="M90 108C90 106.343 91.3431 105 93 105H111C112.657 105 114 106.343 114 108V118V124V179C114 180.657 112.657 182 111 182H93C91.3431 182 90 180.657 90 179V124V118V108Z" class="filled" />
      <path d="M90 118V108C90 106.343 91.3431 105 93 105H111C112.657 105 114 106.343 114 108V118M90 118H114M90 118V124M114 118V124M114 124V179C114 180.657 112.657 182 111 182H93C91.3431 182 90 180.657 90 179V124M114 124H90" stroke="#20A2F7" stroke-width="2" />
      <path class="blink" d="M250 89H229C224.582 89 221 92.5817 221 97V169C221 173.418 224.582 177 229 177H250C254.418 177 258 173.418 258 169V97C258 92.5817 254.418 89 250 89Z" stroke="#B100CE" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="8 12" />
      <circle cx="102" cy="170" r="6" class="filled" stroke="#20A2F7" stroke-width="2" />
      <ellipse cx="139.5" cy="138.5" rx="3.5" ry="2.5" transform="rotate(-180 139.5 138.5)" class="filled" stroke="#20A2F7" stroke-width="2" />
      <path d="M287 180C287 181.105 286.105 182 285 182L265 182C263.895 182 263 181.105 263 180L263 169L263 163L263 115C263 113.895 263.895 113 265 113L285 113C286.105 113 287 113.895 287 115L287 163L287 169L287 180Z" class="filled" />
      <path d="M287 169L287 180C287 181.105 286.105 182 285 182L265 182C263.895 182 263 181.105 263 180L263 169M287 169L263 169M287 169L287 163M263 169L263 163M263 163L263 115C263 113.895 263.895 113 265 113L285 113C286.105 113 287 113.895 287 115L287 163M263 163L287 163" stroke="#20A2F7" stroke-width="2" />
      <path d="M370 180C370 181.105 369.105 182 368 182L348 182C346.895 182 346 181.105 346 180L346 169L346 145L346 119L346 113L346 107C346 105.895 346.895 105 348 105L368 105C369.105 105 370 105.895 370 107L370 113L370 119L370 145L370 169L370 180Z" class="filled" />
      <path d="M370 169L370 180C370 181.105 369.105 182 368 182L348 182C346.895 182 346 181.105 346 180L346 169M370 169L346 169M370 169L370 145M346 169L346 145M346 145L370 145M346 145L346 119M370 145L370 119M346 113L346 107C346 105.895 346.895 105 348 105L368 105C369.105 105 370 105.895 370 107L370 113M346 113L370 113M346 113L346 119M370 113L370 119M370 119L346 119" stroke="#20A2F7" stroke-width="2" />
      <path d="M216 180C216 181.105 215.105 182 214 182L202 182C200.895 182 200 181.105 200 180L200 169L200 145L200 119L200 113L200 107C200 105.895 200.895 105 202 105L214 105C215.105 105 216 105.895 216 107L216 113L216 119L216 145L216 169L216 180Z" class="filled" />
      <path d="M216 169L216 180C216 181.105 215.105 182 214 182L202 182C200.895 182 200 181.105 200 180L200 169M216 169L200 169M216 169L216 145M200 169L200 145M200 145L216 145M200 145L200 119M216 145L216 119M200 113L200 107C200 105.895 200.895 105 202 105L214 105C215.105 105 216 105.895 216 107L216 113M200 113L216 113M200 113L200 119M216 113L216 119M216 119L200 119" stroke="#20A2F7" stroke-width="2" />
      <path d="M346 179C346 180.657 344.657 182 343 182L333 182C331.343 182 330 180.657 330 179L330 165.792L330 158.312L330 89C330 87.3431 331.343 86 333 86L343 86C344.657 86 346 87.3431 346 89L346 158.312L346 165.792L346 179Z" class="filled" />
      <path d="M346 165.792L346 179C346 180.657 344.657 182 343 182L333 182C331.343 182 330 180.657 330 179L330 165.792M346 165.792L330 165.792M346 165.792L346 158.312M330 165.792L330 158.312M330 158.312L330 89C330 87.3431 331.343 86 333 86L343 86C344.657 86 346 87.3431 346 89L346 158.312M330 158.312L346 158.312" stroke="#20A2F7" stroke-width="2" />
      <ellipse cx="275" cy="127" rx="6" ry="8" transform="rotate(-180 275 127)" class="filled" stroke="#20A2F7" stroke-width="2" />
      <path d="M288.864 106.9L308.182 101.724C309.249 101.438 310.346 102.071 310.632 103.138L311.926 107.967C312.993 107.682 314.089 108.315 314.375 109.382L314.893 111.314C315.179 112.38 314.546 113.477 313.479 113.763L319.69 136.945C320.757 136.659 321.854 137.293 322.14 138.359L322.658 140.291C322.943 141.358 322.31 142.455 321.243 142.741L326.678 163.025C327.745 162.739 328.842 163.373 329.128 164.439L329.646 166.371C329.932 167.438 329.298 168.535 328.231 168.821L329.525 173.65C329.811 174.717 329.178 175.814 328.111 176.1L308.793 181.276C307.726 181.562 306.629 180.929 306.343 179.862L305.049 175.032C303.982 175.318 302.886 174.685 302.6 173.618L302.082 171.686C301.796 170.619 302.429 169.523 303.496 169.237L298.061 148.952C296.994 149.238 295.897 148.605 295.612 147.538L295.094 145.606C294.808 144.539 295.441 143.443 296.508 143.157L290.296 119.975C289.23 120.261 288.133 119.627 287.847 118.56L287.329 116.629C287.043 115.562 287.677 114.465 288.744 114.179L287.449 109.35C287.164 108.283 287.797 107.186 288.864 106.9Z" class="filled" />
      <path d="M288.744 114.179L287.449 109.35C287.164 108.283 287.797 107.186 288.864 106.9L308.182 101.724C309.249 101.438 310.346 102.071 310.632 103.138L311.926 107.967M288.744 114.179L311.926 107.967M288.744 114.179V114.179C287.677 114.465 287.043 115.562 287.329 116.629L287.847 118.56C288.133 119.627 289.23 120.261 290.296 119.975V119.975M311.926 107.967V107.967C312.993 107.682 314.089 108.315 314.375 109.382L314.893 111.314C315.179 112.38 314.546 113.477 313.479 113.763V113.763M313.479 113.763L290.296 119.975M313.479 113.763L319.69 136.945M290.296 119.975L296.508 143.157M296.508 143.157L319.69 136.945M296.508 143.157V143.157C295.441 143.443 294.808 144.539 295.094 145.606L295.612 147.538C295.897 148.605 296.994 149.238 298.061 148.952V148.952M319.69 136.945V136.945C320.757 136.659 321.854 137.293 322.14 138.359L322.658 140.291C322.943 141.358 322.31 142.455 321.243 142.741V142.741M321.243 142.741L298.061 148.952M321.243 142.741L326.678 163.025M298.061 148.952L303.496 169.237M303.496 169.237L326.678 163.025M303.496 169.237V169.237C302.429 169.523 301.796 170.619 302.082 171.686L302.6 173.618C302.886 174.685 303.982 175.318 305.049 175.032V175.032M326.678 163.025V163.025C327.745 162.739 328.842 163.373 329.128 164.439L329.646 166.371C329.932 167.438 329.298 168.535 328.231 168.821V168.821M328.231 168.821L305.049 175.032M328.231 168.821L329.525 173.65C329.811 174.717 329.178 175.814 328.111 176.1L308.793 181.276C307.726 181.562 306.629 180.929 306.343 179.862L305.049 175.032" stroke="#20A2F7" stroke-width="2" />
      <circle cx="304.993" cy="128.46" r="6" transform="rotate(-15 304.993 128.46)" class="filled" stroke="#20A2F7" stroke-width="2" />
      <rect x="268" y="173" width="14" height="5" rx="2" class="filled" stroke="#20A2F7" stroke-width="2" />
      <rect x="341" y="91" width="16" height="6" rx="2" transform="rotate(90 341 91)" class="filled" stroke="#20A2F7" stroke-width="2" />
      <rect x="340" y="115" width="35" height="4" rx="2" transform="rotate(90 340 115)" class="filled" stroke="#20A2F7" stroke-width="2" />
      <path d="M374 105C374 103.939 373.579 102.922 372.828 102.172C372.078 101.421 371.061 101 370 101C368.939 101 367.922 101.421 367.172 102.172C366.421 102.922 366 103.939 366 105" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" />
      <path d="M132 140C130.939 140 129.922 140.421 129.172 141.172C128.421 141.922 128 142.939 128 144" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" />
      <rect x="118" y="160" width="78" height="4" rx="2" class="filled" stroke="#20A2F7" stroke-width="2" />
      <rect x="118" y="178" width="78" height="4" rx="2" class="filled" stroke="#20A2F7" stroke-width="2" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M193 164H121V170V174V178H144V170H151V178H193V172V168V164Z" class="filled" />
      <path d="M151 170H144V178V205L147.5 203L151 205V178V170Z" class="filled" />
      <path d="M193 168V164H121V170M193 168H171M193 168V172M121 174V178H144M121 174H129M121 174V170M121 170H136M193 172V178H151M193 172H187M142 170H144M153 170H151M151 170H144M151 170V178M144 170V178M151 178V205L147.5 203L144 205V178" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M187 154V158C187 159.105 186.105 160 185 160H153H149H142H136C134.895 160 134 159.105 134 158V154C134 152.895 134.895 152 136 152H142H149H153H185C186.105 152 187 152.895 187 154Z" class="filled" />
      <path d="M142 152H136C134.895 152 134 152.895 134 154V158C134 159.105 134.895 160 136 160H142M142 152V160M142 152H149M142 160H149M149 152V160M149 152H153M149 160H153M153 152H185C186.105 152 187 152.895 187 154V158C187 159.105 186.105 160 185 160H153M153 152V160" stroke="#20A2F7" stroke-width="2" />
      <path d="M192 146V150C192 151.105 191.105 152 190 152H188H181H177H141C139.895 152 139 151.105 139 150V146C139 144.895 139.895 144 141 144H177H181H188H190C191.105 144 192 144.895 192 146Z" class="filled" />
      <path d="M177 144H141C139.895 144 139 144.895 139 146V150C139 151.105 139.895 152 141 152H177M177 144V152M177 144H181M177 152H181M181 144V152M181 144H188M181 152H188M188 144H190C191.105 144 192 144.895 192 146V150C192 151.105 191.105 152 190 152H188M188 144V152" stroke="#20A2F7" stroke-width="2" />
      <path d="M132 135L132 142C132 143.105 132.895 144 134 144L147 144L153 144L188 144C189.105 144 190 143.105 190 142L190 135C190 133.895 189.105 133 188 133L153 133L147 133L134 133C132.895 133 132 133.895 132 135Z" class="filled" />
      <path d="M153 133L188 133C189.105 133 190 133.895 190 135L190 142C190 143.105 189.105 144 188 144L153 144M153 133L153 144M153 133L147 133M153 133L153 132M153 144L147 144M147 144L134 144C132.895 144 132 143.105 132 142L132 135C132 133.895 132.895 133 134 133L147 133M147 144L147 133M147 133L147 132" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" />
    </svg>
    <section>
      <h1>Oops, that doesn’t seem right…</h1>
      <p>Looks like <em>somebody</em> borrowed this page and never brought it back.</p>
    </section>
    <USButton :dark="dark" primary @click="previousPath ? $router.replace(previousPath) : { name: 'feed' }">Go back to your comofort zone</USButton>
  </USView>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPath = from.fullPath; // eslint-disable-line no-param-reassign
    });
  },
  data() {
    return {
      previousPath: null,
    };
  },
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'

.not-found
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  min-height: 100vh

  &.dark svg
    .filled,
    .filled-circle
      fill: $bg-dark

  svg
    width: 100%
    max-width: 28rem

    .filled
      fill: $bg

    .filled-circle
      fill: #E9F6FF

    .blink
      animation: blink 500ms infinite alternate
      stroke-dashoffset: 6

      @keyframes blink
        0%,
        50%
          opacity: 1
        100%
          opacity: 0

  section
    margin: 2rem 0
</style>

import MarkdownIt from 'markdown-it';
// import * as MarkdownItAnchor from 'markdown-it-anchor'; // disabled to fix plugin.apply undefined error
import * as MarkdownItLinkAttributes from 'markdown-it-link-attributes';
import { full as emoji } from 'markdown-it-emoji';

const md = new MarkdownIt({
  breaks: true,
  linkify: true,
  typographer: true,
  quotes: '“”‘’',
});

md.use(require('markdown-it-anchor').default, { level: 1 }); // disabled to fix plugin.apply undefined error

md.use(emoji);
md.use(MarkdownItLinkAttributes, {
  matcher(href) {
    return /^https?/.test(href);
  },
  attrs: {
    target: '_blank',
    rel: 'noopener nofollow noreferrrer',
  },
});

export default (value, inline = false) => {
  if (typeof value !== 'string' || !value || !value.trim()) return '';
  if (inline) return md.renderInline(value);
  return md.render(value);
};

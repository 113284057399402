<template lang="html">
  <transition name="slide">
    <div v-show="$store.state.application.showSidebar" class="sidebar" :class="{ dark, padded: !(backButton || collapseButton) }">
      <header v-if="backButton || collapseButton">
        <USIconButton v-if="backButton" :dark="dark" icon="arrow-left" @click="$emit('back')" />
        <USIconButton v-if="collapseButton" class="collapse-button" :dark="dark" icon="sidebar-collapse" @click="$store.commit('setShowSidebar', false)" />
      </header>
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    backButton: Boolean,
    dark: Boolean,
    collapseButton: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.sidebar
  width: 23.5rem
  height: 100vh
  box-shadow: 0 0 0 1px $interactable, $shadow-high-right
  border-top-right-radius: 0.75rem
  border-bottom-right-radius: 0.75rem
  background-color: $bg
  overflow-x: hidden
  overflow-y: auto
  padding: 2rem
  padding-left: 3rem // so it can hide under the menu better
  margin-left: -1rem // so it can hide under the menu better
  overscroll-behavior: contain

  &.slide-enter-active,
  &.slide-leave-active
    transition: transform 200ms ease

    &.slide-enter,
    &.slide-leave-to
      transform: translateX(-100%)

  &.padded
    padding-top: 6rem

  &.dark
    box-shadow: 0 0 0 1px $interactable-dark, $shadow-high-right
    background-color: $bg-dark

  > header
    margin: -1rem
    margin-bottom: 2rem
    display: flex

    .collapse-button
      margin-left: auto

</style>

<template lang="html">
  <USView class="explore" :dark="dark" show-back show-notifications>
    <USInput v-if="!mobile" v-model="search" :dark="dark" icon="search" label="Search for tags, writers, etc." sendable @send="sendSearch" />
    <h1 v-if="Object.values(awards).length > 0">Hall of Fame</h1>
    <section v-if="Object.values(awards).length > 0" class="hall-of-fame dynamogrid" :class="{ desktop: !mobile }">
      <USBadge v-for="(post, award) in awards" :author="post.author.name" :award="getAwardString(award)" :dark="dark" :icon="getAwardIcon(award)" :key="award" :title="post.title" @click="$router.push({ name: 'read', params: { id: post._id } })" />
    </section>
    <template v-if="topContent.length > 0">
      <div class="chip-wrapper">
        <h1>Top Content</h1>
        <USChip v-if="topContentTotal > 4" :content="`+${topContentTotal - 4} More`" :dark="dark" prominent @click="$router.push({ name: 'read', query: { listType: 'top' } })" />
      </div>
      <section class="top-content dynamogrid" :class="{ desktop: !mobile }">
        <USPostCard v-for="post in topContent" compact :dark="dark" :key="post._id" :post="post" @click="openPost($event, { params: { id: post._id }, query: { listType: 'top' } })" @mousedown="openPost($event, { params: { id: post._id }, query: { listType: 'top' } })" />
      </section>
    </template>
    <template v-if="staffPicks.length > 0">
      <div class="chip-wrapper">
        <h1 class="capitalize">{{staffPicksName}}</h1>
        <USChip v-if="staffPicksTotal > 4" :content="`+${staffPicksTotal - 4} More`" :dark="dark" prominent @click="$router.push({ name: 'read', query: { listType: 'collection', list: 'staff-picks', listSort: 'hearts' } })" />
      </div>
      <section class="staff-picks dynamogrid" :class="{ desktop: !mobile }">
        <USPostCard v-for="post in staffPicks" compact :dark="dark" :key="post._id" :post="post" @click="openPost($event, { params: { id: post._id }, query: { listType: 'collection', list: 'staff-picks', listSort: 'hearts' } })" @mousedown="openPost($event, { params: { id: post._id }, query: { listType: 'collection', list: 'staff-picks', listSort: 'hearts' } })" />
      </section>
    </template>
    <section class="dynamogrid writers-and-genres" :class="{ desktop: !mobile }">
      <section class="writers">
        <h1>Most Active Writers</h1>
        <USUserCard v-for="author in activeAuthors" :avatar="author.avatar" :dark="dark" :info="`${author.works} Post${author.works === 1 ? '' : 's'}`" :key="author._id" :name="author.name" @click="$router.push({ name: 'profile', params: { id: author._id } })" />
      </section>
      <section class="genres">
        <h1>Genres</h1>
        <USChip v-for="genre in genres" :class="{ dark }" :content="genre" :dark="dark" :key="genre" @click="$router.push({ name: 'tagged', params: { tag: genre } })" />
      </section>
    </section>
    <FadeTransition>
      <USIconButton v-show="mobile && $store.state.application.showActionBars" :dark="dark" fab icon="search" @click="showSearch = true" />
    </FadeTransition>
    <USModal v-if="mobile" :actions="null" :dark="dark" :visible="showSearch" @close="showSearch = false">
      <USInput v-model="search" :dark="dark" icon="search" label="Search for tags, writers, etc." sendable @send="sendSearch" />
    </USModal>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';
import openPost from '@/mixins/openPost';

import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      const { content: awardsString } = await Feathers.service('info').get('awards');
      let awards = [];
      if (awardsString !== 'null') {
        const [prompt, loved, faved, read, commented] = awardsString.split(',');
        const { data: awardPosts } = await Feathers.service('posts').find({ query: { _id: { $in: awardsString.split(',').filter((a) => a && a !== 'null') }, $select: ['_id', 'author', 'title'] } });
        awards = awardPosts.reduce((acc, post) => {
          if (post._id === prompt) acc.prompt = post;
          if (post._id === loved) acc.loved = post;
          if (post._id === faved) acc.faved = post;
          if (post._id === read) acc.read = post;
          if (post._id === commented) acc.commented = post;
          return acc;
        }, {});
      }

      const { data: topContent, total: topContentTotal } = await Feathers.service('posts').find({
        query: {
          starRatio: { $gt: 0.25 },
          $select: ['_id', 'author', 'title', 'blurb', 'createdAt', 'likes', 'comments', 'readers'],
          $sort: { favourites: -1 },
          $limit: 4,
        },
      });

      const { name: staffPicksName } = await Feathers.service('collections').get('staff-picks');
      const { data: staffPicks, total: staffPicksTotal } = await Feathers.service('posts').find({
        query: {
          collections: 'staff-picks',
          $select: ['_id', 'author', 'title', 'blurb', 'createdAt', 'likes', 'comments', 'readers'],
          $sort: { likes: -1 },
          $limit: 4,
        },
      });

      const { content: genres } = await Feathers.service('info').get('genres');

      const rawActiveAuthors = await Feathers.service('posts').find({ query: { $activeAuthors: true } });
      const activeAuthors = rawActiveAuthors.map((author) => {
        const { _id, avatar, name } = author._id;
        return {
          _id,
          avatar,
          name,
          works: author.works,
        };
      });

      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.activeAuthors = activeAuthors;
        vm.awards = awards;
        vm.genres = genres.split(',');
        vm.staffPicks = staffPicks;
        vm.staffPicksName = staffPicksName;
        vm.staffPicksTotal = staffPicksTotal;
        vm.topContent = topContent;
        vm.topContentTotal = topContentTotal;
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  components: {
    FadeTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  data() {
    return {
      activeAuthors: [],
      awards: {},
      genres: [],
      search: '',
      showSearch: false,
      staffPicks: [],
      staffPicksName: 'Staff Picks',
      staffPicksTotal: 0,
      topContent: [],
      topContentTotal: 0,
    };
  },
  methods: {
    getAwardIcon(award) {
      switch (award) {
        case 'prompt':
          return 'writing-prompt';
        case 'loved':
          return 'heart';
        case 'faved':
          return 'star';
        case 'read':
          return 'show';
        case 'commented':
          return 'comment';
        default:
          return 'untold-stories';
      }
    },
    getAwardString(award) {
      switch (award) {
        case 'prompt':
          return 'Best Use of Prompt Award';
        case 'loved':
          return 'Most Loved Award';
        case 'faved':
          return 'Most Favourited Award';
        case 'read':
          return 'Most Read Award';
        case 'commented':
          return 'Most Commented Award';
        default:
          return 'Incognito Award';
      }
    },
    sendSearch() {
      if (!this.search) return;
      if (this.showSearch) {
        this.showSearch = false;
        this.$nextTick(() => this.$router.push({ name: 'search', params: { term: this.search } }));
      } else this.$router.push({ name: 'search', params: { term: this.search } });
    },
  },
  mixins: [openPost],
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'

.explore
  .capitalize
    text-transform: capitalize

  .input.icon
    position: sticky
    top: 0.125rem
    z-index: 3
    width: 100%
    margin-bottom: 6rem

  .chip-wrapper
    display: flex
    align-items: center
    margin-bottom: 1.5rem

    h1
      margin-right: auto
      margin-bottom: 0

    .chip
      flex-shrink: 0
      cursor: pointer

  .dynamogrid
    display: grid
    grid-template-columns: 100%
    grid-gap: 1rem
    margin-bottom: 3rem

    &.desktop
      grid-template-columns: repeat(2, 1fr)
      margin-bottom: 6rem

      &.writers-and-genres
        grid-column-gap: 3rem

    .genres .chip
      margin-left: 0.5rem
      margin-bottom: 0.5rem
      cursor: pointer
      transition: background-color 200ms ease

      &:hover
        background-color: lighten($accent-primary, 30)

        &.dark
          background-color: darken($accent-primary, 5)

    .writers .user-card
      margin-bottom: 1rem

  .modal
    >>> .card
      visibility: hidden

    .input
      margin: 0.625rem
      width: calc(100% - 1.25rem)
      visibility: visible
</style>

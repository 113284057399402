<template lang="html">
  <div
    class="chip-input"
    :class="{
      dark, dirty, error: maxLen && value.length > maxLen, focussed, icon,
    }"
    @click="$refs.input.focus()"
    @focusin="handleFocusIn"
    @focusout="handleFocusOut">
    <div class="ink" />
    <USIcon v-if="icon" :icon="icon" key="icon" />
    <transition-group class="content-wrapper" key="content" name="pop" tag="div">
      <USChip v-for="(chip, index) in value" :class="{ simple: !chip.label && !chip.value }" :content="chip.label || chip.value || chip" :key="chip.value || chip" prominent removable @remove="removeChip(index)" />
      <div class="autogrow-input" key="autogrowInput">
        <input autocapitalize="off" autocomplete="off" ref="input" type="text" :value="newChip" @input="handleInput" @keydown="handleAcceptOrDelete" @keyup="handleMobileComma" @paste="handlePaste">
        <span class="spacer" ref="spacer">|</span>
      </div>
    </transition-group>
    <label>{{combinedLabel}}</label>
    <FadeTransition>
      <ul v-show="filteredSuggestions.length > 0 && focussed" class="suggestions" :style="{ height: suggestionsHeight, transform: suggestionsTransform, width: parentWidth }">
        <li v-for="(suggestion, index) in filteredSuggestions" :key="index" tabindex="0" @click="addChip(suggestion)" @keyup.enter="addChip(suggestion)">
          <div class="ink" />
          <span class="suggestion-content">{{ suggestion.label || suggestion.value || suggestion }}</span>
        </li>
      </ul>
    </FadeTransition>
  </div>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    combinedLabel() {
      if (!this.maxLen || !this.dirty) return this.label;
      return `${this.label} (${this.value.length}/${this.maxLen})`;
    },
    dirty() {
      return (this.value && this.value.length > 0) || this.newChip.length > 0;
    },
    filteredSuggestions() {
      return this.suggestions.filter((suggestion) => !this.value.find((existingSuggestion) => existingSuggestion.value === suggestion.value));
    },
    ownChips() {
      return this.value && this.value.slice(0);
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
    suggestionsHeight() {
      let height = this.cachedHeight || 0;
      if (this.filteredSuggestions.length > 0) height = `${this.filteredSuggestions.length * 2.25 + (this.filteredSuggestions.length - 1) * 0.375 + 2 * 0.375}rem`;
      // fine since we’re just caching
      this.cachedHeight = height; // eslint-disable-line vue/no-side-effects-in-computed-properties
      return height;
    },
    suggestionsTransform() {
      let translate = this.cachedTranslate || 'translate(0, 0)';
      if (this.filteredSuggestions.length > 0 && this.$el) {
        const parentRect = this.$el.getBoundingClientRect();
        const margin = 0.5 * this.remBase;
        const suggestionsHeightInPx = (this.filteredSuggestions.length * 2.25 + (this.filteredSuggestions.length - 1) * 0.375 + 2 * 0.375) * this.remBase;

        if (suggestionsHeightInPx + parentRect.top + parentRect.height + margin < window.innerHeight) translate = `translate(${parentRect.left}px, ${parentRect.top + parentRect.height + margin}px)`;
        else translate = `translate(${parentRect.left}px, ${parentRect.top - suggestionsHeightInPx - margin}px)`;
      }
      // fine since we’re just caching
      this.cachedTranslate = translate; // eslint-disable-line vue/no-side-effects-in-computed-properties
      return translate;
    },
  },
  data() {
    return {
      cachedHeight: null,
      cachedTranslate: null,
      focussed: false,
      newChip: '',
      parentWidth: 0,
      suggestions: [],
      suggestionsVisible: false,
    };
  },
  methods: {
    addChip(chip) {
      const elementExists = this.ownChips.findIndex((element) => {
        if (element.value) return element.value === chip.value;
        return element === chip;
      }) >= 0;

      if (!elementExists) {
        this.ownChips.push(chip);
        this.$emit('input', this.ownChips);
        this.$refs.input.focus();
      }
      this.newChip = '';
      this.$refs.spacer.innerText = '|';
      if (this.suggestions.length > 0) this.suggestions = [];
    },
    async fetchSuggestions() {
      if (!this.model || !this.newChip) return;

      if (Array.isArray(this.model)) {
        this.suggestions = this.model.filter((el) => el.includes(this.newChip.trim())).map((el) => ({ label: el, value: el }));
        return;
      }

      if (!this.fieldToSearch) return;

      const query = { hidden: { $ne: true }, $limit: 5, $select: ['_id', this.fieldToSearch] };
      query[this.fieldToSearch] = { $search: this.newChip.trim() };

      try {
        const { data: fetchedSuggestions } = await this.$feathers.service(this.model).find({ query });
        this.suggestions = fetchedSuggestions.reduce((acc, suggestion) => {
          acc.push({ label: suggestion[this.fieldToSearch], value: suggestion._id });
          return acc;
        }, []);
        if (Number.parseInt(this.parentWidth, 10) === 0) this.parentWidth = `${this.$el.offsetWidth}px`;
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch suggestions: ${err.message}`, type: 'negative' });
      }
    },
    handleAcceptOrDelete(e) {
      if (e.key === ',') e.preventDefault();
      if (this.newChip.length > 0) {
        if (e.key === 'Enter' || e.key === ',') {
          let chip;
          if (this.filteredSuggestions.length > 0) [chip] = this.filteredSuggestions;
          else if (!this.model || this.allowNotSuggested) chip = this.newChip.trim().toLowerCase();

          if (chip) this.addChip(chip);
          else this.$store.commit('addToast', { message: `‘${this.newChip}’ does not exist in ‘${this.model}’`, type: 'negative' });
        }
      } else if (e.key === 'Delete' || e.key === 'Backspace') {
        this.ownChips.pop();
        this.$emit('input', this.ownChips);
      }
    },
    handleFocusIn() {
      window.addEventListener('scroll', this.hideSuggestions, { passive: true, capture: true });
      this.focussed = true;
      this.$emit('focus');
    },
    handleFocusOut(e) {
      if (!e.relatedTarget || !this.$el.contains(e.relatedTarget)) {
        this.focussed = false;
        window.removeEventListener('scroll', this.hideSuggestions, { passive: true, capture: true });
        this.$emit('blur');
      }
    },
    handleInput(e) {
      this.newChip = e.target.value;
      if (this.$refs.input.value) this.$refs.spacer.innerText = this.$refs.input.value; // to fix the jitter I can’t use {{userInput}} in the <pre>
      else this.$refs.spacer.innerText = '|';

      if (this.newChip.length > 3) {
        this.fetchSuggestions();
      } else if (this.suggestions.length > 0) this.hideSuggestions();
    },
    handleMobileComma(e) { // e.key doesn’t work on android chrome, so we have to resort to drastic measures
      if (typeof e.key === 'undefined' || e.key === 'Unidentified') {
        const currentPosition = e.target.selectionStart;

        if (currentPosition > 0 && this.newChip.charAt(currentPosition - 1) === ',') {
          const [newChip, ...rest] = this.newChip.split(',');
          let chip;
          if (this.filteredSuggestions.length > 0) [chip] = this.filteredSuggestions;
          else if (!this.model || this.allowNotSuggested) chip = newChip.trim().toLowerCase();

          if (chip) {
            this.addChip(chip);
            this.newChip = rest.join('');
          } else {
            this.$store.commit('addToast', { message: `‘${this.newChip}’ does not exist in ‘${this.model}’`, type: 'negative' });
            this.newChip = `${newChip} ${rest.join(' ')}`;
          }

          if (this.newChip) this.$refs.spacer.innerText = this.newChip;
          else this.$refs.spacer.innerText = '|';
        }
      }
    },
    handlePaste(e) {
      if (this.model && !this.allowNotSuggested) return;
      const paste = (e.clipboardData || window.clipboardData).getData('text');

      if (paste.includes(',') || paste.includes('\n')) {
        e.preventDefault();
        const items = paste.split(/,|\n/);
        items.forEach((item) => this.addChip(item));
      }
    },
    hideSuggestions() {
      this.suggestions = [];
    },
    removeChip(index) {
      this.ownChips.splice(index, 1);
      this.$emit('input', this.ownChips);
    },
  },
  mounted() {
    this.parentWidth = `${this.$el.offsetWidth}px`;
  },
  props: {
    allowNotSuggested: Boolean,
    dark: Boolean,
    fieldToSearch: String,
    icon: String,
    label: {
      type: String,
      default: 'Type something',
    },
    maxLen: Number,
    model: [Array, String],
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.chip-input
  border: 1px solid $interactable
  box-shadow: $shadow-low
  border-radius: 0.75rem
  display: inline-flex
  vertical-align: middle
  align-items: center
  padding: calc(1rem - 1px)
  cursor: text
  background-color: $bg
  position: relative
  max-width: 100%
  overflow: hidden
  width: 16rem
  transition: box-shadow 200ms ease, border-color 200ms ease

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark
    color: $text-primary-dark

    label
      color: $text-secondary-dark

    input::placeholder
      color: $text-secondary-dark

    .suggestions
      background-color: $bg-dark
      border-color: $interactable-dark

  &:hover,
  &.focussed
    box-shadow: $shadow-high
    border-color: $accent-primary

  &.focussed
    .ink
      transform: scale(1.4142135624)

  &.focussed,
  &.dirty
    .content-wrapper
      transform: translateY(0.625rem)

      .autogrow-input:not(:first-child)
        margin-top: 0.5rem
        margin-right: 0.5rem

    label
      transform: translateY(-0.625rem) scale(0.875)

  &.icon
    width: 15.625rem

    .content-wrapper
      margin-left: 0.875rem
      width: calc(100% - 2.375rem)

    label
      left: 3.375rem

  &.error
    color: $negative

    &:hover,
    &.focussed
      border-color: $negative

    .ink
      background-color: alpha($negative, 0.1)

    label
      color: $negative

  &.disabled
    border-color: $interactable-disabled
    box-shadow: none
    pointer-events: none
    color: $text-disabled

    &.dark
      border-color: $interactable-disabled-dark
      color: $text-disabled-dark

      label
        color: $text-disabled-dark

    label
      color: $text-disabled

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  .icon
    vertical-align: top
    align-self: flex-start
    position: relative

  .content-wrapper
    width: 100%

    .chip
      max-width: 100%

      &:not(:last-child)
        margin-right: 0.5rem
        margin-top: 0.5rem

      &.simple >>> .content
        text-transform: none

    .autogrow-input
      position: relative
      display: inline-block
      height: 1.5rem
      max-width: 100%
      vertical-align: top

      .spacer,
      input
        height: 100%
        background-color: transparent
        border: none
        color: inherit
        font-family: inherit
        font-size: 1rem
        text-shadow: $text-shadow
        margin: 0
        padding: 0
        outline: none
        text-overflow: ellipsis
        min-width: 0
        white-space: pre
        appearance: none

      input
        position: absolute
        width: 100%
        left: 0
        top: 0
        right: 0
        bottom: 0
        user-select: text

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button,
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration
          appearance: none
          display: none
          margin: 0

        &::placeholder
          color: $text-secondary

      .spacer
        display: block
        overflow: hidden
        visibility: hidden

    .pop-enter-active,
    .pop-leave-active,
    .pop-move
      transition: transform 200ms ease, opacity 200ms ease

      &.pop-enter,
      &.pop-leave-to
        transform: scale(0.4)
        opacity: 0

  label
    pointer-events: none
    color: $text-secondary
    position: absolute
    left: calc(1rem - 1px)
    top: @left
    right: @left
    height: 1.5rem
    line-height: @height
    transform-origin: top left
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    transition: transform 200ms ease

  .suggestions
    position: fixed
    top: 0
    left: 0
    background-color: $bg
    margin: 0
    padding: calc(0.375rem - 1px)
    border: 1px solid $interactable
    border-radius: 0.75rem
    box-shadow: $shadow-low
    list-style: none
    z-index: 1
    transition: box-shadow 200ms ease

    li
      display: flex
      align-items: center
      text-align: left
      cursor: pointer
      padding: calc(0.375rem - 1px) calc(0.75rem - 1px)
      border-radius: 0.375rem
      border: 1px solid transparent
      position: relative
      overflow: hidden
      line-height: 1.5rem
      margin-bottom: 0.375rem
      transition: border-color 200ms ease, color 200ms ease

      &:focus,
      &:hover
        border-color: $accent-primary
        color: @border-color

      &:focus .ink
        transform: scale(1.4142135624)

      .ink
        transform: scale(0)

      .suggestion-content
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        text-transform: capitalize
</style>

<template lang="html">
  <div class="event-card" :class="{ dark, interactable }" :tabindex="interactable ? 0 : false" @click="handleClick">
    <header>
      <div class="date">
        <span class="day">{{day}}</span>
        <span class="month">{{month}}</span>
      </div>
      <section class="title">
        <h2>{{event.title}}</h2>
        <span>{{weekdayAndTime}}</span>
      </section>
    </header>
    <article v-if="!compact" class="description" v-html="$options.filters.markdown(event.description)" @click="handleLinkClick" />
    <p class="info">
      <USIcon icon="location" />
      <span>{{event.location}}</span>
    </p>
    <p v-if="event.attendees.length > 0 && showAttendants" class="info">
      <USIcon icon="check" />
      <span>{{formattedAttendees}} going</span>
    </p>
    <p v-if="event.maybeers.length > 0 && showAttendants" class="info">
      <USIcon icon="question-mark" />
      <span>{{formattedMaybeers}} said maybe</span>
    </p>
    <footer v-if="showButtons">
      <USButton :dark="dark" color="danger" :primary="!isGoing && !saidMaybe" @click="handleCant">Can’t</USButton>
      <USButton :dark="dark" :primary="saidMaybe" @click="handleMaybe">Maybe</USButton>
      <USButton :dark="dark" color="positive" :primary="isGoing" @click="handleGoing">Going</USButton>
    </footer>
    <footer v-if="!showButtons && showMeetingLink">
      <USButton :dark="dark" primary @click="joinMeeting">Join Meeting Online</USButton>
    </footer>
    <FadeTransition>
      <div v-show="interactable && editable && showOverlay" class="overlay">
        <section class="chips">
          <USChip v-if="event.recurring" :content="event.recurring" prominent />
          <USChip v-if="event.generateTbd" content="Posts will be discussed" prominent />
          <USChip v-if="event.public" content="public" prominent />
          <USChip v-if="event.restricted && event.restricted.length > 0" :content="`Restricted to: ${fetchedRestricted.join(', ')}`" prominent />
        </section>
        <section class="buttons">
          <USIconButton dark icon="pencil" @click="$emit('edit', event)" />
          <USIconButton dark color="danger" icon="delete" @click="$emit('delete', event._id)" />
        </section>
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import { Date as SugarDate } from 'sugar-date';
import FadeTransition from '@/transitions/FadeTransition.vue';
import markdown from '@/filters/markdown';
import handleLinkClick from '@/mixins/handleLinkClick';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    day() {
      const d = SugarDate.create(this.event.date);
      return SugarDate.format(d, '{dd}');
    },
    formattedAttendees() {
      const numAttendees = this.event.attendees.length;

      if (numAttendees === 1 && this.isGoing) return 'You are';
      if (numAttendees === 1) return '1 person is';
      if (numAttendees === 2 && this.isGoing) return 'You & 1 other are';
      if (this.isGoing) return `You & ${numAttendees - 1} others are`;
      return `${numAttendees} others are`;
    },
    formattedMaybeers() {
      const numMaybeers = this.event.maybeers.length;

      if (numMaybeers === 1 && this.saidMaybe) return 'You';
      if (numMaybeers === 1) return '1 person';
      if (numMaybeers === 2 && this.saidMaybe) return 'You & 1 other';
      if (this.saidMaybe) return `You & ${numMaybeers - 1} others`;
      return `${numMaybeers} others`;
    },
    isGoing() {
      return this.event.attendees.includes(this.$store.getters.userId);
    },
    month() {
      const d = SugarDate.create(this.event.date);
      return SugarDate.format(d, '{mon}').substring(0, 3);
    },
    restricted() {
      return this.event.restricted;
    },
    saidMaybe() {
      return this.event.maybeers.includes(this.$store.getters.userId);
    },
    showMeetingLink() {
      const [hours, minutes] = this.event.startTime.split(':');
      const startDate = new Date(this.event.date).setHours(Number.parseInt(hours, 10), Number.parseInt(minutes, 10), 0, 0);
      return this.event.link && Date.now() >= startDate - 5 * 60 * 1000 && Date.now() < this.event.date; // T minus 5 minutes until the end of the event
    },
    weekdayAndTime() {
      const d = SugarDate.create(this.event.date);
      return `${SugarDate.format(d, '{Weekday}')}, ${this.event.startTime} – ${this.event.endTime}`;
    },
  },
  created() {
    if (this.editable && this.event.restricted && this.event.restricted.length > 0) this.fetchRestricted();
  },
  data() {
    return {
      fetchedRestricted: [],
      showOverlay: false,
    };
  },
  filters: {
    markdown,
  },
  methods: {
    async fetchRestricted() {
      const { data: fetched } = await this.$feathers.service('groups').find({ query: { _id: { $in: this.event.restricted }, $select: ['name'], $limit: 8 } });
      this.fetchedRestricted = fetched.map((group) => group.name);
    },
    handleClick(e) {
      if (
        e.target.tagName.toLowerCase() !== 'button'
        && e.target.parentElement.tagName.toLowerCase() !== 'button'
        && e.target.tagName.toLowerCase() !== 'a'
        && e.target.parentElement.tagName.toLowerCase() !== 'a'
      ) {
        if (this.editable && !this.showOverlay) this.showOverlay = true;
        else this.showOverlay = false;
        if (this.interactable) this.$emit('click', e);
      }
    },
    async handleCant() {
      const { userId } = this.$store.getters;
      if (!userId) return;
      try {
        await this.$feathers.service('events').patch(this.event._id, { $pull: { attendees: userId, maybeers: userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while sending RSVP: ${err.message}`, type: 'negative' });
      }
    },
    async handleMaybe() {
      const { userId } = this.$store.getters;
      if (!userId) return;
      try {
        await this.$feathers.service('events').patch(this.event._id, { $pull: { attendees: userId }, $addToSet: { maybeers: userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while sending RSVP: ${err.message}`, type: 'negative' });
      }
    },
    async handleGoing() {
      const { userId } = this.$store.getters;
      if (!userId) return;
      try {
        await this.$feathers.service('events').patch(this.event._id, { $pull: { maybeers: userId }, $addToSet: { attendees: userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while sending RSVP: ${err.message}`, type: 'negative' });
      }
    },
    async joinMeeting() {
      if (!this.isGoing) await this.handleGoing();
      window.open(this.event.link, '_blank');
    },
  },
  mixins: [handleLinkClick],
  props: {
    compact: Boolean,
    dark: Boolean,
    editable: Boolean,
    event: Object,
    interactable: Boolean,
    showAttendants: Boolean,
    showButtons: Boolean,
  },
  watch: {
    editable(newVal) {
      if (newVal) this.fetchRestricted();
    },
    restricted(newVal) {
      if (!this.editable || !newVal || newVal.length === 0) return;
      this.fetchRestricted();
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.event-card
  border-radius: 0.75rem
  border: 1px solid $interactable-disabled
  padding: calc(0.375rem - 1px)
  position: relative
  overflow: hidden

  &.dark
    border-color: $interactable-disabled-dark

    header .title span,
    .info
      color: $text-secondary-dark

  &.interactable
    cursor: pointer
    border-color: $interactable
    box-shadow: $shadow-low
    transition: box-shadow 200ms ease

    &:hover,
    &:focus
      box-shadow: $shadow-high

    &.dark
      border-color: $interactable-dark
      transition: box-shadow 200ms ease, background-color 200ms ease

      &:hover,
      &:focus
        background-color: $elevation-primary-dark

  header
    display: flex
    align-items: center

    .date
      background-color: $accent-primary
      padding: 0.375rem
      border-radius: 0.375rem
      font-weight: 800
      color: $text-primary-dark
      text-transform: uppercase
      text-align: center
      width: 4rem
      margin-right: 1rem
      flex-shrink: 0

      .day,
      .month
        display: block

      .day
        font-size: 1.5rem
        margin-top: 0.125rem
        margin-bottom: -0.5rem

      .month
        margin-bottom: 0.1875rem

    .title
      width: calc(100% - 5rem)
      h2
        color: inherit
        margin-bottom: 0
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

      span
        display: block
        color: $text-secondary
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

  .description
    padding: 0 0.625rem

  .info
    color: $text-secondary
    margin: 0.5rem 0
    display: flex

    .icon
      margin: 0 0.5rem
      color: $accent-primary

    > span
      display: inline-block
      padding-right: 0.625rem

  footer
    display: flex
    margin-top: 1rem

    .button
      width: 100%
      box-shadow: none
      padding: calc(0.375rem - 1px) 1rem
      border-radius: 0.375rem

      &:not(:last-child)
        margin-right: 0.375rem

      &:only-child.primary:hover
        background-color: lighten($accent-primary, 10)

  .overlay
    display: flex
    flex-direction: column
    background-color: alpha(black, 0.6)
    position: absolute
    top: 0
    left: @top
    right: @top
    bottom: @top
    padding: 1rem

    .chips .chip
      margin: 0.25rem

    .buttons
      margin-top: auto
      text-align: right

      .icon-button:not(:last-child)
        margin-right: 0.5rem
</style>

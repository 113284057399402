import Vue from 'vue';
import Router from 'vue-router';

import Feathers from '@/feathersApp';
import Store from '@/store';

// import Components from './views/Components.vue';
import Explore from './views/Explore.vue';
import Feed from './views/Feed.vue';
import Forum from './views/Forum.vue';
import GeneralError from './views/GeneralError.vue';
import Home from './views/Home.vue';
import Inspiration from './views/Inspiration.vue';
import NotFound from './views/NotFound.vue';
import Read from './views/Read.vue';
import Tagged from './views/Tagged.vue';
import TimeOut from './views/TimeOut.vue';
import User from './views/User.vue';
import Write from './views/Write.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        noMenu: { desktop: true, mobile: true },
        requiresAuth: false,
      },
      async beforeEnter(to, from, next) {
        const auth = await Feathers.get('authentication');
        if (to.query.page === 'verify') {
          if (to.query.token || to.query.newToken) next();
          else next({ name: 'home' });
        } else if (auth && !auth.user.groups.includes('applicants')) next({ name: 'feed' });
        else if (auth && to.query.page !== 'welcome') next({ name: 'home', query: { page: 'welcome' } });
        else next();
      },
    },
    // {
    //   path: '/components',
    //   name: 'components',
    //   component: Components,
    //   meta: {
    //     noMenu: { mobile: true },
    //     title: 'Components',
    //   },
    // },
    {
      path: '/events',
      name: 'events',
      component: () => import(/* webpackChunkName: "events" */ './views/Events.vue'),
      meta: {
        noMenu: { desktop: false, mobile: true },
        title: 'Upcoming Events',
        requiresAuth: false,
      },
    },
    {
      path: '/feed',
      name: 'feed',
      component: Feed,
      meta: {
        showBack: false,
        title: 'Feed',
      },
      beforeEnter(to, from, next) {
        if (Store.state.user.groups.includes('applicants')) next({ name: 'home', query: { page: 'welcome' } });
        else if (from.name !== 'home' && Store.state.application.installPrompt) {
          if (window.localStorage) { // we don’t want to spam people with a non-working local storage
            const prompted = window.localStorage.getItem('installPrompted');
            if (prompted !== 'true') {
              Store.commit('addToast', {
                action: async () => {
                  Store.state.application.installPrompt.prompt();
                  const choice = await Store.state.application.installPrompt.userChoice;
                  if (choice.outcome === 'accepted') Store.commit('addToast', { message: 'Great! Untold Stories should have been added to your homescreen or desktop. You can now launch it from there and close this tab.' });
                  else Store.commit('addToast', { message: 'Alright, we won’t bother you again. If you change your mind, you can install the app in “Settings”.' });
                },
                actionLabel: 'Install',
                message: 'You seem to be enjoying Untold Stories! Would you like to install it to your device?',
                timeout: -1,
              });
              window.localStorage.setItem('installPrompted', true);
            }
          }
          next();
        } else next();
      },
    },
    {
      path: '/explore',
      name: 'explore',
      component: Explore,
      meta: {
        showBack: false,
        title: 'Explore',
      },
    },
    {
      path: '/search/:term?',
      name: 'search',
      component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
      meta: {
        noMenu: { desktop: false, mobile: true },
      },
      beforeEnter(to, from, next) {
        if (!to.params.term) next({ name: 'explore' });
        else next();
      },
    },
    {
      path: '/forum',
      name: 'forum',
      component: Forum,
      meta: {
        showBack: false,
        title: 'Forum',
      },
    },
    {
      path: '/thread/:id',
      name: 'thread',
      component: () => import(/* webpackChunkName: "thread" */ './views/Thread.vue'),
      meta: {
        noMenu: { desktop: false, mobile: true },
      },
    },
    {
      path: '/inspiration',
      name: 'inspiration',
      component: Inspiration,
      meta: {
        collapsibleSidebar: true,
        requiresGroup: 'members',
        showBack: false,
        title: 'Inspiration',
      },
    },
    {
      path: '/inspiration/emoji-game',
      name: 'emoji-game',
      component: () => import(/* webpackChunkName: "games" */ './views/games/EmojiGame.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: 'members',
        showBack: false,
        title: 'What the ’moji?',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'inspiration', query: { page: 'emoji-game' } });
        else next();
      },
    },
    {
      path: '/inspiration/freewriting',
      name: 'freewriting',
      component: () => import(/* webpackChunkName: "games" */ './views/games/Freewriting.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: 'members',
        showBack: false,
        title: 'Free Writing',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'inspiration', query: { page: 'freewriting' } });
        else next();
      },
    },
    {
      path: '/inspiration/sentence-game',
      name: 'sentence-game-lobby',
      component: () => import(/* webpackChunkName: "games" */ './views/games/SentenceGameLobby.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: 'members',
        showBack: false,
        title: 'Sentence Game Lobby',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'inspiration', query: { page: 'sentence-game-lobby' } });
        else next();
      },
    },
    {
      path: '/inspiration/sentence-game/:id',
      name: 'sentence-game',
      component: () => import(/* webpackChunkName: "games" */ './views/games/SentenceGame.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: 'members',
        showBack: true,
        title: 'Sentence Game',
      },
    },
    {
      path: '/user',
      name: 'user',
      component: User,
      meta: {
        collapsibleSidebar: true,
        showBack: false,
        title: 'User',
      },
    },
    {
      path: '/user/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "user" */ './views/Settings.vue'),
      meta: {
        noMenu: { mobile: true },
        showBack: false,
        title: 'User',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'user', query: { page: 'settings' } });
        else next();
      },
    },
    {
      path: '/user/drafts',
      name: 'drafts',
      component: () => import(/* webpackChunkName: "user" */ './views/Drafts.vue'),
      meta: {
        noMenu: { mobile: true },
        showBack: false,
        title: 'Drafts',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'user', query: { page: 'drafts' } });
        else next();
      },
    },
    {
      path: '/user/favourites',
      name: 'favourites',
      component: () => import(/* webpackChunkName: "user" */ './views/Favourites.vue'),
      meta: {
        noMenu: { mobile: true },
        showBack: false,
        title: 'Favourites',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'user', query: { page: 'favourites' } });
        else next();
      },
    },
    {
      path: '/profile/:id',
      name: 'profile',
      component: () => import(/* webpackChunkName: "user" */ './views/Profile.vue'),
      meta: {
        noMenu: { mobile: true },
        title: 'Profile',
      },
    },
    {
      path: '/write',
      name: 'write',
      component: Write,
      meta: {
        collapsibleSidebar: true,
        noMenu: { mobile: true },
        requiresGroup: 'members',
        showBack: false,
        title: 'Write',
      },
    },
    {
      path: '/advanced',
      name: 'advanced-options',
      component: () => import(/* webpackChunkName: "advanced" */ './views/AdvancedOptions.vue'),
      meta: {
        collapsibleSidebar: true,
        noMenu: { mobile: true },
        requiresGroup: ['councilmembers', 'moderators', 'admins'],
        showBack: false,
        title: 'Advanced Options',
      },
    },
    {
      path: '/group/:id',
      name: 'group',
      component: () => import(/* webpackChunkName: "details" */ './views/GroupDetail.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['moderators', 'admins'],
      },
    },
    {
      path: '/prompts',
      name: 'writing-prompts',
      component: () => import(/* webpackChunkName: "details" */ './views/WritingPrompts.vue'),
      meta: {
        noMenu: { mobile: true },
        title: 'Writing Prompts',
      },
    },
    {
      path: '/collection/:id',
      name: 'collection',
      component: () => import(/* webpackChunkName: "details" */ './views/CollectionDetail.vue'),
      meta: {
        noMenu: { mobile: true },
      },
    },
    {
      path: '/advanced/communication',
      name: 'communication',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/Communication.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['moderators', 'admins'],
        showBack: false,
        title: 'Communication',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'communication' } });
        else next();
      },
    },
    {
      path: '/advanced/collections',
      name: 'collections',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/Collections.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['moderators', 'admins'],
        showBack: false,
        title: 'Collections',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'collections' } });
        else next();
      },
    },
    {
      path: '/advanced/genres',
      name: 'genres',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/Genres.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['moderators', 'admins'],
        showBack: false,
        title: 'Genres',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'genres' } });
        else next();
      },
    },
    {
      path: '/advanced/users',
      name: 'users',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/Users.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['councilmembers', 'moderators', 'admins'],
        showBack: false,
        title: 'Users',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'users' } });
        else next();
      },
    },
    {
      path: '/advanced/groups',
      name: 'groups',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/Groups.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['moderators', 'admins'],
        showBack: false,
        title: 'Groups',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'groups' } });
        else next();
      },
    },
    {
      path: '/advanced/text-content',
      name: 'text-content',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/TextContent.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['moderators', 'admins'],
        showBack: false,
        title: 'Text Content',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'text-content' } });
        else next();
      },
    },
    {
      path: '/advanced/stats',
      name: 'stats',
      component: () => import(/* webpackChunkName: 'advanced' */ './views/advanced/Stats.vue'),
      meta: {
        noMenu: { mobile: true },
        requiresGroup: ['councilmembers', 'moderators', 'admins'],
        showBack: false,
        title: 'Stats',
      },
      beforeEnter(to, from, next) {
        if (!Store.state.application.mobile) next({ name: 'advanced-options', query: { page: 'stats' } });
        else next();
      },
    },
    {
      path: '/read/:id?',
      name: 'read',
      component: Read,
      meta: {
        collapsibleSidebar: true,
        noMenu: { dasktop: false, mobile: true },
        requiresAuth: false,
      },
      async beforeEnter(to, from, next) {
        if (!to.params.id) {
          let { listType } = to.query;
          let listQuery = {};
          const { list } = to.query;

          if ((!list && !listType) || !listType || !Store.getters.userId) {
            listType = 'public';
          }

          switch (listType) {
            case 'user':
              listQuery = { author: list };
              break;
            case 'tbd':
              listQuery = { toBeDiscussed: true };
              break;
            case 'top':
              listQuery = { starRatio: { $gt: 0.25 } };
              break;
            case 'public':
              listQuery = { public: true };
              break;
            case 'drafts':
              listQuery = { draft: true };
              break;
            case 'favourites':
              listQuery = { favourers: Store.getters.userId };
              break;
            case 'collection':
              listQuery = { collections: list };
              break;
            default:
              listQuery = { tags: list };
          }

          const { data: postList } = await Feathers.service('posts').find({
            query: {
              ...listQuery, $limit: 1, $sort: { createdAt: -1 }, $select: ['_id'],
            },
          });
          const id = postList[0] && postList[0]._id;
          if (id) next({ name: to.name, params: { ...to.params, id }, query: to.query });
          else next({ name: 'not-found' });
          return;
        }
        next();
      },
    },
    {
      path: '/tagged/:tag',
      name: 'tagged',
      component: Tagged,
      meta: {
        noMenu: { mobile: true },
      },
    },
    {
      path: '/timeout',
      name: 'timeout',
      component: TimeOut,
      meta: {
        requiresAuth: false,
        title: 'Connection Timed Out',
      },
    },
    {
      path: '/general-error',
      name: 'error',
      component: GeneralError,
      meta: {
        requiresAuth: false,
        title: 'Oops',
      },
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
      meta: {
        // noMenu: { desktop: true, mobile: true },
        requiresAuth: false,
        title: 'Oops',
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    if (savedPosition) position = savedPosition;
    else if (to.hash) position = { selector: to.hash, offset: 100 };
    else position = { x: 0, y: 0 };
    return new Promise((resolve) => {
      this.app.$root.$once('triggerScroll', () => {
        document.documentElement.style.setProperty('scroll-behavior', 'auto');
        window.setTimeout(() => resolve(position), 10);
        window.setTimeout(() => document.documentElement.style.removeProperty('scroll-behavior'), 250);
      });
    });
  },
});

router.beforeEach(async (to, from, next) => {
  Store.commit('setTooltip', null);
  Store.commit('setPreviousRoute', from.fullPath);

  if (Store.state.application.modalVisible) {
    Store.commit('setModalVisible', false);
    next(false);
    return;
  }

  Store.dispatch('startApplicationLoading');

  // handle reauthentication
  if (!Store.state.application.reauthAttemptMade) {
    try {
      const auth = await Feathers.reAuthenticate();
      Store.commit('setUser', auth.user);
      if (!auth.user.isVerified) Store.commit('addToast', { message: 'Looks like you haven’t yet verified your email address with the link we sent you. Please do so in order to be able to reset your password in case you forget it.', timeout: -1, type: 'warning' });
      Store.commit('setForceHiddenMenu', false);
      Store.commit('setReAuthAttemptMade', true);
    } catch (err) {
      if (err.code === 408) { // a timeout
        Store.commit('setReAuthAttemptMade', true);
        next({ name: 'timeout', query: { reauthFailed: true, redirect: to.fullPath } });
        return;
      }
      if (err.code !== 401) {
        await Feathers.authentication.removeAccessToken();
        Store.commit('setReAuthAttemptMade', true);
        next(new Error(`error while re-authenticating: ${err.message}`));
        return;
      }
      // otherwise we just haven’t logged in on this machine yet
      Store.commit('setReAuthAttemptMade', true);
    }
  }

  if (to.name === 'error' || to.name === 'timeout') { // should always be reachable despite something not working, like the connection to the server
    next();
    return;
  }

  // hide notification bar if it’s visible
  if (Store.state.application.showNotificationBar) Store.commit('setShowNotificationBar', false);

  const auth = await Feathers.get('authentication');
  if (to.matched.some((route) => route.meta.requiresAuth === false && !route.meta.requiresGroup)) next();
  else if (!auth) next({ name: 'home', query: { page: 'login', redirect: to.fullPath } });
  else if (to.matched.some((route) => route.meta.requiresGroup)) {
    let requiredGroups = [];
    const { groups } = auth.user;

    to.matched.forEach((route) => {
      if (route.meta && route.meta.requiresGroup) {
        if (Array.isArray(route.meta.requiresGroup)) requiredGroups = requiredGroups.concat(route.meta.requiresGroup);
        else requiredGroups.push(route.meta.requiresGroup);
      }
    });

    if (requiredGroups.some((g) => groups.includes(g))) next();
    else {
      Store.commit('addToast', { message: 'You don’t have access to this path.', type: 'negative' });
      Store.dispatch('stopApplicationLoading');
      next(false);
    }
  } else next();
});

router.afterEach((to) => {
  const nearestRouteWithTitle = to.matched.slice().reverse().find((route) => route.meta && route.meta.title);
  if (nearestRouteWithTitle) document.title = `${nearestRouteWithTitle.meta.title} – Untold Stories`;
  else document.title = 'Untold Stories';
  Store.dispatch('stopApplicationLoading');
});

router.onError((err) => {
  Store.dispatch('stopApplicationLoading');
  Store.commit('addToast', { message: `Something went wrong: ${err.message}`, timeout: -1, type: 'negative' });
  router.push({ name: 'error' });
});

export default router;

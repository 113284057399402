<template lang="html">
  <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path :d="pathData" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  computed: {
    pathData() {
      switch (this.icon) {
        case 'add-award':
          return 'M18 3V1M18 3H20M18 3H16M18 3V5M16 15H18V11.5L20 9L19.6 8.5M16 15H14.5L12 17L9.5 15H8M16 15V23L12 21L8 23V15M8 15H6V11.5L4 9L6 6.5V3H9.5L12 1L12.5 1.4';
        case 'add-bookmark':
          return 'M18 9V21L12.8944 18.4472C12.3314 18.1657 11.6686 18.1657 11.1056 18.4472L6 21V5C6 3.89543 6.89543 3 8 3H12M18 1V3M18 5V3M18 3H20M18 3H16';
        case 'add-calendar':
          return 'M5 5V3M5 5H15V7H3M5 5C3.89543 5 3 5.89543 3 7M3 7V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V11M23 5H21M21 5V3M21 5V7M21 5H19M17 17H15V15H17V17Z';
        case 'add-comment':
          return 'M7 10H17M7 14H12M20 2V4M20 4V6M20 4H22M20 4H18M14 3.22302C13.3568 3.07706 12.6874 3 12 3C7.02944 3 3 7.02944 3 12V19C3 20.1046 3.89543 21 5 21H12C16.9706 21 21 16.9706 21 12C21 11.3126 20.9229 10.6432 20.777 10';
        case 'add-forum':
          return 'M5 8H15M5 12H8M11 19H3C1.89543 19 1 18.1046 1 17V10C1 5.02944 5.02944 1 10 1V1C14.9706 1 19 5.02944 19 10V11M17 17V15M17 17V19M17 17H19M17 17H15M17 11V11C13.6863 11 11 13.6863 11 17V17C11 20.3137 13.6863 23 17 23H21C22.1046 23 23 22.1046 23 21V17C23 13.6863 20.3137 11 17 11Z';
        case 'add-heart':
          return 'M20.8677 11C20.6668 12.0806 20.2371 13.2305 19.5786 14.3805C18.253 16.8628 14.8417 19.3451 12 21C9.15828 19.3451 5.74696 16.8628 4.42139 14.3805C2.5262 11.0707 2.5262 7.76096 4.42139 6.10608C6.31657 4.4512 10.1048 4.4512 12 7.50202C12.7714 6.26016 13.8566 5.52381 15 5.19881M21 3V5M21 5V7M21 5H23M21 5H19';
        case 'add-mail':
          return 'M10 11.5L3.60765 17.4358M10 11.5L3.65804 6.51703M10 11.5C11.1852 12.3889 12.8148 12.3889 14 11.5M3.60765 17.4358C3.2329 17.0723 3 16.5633 3 16V8C3 7.41184 3.25388 6.88298 3.65804 6.51703M3.60765 17.4358C3.96772 17.785 4.45875 18 5 18H19C19.5412 18 20.0323 17.785 20.3923 17.4358M14 11.5L20.3923 17.4358M14 11.5L16 9.92857M20.3923 17.4358C20.7671 17.0723 21 16.5633 21 16V12M3.65804 6.51703C4.01289 6.19572 4.48359 6 5 6H15M23 6H21M21 6V8M21 6V4M21 6H19';
        case 'add-star':
          return 'M21 1V3M21 3H23M21 3V5M21 3H19M18.5496 14.2891L20.9303 11.4711C21.8853 10.3408 21.3246 8.60083 19.8894 8.24061L16.2241 7.32065C15.971 7.25712 15.7528 7.09715 15.616 6.8749L13.6924 3.74969C12.914 2.48504 11.0775 2.4798 10.2919 3.74L8.29485 6.94368C8.15675 7.16523 7.93748 7.32398 7.68389 7.38602L4.11581 8.259C2.67833 8.6107 2.10743 10.3476 3.05593 11.4836L5.48782 14.3961C5.6545 14.5957 5.73685 14.8524 5.71742 15.1117L5.4419 18.7884C5.33108 20.2673 6.8119 21.3482 8.1866 20.7918L11.6782 19.3788C11.9207 19.2806 12.192 19.2814 12.4339 19.381L15.8207 20.7746C17.1921 21.3389 18.6791 20.2668 18.577 18.7874L18.3159 15.0033C18.298 14.7439 18.3818 14.4877 18.5496 14.2891Z';
        case 'add-user':
          return 'M3 21C3 17.134 6.13401 14 10 14C13.866 14 17 17.134 17 21M21 12H19M17 12H19M19 12V10M19 12V14M14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z';
        case 'announcement':
          return 'M1 9V13M20 6L22 5M20 11H23M20 16L22 17M6 15.3333L5.6712 15.2785C4.70683 15.1178 4 14.2834 4 13.3057V8.69425C4 7.71658 4.70683 6.8822 5.6712 6.72147L13.6712 5.38813C14.8903 5.18496 16 6.12504 16 7.36092V14.6391C16 15.875 14.8903 16.815 13.6712 16.6119L10 16M6 15.3333V16.8755C6 17.5709 6.3726 18.2129 6.97635 18.5579V18.5579C8.1769 19.2439 9.68901 18.4879 9.86052 17.1158L10 16M6 15.3333L10 16';
        case 'arrow-bottom':
          return 'M12 5L12 19M12 19L7 14M12 19L17 14';
        case 'arrow-left':
          return 'M19 12H5M5 12L10 7M5 12L10 17';
        case 'arrow-right':
          return 'M5 12H19M19 12L14 17M19 12L14 7';
        case 'arrow-top':
          return 'M12 19V5M12 5L17 10M12 5L7 10';
        case 'award':
          return 'M16 15H18V11.5L20 9L18 6.5V3H14.5L12 1L9.5 3H6V6.5L4 9L6 11.5V15H8M16 15H14.5L12 17L9.5 15H8M16 15V23L12 21L8 23V15';
        case 'bell-ringing':
          return 'M11.7492 18.1764L11.2492 19.0424C10.9731 19.5207 11.137 20.1323 11.6153 20.4085C12.0935 20.6846 12.7051 20.5207 12.9813 20.0424L13.4813 19.1764M11.7492 18.1764L13.4813 19.1764M11.7492 18.1764L4.82103 14.1764M5.82103 12.4444L3.955 13.6764L4.82103 14.1764M5.82103 12.4444L8.32103 8.11423C8.32103 8.11423 11.388 1.80205 17.0172 5.05205M5.82103 12.4444L4.82103 14.1764M17.0172 5.05205C22.6463 8.30205 18.7133 14.1142 18.7133 14.1142L16.2133 18.4444M17.0172 5.05205L18.0172 3.32M16.2133 18.4444L16.0794 20.6764L15.2133 20.1764M16.2133 18.4444L15.2133 20.1764M13.4813 19.1764L15.2133 20.1764';
        case 'bell':
          return 'M11 19V20C11 20.5523 11.4477 21 12 21V21C12.5523 21 13 20.5523 13 20V19M11 19H13M11 19H6M6 17L5 19H6M6 17V12C6 12 5.5 5 12 5M6 17V19M12 5C18.5 5 18 12 18 12V17M12 5V3M18 17L19 19H18M18 17V19M13 19H18';
        case 'bold':
          return 'M6 10V21H12.5C15.5376 21 18 18.5376 18 15.5C18 13.2979 16.7058 11.398 14.8366 10.5196M6 10V5H8M6 10H8M6 12V3H11.5C13.9853 3 16 5.01472 16 7.5C16 8.66199 15.5596 9.72112 14.8366 10.5196M6 12H11.5H12.625C14.489 12 16 13.567 16 15.5C16 17.433 14.489 19 12.625 19H8M6 12V19H8M6 12H8V19M11.6667 10C12.9553 10 14 8.88071 14 7.5C14 6.11929 12.9553 5 11.6667 5H8M11.6667 10H8M11.6667 10H12.5C13.3354 10 14.1274 10.1863 14.8366 10.5196M8 10V5';
        case 'bookmark':
          return 'M16 3H8C6.89543 3 6 3.89543 6 5V21L11.1056 18.4472C11.6686 18.1657 12.3314 18.1657 12.8944 18.4472L18 21V5C18 3.89543 17.1046 3 16 3Z';
        case 'bullet-list':
          return 'M20 7H9M20 12H9M20 17H9M4.5 17.5V17.5C4.77614 17.7761 5.22386 17.7761 5.5 17.5V17.5C5.77614 17.2239 5.77614 16.7761 5.5 16.5V16.5C5.22386 16.2239 4.77614 16.2239 4.5 16.5V16.5C4.22386 16.7761 4.22386 17.2239 4.5 17.5ZM4.5 12.5V12.5C4.77614 12.7761 5.22386 12.7761 5.5 12.5V12.5C5.77614 12.2239 5.77614 11.7761 5.5 11.5V11.5C5.22386 11.2239 4.77614 11.2239 4.5 11.5V11.5C4.22386 11.7761 4.22386 12.2239 4.5 12.5ZM4.5 7.5V7.5C4.77614 7.77614 5.22386 7.77614 5.5 7.5V7.5C5.77614 7.22386 5.77614 6.77614 5.5 6.5V6.5C5.22386 6.22386 4.77614 6.22386 4.5 6.5V6.5C4.22386 6.77614 4.22386 7.22386 4.5 7.5Z';
        case 'calendar':
          return 'M5 5V3M5 5H19M5 5C3.89543 5 3 5.89543 3 7M19 5V3M19 5C20.1046 5 21 5.89543 21 7M21 7V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V7M21 7H3M17 17H15V15H17V17Z';
        case 'center':
          return 'M4 6H20M6 10H18M5 14H19M7 18H17';
        case 'check':
          return 'M6 12L10 16L18 8';
        case 'chevron-down':
          return 'M7 10L12 14L17 10';
        case 'chevron-left':
          return 'M16 3L8 12L16 21';
        case 'chevron-right':
          return 'M8 3L16 12L8 21';
        case 'chevron-up':
          return 'M7 14L12 10L17 14';
        case 'clear':
          return 'M10 8H20M7 12H17M4 16H14';
        case 'clock':
          return 'M12 6V12L15 15M22 12C22 18.0751 18.0751 22 12 22C5.92487 22 2 18.0751 2 12C2 5.92487 5.92487 2 12 2C18.0751 2 22 5.92487 22 12Z';
        case 'collection-add':
          return 'M6 11H12M6 15H12M6 19H9M16 20H17C18.1046 20 19 19.1046 19 18V8M5 7H14C15.1046 7 16 7.89543 16 9V21C16 22.1046 15.1046 23 14 23H4C2.89543 23 2 22.1046 2 21V9C2 7.89543 2.89543 7 4 7H5ZM5 7V6C5 4.89543 5.89543 4 7 4H15M19 17H20C21.1046 17 22 16.1046 22 15V9M8 4V3C8 1.89543 8.89543 1 10 1H16M21 1V3M21 3H23M21 3V5M21 3H19';
        case 'collection-remove':
          return 'M6 11H12M6 15H12M6 19H9M16 20H17C18.1046 20 19 19.1046 19 18V7M5 7H14C15.1046 7 16 7.89543 16 9V21C16 22.1046 15.1046 23 14 23H4C2.89543 23 2 22.1046 2 21V9C2 7.89543 2.89543 7 4 7H5ZM5 7V6C5 4.89543 5.89543 4 7 4H15M19 17H20C21.1046 17 22 16.1046 22 15V7M8 4V3C8 1.89543 8.89543 1 10 1H16M23 3H21H19';
        case 'collection':
          return 'M6 11H12M6 15H12M6 19H9M16 20H17C18.1046 20 19 19.1046 19 18V6C19 4.89543 18.1046 4 17 4H7C5.89543 4 5 4.89543 5 6V7M5 7H14C15.1046 7 16 7.89543 16 9V21C16 22.1046 15.1046 23 14 23H4C2.89543 23 2 22.1046 2 21V9C2 7.89543 2.89543 7 4 7H5ZM19 17H20C21.1046 17 22 16.1046 22 15V3C22 1.89543 21.1046 1 20 1H10C8.89543 1 8 1.89543 8 3V4';
        case 'comment':
          return 'M7 10H17M7 14H12M12 3V3C16.9706 3 21 7.02944 21 12V12C21 16.9706 16.9706 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 7.02944 7.02944 3 12 3Z';
        case 'cross':
          return 'M7 7L12 12M12 12L17 17M12 12L7 17M12 12L17 7';
        case 'crown-add':
          return 'M6 20H18M21 3V5M21 7V5M21 5H23M21 5H19M19.5 10.5L18.3578 15.4497C18.1483 16.3572 17.3403 17 16.409 17H7.59103C6.65971 17 5.85166 16.3572 5.64224 15.4497L3.78404 7.3975C3.55812 6.41854 4.75511 5.75511 5.46554 6.46554L9 10L11.1056 5.78885C11.4741 5.05181 12.5259 5.05181 12.8944 5.78885L15 10L17 8';
        case 'crown':
          return 'M6 20H18M9 10L5.46554 6.46554C4.75511 5.75511 3.55812 6.41854 3.78404 7.3975L5.64224 15.4497C5.85166 16.3572 6.65971 17 7.59103 17H16.409C17.3403 17 18.1483 16.3572 18.3578 15.4497L20.216 7.3975C20.4419 6.41854 19.2449 5.75512 18.5345 6.46554L15 10L12.8944 5.78885C12.5259 5.05181 11.4741 5.05181 11.1056 5.78885L9 10Z';
        case 'crown-remove':
          return 'M6 20H18M19 5H23M17 8L15 10L12.8944 5.78885C12.5259 5.05181 11.4741 5.05181 11.1056 5.78885L9 10L5.46554 6.46554C4.75511 5.75511 3.55812 6.41854 3.78404 7.3975L5.64224 15.4497C5.85166 16.3572 6.65971 17 7.59103 17H16.409C17.3403 17 18.1483 16.3572 18.3578 15.4497L19.8462 9';
        case 'delete':
          return 'M18 6V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V6M18 6H19V5H13M18 6H13M6 6H5V5H11M6 6H11M11 6H13M11 6V5M13 6V5M10 17V10M14 17V10M13 5V4C13 3.44772 12.5523 3 12 3V3C11.4477 3 11 3.44772 11 4V5';
        case 'derpy':
          return 'M7 16H17M7 16V15M7 16V17M17 16V15M17 16V17M11 19H13M12 22C18.0751 22 22 18.0751 22 12C22 5.92487 18.0751 2 12 2C5.92487 2 2 5.92487 2 12C2 18.0751 5.92487 22 12 22ZM8 9.5C8 10.3284 7.55228 11 7 11C6.44772 11 6 10.3284 6 9.5C6 8.67157 6.44772 8 7 8C7.55228 8 8 8.67157 8 9.5ZM18 9.5C18 10.3284 17.5523 11 17 11C16.4477 11 16 10.3284 16 9.5C16 8.67157 16.4477 8 17 8C17.5523 8 18 8.67157 18 9.5Z';
        case 'description':
          return 'M7 8H17M7 12H17M7 16H13M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z';
        case 'document':
          return 'M9 8H15M9 12H15M9 16H12M7 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21Z';
        case 'draft-add':
          return 'M9 8H15M9 12H15M9 16H12M8 3H7C5.89543 3 5 3.89543 5 5V6M11 3H13M5 9V10M5 18V19C5 20.1046 5.89543 21 7 21H8M5 15V14M19 9V10M19 14V15M19 18V19C19 20.1046 18.1046 21 17 21H16M11 21H13M19 5V3M19 3V1M19 3H17M19 3H21';
        case 'draft':
          return 'M9 8H15M9 12H15M9 16H12M8 3H7C5.89543 3 5 3.89543 5 5V6M11 3H13M16 3H17C18.1046 3 19 3.89543 19 5V6M5 9V10M5 18V19C5 20.1046 5.89543 21 7 21H8M5 15V14M19 9V10M19 14V15M19 18V19C19 20.1046 18.1046 21 17 21H16M11 21H13';
        case 'error':
          return 'M12 7V13M12 16V17M22 12C22 18.0751 18.0751 22 12 22C5.92487 22 2 18.0751 2 12C2 5.92487 5.92487 2 12 2C18.0751 2 22 5.92487 22 12Z';
        case 'forum':
          return 'M5 8H15M5 12H8M11 19H3C1.89543 19 1 18.1046 1 17V10C1 5.02944 5.02944 1 10 1V1C14.9706 1 19 5.02944 19 10V11M17 11V11C13.6863 11 11 13.6863 11 17V17C11 20.3137 13.6863 23 17 23H21C22.1046 23 23 22.1046 23 21V17C23 13.6863 20.3137 11 17 11Z';
        case 'group':
          return 'M1 21C1 17.134 4.13401 14 8 14C11.866 14 15 17.134 15 21M16 14C19.866 14 23 17.134 23 21M15 10.874C15.3196 10.9562 15.6547 11 16 11C18.2091 11 20 9.20914 20 7C20 4.79086 18.2091 3 16 3C15.6547 3 15.3196 3.04375 15 3.12602M12 7C12 9.20914 10.2091 11 8 11C5.79086 11 4 9.20914 4 7C4 4.79086 5.79086 3 8 3C10.2091 3 12 4.79086 12 7Z';
        case 'hashtag':
          return 'M19 9H5M19 15H5M11 4L7 20M17 4L13 20';
        case 'heading1':
          return 'M10 7V12M10 17V12M10 12H3M3 12V7M3 12V17M15 17L18 17M21 17L18 17M18 17V7L15 10';
        case 'heading2':
          return 'M21 17H15C18.5 13.5 20.3194 12.0957 20.5 10C20.7458 7.14661 17 6 15 8.50001M10 7V12M10 17V12M10 12H3M3 12V7M3 12V17';
        case 'heart':
          return 'M19.5786 6.10608C17.6834 4.4512 13.8952 4.4512 12 7.50202C10.1048 4.4512 6.31657 4.4512 4.42139 6.10608C2.5262 7.76096 2.5262 11.0707 4.42139 14.3805C5.74696 16.8628 9.15828 19.3451 12 21C14.8417 19.3451 18.253 16.8628 19.5786 14.3805C21.4738 11.0707 21.4738 7.76096 19.5786 6.10608Z';
        case 'hide':
          return 'M20 4L16.823 7.177M4 20L7.177 16.823M13.4142 10.5858C13.7761 10.9477 14 11.4477 14 12C14 13.1046 13.1046 14 12 14C11.4477 14 10.9477 13.7761 10.5858 13.4142M13.4142 10.5858L10.5858 13.4142M13.4142 10.5858L16.823 7.177M10.5858 13.4142L7.177 16.823M16.823 7.177C19.9747 8.92414 21 12 21 12C21 12 19 18 12 18C10.0119 18 8.42718 17.516 7.177 16.823M11 6.04238C4.80751 6.57749 3 12 3 12C3 12 3.13432 12.403 3.45516 13';
        case 'home':
          return 'M5 10V19C5 20.1046 5.89543 21 7 21H10V17C10 15.8954 10.8954 15 12 15V15C13.1046 15 14 15.8954 14 17V21H17C18.1046 21 19 20.1046 19 19V10M5 10L12 3L19 10M5 10L3 12M19 10L21 12';
        case 'image-add':
          return 'M4 18L9.5 10L13 15L15 13L20 18M15 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V11M21 5V7M21 5V3M21 5H19M21 5H23';
        case 'info':
          return 'M12 17V11M12 8V7M22 12C22 5.92487 18.0751 2 12 2C5.92487 2 2 5.92487 2 12C2 18.0751 5.92487 22 12 22C18.0751 22 22 18.0751 22 12Z';
        case 'italic':
          return 'M10 3H14M17 3H14M14 3L10 21M10 21H14M10 21H7';
        case 'justify':
          return 'M4 6H20M4 10H20M4 14H20M4 18H20';
        case 'key':
          return 'M10 12C10 14 9 15 7 15C5 15 4 14 4 12C4 10 5 9 7 9C9 9 10 10 10 12ZM10 12H16M20 12H18M18 12V15M18 12H16M16 12V15';
        case 'left':
          return 'M4 6H20M4 10H16M4 14H18M4 18H14';
        case 'like-comment':
          return 'M7 10H17M7 14H8M20.777 10C19.8675 5.99202 16.2832 3 12 3C7.02944 3 3 7.02944 3 12V19C3 20.1046 3.89543 21 5 21H9M17 15.4074C15.9471 13.6913 13.8425 13.6913 12.7897 14.6222C11.7368 15.553 11.7368 17.4148 12.7897 19.2765C13.058 19.7853 13.4802 20.2941 13.9849 20.7804C14.8652 21.6286 15.9965 22.4083 17 23C18.5787 22.0691 20.4739 20.6728 21.2103 19.2765C22.2632 17.4148 22.2632 15.553 21.2103 14.6222C21.0616 14.4907 20.8919 14.3778 20.7072 14.2857C19.5839 13.7255 17.9042 13.9337 17 15.4074Z';
        case 'line-break':
          return 'M5 13H16C17.6569 13 19 11.6569 19 10V8M5 13L8 10M5 13L8 16';
        case 'link':
          return 'M10 6H9C5.68629 6 3 8.68629 3 12C3 15.3137 5.68629 18 9 18H10M14 18H15C18.3137 18 21 15.3137 21 12C21 8.68629 18.3137 6 15 6H14M15 12H9';
        case 'location':
          return 'M12 3C15.866 3 19 6.2256 19 10.2C19 14.1744 16.5163 16.9811 12 21C7.478 16.68 5 14.1744 5 10.2C5 6.2256 8.136 3 12 3ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z';
        case 'lock-closed':
          return 'M9 9.5C6.97607 10.3214 6 12.1547 6 15C6 19 8 21 12 21C16 21 18 19 18 15C18 12.1716 17 10.3431 15 9.51472M9 9.5C9.82137 9.16667 10.8453 9 12 9C13.1716 9 14.1716 9.17157 15 9.51472M9 9.5V6C9 6 9 3 12 3C15 3 15 6 15 6V9.51472M12.5 15.5V15.5C12.7761 15.2239 12.7761 14.7761 12.5 14.5V14.5C12.2239 14.2239 11.7761 14.2239 11.5 14.5V14.5C11.2239 14.7761 11.2239 15.2239 11.5 15.5V15.5C11.7761 15.7761 12.2239 15.7761 12.5 15.5Z';
        case 'lock-open':
          return 'M9 9.5C6.97607 10.3214 6 12.1547 6 15C6 19 8 21 12 21C16 21 18 19 18 15C18 11 16 9 12 9C10.8453 9 9.82137 9.16667 9 9.5ZM9 9.5V6C9 6 9 3 12 3C13.8391 3 14.5508 4.12744 14.8262 5M12.5 15.5V15.5C12.7761 15.2239 12.7761 14.7761 12.5 14.5V14.5C12.2239 14.2239 11.7761 14.2239 11.5 14.5V14.5C11.2239 14.7761 11.2239 15.2239 11.5 15.5V15.5C11.7761 15.7761 12.2239 15.7761 12.5 15.5Z';
        case 'mail':
          return 'M14 11.5L20.3923 17.4358M14 11.5L20.342 6.51703M14 11.5V11.5C12.8148 12.3889 11.1852 12.3889 10 11.5V11.5M10 11.5L3.60765 17.4358M10 11.5L3.65804 6.51703M3.60765 17.4358C3.2329 17.0723 3 16.5633 3 16V8C3 7.41184 3.25388 6.88298 3.65804 6.51703M3.60765 17.4358C3.96772 17.785 4.45875 18 5 18H19C19.5412 18 20.0323 17.785 20.3923 17.4358M20.3923 17.4358C20.7671 17.0723 21 16.5633 21 16V8C21 7.41184 20.7461 6.88298 20.342 6.51703M3.65804 6.51703C4.01289 6.19572 4.48359 6 5 6H19C19.5164 6 19.9871 6.19572 20.342 6.51703';
        case 'minus':
          return 'M6 12H18';
        case 'ordered-list':
          return 'M20 7H9M20 12H9M20 17H9M5 8V6H4M5 13H4L5 11H4M4 16H5V18H4';
        case 'paragraph':
          return 'M12 20V12M12 4H9C9 4 6 4 6 8C6 12 9 12 9 12H12M12 4V12M12 4H16M16 4V20M16 4H18';
        case 'pencil':
          return 'M15 6L4 17L3 21L7 20L18 9M15 6L16.5858 4.41422C17.3668 3.63317 18.6332 3.63317 19.4142 4.41422L19.5858 4.58579C20.3668 5.36684 20.3668 6.63316 19.5858 7.41421L18 9M15 6L18 9';
        case 'play':
          return 'M19.29 11.05C19.7618 11.6096 19.7618 12.3904 19.29 12.95C18.4605 13.9338 16.8486 15.5411 14.1667 17.0891C11.6919 18.5174 9.73768 19.2674 8.53004 19.6458C7.72264 19.8988 6.91757 19.4892 6.69929 18.6717C6.37462 17.4558 6 15.3311 6 12C6 8.66886 6.37462 6.54414 6.6993 5.32822C6.91758 4.51077 7.72261 4.10118 8.52999 4.35417C9.73763 4.73258 11.6918 5.48251 14.1667 6.91089C16.8486 8.45881 18.4606 10.0662 19.29 11.05Z';
        case 'plus':
          return 'M6 12H18M12 18V6';
        case 'publish':
          return 'M6 10L5 10C3.89543 10 3 10.8954 3 12L3 18C3 19.1046 3.89543 20 5 20L19 20C20.1046 20 21 19.1046 21 18L21 12C21 10.8954 20.1046 10 19 10L18 10M12 4L9 7M12 4L15 7M12 4L12 12';
        case 'question-mark':
          return 'M8 9C8 3.90912 16 3.90909 16 9C16 12.021 12 11.979 12 15M12 18V19';
        case 'quote':
          return 'M10 9C10 11.2091 9.20914 12 7 12C4.79086 12 4 11.2091 4 9C4 6.79086 4.79086 6 7 6C9.20914 6 10 6.79086 10 9ZM10 9V14C10 18 6 18 6 18M20 9C20 11.2091 19.2091 12 17 12C14.7909 12 14 11.2091 14 9C14 6.79086 14.7909 6 17 6C19.2091 6 20 6.79086 20 9ZM20 9V14C20 18 16 18 16 18';
        case 'refresh':
          return 'M19 12C19 16.2526 16.2526 19 12 19C7.74741 19 5 16.2526 5 12C5 7.74741 7.74741 5.00001 12 5.00001C14.6014 5.00001 16.6396 6.02813 17.8302 7.80001M18.364 4.22183V8.46447H14.1213';
        case 're-roll':
          return 'M13.7785 18.1823C13.4832 19.2844 12.903 20.2894 12.0962 21.0962C11.2894 21.903 10.2844 22.4832 9.18232 22.7785C8.08021 23.0738 6.91979 23.0738 5.81768 22.7785C4.71557 22.4832 3.71061 21.903 2.90381 21.0962C2.09701 20.2894 1.51679 19.2844 1.22148 18.1823C0.926173 17.0802 0.926173 15.9198 1.22148 14.8177C1.51679 13.7156 2.09701 12.7106 2.90381 11.9038C3.71061 11.097 4.71557 10.5168 5.81768 10.2215M5.81768 10.2215L3 9M5.81768 10.2215L5 13M13 1H20C21.6569 1 23 2.34315 23 4V11C23 12.6569 21.6569 14 20 14H13C11.3431 14 10 12.6569 10 11V4C10 2.34315 11.3431 1 13 1ZM13 11C12.7239 10.7239 12.7239 10.2761 13 10C13.2761 9.72386 13.7239 9.72386 14 10C14.2761 10.2761 14.2761 10.7239 14 11C13.7239 11.2761 13.2761 11.2761 13 11ZM16 8C15.7239 7.72386 15.7239 7.27614 16 7C16.2761 6.72386 16.7239 6.72386 17 7C17.2761 7.27614 17.2761 7.72386 17 8C16.7239 8.27614 16.2761 8.27614 16 8ZM19 5C18.7239 4.72386 18.7239 4.27614 19 4C19.2761 3.72386 19.7239 3.72386 20 4C20.2761 4.27614 20.2761 4.72386 20 5C19.7239 5.27614 19.2761 5.27614 19 5Z';
        case 'reading-list':
          return 'M6 11H12M6 15H11M6 19H9M5 7H14C15.1046 7 16 7.89543 16 9V11M5 7H4C2.89543 7 2 7.89543 2 9V21C2 22.1046 2.89543 23 4 23H11M5 7V6C5 4.89543 5.89543 4 7 4H17C18.1046 4 19 4.89543 19 6V11M8 4V3C8 1.89543 8.89543 1 10 1H20C21.1046 1 22 1.89543 22 3V12M18 15C14.8581 15 13.5671 17.4679 13.1578 18.5268C13.0394 18.833 13.0394 19.167 13.1578 19.4732C13.5671 20.5321 14.8581 23 18 23C21.1419 23 22.4329 20.5321 22.8422 19.4732C22.9606 19.167 22.9606 18.833 22.8422 18.5268C22.4329 17.4679 21.1419 15 18 15ZM17.5 19.5C17.2239 19.2239 17.2239 18.7761 17.5 18.5C17.7761 18.2239 18.2239 18.2239 18.5 18.5C18.7761 18.7761 18.7761 19.2239 18.5 19.5C18.2239 19.7761 17.7761 19.7761 17.5 19.5Z';
        case 'reading-time':
          return 'M21 11C21 17.0751 17.0751 21 11 21C4.92487 21 1 17.0751 1 11C1 4.92487 4.92487 1 11 1C17.0751 1 21 4.92487 21 11ZM11 17C14.6451 17 17 14.6451 17 11C17 7.35492 14.6451 5 11 5V11L7 15.6997C8.01665 16.5355 9.38107 17 11 17Z';
        case 'remove-award':
          return 'M16 15H18V11.5L20 9L18 6.5M16 15H14.5L12 17L9.5 15H8M16 15V23L12 21L8 23V15M8 15H6V11.5L4 9L6 6.5V3H9.5L12 1L12.5 1.4M20 3H18H16';
        case 'reply-comment':
          return 'M13 3H19C20.1046 3 21 3.89543 21 5V7M13 3L15 1M13 3L15 5M9 3.51212C5.50442 4.74763 3 8.08134 3 12V19C3 20.1046 3.89543 21 5 21H12C16.9706 21 21 16.9706 21 12C21 11.662 20.9814 11.3283 20.9451 11M7 10H17M7 14H12';
        case 'reply-forum':
          return 'M5 8H15M5 12H8M11 19H3C1.89543 19 1 18.1046 1 17V10C1 5.02944 5.02944 1 10 1V1C14.9706 1 19 5.02944 19 10V11M19 17H17H15M15 17L17 15M15 17L17 19M17 11V11C13.6863 11 11 13.6863 11 17V17C11 20.3137 13.6863 23 17 23H21C22.1046 23 23 22.1046 23 21V17C23 13.6863 20.3137 11 17 11Z';
        case 'right':
          return 'M4 6H20M8 10H20M6 14H20M10 18H20';
        case 'search':
          return 'M15.1049 15.1049C18.1444 12.0653 18.1444 8.31917 15.1049 5.27965C12.0653 2.24012 8.31916 2.24012 5.27964 5.27964C2.24011 8.31917 2.24013 12.0653 5.27965 15.1049C8.92708 18.7523 12.0653 18.1444 15.1049 15.1049ZM15.1049 15.1049L21 21';
        case 'send':
          return 'M4.21887 3.27961C11.3081 4.96038 14.5138 6.73915 19.7139 10.3792C20.8381 11.1662 20.8381 12.8338 19.7139 13.6208C14.5138 17.2609 11.3081 19.0396 4.21887 20.7204C3.59638 20.868 3 20.3931 3 19.7534V15C3 14.4477 3.44911 14.0013 4.00129 13.9902C6.97522 13.9302 8.93865 13.6011 11.1128 13.1556C12.2193 12.9288 12.2193 11.0712 11.1128 10.8444C8.93865 10.3989 6.97522 10.0698 4.00129 10.0098C3.44912 9.99868 3 9.55228 3 9V4.24662C3 3.60687 3.59638 3.13202 4.21887 3.27961Z';
        case 'settings':
          return 'M12.5719 1.12284C12.0598 1.04199 11.5348 1 11 1C10.4652 1 9.94014 1.04199 9.42805 1.12284C8.56503 1.2591 7.99999 2.05226 7.99999 2.92597V3.58152C7.30515 3.86278 6.65881 4.23883 6.07703 4.69363L5.50991 4.3662C4.75332 3.92938 3.78414 4.02212 3.23338 4.70029C2.93844 5.06345 2.66847 5.44771 2.42606 5.85044L2.24407 6.16567C2.02092 6.56898 1.82483 6.98933 1.65824 7.42429C1.34607 8.23934 1.75064 9.12399 2.5065 9.56038L3.07645 9.88944C3.02604 10.2524 2.99999 10.6232 2.99999 11C2.99999 11.3768 3.02604 11.7476 3.07645 12.1105L2.5065 12.4396C1.75064 12.876 1.34607 13.7606 1.65824 14.5757C1.82477 15.0105 2.02079 15.4307 2.24384 15.8339L2.4263 16.1499C2.66864 16.5525 2.93854 16.9367 3.23338 17.2997C3.78413 17.9779 4.75332 18.0706 5.5099 17.6338L6.07703 17.3064C6.65881 17.7612 7.30515 18.1372 7.99999 18.4185V19.074C7.99999 19.9477 8.56503 20.7409 9.42805 20.8772C9.94014 20.958 10.4652 21 11 21C11.5348 21 12.0598 20.958 12.5719 20.8772C13.4349 20.7409 14 19.9477 14 19.074V18.4185C14.6948 18.1372 15.3411 17.7612 15.9229 17.3064L16.4901 17.6338C17.2467 18.0706 18.2158 17.9779 18.7666 17.2997C19.0613 16.9368 19.3311 16.5529 19.5733 16.1506L19.7565 15.8333C19.9794 15.4303 20.1753 15.0103 20.3417 14.5757C20.6539 13.7607 20.2493 12.876 19.4935 12.4396L18.9235 12.1106C18.9739 11.7476 19 11.3768 19 11C19 10.6232 18.9739 10.2524 18.9235 9.88943L19.4935 9.56038C20.2493 9.12398 20.6539 8.23933 20.3417 7.42428C20.1752 6.98956 19.9793 6.56943 19.7563 6.16634L19.5735 5.84979C19.3312 5.44729 19.0614 5.06325 18.7666 4.70028C18.2158 4.02212 17.2466 3.92938 16.4901 4.3662L15.9229 4.69363C15.3411 4.23883 14.6948 3.86278 14 3.58152V2.92597C14 2.05226 13.4349 1.2591 12.5719 1.12284ZM11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C9.89542 9 8.99999 9.89543 8.99999 11C8.99999 12.1046 9.89542 13 11 13Z';
        case 'show':
          return 'M3 12C3 12 5 6 12 6C19 6 21 12 21 12C21 12 19 18 12 18C5 18 3 12 3 12ZM10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z';
        case 'separator-add':
          return 'M1 12H6M18 12H23M12 12V10M12 12V14M12 12H14M12 12H10';
        case 'sidebar-collapse':
          return 'M6 3H15C16.6569 3 18 4.34315 18 6V18C18 19.6569 16.6569 21 15 21H6M14 7L11 12L14 17';
        case 'sidebar-expand':
          return 'M6 3H8C9.65685 3 11 4.34315 11 6V18C11 19.6569 9.65685 21 8 21H6M15 7L18 12L15 17';
        case 'sidebar-collapse-mobile':
          return 'M3 18L3 9C3 7.34315 4.34315 6 6 6L18 6C19.6569 6 21 7.34315 21 9L21 18M7 10L12 13L17 10';
        case 'sidebar-expand-mobile':
          return 'M3 18L3 16C3 14.3431 4.34315 13 6 13L18 13C19.6569 13 21 14.3431 21 16L21 18M7 9L12 6L17 9';
        case 'sign-in':
          return 'M7 6V5C7 3.89543 7.89543 3 9 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H9C7.89543 21 7 20.1046 7 19V18M13 12L10 9M13 12L10 15M13 12H5';
        case 'sign-out':
          return 'M14 6V5C14 3.89543 13.1046 3 12 3H6C4.89543 3 4 3.89543 4 5V19C4 20.1046 4.89543 21 6 21H12C13.1046 21 14 20.1046 14 19V18M20 12L17 9M20 12L17 15M20 12H12';
        case 'star':
          return 'M18.577 18.7874C18.6791 20.2668 17.1921 21.3389 15.8207 20.7746L12.4339 19.381C12.192 19.2814 11.9207 19.2806 11.6782 19.3788L8.1866 20.7918C6.8119 21.3482 5.33108 20.2673 5.4419 18.7884L5.71742 15.1117C5.73685 14.8524 5.6545 14.5957 5.48781 14.3961L3.05593 11.4836C2.10743 10.3476 2.67833 8.6107 4.11581 8.259L7.68389 7.38602C7.93748 7.32398 8.15675 7.16523 8.29485 6.94368L10.2919 3.74C11.0775 2.4798 12.914 2.48504 13.6924 3.74969L15.616 6.8749C15.7528 7.09715 15.971 7.25712 16.2241 7.32065L19.8894 8.24061C21.3246 8.60083 21.8853 10.3408 20.9303 11.4711L18.5496 14.2891C18.3818 14.4877 18.298 14.7439 18.3159 15.0033L18.577 18.7874Z';
        case 'stats':
          return 'M17 14c.6 0 1 .4 1 1a8 8 0 11-9-9c.6 0 1 .4 1 1v5c0 1.1.9 2 2 2h5z M13 4c0-.6.4-1 1-1a8 8 0 017 7c0 .6-.4 1-1 1h-7V4z';
        case 'stopwatch':
          return 'M12 6C7.13989 6 4 9.13989 4 14C4 18.8601 7.13989 22 12 22C16.8601 22 20 18.8601 20 14C20 11.2789 19.0157 9.09706 17.2964 7.7036M12 6V3M12 6C14.139 6 15.9448 6.60821 17.2964 7.7036M14 16L12 14V10M12 3H14V2H10V3H12ZM20 5L19 4M20 5L21 6M20 5L17.2964 7.7036';
        case 'strike':
          return 'M17 7.5C17 5.01472 14.7614 3 12 3C9.23858 3 7 5.01472 7 7.5C7 9.98528 9.23858 12 12 12C14.7614 12 17 14.0147 17 16.5C17 18.9853 14.7614 21 12 21C9.23858 21 7 18.9853 7 16.5M5 12H19';
        case 'title':
          return 'M7 10H17M10 14H14M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z';
        case 'underline':
          return 'M7 3V12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12V3M7 21H17';
        case 'unlink':
          return 'M10 6H9C5.68629 6 3 8.68629 3 12C3 13.0929 3.29218 14.1175 3.80269 15M10 18H9C8.11959 18 7.28347 17.8104 6.53026 17.4697M14 18H15C18.3137 18 21 15.3137 21 12C21 9.77915 19.7934 7.84012 18 6.80269M15 12H12M20 4L12 12M4 20L6.53026 17.4697M12 12L6.53026 17.4697';
        case 'untold-stories':
          return 'M12 23L21.5858 13.4142C22.3668 12.6332 22.3668 11.3668 21.5858 10.5858L19 8M12 23L2.41422 13.4142C1.63317 12.6332 1.63317 11.3668 2.41422 10.5858L5 8M12 23V13.5M19 8H5M19 8L16 5M5 8L8 5M16 5L13.4142 2.41421C12.6332 1.63317 11.3668 1.63316 10.5858 2.41421L8 5M16 5H8M12.75 12.25V12.25C13.1642 12.6642 13.1642 13.3358 12.75 13.75V13.75C12.3358 14.1642 11.6642 14.1642 11.25 13.75V13.75C10.8358 13.3358 10.8358 12.6642 11.25 12.25V12.25C11.6642 11.8358 12.3358 11.8358 12.75 12.25Z';
        case 'user':
          return 'M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z';
        case 'warning':
          return 'M12 18V17M12 14V9M13.7889 3.57771L21.5528 19.1056C22.2177 20.4354 21.2507 22 19.7639 22H4.23607C2.7493 22 1.78231 20.4354 2.44721 19.1056L10.2111 3.57771C10.9482 2.10361 13.0518 2.10361 13.7889 3.57771Z';
        case 'writing-prompt':
          return 'M10 20H14M18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10C6 12.2208 7.2066 14.1599 9 15.1973V17H15V15.1973C16.7934 14.1599 18 12.2208 18 10Z';
        case 'view-hearts':
          return 'M20.9895 10C21.0778 8.39079 20.6075 7.00454 19.5786 6.10608C17.6834 4.4512 13.8952 4.4512 12 7.50202C10.1048 4.4512 6.31657 4.4512 4.42139 6.10608C2.5262 7.76096 2.5262 11.0707 4.42139 14.3805C5.50131 16.4028 7.96558 18.4251 10.3784 20M13 17C13 17 14 13 18 13C22 13 23 17 23 17C23 17 22 21 18 21C14 21 13 17 13 17ZM17.5 17.5V17.5C17.7761 17.7761 18.2239 17.7761 18.5 17.5V17.5C18.7761 17.2239 18.7761 16.7761 18.5 16.5V16.5C18.2239 16.2239 17.7761 16.2239 17.5 16.5V16.5C17.2239 16.7761 17.2239 17.2239 17.5 17.5Z';
        case 'view-stars':
          return 'M21.3952 10C21.3203 9.20058 20.7691 8.46141 19.8894 8.24061L16.2241 7.32065C15.971 7.25712 15.7527 7.09715 15.616 6.8749L13.6924 3.74969C12.914 2.48504 11.0775 2.4798 10.2919 3.74L8.29485 6.94368C8.15674 7.16523 7.93747 7.32398 7.68389 7.38602L4.11581 8.259C2.67833 8.6107 2.10742 10.3476 3.05593 11.4836L5.48781 14.3961C5.65449 14.5957 5.73685 14.8524 5.71742 15.1117L5.4419 18.7884C5.33108 20.2673 6.8119 21.3482 8.18659 20.7918L10.1432 20M13 17C13 17 14 13 18 13C22 13 23 17 23 17C23 17 22 21 18 21C14 21 13 17 13 17ZM17.5 17.5C17.2239 17.2239 17.2239 16.7761 17.5 16.5C17.7761 16.2239 18.2239 16.2239 18.5 16.5C18.7761 16.7761 18.7761 17.2239 18.5 17.5C18.2239 17.7761 17.7761 17.7761 17.5 17.5Z';
        default:
          return 'M12 23L21.5858 13.4142C22.3668 12.6332 22.3668 11.3668 21.5858 10.5858L19 8M12 23L2.41422 13.4142C1.63317 12.6332 1.63317 11.3668 2.41422 10.5858L5 8M12 23V13.5M19 8H5M19 8L16 5M5 8L8 5M16 5L13.4142 2.41421C12.6332 1.63317 11.3668 1.63316 10.5858 2.41421L8 5M16 5H8M12.75 12.25V12.25C13.1642 12.6642 13.1642 13.3358 12.75 13.75V13.75C12.3358 14.1642 11.6642 14.1642 11.25 13.75V13.75C10.8358 13.3358 10.8358 12.6642 11.25 12.25V12.25C11.6642 11.8358 12.3358 11.8358 12.75 12.25Z';
      }
    },
  },
  props: {
    icon: String,
  },
};
</script>

<style lang="stylus" scoped>
.icon
  fill: none
  width: 1.5rem
  height: @width
  display: inline-block
  vertical-align: middle
  flex-shrink: 0
  stroke: currentColor
</style>

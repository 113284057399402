<template lang="html">
  <button
    class="button"
    :class="[color, {
      dark, 'icon-left': iconLeft, 'icon-right': iconRight, primary,
    }]"
    :disabled="disabled || loading"
    type="button"
    @click="$emit('click', $event)">
    <div class="ink" />
    <USIcon v-if="iconLeft" :icon="iconLeft" />
    <span class="content">{{$slots.default[0].text}}</span>
    <USIcon v-if="iconRight" :icon="iconRight" />
    <FadeTransition>
      <div v-if="loading" class="loader">
        <USSpinner type="squiggle" />
      </div>
    </FadeTransition>
  </button>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  props: {
    color: String,
    dark: Boolean,
    disabled: Boolean,
    iconLeft: String,
    iconRight: String,
    loading: Boolean,
    primary: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.button
  display: inline-flex;
  justify-content: space-between
  align-items: center
  cursor: pointer
  padding: calc(0.75rem - 1px) 2.25rem
  border: 1px solid $interactable
  border-radius: 0.75rem
  background-color: transparent
  color: $text-primary
  line-height: 1.5rem
  box-shadow: $shadow-low
  text-shadow: $text-shadow
  position: relative
  max-width: 100%
  overflow: hidden
  z-index: 0
  vertical-align: top
  transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease, border-color 200ms ease

  &.dark
    color: $text-primary-dark
    border-color: $interactable-dark

    .loader
      background-color: $bg-dark

  &.danger
    color: $negative

    .ink
      background-color: alpha($negative, 0.1)

  &.positive
    color: $positive

    .ink
      background-color: alpha($positive, 0.1)

  &.warning
    color: $warning

    .ink
      background-color: alpha( $warning, 0.1)

  &.primary
    border-color: $accent-primary
    background-color: $accent-primary
    color: $text-primary-dark
    font-weight: 800

    .ink
      background-color: darken($accent-primary, 10)

    &.danger
      background-color: $negative
      border-color: $negative

      .ink
        background-color: darken($negative, 10)

    &.positive
      background-color: $positive
      border-color: $positive

      .ink
        background-color: darken($positive, 10)

    &.warning
      background-color: $warning
      border-color: $warning

      .ink
        background-color: darken( $warning, 10)

  &.icon-left
    padding-left: 0.75rem
    padding-right: 3rem

    .icon
      margin-right: 1.5rem

  &.icon-right
    padding-right: 0.75rem
    padding-left: 3rem

    .icon
      margin-left: 1.5rem

  &:hover,
  &:focus
    border-color: $accent-primary
    box-shadow: $shadow-high
    color: $accent-primary

    &.danger
      color: $negative
      border-color: @color

    &.positive
      color: $positive
      border-color: @color

    &.warning
      color: $warning
      border-color: @color

    &.primary
      color: $text-primary-dark

  &:focus .ink
    transform: scale(1.4142135624)

  &:active
    transform: translateY(2px)

  &:disabled
    pointer-events: none
    color: $text-disabled
    border-color: $interactable-disabled
    box-shadow: none

    &.primary
      background-color: $interactable-disabled
      font-weight: normal
      background-clip: padding-box

    &.dark
      color: $text-disabled-dark
      border-color: $interactable-disabled-dark

      &.primary
        background-color: $interactable-disabled-dark
        font-weight: normal

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    z-index: -1
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  .content
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .loader
    background-color: $bg
    position: absolute
    top: 0
    left: @top
    right: @top
    bottom: @top
    display: flex
    align-items: center
    justify-content: center

</style>

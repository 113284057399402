import { isEqual } from 'lodash-es';

function generateQuery(queryString, initialQuery = {}) {
  const queries = { ...initialQuery };
  queryString.forEach((query) => {
    const [key, value] = query.split('=');
    if (key && value) queries[key] = value;
  });

  return queries;
}

export default {
  methods: {
    handleLinkClick(e, closeSidebar) {
      // Code adapted from https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
      let { target } = e;
      while (target && target.tagName && target.tagName.toLowerCase() !== 'a' && target !== e.currentTarget) target = target.parentNode;
      if (target && target.matches('a:not([href*=\'://\'])') && target.href) {
        const {
          altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented,
        } = e;
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        if (defaultPrevented) return;
        if (button !== undefined && button !== 0) return;
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target');
          if (/\b_blank\b/i.test(linkTarget)) return;
        }

        if (target.getAttribute && target.getAttribute('href')[0] === '?') {
          const queries = target.getAttribute('href').substring(1).split('&');

          const newQuery = generateQuery(queries, this.$route.query);

          if (isEqual(newQuery, this.$route.query) && e.preventDefault) {
            e.preventDefault();
            return;
          }
          if (e.preventDefault) {
            e.preventDefault();
            if (closeSidebar) {
              this.$store.commit('setShowSidebar', false);
              this.$store.commit('setBodyLock', false);
            }
            this.$router.push({ query: newQuery }); // .catch(err => err);
            return;
          }
        }

        const url = new URL(target.href);
        const to = url.pathname;
        if (window.location.pathname !== to && e.preventDefault) {
          const newQuery = generateQuery(url.search.substring(1).split('&'));
          e.preventDefault();
          if (closeSidebar) {
            this.$store.commit('setShowSidebar', false);
            this.$store.commit('setBodyLock', false);
          }
          this.$router.push({ path: to, query: newQuery });
        } else if (window.location.search !== url.search && e.preventDefault) {
          const newQuery = generateQuery(url.search.substring(1).split('&'));
          e.preventDefault();
          if (closeSidebar) {
            this.$store.commit('setShowSidebar', false);
            this.$store.commit('setBodyLock', false);
          }
          this.$router.push({ query: newQuery });
        }
      }
    },
  },
};

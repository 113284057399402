<template lang="html">
  <USView class="tagged" :dark="dark" show-back>
    <h1>{{$route.params.tag}}</h1>
    <p>Posts tagged ‘<span class="tag">{{$route.params.tag}}</span>’.</p>
    <label>
      Sort by:
      <USSelectBox v-model="sort" :dark="dark" :options="[{ label: 'Newest First', value: -1 }, { label: 'Oldest First', value: 1 }]" />
    </label>
    <ListTransition>
      <template v-if="posts.length > 0 || nextPageAvailable">
        <USPostCard v-for="post in posts" :dark="dark" :key="post._id" :post="post" @click="openPost($event, { params: { id: post._id }, query: { list: $route.params.tag, listType: 'tag' } })" @mousedown="openPost($event, { params: { id: post._id }, query: { list: $route.params.tag, listType: 'tag' } })" />
        <USButton v-show="nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="postsLoading" @click="fetchPostsPage">Load More</USButton>
      </template>
      <section v-else class="empty-state" key="emptyState">
        <p>There aren’t any posts with this tag yet.</p>
        <USButton v-if="!$store.state.user.groups.includes('applicants')" :dark="dark" icon-left="plus" @click="$router.push({ name: 'write' })">Create One</USButton>
      </section>
    </ListTransition>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';

import openPost from '@/mixins/openPost';

import ListTransition from '@/transitions/ListTransition.vue';

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: posts, total } = await Feathers.service('posts').find({
        query: {
          tags: to.params.tag,
          $limit: 15,
          $sort: { createdAt: -1 },
        },
      });

      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.posts = posts;
        vm.currentPage += 1;
        vm.nextPageAvailable = vm.currentPage < Math.ceil(total / vm.pageSize);
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      else if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchPostsPage();
    next();
  },
  components: {
    ListTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  data() {
    return {
      currentPage: 0,
      nextPageAvailable: true,
      pageSize: 15,
      posts: [],
      postsLoading: false,
      sort: -1,
    };
  },
  methods: {
    async fetchPostsPage() {
      const timeoutId = window.setTimeout(() => { this.postsLoading = true; }, 200);
      try {
        const { data: posts, total } = await this.$feathers.service('posts').find({
          query: {
            tags: this.$route.params.tag,
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage,
            $sort: { createdAt: this.sort },
          },
        });
        this.posts = this.posts.concat(posts);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more posts: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.postsLoading = false;
    },
  },
  mixins: [openPost],
  mounted() {
    document.title = `Tagged: ${this.$route.params.tag} – Untold Stories`;
  },
  props: {
    dark: Boolean,
  },
  watch: {
    sort() {
      this.posts = [];
      this.currentPage = 0;
      this.fetchPostsPage();
    },
  },
};
</script>

<style lang="stylus" scoped>
.tagged
  > h1
    text-transform: capitalize

  > p
    margin-bottom: 3rem

    .tag
      text-transform: capitalize

  .button
    display: flex
    margin: 0 auto
    margin-top: 1.5rem

  label
    display: flex
    align-items: center
    margin-bottom: 2rem

    .select-box
      margin-left: auto

  .post-card:not(:last-of-type)
    margin-bottom: 1.5rem

  .empty-state
    margin-bottom: 0

    p
      font-weight: 800
      opacity: 0.6
      text-align: center
</style>

import { render, staticRenderFns } from "./User.vue?vue&type=template&id=c7fa1d36&scoped=true&lang=html"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"
import style0 from "./User.vue?vue&type=style&index=0&id=c7fa1d36&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7fa1d36",
  null
  
)

export default component.exports
<template lang="html">
  <transition duration="200" mode="out-in" :name="name" v-bind="$attrs" v-on="$listeners">
    <slot />
  </transition>
</template>

<script>
export default {
  computed: {
    name() {
      if (this.direction === 'down') return 'count-down';
      return 'count-up';
    },
  },
  props: {
    direction: {
      type: String,
      default: 'up',
      validator(v) {
        return ['up', 'down'].includes(v);
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.count-up-enter-active,
.count-up-leave-active
  transition: transform 200ms ease, opacity 200ms ease

  &.count-up-enter
    opacity: 0
    transform: translateY(50%)

  &.count-up-leave-to
    opacity: 0
    transform: translateY(-50%)

.count-down-enter-active,
.count-down-leave-active
  transition: transform 200ms ease, opacity 200ms ease

  &.count-down-enter
    opacity: 0
    transform: translateY(-50%)

  &.count-down-leave-to
    opacity: 0
    transform: translateY(50%)
</style>

/* eslint-disable no-console */

import { register } from 'register-service-worker';
import Store from './store';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) {
        console.log(
          'App is being served from cache by a service worker.\n'
          + 'For more details, visit https://goo.gl/AFskqB',
        );
      }
    },
    registered(registration) {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) console.log('Service worker has been registered.');
      Store.commit('setServiceWorkerRegistration', registration);
    },
    cached() {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) console.log('Content has been cached for offline use.');
    },
    updatefound() {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) console.log('New content is downloading.');
    },
    updated(registration) {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) console.log('New content is available; please refresh.');
      if (window.localStorage) window.localStorage.setItem('forceChangelog', true);
      Store.commit('addToast', {
        action: () => {
          if (registration.waiting) registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload(true);
        },
        actionLabel: 'Refresh',
        message: 'A new version of Untold Stories is available, refresh to use the newest version.',
        timeout: -1,
      });
    },
    offline() {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      if (window.location.host.includes('localhost') || window.location.host.includes('beta') || window.location.host.includes('172.0.0.1')) console.error('Error during service worker registration:', error);
      Store.commit('addToast', { message: `There was an error registering the service worker: ${error.message}`, type: 'negative' });
    },
  });
}

<template lang="html">
  <div class="counter-icon-button" @click="handleClick">
    <USIconButton :class="{ chipless: !showCounter && amount <= 0 }" :dark="dark" :disabled="disabled" :icon="icon" :loading="loading" :tooltip="tooltip" />
    <USChip v-show="showCounter || amount > 0" :content="amount" :dark="dark" :prominent="!disabled && !loading" />
  </div>
</template>

<script>

export default {
  props: {
    amount: Number,
    dark: Boolean,
    disabled: Boolean,
    icon: String,
    loading: Boolean,
    showCounter: {
      type: Boolean,
      default: true,
    },
    tooltip: String,
  },
  methods: {
    handleClick(e) {
      if (!this.disabled) this.$emit('click', e);
    },
  },
};
</script>

<style lang="stylus" scoped>
.counter-icon-button
  display: inline-block
  pointer-events: none

  *
    pointer-events: auto

  .icon-button:not(.chipless)
    margin-right: 0.5rem

  .chip
    padding-top: 0.25rem
    transition: color 200ms ease, background-color 200ms ease
</style>

<template lang="html">
  <div class="calendar" :class="{ dark }">
    <USCounterButton :dark="dark" :disabled="{ left: !previousMonthAvailable }" icon-left="chevron-left" icon-right="chevron-right" @decrement="moveMonth('backward')" @increment="moveMonth('forward')">{{ pickerMonth }}</USCounterButton>
    <div class="calendar-wrapper">
      <header>
        <span class="label">Mon</span>
        <span class="label">Tue</span>
        <span class="label">Wed</span>
        <span class="label">Thu</span>
        <span class="label">Fri</span>
        <span class="label">Sat</span>
        <span class="label">Sun</span>
      </header>
      <FadeTransition>
        <section class="days" :key="pickerMonth">
          <USButton v-for="date in pickerDays" :dark="dark" :disabled="date.disabled" :key="date.rawDate.valueOf()" :primary="date.active" @click.native="setPickerDate(date.rawDate)">{{date.day}}</USButton>
        </section>
      </FadeTransition>
    </div>
  </div>
</template>

<script>
import { Date as SugarDate } from 'sugar-date';
import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    previousMonthAvailable() {
      const date = new SugarDate(this.pickerDate);
      return !date.clone().addMonths(-1, true).endOfMonth().isPast().raw;
    },
    pickerMonth() {
      return new SugarDate(this.pickerDate).format('{Month} {yyyy}').raw;
    },
    pickerDays() {
      const date = new SugarDate(this.pickerDate);
      const start = date.clone().beginningOfMonth().beginningOfISOWeek().raw;
      const end = date.clone().endOfMonth().endOfISOWeek().raw;
      const rawDays = SugarDate.range(start, end).every('day');
      const cleanDays = [];
      rawDays.forEach((day) => {
        cleanDays.push({
          active: SugarDate.is(day, SugarDate.beginningOfDay(new Date(this.pickerDate))),
          day: SugarDate.format(day, '{d}'),
          disabled: SugarDate.isPast(new SugarDate(day).endOfDay()),
          rawDate: day,
        });
      });
      return cleanDays;
    },
  },
  data() {
    return {
      pickerDate: this.value ? new Date(this.value) : new Date(),
    };
  },
  methods: {
    moveMonth(direction) {
      if (direction === 'backward' && this.previousMonthAvailable) this.setPickerDate(SugarDate.addMonths(new Date(this.pickerDate), -1, true));
      else this.setPickerDate(SugarDate.addMonths(new Date(this.pickerDate), 1, true));
    },
    setPickerDate(date) {
      this.$emit('input', date.valueOf());
    },
  },
  props: {
    dark: Boolean,
    value: Number,
  },
  watch: {
    value(newVal) {
      this.pickerDate = new Date(newVal);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.calendar
  max-width: 36rem

  &.dark .calendar-wrapper
    border-color: $interactable-dark

    &:hover
      background-color: $elevation-primary-dark

    header
      color: $text-secondary-dark

  .counter-button
    margin-bottom: 1rem
    width: 100%

  .calendar-wrapper
    border: 1px solid $interactable
    border-radius: 0.75rem
    box-shadow: $shadow-low
    padding: 0.5rem
    padding-top: 1rem
    transition: box-shadow 200ms ease, background-color 200ms ease

    &:hover,
    &:focus-within
      box-shadow: $shadow-high

    header
      display: flex
      justify-content: space-around
      color: $text-secondary
      margin-bottom: 0.75rem

      .label
        margin: 0 0.375rem
        width: 100%
        text-align: center
        font-size: 0.75rem

        &:first-child
          margin-left: 0

        &:last-child
          margin-right: 0

    .days
      display: flex
      flex-wrap: wrap
      margin: 0 -0.1875rem

      .button
        margin: 0.1875rem 0.1875rem
        width: calc(100% / 7 - 0.375rem)
        padding: 0.125rem
        border-radius: 0.375rem

        &:not(.primary)
          box-shadow: none

        &:not(:hover):not(:focus)
          border-color: transparent

</style>

<template lang="html">
  <div class="toast" :class="[type]">
    <span>{{message}}</span>
    <USButton v-if="action && actionLabel" dark @click="handleAction">{{ actionLabel }}</USButton>
    <USIconButton v-if="dismissable" dark icon="cross" @click="$emit('dismiss')" />
  </div>
</template>

<script>
export default {
  methods: {
    handleAction() {
      this.action();
      this.$emit('dismiss');
    },
  },
  mounted() {
    if (this.timeout > 0) {
      window.setTimeout(() => {
        this.$emit('dismiss');
      }, this.timeout);
    }
  },
  props: {
    action: Function,
    actionLabel: String,
    dismissable: Boolean,
    message: String,
    timeout: {
      type: Number,
      default: 2500,
    },
    type: {
      type: String,
      validator: (v) => ['negative', 'normal', 'positive', 'warning'].includes(v),
      default: 'normal',
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.toast
  max-width: 40rem
  width: 100%
  padding: 0.75rem
  border-radius: 0.75rem
  box-shadow: $shadow-high
  color: $text-primary-dark
  display: flex
  align-items: center
  font-weight: 800

  &.normal
    background-color: $accent-primary

  &.negative
    background-color: $negative

  &.positive
    background-color: $positive

  &.warning
    background-color: $warning

  &.negative,
  &.warning
    color: $text-primary
    font-weight: normal

    .button,
    .icon-button
      color: inherit
      font-weight: normal

  span
    margin-right: auto
    margin-left: 0.375rem
    white-space: pre-line

  .button,
  .icon-button
    box-shadow: none
    border-radius: 0.375rem
    border-color: transparent
    font-weight: 800
    margin-left: 0.75rem
    flex-shrink: 0

    &:hover,
    &:focus
      border-color: inherit
      color: @border-color

    >>> .ink
      background-color: alpha(white, 0.4)

  .button
    padding: calc(0.375rem - 1px) calc(1rem - 1px)

  .icon-button
    padding: calc(0.375rem - 1px)
</style>

<template>
  <div class="back-gesture" :class="[side]">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 80">
      <path fill="#000" :d="path" />
    </svg>
    <transition>
      <USIcon v-show="x >= 40" :class="[side]" icon="chevron-left" />
    </transition>
  </div>
</template>

<script>
import USIcon from './USIcon.vue';

function clamp(min, value, max) {
  return Math.max(min, Math.min(value, max));
}

function easeInOutQuint(n) {
  return n < 0.5 ? 16 * n * n * n * n * n : 1 - (-2 * n + 2) ** 5 / 2;
}

function animate(callback, ease, duration, from, to) {
  let id;
  const start = performance.now();

  function frame(now) {
    const delta = now - start;
    if (delta >= duration) {
      cancelAnimationFrame(id);
      callback(to);
      return;
    }
    callback(from + (to - from) * ease(delta / duration));
    id = requestAnimationFrame(frame);
  }
  id = requestAnimationFrame(frame);
}

export default {
  beforeDestroy() {
    window.removeEventListener('pointerdown', this.handlePointerDown);
    window.addEventListener('touchstart', this.preventBackGesture, { passive: false });
  },
  components: {
    USIcon,
  },
  computed: {
    flipped() {
      return this.y / this.scalingFactor > 40;
    },
    scale() {
      if (this.flipped || this.side === 'right') return `${this.side === 'right' ? -1 : 1} ${this.flipped ? -1 : 1}`;
      return null;
    },
    normalizedY() {
      if (this.flipped) return 40 - (this.y / this.scalingFactor - 40);
      return this.y / this.scalingFactor;
    },
    path() {
      return `M0 0C0 ${(this.normalizedY / 40) * 24}, ${this.x / this.scalingFactor} ${(this.normalizedY / 40) * 24}, ${this.x / this.scalingFactor} ${this.normalizedY}S0 ${(this.normalizedY / 40) * 56}, 0 80Z`;
    },
  },
  data() {
    return {
      x: 0,
      y: 40,
      top: 0,
      side: 'left',
      scalingFactor: 1,
    };
  },
  methods: {
    handlePointerMove(e) {
      const xMax = 16 * this.scalingFactor;
      const yMax = 48 * this.scalingFactor;
      const yMin = 32 * this.scalingFactor;

      if (this.x < xMax) this.x = clamp(0, this.x + e.movementX * (this.side === 'right' ? -1 : 1), xMax);
      else this.x += e.movementX * (xMax / (this.x * (xMax / 2))) * (this.side === 'right' ? -1 : 1);

      if (this.y > yMin && this.y < yMax) this.y = clamp(yMin, this.y + e.movementY, yMax);
      else if (this.y > yMax) this.y += e.movementY * (yMax / (this.y * 8 * this.scalingFactor));
      else this.y += e.movementY * (yMin / (this.y * 8 * this.scalingFactor));
    },
    handlePointerUp() {
      window.removeEventListener('pointermove', this.handlePointerMove);
      window.removeEventListener('pointerup', this.handlePointerUp);
      window.removeEventListener('pointercancel', this.handlePointerUp);

      if (this.x / this.scalingFactor >= 16) {
        if (this.side === 'left') this.$router.back();
        else this.$router.forward();
      }

      animate((v) => { this.x = v; }, easeInOutQuint, 235, this.x, 0);
    },
    handlePointerDown(e) {
      if (!e.isPrimary || (e.pageX > 20 && e.pageX < window.innerWidth - 20)) return;

      window.addEventListener('pointermove', this.handlePointerMove);
      window.addEventListener('pointerup', this.handlePointerUp);
      window.addEventListener('pointercancel', this.handlePointerUp);

      const elHeight = this.$el.getBoundingClientRect().height;
      this.scalingFactor = elHeight / 80;
      this.top = `${e.clientY - elHeight / 2}px`;

      if (e.clientX > window.innerWidth / 2) this.side = 'right';
      else this.side = 'left';

      this.y = 40 * this.scalingFactor;
    },
    preventBackGesture(e) {
      if (e.changedTouches[0].clientX > 20 && e.changedTouches[0].clientX < window.innerWidth - 20) return;
      e.preventDefault();
    },
  },
  mounted() {
    window.addEventListener('pointerdown', this.handlePointerDown);
    window.addEventListener('touchstart', this.preventBackGesture, { passive: false });
  },
};
</script>

<style lang="stylus" scoped>
.back-gesture
  position: fixed
  top: v-bind(top)
  height: 15rem
  z-index: 999
  pointer-events: none

  &.left
    left: 0

  &.right
    right: 0

  svg
    display: block
    overflow: visible
    height: 100%
    transform-box: fill-box

    > path
      transform-origin: center
      scale: v-bind(scale)

  .icon
    position: absolute
    top: 0

    &.left
      left: calc(v-bind(x) * 0.75px)
      translate: -100% calc(v-bind(y) * 1px - 50%)

    &.right
      translate: 100% calc(v-bind(y) * 1px - 50%)
      scale: -1 1
      left: auto
      right: calc(v-bind(x) * 0.75px)

    &.v-enter-active,
    &.v-leave-active
      transition: opacity 200ms ease

      &.v-enter,
      &.v-leave-to
        opacity: 0
</style>

export default function generateBlurb(el) {
  const paragraphItems = el.querySelectorAll('.ql-editor p, .ql-editor li, .ql-editor blockquote');
  let blurb = '';
  for (let i = 0; i < paragraphItems.length; i += 1) {
    if (paragraphItems[i].textContent) {
      const blurbCandidate = paragraphItems[i].textContent.split('\n')[0].substring(0, 255).trim();
      if (!/(\w|,)$/.test(blurbCandidate)) blurb = blurbCandidate;
      else if (blurbCandidate.endsWith(',')) blurb = blurbCandidate.substring(0, blurbCandidate.length - 1);
      else blurb = `${blurbCandidate}…`;
      break;
    }
  }
  return blurb;
}

<template lang="html">
  <nav class="mobile-menu" :class="{ dark }">
    <router-link :to="{ name: 'feed' }">
      <div class="ink" />
      <USIcon icon="home" />
      <span>Feed</span>
      <transition name="pop">
        <div v-show="showFeedNotificationDot" class="notification-dot" />
      </transition>
    </router-link>
    <router-link :to="{ name: 'explore' }">
      <div class="ink" />
      <USIcon icon="search" />
      <span>Explore</span>
    </router-link>
    <router-link :to="{ name: 'forum' }">
      <div class="ink" />
      <USIcon icon="forum" />
      <span>Forum</span>
      <transition name="pop">
        <div v-show="showForumNotificationDot" class="notification-dot" />
      </transition>
    </router-link>
    <router-link :to="{ name: 'inspiration' }">
      <div class="ink" />
      <USIcon icon="writing-prompt" />
      <span>Inspiration</span>
    </router-link>
    <router-link :to="{ name: 'user' }">
      <div class="ink" />
      <USIcon icon="user" />
      <span>User</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  computed: {
    showForumNotificationDot() {
      return this.$store.state.application.showForumNotificationDot;
    },
  },
  data() {
    return {
      showFeedNotificationDot: false,
    };
  },
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.mobile-menu
  border-top-left-radius: 0.75rem
  border-top-right-radius: 0.75rem
  box-shadow: 0 0 0 1px $interactable, $shadow-low-reverse
  background-color: $bg
  padding: 0.375rem
  padding-bottom: 0
  padding-bottom: env(safe-area-inset-bottom, 0)
  display: flex

  &.dark
    background-color: $bg-dark
    box-shadow: 0 0 0 1px $interactable-dark, $shadow-low-reverse

    a
      color: $text-secondary-dark

      .notification-dot
        border-color: $bg-dark

  a
    display: inline-block
    width: 100%
    text-align: center
    border-top-left-radius: 0.375rem
    border-top-right-radius: 0.375rem
    border-bottom-left-radius: clamp(0rem, env(safe-area-inset-bottom, 0rem), 0.375rem)
    border-bottom-right-radius: clamp(0rem, env(safe-area-inset-bottom, 0rem), 0.375rem)
    padding-top: 0.375rem
    padding-bottom: 0.1875rem
    text-decoration: none
    overflow: hidden
    position: relative
    color: $text-secondary
    transition: color 200ms ease

    &:not(:last-child)
      margin-right: 0.375rem

    &.active
      color: $text-primary-dark
      box-shadow: $shadow-low-reverse

      .ink
        transform: translateY(-50%) scale(1.4142135624)

      span
        font-weight: bold

      .notification-dot
        border-color: $accent-primary
        background-color: alpha(mix($text-primary-dark, $accent-primary, 87), 1)

    .ink
      position: absolute
      width: 100%
      top: 50%
      transform: translateY(-50%) scale(0)
      background-color: $accent-primary
      border-radius: 50%
      transition: transform 200ms ease

      &::before
        content: ''
        display: block
        padding-bottom: 100%

    .icon
      display: block
      margin: auto
      margin-bottom: 0.1875rem
      position: relative

    span
      display: block
      font-size: 0.75rem
      line-height: @font-size
      position: relative
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    .notification-dot
      border: 2px solid $bg
      width: calc(0.5rem + 4px)
      height: @width
      border-radius: 50%
      position: absolute
      top: calc(0.375rem - 0.125rem)
      left: calc(50% + 0.125rem)
      background-color: $accent-secondary
      transition: border-color 200ms ease, background-color 200ms ease

      &.pop-enter-active,
      &.pop-leave-active
        transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)

        &.pop-enter,
        &.pop-leave-to
          transform: size(0)
</style>

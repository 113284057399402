<template lang="html">
  <USView class="sentence-game-lobby" :dark="dark" show-back>
    <FadeTransition>
      <USActionBar
        v-show="$store.state.application.showActionBars"
        :actions="[{
          action: () => showAddGame = true, color: 'positive', icon: 'plus', tooltip: 'Start new sentence game',
        }]"
        :dark="dark"
        :vertical="!mobile" />
    </FadeTransition>
    <h1>Sentence Game</h1>
    <p>The Untold Stories classic—now right within the app! Write a sentence and see where it goes.</p>
    <p>Each participant continues the story passed on to them by the previous player, so everyone ends up with their own little piece at the end. These stories might inspire a longer piece, or at the very least draw out a good laugh!</p>
    <p><strong>Please note:</strong> these games are ephemeral in nature, make sure to save your story as a draft when the game is over to ensure you won’t lose it when the game gets removed.</p>
    <p>You’ll find a list of ongoing games below, and an option to create a new one in the action bar. You can join a game by clicking on it, but please keep in mind that you’ll not be able to join ongoing games.</p>
    <ListTransition>
      <template v-if="games.length > 0 || nextPageAvailable">
        <button v-for="game in games" class="game" :class="{ dark }" :disabled="game.status === 'started'" :key="game._id" type="button" @click="joinGame(game._id, game.status)">
          <div class="ink" />
          <div class="info">
            <h2>{{game.title}}</h2>
            <span>{{Object.keys(game.players).length}} Player{{Object.keys(game.players).length !== 1 ? 's' : ''}}</span>
          </div>
          <USChip :content="game.status === 'waiting' && !mobile ? 'Waiting for players' : game.status" :dark="dark" :prominent="game.status === 'waiting'" />
        </button>
        <USButton v-show="nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="gamesLoading" @click="fetchGamesPage">Load More</USButton>
      </template>
      <section v-else class="empty-state" key="emptyState">
        <p>Currently there are no ongoing games.</p>
        <USButton color="positive" :dark="dark" icon-left="plus" @click="showAddGame = true">Create One</USButton>
      </section>
    </ListTransition>
    <USModal :actions="[{ icon: 'cross', label: 'cancel' }, { action: createGame, icon: 'plus', label: 'Create' }]" :dark="dark" title="Create Sentence Game" :visible="showAddGame" @close="showAddGame = false">
      <p>How long should participants have to write their sentence before the game automatically moves into the next round?</p>
      <div class="masonry">
        <USSelectableCard v-for="time in timeouts" :dark="dark" icon="stopwatch" :key="time.value" :selected="newGame.timeout === time.value" :title="time.label" @click="newGame.timeout = time.value" />
      </div>
      <label>
        <span>Enable Single Sentence Mode</span>
        <USSwitch v-model="newGame.singleSentenceMode" :dark="dark" />
      </label>
      <FadeTransition>
        <p v-show="newGame.singleSentenceMode">In <strong>Single Sentence Mode</strong>, players will only see the sentence that was last added to a story instead of the whole story for an extra bit of surprise and creative mayhem.</p>
      </FadeTransition>
    </USModal>
  </USView>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

export default {
  beforeDestroy() {
    this.$feathers.service('sentence-game').off('created', this.addGame);
    this.$feathers.service('sentence-game').off('patched', this.updateGame);
    this.$feathers.service('sentence-game').off('removed', this.removeGame);
  },
  components: {
    FadeTransition,
    ListTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  async created() {
    this.$feathers.service('sentence-game').on('created', this.addGame);
    this.$feathers.service('sentence-game').on('patched', this.updateGame);
    this.$feathers.service('sentence-game').on('removed', this.removeGame);

    this.gamesLoading = true;
    await this.fetchGamesPage();
    this.gamesLoading = false;
  },
  data() {
    return {
      currentPage: 0,
      games: [],
      gamesLoading: false,
      newGame: {
        singleSentenceMode: false,
        timeout: 2 * 60 * 1000,
      },
      nextPageAvailable: true,
      pageSize: 5,
      showAddGame: false,
      skipCounter: 0,
      timeouts: [
        {
          label: 'Two Minutes',
          value: 2 * 60 * 1000,
        },
        {
          label: 'Four Minutes',
          value: 4 * 60 * 1000,
        },
        {
          label: 'Eight Minutes',
          value: 8 * 60 * 1000,
        },
      ],
    };
  },
  methods: {
    addGame(game) {
      this.games.unshift(game);
      this.skipCounter += 1;
    },
    async createGame() {
      try {
        const newGame = {
          mode: this.newGame.singleSentenceMode ? 'single' : 'full',
          timeout: this.newGame.timeout,
        };
        this.showAddGame = false;
        const game = await this.$feathers.service('sentence-game').create(newGame);
        this.newGame = {
          singleSentenceMode: false,
          timeout: 2 * 60 * 1000,
        };
        this.$router.push({ name: 'sentence-game', params: { id: game._id } });
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => { this.showAddPrompt = true; },
          actionLabel: 'Try again',
          message: `Could not create game: ${err.message}`,
          type: 'negative',
        });
      }
    },
    async fetchGamesPage() {
      const timeoutId = window.setTimeout(() => { this.gamesLoading = true; }, 200);
      try {
        const { data: games, total } = await this.$feathers.service('sentence-game').find({
          query: {
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage + this.skipCounter,
            $sort: { createdAt: -1 },
          },
        });

        this.games = this.games.concat(games);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more games: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.gamesLoading = false;
    },
    joinGame(id, status) {
      if (status === 'started') return;
      this.$router.push({ name: 'sentence-game', params: { id } });
    },
    removeGame(game) {
      const index = this.games.findIndex((existingGame) => game._id === existingGame._id);

      if (index > -1) this.games.splice(index, 1);
    },
    updateGame(game) {
      const index = this.games.findIndex((existingGame) => game._id === existingGame._id);

      if (index > -1) this.games.splice(index, 1, game);
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'inspiration', query: { page: 'sentence-game-lobby' } });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../../styles/colors'
@require '../../styles/shadows'

.sentence-game-lobby
  > p:last-of-type
    margin-bottom: 3rem

  .game
    width: 100%
    display: flex
    align-items: center
    padding: calc(1rem - 1px)
    background-color: $bg
    border: 1px solid $interactable
    border-radius: 0.75rem
    box-shadow: $shadow-low
    cursor: pointer
    position: relative
    overflow: hidden
    z-index: 0
    transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, color 200ms ease

    &:not(:last-child)
      margin-bottom: 1rem

    &.dark
      background-color: $bg-dark
      border-color: $interactable-dark
      color: $text-primary-dark

    &:hover,
    &:focus
      border-color: $accent-primary
      box-shadow: $shadow-high

      &.dark
        background-color: $elevation-primary-dark

    &:focus .ink
        transform: scale(1.4142135624)

    &:active
      transform: translateY(2px)

    &:disabled
      pointer-events: none
      color: $text-disabled
      border-color: $interactable-disabled
      box-shadow: none

      &.dark
        color: $text-disabled-dark
        border-color: $interactable-disabled-dark

    .ink
      width: 100%
      border-radius: 50%
      background-color: alpha($accent-primary, 0.1)
      position: absolute
      left: 0
      transform: scale(0)
      z-index: -1
      transition: transform 200ms ease, background-color 200ms ease

      &::before
        content: ''
        display: block
        padding-bottom: 100%

    .info
      text-align: left
      margin-right: 1rem
      width: calc(100% - 6rem)

      h2
        margin-bottom: 0
        color: inherit
        width: 100%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        text-transform: capitalize

      span
        margin-top: 0.25rem
        display: inline-block
        font-size: 0.875rem
        line-height: @font-size
        width: 100%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

    .chip
      flex-shrink: 0
      margin-left: auto

  .empty-state
    margin-bottom: 0

    p
      font-weight: 800
      opacity: 0.6
      text-align: center

  .button
    display: flex
    margin: 0 auto

  .modal
    .masonry
      display: flex
      flex-wrap: wrap
      margin: 0 -0.5rem 2rem -0.5rem

      .selectable-card
        flex: 1 1 calc(33.33% - 2rem)
        min-width: (160 / 16)rem
        margin: 0.5rem

    label
      display: flex
      align-items: center

      > span
        margin-right: 1rem

      .switch
        flex-shrink: 0
        margin-left: auto
</style>

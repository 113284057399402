<template>
  <div id="app" :class="{ dark, loading: $store.state.application.loading }" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <header
      v-if="!mobile && !noMenuRoute && (showNotifications || (showBack && !sidebar) || (collapsibleSidebar && !sidebar))"
      class="header"
      :class="{
        dark, 'no-menu': forceHiddenMenu, scrolled: this.lastScrollTop > 0, sidebar,
      }">
      <USIconButton v-show="showBack && !sidebar" :dark="dark" icon="arrow-left" @click="handleBack" />
      <USIconButton v-show="collapsibleSidebar && !sidebar" :dark="dark" icon="sidebar-expand" @click="$store.commit('setShowSidebar', true)" />
      <USCounterIconButton v-show="showNotifications" :amount="notificationAmount" :dark="dark" :icon="notificationAmount > 0 ? 'bell-ringing' : 'bell'" :show-counter="false" @click="showNotificationBar = true" />
    </header>
    <main :class="{ mobile, 'no-menu': noMenuRoute || forceHiddenMenu, sidebar }">
      <transition name="slide">
        <USMenu v-show="!mobile && !noMenuRoute && !forceHiddenMenu" :dark="dark" />
      </transition>
      <transition name="slide">
        <USMobileMenu v-if="mobile && showMenu && !noMenuRoute && !forceHiddenMenu" :dark="dark" />
      </transition>
      <FadeTransition @before-enter="triggerScroll">
        <router-view :dark="dark" :key="$route.name" @show-changelog="showChangelog = true" />
      </FadeTransition>
      <FadeTransition>
        <USLoader v-if="$store.state.application.loading" :class="{ dark }" key="loader" />
      </FadeTransition>
    </main>
    <NotificationBar v-if="$store.getters.userId" :dark="dark" :visible="showNotificationBar" @close="showNotificationBar = false" />
    <transition-group class="snackbar" :class="{ mobile, menu: mobile && showMenu && !noMenuRoute }" name="snackbar" :style="{ transform: snackbarTransform }" tag="div">
      <USToast v-for="toast in toasts" :action="toast.action" :actionLabel="toast.actionLabel" :dismissable="toast.dismissable !== false" :key="toast.id" :message="toast.message" :timeout="toast.timeout" :type="toast.type" @dismiss="$store.commit('deleteToast', toast.id)" />
    </transition-group>
    <USTooltip :content="tooltipContentCache" :force-side="tooltip && tooltip.forceSide" :target="tooltip && tooltip.target" :visible="tooltip !== null" />
    <USModal :actions="null" :dark="dark" title="Here’s What’s New" :visible="showChangelog" @close="showChangelog = false">
      <article v-html="changelog" />
    </USModal>
    <BackGesture v-if="onIOS" />
    <div id="layoutViewport" />
  </div>
</template>
<script>
import { debounce } from 'lodash-es';

import isIOS from '@/scripts/isIOS';

import FadeTransition from '@/transitions/FadeTransition.vue';

import BackGesture from '@/components/BackGesture.vue';
import NotificationBar from '@/components/NotificationBar.vue';

import changelog from '@/../CHANGELOG.md';

export default {
  components: {
    BackGesture,
    FadeTransition,
    NotificationBar,
  },
  computed: {
    collapsibleSidebar() {
      return !this.mobile && this.$route.meta.collapsibleSidebar;
    },
    dark() {
      return this.$store.state.application.darkTheme;
    },
    forceHiddenMenu() {
      return this.$store.state.application.forceHiddenMenu;
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    noMenuRoute() {
      if (!this.mobile) return this.$route.meta.noMenu && this.$route.meta.noMenu.desktop;
      return this.$route.meta.noMenu && this.$route.meta.noMenu.mobile;
    },
    notificationAmount() {
      return this.$store.state.application.notificationAmount;
    },
    onIOS() {
      return isIOS();
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
    showBack() {
      if (this.$route.meta.showBack !== false) return true;
      return false;
    },
    showNotificationBar: {
      get() {
        return this.$store.state.application.showNotificationBar;
      },
      set(v) {
        this.$store.dispatch('setShowNotificationBar', v);
      },
    },
    showNotifications() {
      if (this.$route.meta.showNotifications !== false && this.$store.getters.userId) return true;
      return false;
    },
    sidebar() {
      return this.$store.state.application.showSidebar && !this.mobile;
    },
    toasts() {
      if (this.mobile) return [...this.$store.state.application.toasts].reverse();
      return this.$store.state.application.toasts;
    },
    tooltip() {
      return this.$store.state.application.tooltip;
    },
    userId() {
      return this.$store.state.user._id;
    },
  },
  created() {
    if (window.history.scrollRestoration) window.history.scrollRestoration = 'manual'; // prevent scroll jump
    window.addEventListener('scroll', this.handleScroll, { capture: true, passive: true });
    window.addEventListener('resize', this.checkMobile, { passive: true });

    this.$feathers.service('users').on('patched', this.updateUser);
    this.$feathers.service('users').on('removed', async (user) => {
      if (user._id === this.$store.getters.userId) {
        await this.$feathers.authentication.removeAccessToken();
        this.$router.push({ name: 'home' });
        window.setTimeout(() => {
          this.$store.commit('resetUser');
          this.$store.commit('addToast', { message: 'Your account was terminated. If you were waiting for your request for membership to be approved this means that, unfortunately, it was declined. Please check your email for details.', timeout: -1, type: 'negative' });
        }, 200);
      }
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', async (e) => {
        if (e.data && e.data.type === 'NOTIFICATION_CLICK') {
          if (e.data.path) this.$router.push(e.data.path);
          if (e.data.id) {
            if (this.$store.getters.userId) this.removeNotification(e.data.id);
            else { // give everything some time to settle in since we just opened a new instance
              this.$store.commit('addToast', { message: 'Timeout for notification removal set', timeout: 5000, type: 'warning' });
              window.setTimeout(async () => {
                this.removeNotification(e.data.id);
              }, 5000); // 5000ms reauth timeout
            }
          }
        }
      });
    }

    if (this.$route.name !== 'home') {
      if (this.dark) document.querySelector('meta[name=theme-color]').setAttribute('content', '#343C41');
      else document.querySelector('meta[name=theme-color]').setAttribute('content', '#fefefe');
    }
  },
  data() {
    return {
      animationFrame: null,
      changelog,
      lastScrollTop: 0,
      mobileThreshold: 920, // 640 content, 2 * 40 spacing towards action bar, 2*56 action bar, 32 padding, 56 menu
      notificationRemoved: false,
      showChangelog: false,
      showMenu: true,
      snackbarTransform: null,
      tooltipContentCache: '',
    };
  },
  methods: {
    checkMobile: debounce(function () { // eslint-disable-line func-names, prefer-arrow-callback
      if (window.innerWidth / (this.remBase / 16) > this.mobileThreshold) this.$store.commit('setMobile', false);
      else this.$store.commit('setMobile', true);
    }, 100),
    handleBack() {
      if (this.$store.state.application.previousRoute && (this.$store.state.application.previousRoute !== '/' || !this.userId)) this.$router.back();
      else this.$router.push({ name: 'feed' });
    },
    handleScroll() {
      const currentScrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
      if (this.animationFrame) window.cancelAnimationFrame(this.animationFrame);
      if (this.tooltip) this.$store.commit('setTooltip', null);
      this.animationFrame = window.requestAnimationFrame(() => {
        if (currentScrollTop < (document.body.scrollHeight - window.innerHeight) && currentScrollTop <= this.lastScrollTop && !this.showMenu) this.showMenu = true;
        else if (currentScrollTop > this.lastScrollTop && this.showMenu) this.showMenu = false;
        this.lastScrollTop = currentScrollTop;
      });
    },
    handleTouchStart(e) {
      if (this.onIOS && e.changedTouches[0].clientX > window.innerWidth - 20) return;

      this.touchStart = {
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY,
      };
    },
    handleTouchEnd(e) {
      if (this.$store.state.application.modalVisible) return;

      const actionBars = this.$el.querySelectorAll('.action-bar');
      let insideActionbar = false;
      actionBars.forEach((bar) => {
        if (bar.contains(e.target)) insideActionbar = true;
      });
      if (insideActionbar) return;

      const dx = e.changedTouches[0].clientX - this.touchStart.x;
      const dy = e.changedTouches[0].clientY - this.touchStart.y;
      if (Math.abs(dx) > Math.abs(dy) && Math.abs(dx) > 40) {
        if (dx < 0 && this.touchStart.x >= window.innerWidth / 2) {
          this.$store.dispatch('setShowNotificationBar', true);
        } else if (!this.$el.querySelector('.notifications') || !this.$el.querySelector('.notifications').contains(e.target)) {
          this.$store.dispatch('setShowNotificationBar', false);
        }
      }
    },
    async removeNotification(id) {
      try {
        await this.$feathers.service('notifications').patch(id, { $pull: { targets: this.$store.getters.userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong while removing the notification: ${err.message}`, timeout: 5000, type: 'negative' });
      }
    },
    showForumNotificationDot(thread) {
      if (!thread.readers.includes(this.$store.getters.userId)) this.$store.commit('setShowForumNotificationDot', true);
    },
    triggerScroll() {
      this.$root.$emit('triggerScroll');
    },
    updateSnackbarTransform(e) {
      if (this.pendingUpdate) return;
      this.pendingUpdate = true;

      window.requestAnimationFrame(() => {
        this.pendingUpdate = false;

        const visualViewport = e.target;
        const layoutViewportHeight = document.getElementById('layoutViewport').getBoundingClientRect().height;
        if (this.mobile) {
          if (layoutViewportHeight !== visualViewport.height) this.snackbarTransform = `translateY(${visualViewport.height - layoutViewportHeight + visualViewport.offsetTop}px)`;
          else this.snackbarTransform = null;
        } else this.snackbarTransform = `translateX(-50%) translateY(${visualViewport.offsetTop})`;
      });
    },
    async updateUser(user) {
      if (user._id !== this.$store.getters.userId) return;
      const oldGroups = [...this.$store.state.user.groups];
      this.$store.commit('setUser', user);
      if (user.groups && oldGroups.includes('applicants') && !user.groups.includes('applicants')) {
        this.$store.commit('addToast', { message: 'Good news! Your application has been approved, you’re now a member.', timeout: -1, type: 'positive' });
        if (this.$route.name === 'home') this.$router.push({ name: 'feed' });
        if (this.forceHiddenMenu) this.$store.commit('setForceHiddenMenu', false);
      }
      if (user.groups) {
        let groupsChanged = user.groups.length !== oldGroups.length;

        if (groupsChanged === false) { // no need to loop if we know they’re different
          for (let i = 0; i < user.groups.length; i += 1) {
            if (!oldGroups.includes(user.groups[i])) {
              groupsChanged = true;
              break;
            }
          }
        }

        if (groupsChanged) {
          try {
            await this.$feathers.reAuthenticate(true);
          } catch (err) {
            this.$store.commit('addToast', {
              action: window.location.reload,
              actionLabel: 'Refresh',
              message: `Could not reauthenticate after group-change: ${err.message}. You might have to reload the page to avoid issues.`,
              type: 'negative',
            });
          }
        }
      }
    },
  },
  async mounted() {
    let remBase;
    if (window.localStorage) remBase = Number.parseInt(window.localStorage.getItem('remBase'), 10);
    if (!remBase) remBase = Number.parseInt(window.getComputedStyle(document.documentElement).fontSize, 10);
    this.$store.commit('setRemBase', remBase);
    if (window.innerWidth / (remBase / 16) > this.mobileThreshold) this.$store.commit('setMobile', false);

    try {
      const { content: bannerMessage } = await this.$feathers.service('info').get('announcement-banner');
      if (bannerMessage) this.$store.commit('addToast', { message: bannerMessage, timeout: -1 });
    } catch (err) {
      // swallow it, it’s not important if it doesn’t work
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) document.body.classList.add('dark');

    window.visualViewport.addEventListener('resize', this.updateSnackbarTransform);
    window.visualViewport.addEventListener('scroll', this.updateSnackbarTransform);

    // check if we should show the changelog
    this.$nextTick(() => {
      if (window.localStorage) {
        const forceChangelog = window.localStorage.getItem('forceChangelog');
        if (forceChangelog === 'true') { // forceChangelog is a string
          this.showChangelog = true;
          window.localStorage.setItem('forceChangelog', false);
        }
      }
    });
  },
  watch: {
    $route(newVal) {
      this.lastScrollTop = 0;
      this.showMenu = true;

      // when there’s a cold start, the NOTIFICATION_CLICK might not reach the app
      // so we’re removing the notification by the id provided in the query
      if (newVal.query.notificationId && this.$store.getters.userId && !this.notificationRemoved) {
        this.removeNotification(newVal.query.notificationId);
        this.notificationRemoved = true; // so it doesn’t keep trying to remove a removed / non existing notification
      }
    },
    dark(newVal) {
      if (newVal) {
        document.body.classList.add('dark');
        document.querySelector('meta[name=theme-color]').setAttribute('content', '#343C41');
      } else {
        document.body.classList.remove('dark');
        document.querySelector('meta[name=theme-color]').setAttribute('content', '#fefefe');
      }
    },
    remBase() {
      this.checkMobile();
    },
    showMenu(newVal) {
      if (!this.mobile && this.$route.name !== 'read') return;
      this.$store.commit('setShowActionBars', newVal);
    },
    tooltip(newVal) {
      if (newVal) this.tooltipContentCache = newVal.content;
    },
    async userId(newVal) {
      if (newVal) {
        try {
          const { total } = await this.$feathers.service('threads').find({ query: { $limit: 0, readers: { $ne: newVal } } });
          if (total > 0) this.$store.commit('setShowForumNotificationDot', true);
        } catch (err) {
          // swallow, it’s not important
        } finally {
          this.$feathers.service('threads').off('created', this.showForumNotificationDot);
          this.$feathers.service('threads').off('patched', this.showForumNotificationDot);
          this.$feathers.service('threads').on('created', this.showForumNotificationDot);
          this.$feathers.service('threads').on('patched', this.showForumNotificationDot);
        }
      }
    },
  },
};
</script>
<style lang="stylus">
@require 'styles/base'

#layoutViewport
  position: fixed
  width: 100%
  height: 100%
  visibility: hidden

#app
  background-color: $bg

  &.dark
    background-color: $bg-dark
    color: $text-primary-dark

  .header
    display: flex
    position: fixed
    left: 2.5rem
    right: 0
    z-index: 3
    padding: 1rem 2rem
    padding-left: 3rem
    border-bottom-right-radius: 0.75rem
    transition: padding 200ms ease, background-color 200ms ease, box-shadow 200ms ease

    &.no-menu
      border-bottom-left-radius: 0.75rem
      left: 0
      padding-left: 2rem

    &.sidebar
      left: 25rem

    &.scrolled
      background-color: $bg
      box-shadow: $shadow-high
      padding: 0.375rem 1rem
      padding-left: 2rem

    &.dark
      background-color: $bg-dark

    .icon-button:nth-child(2)
      margin-left: 0.5rem

    .counter-icon-button
      margin-left: auto

  main
    position: relative
    transition: margin-left 200ms ease
    min-height: 100%

    &:not(.mobile):not(.no-menu):not(.sidebar)
      margin-left: 3.5rem

    &.sidebar
      margin-left: 26rem

      // > .loader
      //   left: 26rem

      &.no-menu
        margin-left: 22.5rem

        // > .loader
        //   left: 22.5rem

    > .menu
      position: fixed
      left: 0
      z-index: 4

      &.slide-enter-active,
      &.slide-leave-active
        transition: transform 200ms ease

        &.slide-enter,
        &.slide-leave-to
          transform: translateX(-100%)

    > .mobile-menu
      position: fixed
      bottom: 0
      left: 0
      right: 0
      z-index: 3

      &.slide-enter-active,
      &.slide-leave-active
        transition: transform 200ms ease

        &.slide-enter,
        &.slide-leave-to
          transform: translateY(100%)

    > .loader
      z-index: 9
      position: fixed
      width: 12rem
      height: 10rem
      max-width: 95%
      max-height: 95%
      left: calc(50% - 6rem)
      top: calc(50% - 5rem)
      padding: 1rem
      border-radius: 0.75rem
      box-shadow: $shadow-high
      background-color: $bg
      border: 1px solid $interactable-disabled

      &.dark
        background-color: $elevation-primary-dark
        border-color: $interactable-disabled-dark

      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity 200ms ease, transform 200ms ease !important

        &.fade-enter,
        &.fade-leave-to
          transform: scale(1.5)
          opacity: 0

        // &.fade-enter

  .snackbar
    position: fixed
    top: 1rem
    left: 50% // center it so we can have proper transitions for the toasts
    transform: translateX(-50%)
    width: calc(100% - 2rem)
    max-width: 40rem
    z-index: 10
    pointer-events: none
    transition: transform 200ms ease

    &.mobile
      top: auto
      bottom: 1rem
      left: @bottom
      right: @bottom
      width: auto
      max-width: none
      transform: none

      &.menu
        transform: translateY(-3.5rem)

      .toast
        margin-top: 1rem
        margin-bottom: 0
        max-width: 40rem

        &.snackbar-enter
          transform: translateY(100%)

        &.snackbar-leave-active
          bottom: 0

          &.snackbar-leave-to
            transform: translateX(100%)

    .toast
      margin: 0 auto
      margin-bottom: 1rem
      pointer-events: auto

    .snackbar-enter-active,
    .snackbar-move
      transition: transform 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 200ms ease

      &.snackbar-enter
        transform: translateY(-100%)
        opacity: 0

    .snackbar-leave-active
      position: absolute
      z-index: 2
      transition: transform 350ms ease, opacity 200ms ease

      &.snackbar-leave-to
        transform: translateX(8rem)
        opacity: 0
</style>

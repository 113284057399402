<template lang="html">
  <svg v-if="type === 'spin'" class="spinner spin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 23L21.5858 13.4142C22.3668 12.6332 22.3668 11.3668 21.5858 10.5858L13.4142 2.41421C12.6332 1.63316 11.3668 1.63317 10.5858 2.41421L2.41422 10.5858C1.63317 11.3668 1.63317 12.6332 2.41422 13.4142L12 23Z" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear" x1="1" y1="1" x2="23" y2="23" gradientUnits="userSpaceOnUse">
        <stop offset="0.18232" stop-color="#20A2F7" />
        <stop offset="1" stop-color="#B100CE" />
      </linearGradient>
    </defs>
  </svg>
  <svg v-else class="spinner squiggle" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12C9.90288 9.14567 14.0971 9.14567 16 12C17.9029 14.8543 22.0971 14.8543 24 12C25.9029 9.14567 30.0971 9.14567 32 12C33.9029 14.8543 38.0971 14.8543 40 12C41.9029 9.14567 46.0971 9.14567 48 12C49.9029 14.8543 54.0971 14.8543 56 12" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear" x1="8" y1="9.85925" x2="8.75776" y2="18.3546" gradientUnits="userSpaceOnUse">
        <stop offset="0.18232" stop-color="#20A2F7" />
        <stop offset="1" stop-color="#B100CE" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      validator(v) {
        return ['spin', 'squiggle'].includes(v);
      },
      default: 'spin',
    },
  },
};
</script>

<style lang="stylus" scoped>
.spinner
  &.squiggle
    width: 4rem
    height: 1.5rem
    stroke-dasharray: 56.726993560791016
    stroke-dashoffset: 170.180980682
    animation: squiggle 1.5s ease infinite

    @keyframes squiggle
      0%
        stroke-dashoffset: 170.180980682
      50%
        stroke-dashoffset: 113.453987122
      100%
        stroke-dashoffset: 56.726993560791016

  &.spin
    width: 1.5rem
    height: @width
    stroke-dasharray: 59.651485443115234
    stroke-dashoffset: 59.651485443115234
    animation spin 1.5s ease infinite

    @keyframes spin
      0%
        stroke-dashoffset: 59.651485443115234
      50%
        stroke-dashoffset: 119.302970886
      100%
        stroke-dashoffset: 178.954456329
</style>

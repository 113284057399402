<template lang="html">
  <USView class="emoji-game" :dark="dark" show-back>
    <FadeTransition>
      <USActionBar
        v-show="$store.state.application.showActionBars"
        :actions="[{
          action: generateEmojiSequence, disabled: generating, icon: 're-roll', tooltip: 'Re-roll emoji',
        }]"
        :dark="dark"
        :vertical="!mobile" />
    </FadeTransition>
    <h1>What the ’moji?</h1>
    <p>Below you’ll see four Emoji. What story do they tell? Is it a cryptic message? What does it mean?</p>
    <transition-group class="emojis" tag="div" @after-leave="generateEmojiSequence">
      <span v-for="(emoji, index) in emojis" class="emoji" :data-index="index" :key="`${index}`">{{emoji}}</span>
      <span class="spacer" key="spacer" />
    </transition-group>
    <USEditor v-model="draft" :dark="dark" disable-tab :formats="['bold', 'blockquote', 'header', 'italic', 'linebreak', 'list', 'separator', 'strike', 'underline']" mode="delta" placeholder="Your emoji story…" scrolling-container="html" />
    <div class="button-bar" :class="{ mobile }">
      <USButton :dark="dark" :disabled="!unsavedChanges" :loading="savingDraft" primary icon-left="draft-add" @click="saveAsDraft">Save to Private Drafts</USButton>
    </div>
  </USView>
</template>

<script>
import emoji from '@/assets/availableEmoji.json';

import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventUnintentionalClose);
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.$store.commit('addToast', {
        action: next,
        actionLabel: 'Discard Changes',
        message: 'You have unsaved changes, do you want to discard them?',
        type: 'warning',
      });
    } else next();
  },
  components: {
    FadeTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
    unsavedChanges() {
      return Object.keys(this.draft).length > 0;
    },
  },
  created() {
    window.addEventListener('beforeunload', this.preventUnintentionalClose);
  },
  data() {
    return {
      draft: {},
      emojis: [],
      generating: false,
      savingDraft: false,
    };
  },
  methods: {
    generateEmojiSequence(el) {
      this.generating = true;

      if (this.emojis.length > 0) {
        this.emojis = [];
        return;
      }

      // so we only generate new emoji after the last emoji left
      if (typeof el !== 'undefined' && el.dataset.index !== '3') return;

      for (let i = 0; i < 4; i += 1) {
        window.setTimeout(() => {
          this.emojis.push(emoji.availableEmoji[Math.floor(Math.random() * emoji.availableEmoji.length)]);
          if (i === 3) this.generating = false;
        }, 250 * i);
      }
    },
    preventUnintentionalClose(e) {
      if (this.unsavedChanges) {
        this.$store.commit('addToast', {
          message: 'You have unsaved changes, save them before exiting if you don’t want to lose them.',
          type: 'warning',
          timeout: 10000,
        });
        e.preventDefault();
        e.returnValue = ''; // for chrome
      }
    },
    async saveAsDraft() {
      if (!this.draft.ops || this.draft.ops.length === 0) {
        this.$store.commit('addToast', { message: 'Could not save draft: post has no content', type: 'negative' });
        return;
      }

      this.savingDraft = true;

      const post = {
        blurb: this.emojis.join(' '),
        content: this.draft.ops,
        draft: true,
        public: false,
        indentedParagraphs: false,
        readers: [this.$store.getters.userId],
        reads: 1,
        tags: ['what the moji', 'emoji game', 'writing exercise'],
        title: 'Untitled Emoji Story',
        toBeDiscussed: false,
      };

      try {
        const newPost = await this.$feathers.service('posts').create(post);
        this.$store.commit('addToast', {
          action: () => this.$router.replace({ name: 'read', params: { id: newPost._id }, query: { draft: true, listType: 'drafts' } }),
          actionLabel: 'Open',
          dismissable: true,
          message: 'Your piece has been added to your drafts. You can edit and publish it from there.',
          timeout: 10000,
          type: 'positive',
        });

        this.draft = {};
        this.generateEmojiSequence();
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not save post: ${err.message}`, type: 'negative' });
      }

      this.savingDraft = false;
    },
  },
  mounted() {
    this.generateEmojiSequence();
  },
  props: {
    dark: Boolean,
  },
  watch: {
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'inspiration', query: { page: 'emoji-game' } });
    },
    unsavedChanges(nv) {
      this.$emit('update-unsaved-changes', nv);
    },
  },
};
</script>

<style lang="stylus" scoped>
.emoji-game
  .emojis
    margin: 4rem 0
    text-align: center
    font-size: 2rem

    .spacer
      display: inline-block
      vertical-align: top
      height: (44 / 16)rem

    .emoji
      margin-right: 1rem
      display: inline-block

      &.v-enter-active
        transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)

      &.v-leave-active,
      &.v-move
        transition: transform 200ms ease

      &.v-enter,
      &.v-leave-to
        transform: scale(0)

  .button-bar
    text-align: right

    &.mobile .button
      width: 100%
</style>

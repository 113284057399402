<template lang="html">
  <button class="selectable-card" :class="{ dark, selected }" type="button" @click="$emit('click', $event)">
    <div class="ink" />
    <USIcon :icon="selected ? 'check' : icon" />
    <div class="content">
      <h2>{{title}}</h2>
      <span v-if="content">{{content}}</span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    content: String,
    dark: Boolean,
    icon: String,
    selected: Boolean,
    title: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.selectable-card
  width: 100%
  display: flex
  align-items: center
  padding: calc(1rem - 1px)
  background-color: $bg
  border: 1px solid $interactable
  border-radius: 0.75rem
  box-shadow: $shadow-low
  cursor: pointer
  position: relative
  overflow: hidden
  z-index: 0
  transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, color 200ms ease

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark
    color: $text-primary-dark

  &.selected
    color: $text-primary-dark
    border-color: $accent-primary

    .ink
      background-color: $accent-primary
      transform: scale(1.4142135624)

  &:hover,
  &:focus
    border-color: $accent-primary
    box-shadow: $shadow-high

    &.dark
      background-color: $elevation-primary-dark

  &:focus .ink
      transform: scale(1.4142135624)

  &:active
    transform: translateY(2px)

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    z-index: -1
    transition: transform 200ms ease, background-color 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  .icon
    margin-right: 1rem

  .content
    width: calc(100% - 2.5rem)
    text-align: left

    h2
      margin-bottom: 0
      color: inherit
      width: 100%
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    span
      margin-top: 0.25rem
      display: inline-block
      font-size: 0.875rem
      line-height: @font-size
      width: 100%
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
</style>

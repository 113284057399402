<template lang="html">
  <USView class="read" :dark="dark" show-back>
    <USReadingList :class="{ 'no-menu': $store.state.application.forceHiddenMenu }" :dark="dark" :description="listDescription" :initial-sort="this.listSort" :loading="loading.posts" :more-available="nextPageAvailable.posts" :name="listName" :posts="listPosts" sortable @back="handleReadingListBack" @bottom-reached="handleListFetch" @sortchange="changeSort" />
    <FadeTransition @before-enter="$root.$emit('triggerScroll')">
      <template v-if="post._id">
        <div class="post-wrapper" :key="post._id">
          <div class="editor-wrapper">
            <FadeTransition>
              <USActionBar v-show="(actions.length > 0 && $store.state.application.showActionBars) || (actions.length > 0 && !mobile && forceActionBar)" :actions="actions" :dark="dark" :vertical="!mobile" />
            </FadeTransition>
            <header class="date" :class="{ dark, mobile }">
              <span>{{ post.createdAt | customDate }}</span>
              <span>{{wordCount | readingTime }} read{{ hasHistory ? ', ' : ''}}<u v-if="hasHistory" @click="showEdits = !showEdits">{{showEdits ? 'changes' : 'edited'}}</u></span>
            </header>
            <section v-if="hasAnyAward || isStaffPick" class="awards" :class="{ dark }">
              <div v-for="(value, key) in awards" class="award" :key="key">
                <template v-if="value === post._id">
                  <USIcon icon="award" />
                  <span v-if="key === 'prompt'">Best Use of Prompt</span>
                  <span v-if="key === 'loved'">Most Loved</span>
                  <span v-if="key === 'faved'">Most Favourited</span>
                  <span v-if="key === 'read'">Most Read</span>
                  <span v-if="key === 'commented'">Most Commented</span>
                </template>
              </div>
              <div v-if="isStaffPick" class="award">
                <USIcon icon="award" />
                <span>{{staffPicksName | capitalize}}</span>
              </div>
            </section>
            <h1 class="title">{{post.title || 'Untitled'}}</h1>
            <span class="author">by <router-link v-if="!isPublic" :to="{ name: 'profile', params: { id: (post.author && post.author._id) || 'undefined' } }">{{ post.author && post.author.name | capitalize }}</router-link><span v-else>{{ post.author && post.author.name | capitalize }}</span></span>
            <blockquote v-if="!isGeneratedBlurb" class="blurb">{{ post.blurb }}</blockquote>
            <USEditor v-model="post.content" :bookmarkable="!isPublic && !post.draft" :dark="dark" disabled :indented-paragraphs="post.indentedParagraphs" mode="delta" :original-content="post.originalContent" placeholder="No Story Here" :quotable="!isPublic && !isApplicant" :selection="editorSelection" :show-changes="post.originalContent && post.originalContent.length > 0 && showEdits" scrolling-container="html" @bookmark="handleBookmark" @click.native="handleLinkClick" @quote="handleQuote" @mounted="handleContentChanged" />
          </div>
          <IntersectionGuard @enter="$store.commit('setShowActionBars', true)" root-margin="0px" :threshold="1">
            <section class="stats">
              <ListTransition>
                <USCounterIconButton :amount="post.reads || 0" :dark="dark" disabled icon="show" key="reads" />
                <USCounterIconButton v-if="post.likes > 0" :amount="post.likes" :dark="dark" :disabled="isPublic || isApplicant" :icon="isPublic ? 'heart' : 'view-hearts'" key="hearts" tooltip="Show Likes" @click="showLikers" />
                <USCounterIconButton v-if="post.favourites > 0" :amount="post.favourites" :dark="dark" :disabled="isPublic || isApplicant" :icon="isPublic ? 'star' : 'view-stars'" key="favs" tooltip="Show Favourites" @click="showFavourers" />
              </ListTransition>
            </section>
          </IntersectionGuard>
          <section v-if="post.tags && post.tags.length > 0" class="tags" :class="{ dark, public: isPublic }">
            <USChip v-for="tag in post.tags" :content="tag" :dark="dark" :key="tag" @click="$router.push({ name: 'tagged', params: { tag } })" />
          </section>
          <section v-if="isPublic" class="cta">
            <h2>Do you like content like this?</h2>
            <p>Gain access to many more pieces, tell the author you liked them with a heart or a comment, or even add them to your favourites, and unlock the ability to contribute pieces of your own by signing up today!</p>
            <USButton :dark="dark" icon-left="add-user" primary @click="$router.push({ name: 'home', query: { page: 'terms' } })">Request Membership</USButton>
          </section>
          <section id="comments">
            <USInput v-if="!isPublic && !isApplicant" v-model="comment" :class="{ mobile }" :dark="dark" :formats="['blockquote', 'bold', 'image', 'indent', 'italic', 'linebreak', 'link', 'list', 'strike']" icon="comment" label="Say something nice…" :loading="submittingComment" :max-len="4096" multiline ref="commentInput" sendable @send="createComment" />
            <FadeTransition>
              <label v-if="!isPublic && !isApplicant && post.toBeDiscussed && comment.length > 0">
                Hidden until discussed
                <USSwitch v-model="commentHidden" :dark="dark" />
              </label>
            </FadeTransition>
            <template v-if="!isPublic">
              <ListTransition>
                <section v-for="comment in commentsWithoutSoftDeleted" class="comment-thread" :key="`thread_${comment._id}`" @click="handleSelectClick">
                  <USComment :author="comment.author" :class="{ flash: $route.query.commentId === comment._id }" :content="comment.content" :created="comment.createdAt" :dark="dark" :id="comment._id" :interactable="!isApplicant" :key="comment._id" :likes="comment.likers.length" :liked-by-current-user="comment.likers.includes($store.getters.userId)" :original-content="comment.originalContent" :quotable="!isApplicant" :unpublished="comment.hidden" @delete="deleteComment" @edit="handleCommentEdit" @like="toggleCommentLike($event, comment)" @quote="handleQuote" @reply="handleCommentReply" />
                  <USComment v-for="reply in comment.replies" v-show="!$store.getters.isSoftDeleted(reply._id)" :author="reply.author" :class="{ flash: $route.query.commentId === reply._id }" compact :content="reply.content" :created="reply.createdAt" :dark="dark" :id="reply._id" :interactable="!isApplicant" :key="reply._id" :likes="reply.likers.length" :liked-by-current-user="reply.likers.includes($store.getters.userId)" :original-content="reply.originalContent" :quotable="!isApplicant" @delete="deleteReply(comment._id, $event)" @edit="handleCommentEdit(comment._id, reply._id)" @like="toggleCommentLike(comment._id, comment, reply._id)" @quote="handleQuote($event, comment._id)" @reply="handleCommentReply(comment._id, $event)" />
                </section>
                <USButton v-show="nextPageAvailable.comments" :dark="dark" key="loadMoreButton" :loading="loading.comments" @click="fetchCommentsPage">Load More</USButton>
              </ListTransition>
            </template>
          </section>
        </div>
      </template>
    </FadeTransition>
    <USModal :actions="null" :dark="dark" title="People who added this post to their favourites" :visible="modals.favourers" @close="modals.favourers = false">
      <ListTransition>
        <USUserCard v-for="user in favourers" :avatar="user.avatar" :dark="dark" :info="'Last active ' + $options.filters.relativeTime(user.lastActive || user.createdAt)" :key="user._id" :name="user.name" @click="goToUserProfile(user._id)" />
        <USButton v-if="nextPageAvailable.favourers" :dark="dark" key="loadMoreButton" :loading="loading.favourers" @click="fetchFavourersPage">Load More</USButton>
      </ListTransition>
    </USModal>
    <USModal :actions="null" :dark="dark" title="People liking this post" :visible="modals.likers" @close="modals.likers = false">
      <ListTransition>
        <USUserCard v-for="user in likers" :avatar="user.avatar" :dark="dark" :info="'Last active ' + $options.filters.relativeTime(user.lastActive || user.createdAt)" :key="user._id" :name="user.name" @click="goToUserProfile(user._id)" />
        <USButton v-if="nextPageAvailable.likers" :dark="dark" key="loadMoreButton" :loading="loading.likers" @click="fetchLikersPage">Load More</USButton>
      </ListTransition>
    </USModal>
    <USModal :actions="null" :dark="dark" title="Manage Collections" :visible="modals.collections" @close="modals.collections = false">
      <ListTransition>
        <USSelectableCard v-for="collection in collections" :content="$options.filters.customDate(collection.createdAt)" :dark="dark" icon="collection" :key="collection._id" :selected="post.collections.includes(collection._id)" :title="collection.name" @click="toggleCollection(collection._id)" />
        <USButton v-if="nextPageAvailable.collections" :dark="dark" key="loadMoreButton" :loading="loading.collections" @click="fetchCollectionsPage">Load More</USButton>
      </ListTransition>
    </USModal>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: saveComment, disabled: comment.length === 0, icon: 'send', label: 'save',
      }]"
      :dark="dark"
      title="Edit Comment"
      :visible="modals.editComment"
      @close="hideEditComment">
      <USInput v-model="comment" :dark="dark" :error="commentError" :formats="['blockquote', 'bold', 'image', 'indent', 'italic', 'linebreak', 'link', 'list', 'strike']" icon="comment" label="Say something nice…" :max-len="4096" multiline @blur="comment.length === 0 ? commentError = 'Cannot be empty' : commentError = ''" />
      <label>
        Version History
        <USSwitch v-model="commentHistory" :dark="dark" />
      </label>
      <label v-if="post.toBeDiscussed && !commentReplyId">
        Hidden until discussed
        <USSwitch v-model="commentHidden" :dark="dark" />
      </label>
    </USModal>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: createReply, disabled: comment.length === 0, icon: 'send', label: 'save',
      }]"
      :dark="dark"
      title="Add Reply"
      :visible="modals.addReply"
      @close="hideAddReply">
      <USComment v-if="commentBeingReplied" :author="commentBeingReplied.author" :content="commentBeingReplied.content" :created="commentBeingReplied.createdAt" :dark="dark" :id="commentBeingReplied._id" :interactable="false" :likes="commentBeingReplied.likers.length" :original-content="commentBeingReplied.originalContent" @quote="handleReplyQuote" />
      <USInput v-model="comment" :class="{ mobile }" :dark="dark" :error="commentError" :formats="['blockquote', 'bold', 'image', 'indent', 'italic', 'linebreak', 'link', 'list', 'strike']" icon="comment" label="Say something nice…" :max-len="4096" multiline @blur="comment.length === 0 ? commentError = 'Cannot be empty' : commentError = ''" />
    </USModal>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';
import Store from '@/store';
import IntersectionGuard from '@/components/IntersectionGuard.vue';
import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

import capitalize from '@/filters/capitalize';
import customDate from '@/filters/customDate';
import readingTime from '@/filters/readingTime';
import relativeTime from '@/filters/relativeTime';

import handleLinkClick from '@/mixins/handleLinkClick';
import generateBlurb from '@/scripts/generateBlurb';

export default {
  beforeDestroy() {
    if (!this.mobile) {
      this.$store.commit('setShowSidebar', false);
      this.$store.commit('setShowActionBars', true); // so they don’t stay hidden inadvertedly
    }
    this.$feathers.service('posts').off('patched', this.updatePost);
    this.$feathers.service('comments').off('created', this.addComment);
    this.$feathers.service('comments').off('removed', this.removeComment);
    this.$feathers.service('comments').off('patched', this.updateComment);
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { content: awardsString } = await Feathers.service('info').get('awards');
      const { name: staffPicksName } = await Feathers.service('collections').get('staff-picks');
      // to.query.draft is the string "true" when entering the url into the browser bar for example, so we need to account for that here
      const post = await Feathers.service('posts').get(to.params.id, { query: { draft: to.query.draft === 'true' || (typeof to.query.draft === 'boolean' && to.query.draft) } });
      const [prompt, loved, faved, read, commented] = awardsString.split(',');
      const postAwards = {
        prompt, loved, faved, read, commented,
      };

      let wordCount = 0;
      const textContent = post.content.map((op) => op.insert).join();
      if (textContent === '\n') wordCount = 0;
      else wordCount = textContent.split(/\s+|-+|–+|—+/g).length - 1; // there’s always an empty string at the end because ops ends with a \n

      let comments = [];
      let total = 0;

      if (Store.getters.userId) ({ data: comments, total } = await Feathers.service('comments').find({ query: { parentPost: post._id, $limit: 15, $sort: { createdAt: -1 } } }));

      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.awards = postAwards;
        vm.comments = comments;
        vm.currentPage.comments += 1;
        vm.nextPageAvailable.comments = vm.currentPage.comments < Math.ceil(total / vm.pageSize);
        vm.post = post;
        vm.post.content = { ops: post.content };
        vm.previousPage = from.fullPath;
        vm.staffPicksName = staffPicksName;
        vm.wordCount = wordCount;

        vm.setUpReadTimer();
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      else if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.mobile && this.$store.state.application.showSidebar) {
      this.$store.commit('setShowSidebar', false);
      this.$store.commit('setBodyLock', false);
      next(false);
      return;
    }

    if (this.readTimeout) window.clearTimeout(this.readTimeout);

    const editorWrapper = this.$el.querySelector('.editor-wrapper');
    const editorRect = editorWrapper && editorWrapper.getBoundingClientRect();
    const { userId } = this.$store.getters;

    if (!this.post.draft && userId && editorRect && !this.$store.state.user.bookmark && editorRect.bottom > window.innerHeight) {
      const scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
      const percentage = Math.round((scrollPosition / editorRect.height) * 100);

      if (percentage > 0) {
        try {
          await this.$feathers.service('users').patch(userId, { bookmark: { percentage, post: this.post._id } });
        } catch (err) {
          // don’t do anything
        }
      }
    }

    next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.mobile && this.$store.state.application.showSidebar) {
      this.$store.commit('setShowSidebar', false);
      this.$store.commit('setBodyLock', false);
    }

    if (to.params.id === from.params.id) {
      this.$nextTick(() => { document.title = `${this.post.title} – Untold Stories`; });
      next();
      return;
    }

    if (this.readTimeout) window.clearTimeout(this.readTimeout);

    if (to.query.selection || to.query.commentId) {
      const queryClone = { ...to.query };
      delete queryClone.selection;
      delete queryClone.commentId;
      next({ name: to.name, params: to.params, query: queryClone });
      return;
    }

    try {
      const post = await Feathers.service('posts').get(to.params.id, { query: { draft: to.query.draft } });
      post.content = { ops: post.content };
      this.post = post;

      if (this.$store.getters.userId) {
        this.currentPage.comments = 0;
        this.skipCounter.comments = 0;
        const { data: comments, total } = await Feathers.service('comments').find({ query: { parentPost: post._id, $limit: this.pageSize, $sort: { createdAt: -1 } } });
        this.comments = comments;
        this.currentPage.comments = 1;
        this.nextPageAvailable.comments = this.currentPage.comments < Math.ceil(total / this.pageSize);

        this.currentPage.favourers = 0;
        this.favourers = [];

        this.currentPage.likers = 0;
        this.likers = [];
      }

      this.currentIndex = this.listPosts.findIndex((existingPost) => existingPost._id === post._id);
      const textContent = this.post.content.ops.map((op) => op.insert).join();
      if (textContent === '\n') this.wordCount = 0;
      else this.wordCount = textContent.split(/\s+|-+|–+|—+/g).length - 1; // there’s always an empty string at the end because ops ends with a \n
      this.setUpReadTimer();
      this.$nextTick(() => {
        document.title = `${this.post.title} – Untold Stories`;

        // HACK: Using timeout so the transitions can do their thing otherwise
        // the value from the **old** post is used, which is wrong
        window.setTimeout(() => {
          // fix the action bar hiding and never reappearing on short posts
          const editorWrapper = this.$el.querySelector('.editor-wrapper');
          const editorRect = editorWrapper && editorWrapper.getBoundingClientRect();
          if (editorRect && editorRect.bottom < window.innerHeight) this.forceActionBar = true;
          else this.forceActionBar = false;
        }, 400);
      });
      next();
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      else if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  components: {
    FadeTransition,
    IntersectionGuard,
    ListTransition,
  },
  computed: {
    actions() {
      const actions = [];

      if ((this.isApplicant || this.isMember) && !this.isOwner) actions.push({ action: this.likePost, active: this.hasLiked, icon: 'heart', tooltip: 'Like Post' }); // eslint-disable-line object-curly-newline
      if (this.isMember && !this.isOwner) actions.push({ action: this.favPost, active: this.hasFaved, icon: 'star', tooltip: 'Mark as Favourite' }); // eslint-disable-line object-curly-newline
      if (this.canCheck && this.post.toBeDiscussed && !this.post.draft) actions.push({ action: this.markAsDiscussed, icon: 'check', tooltip: 'Mark as discussed' });
      if (this.isOwner && this.post.draft) actions.push({ action: this.publishPost, icon: 'send', tooltip: 'Publish Post' });
      if (this.isOwner) actions.push({ action: () => this.$router.push({ name: 'write', query: { edit: this.post._id, draft: this.post.draft } }), icon: 'pencil', tooltip: 'Edit Post' }); // eslint-disable-line vue/no-side-effects-in-computed-properties
      if (this.isOwner || this.isPrivileged) actions.push({ action: this.deletePost, icon: 'delete', tooltip: 'Delete Post' });
      if (this.isPrivileged && !this.post.draft) {
        actions.push({ action: this.showCollections, icon: 'collection-add', tooltip: 'Manage Collections' });
        if (['writing prompt', 'prompt'].some((tag) => this.post.tags && this.post.tags.includes(tag))) actions.push({ action: this.togglePromptAward, icon: this.hasPromptAward ? 'remove-award' : 'add-award', tooltip: this.hasPromptAward ? 'Remove Best use of Prompt' : 'Award Best use of Prompt' });
      }
      if (this.mobile && this.listPosts.length > 1) {
        if (this.isPublic && this.currentIndex > 0) actions.unshift({ action: this.previousPost, icon: 'chevron-left', tooltip: 'Previous Post' });
        actions.push({
          action: () => {
            if (this.$store.state.application.showSidebar) {
              this.$store.commit('setBodyLock', false);
              this.$store.commit('setShowSidebar', false);
            } else {
              this.$store.commit('setBodyLock', true);
              this.$store.commit('setShowActionBars', true);
              this.$store.commit('setShowSidebar', true);
            }
          },
          active: this.$store.state.application.showSidebar,
          icon: 'reading-list',
          tooltip: this.$store.state.application.showSidebar ? 'Show reading list' : 'Hide reading list',
        });
        if (this.isPublic && this.currentIndex < this.listPosts.length - 1) actions.push({ action: this.nextPost, icon: 'chevron-right', tooltip: 'Next Post' });
      }
      return actions;
    },
    canCheck() {
      return ['councilmembers', 'moderators', 'admins'].some((group) => this.$store.state.user.groups.includes(group));
    },
    commentsWithoutSoftDeleted() {
      return this.comments.filter((comment) => !this.$store.getters.isSoftDeleted(comment._id));
    },
    hasAnyAward() {
      return Object.values(this.awards).some((val) => val === this.post._id);
    },
    hasFaved() {
      return this.post.favourers && this.post.favourers.includes(this.$store.getters.userId);
    },
    hasHistory() {
      return !this.isPublic && this.post.originalContent && this.post.originalContent.length > 0;
    },
    hasLiked() {
      return this.post.likers && this.post.likers.includes(this.$store.getters.userId);
    },
    hasPromptAward() {
      return this.awards.prompt === this.post._id;
    },
    isApplicant() {
      return this.$store.state.user.groups.includes('applicants');
    },
    isMember() {
      return this.$store.state.user.groups.includes('members');
    },
    isOwner() {
      if (!this.post.author) return false;
      return this.$store.getters.userId === this.post.author._id;
    },
    isPrivileged() {
      return ['moderators', 'admins'].some((group) => this.$store.state.user.groups.includes(group));
    },
    isPublic() {
      return !this.$store.getters.userId;
    },
    isStaffPick() {
      return this.post.collections && this.post.collections.includes('staff-picks');
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  created() {
    if (!this.mobile) this.$store.commit('setShowSidebar', true);
    if (!this.$feathers.get('authentication')) this.$store.commit('setForceHiddenMenu', true);
    this.$feathers.service('posts').on('patched', this.updatePost);
    this.$feathers.service('comments').on('created', this.addComment);
    this.$feathers.service('comments').on('removed', this.removeComment);
    this.$feathers.service('comments').on('patched', this.updateComment);
  },
  data() {
    return {
      awards: {},
      collections: [],
      comment: '',
      commentBeingEdited: null,
      commentBeingReplied: null,
      commentError: '',
      commentHidden: false,
      commentHistory: true,
      commentParent: null,
      commentReplyBeingEdited: null,
      commentReplyId: null,
      comments: [],
      currentIndex: -1,
      currentPage: {
        collections: 0,
        comments: 0,
        favourers: 0,
        likers: 0,
        posts: 0,
      },
      editorSelection: [],
      favourers: [],
      forceActionBar: false,
      isGeneratedBlurb: true,
      likers: [],
      listDescription: '',
      listInitialized: false,
      listName: '',
      listPosts: [],
      listQuery: {},
      listSort: this.$route.query.listSort || 'newest',
      loading: {
        collections: false,
        comments: false,
        favourers: false,
        likers: false,
        posts: false,
      },
      modals: {
        addReply: false,
        collections: false,
        editComment: false,
        favourers: false,
        likers: false,
      },
      nextPageAvailable: {
        comments: true,
        posts: true,
        collections: true,
        favourers: true,
        likers: true,
      },
      pageSize: 15,
      post: {},
      previousPage: '',
      readTimeout: null,
      showEdits: false,
      skipCounter: {
        comments: 0,
        posts: 0,
      },
      staffPicksName: 'Staff Picks',
      submittingComment: false,
      wordCount: 0,
    };
  },
  filters: {
    capitalize,
    customDate,
    readingTime,
    relativeTime,
  },
  methods: {
    addComment(comment, context, index) {
      if (comment.parentPost !== this.post._id || (comment.hidden && comment.author._id !== this.$store.getters.userId)) return;
      if (index > -1) this.comments.splice(index, 0, comment);
      else this.comments.unshift(comment);
      this.skipCounter.comments += 1;
    },
    cacheComment(comment) {
      try {
        const id = `comment-${this.$route.params.id}`;
        if (comment) window.localStorage.setItem(id, comment);
        else window.localStorage.removeItem(id);
      } catch (err) {
        // ignore it, it’s not crucial if it doesn’t work
      }
    },
    changeSort(sort) {
      this.listPosts = [];
      this.currentPage.posts = 0;
      this.skipCounter.posts = 0;
      this.nextPageAvailable.posts = true;
      this.listSort = sort;
      this.handleListFetch();
    },
    async createComment() {
      if (!this.comment) return;

      this.$refs.commentInput.blur();

      this.submittingComment = true;

      try {
        await this.$feathers.service('comments').create({
          content: this.comment,
          hidden: this.commentHidden,
          parentPost: this.post._id,
        });
        this.comment = '';
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not create comment: ${err.message}`, type: 'negative' });
      }

      this.submittingComment = false;
    },
    async createReply() {
      const reply = {
        content: this.comment,
        replyTo: this.commentBeingReplied._id,
      };
      try {
        await this.$feathers.service('comments').patch(this.commentParent, { $push: { replies: reply } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not add reply: ${err.message}`, type: 'negative' });
      }
    },
    deleteComment(commentId) {
      const timeout = 5000;
      const timeoutId = window.setTimeout(async () => {
        try {
          await this.$feathers.service('comments').remove(commentId);
        } catch (err) {
          this.$store.commit('addToast', { message: `Could not remove the comment: ${err.message}`, type: 'negative' });
        } finally {
          this.$store.commit('removeFromSoftDelete', commentId);
        }
      }, timeout);

      this.$store.commit('addToSoftDelete', commentId);
      this.$store.commit('addToast', {
        action: () => {
          window.clearTimeout(timeoutId);
          this.$store.commit('removeFromSoftDelete', commentId);
        },
        actionLabel: 'Undo',
        message: 'The comment was deleted.',
        timeout: timeout - 200, // for safety
        type: 'warning',
      });
    },
    deletePost() {
      const postId = this.$route.params.id;
      const { draft, list, listType } = this.$route.query;
      const timeout = 5000;
      const timeoutId = window.setTimeout(async () => {
        try {
          await this.$feathers.service('posts').remove(postId);
        } catch (err) {
          this.$store.commit('addToast', { message: `Could not remove the post: ${err.message}`, type: 'negative' });
        } finally {
          this.$store.commit('removeFromSoftDelete', postId);
        }
      }, timeout);

      this.$store.commit('addToSoftDelete', postId);
      this.$router.replace(this.previousPage);
      this.$store.commit('addToast', {
        action: () => {
          window.clearTimeout(timeoutId);
          this.$store.commit('removeFromSoftDelete', postId);
          this.$router.push({ name: 'read', params: { id: postId }, query: { draft, list, listType } });
        },
        actionLabel: 'Undo',
        message: `'${this.post.title}' was deleted.`,
        timeout: timeout - 200, // for safety
        type: 'warning',
      });
    },
    deleteReply(commentId, replyId) {
      const timeout = 5000;
      const timeoutId = window.setTimeout(async () => {
        try {
          await this.$feathers.service('comments').patch(commentId, { $pull: { replies: { _id: replyId } } });
        } catch (err) {
          this.$store.commit('addToast', { message: `Could not remove the reply: ${err.message}`, type: 'negative' });
        } finally {
          this.$store.commit('removeFromSoftDelete', replyId);
        }
      }, timeout);

      this.$store.commit('addToSoftDelete', replyId);
      this.$store.commit('addToast', {
        action: () => {
          window.clearTimeout(timeoutId);
          this.$store.commit('removeFromSoftDelete', replyId);
        },
        actionLabel: 'Undo',
        message: 'The comment was deleted.',
        timeout: timeout - 200, // for safety
        type: 'warning',
      });
    },
    async favPost() {
      try {
        if (!this.hasFaved) await this.$feathers.service('posts').patch(this.post._id, { $addToSet: { favourers: this.$store.getters.userId } });
        else await this.$feathers.service('posts').patch(this.post._id, { $pull: { favourers: this.$store.getters.userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong: ${err.message}`, type: 'negative' });
      }
    },
    async fetchCollectionsPage() {
      const timeoutId = window.setTimeout(() => { this.loading.collections = true; }, 200);
      try {
        const query = {
          $limit: this.pageSize,
          $skip: this.pageSize * this.currentPage.collections,
          $sort: { createdAt: -1 },
        };

        const { data: collections, total } = await this.$feathers.service('collections').find({ query });
        this.collections = this.collections.concat(collections);
        this.currentPage.collections += 1;
        this.nextPageAvailable.collections = this.currentPage.collections < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more collections: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.loading.collections = false;
    },
    async fetchCommentsPage() {
      const timeoutId = window.setTimeout(() => { this.loading.comments = true; }, 200);
      try {
        const query = {
          parentPost: this.$route.params.id,
          $limit: this.pageSize,
          $skip: this.pageSize * this.currentPage.comments + this.skipCounter.comments,
          $sort: { createdAt: -1 },
        };

        const { data: comments, total } = await this.$feathers.service('comments').find({ query });
        this.comments = this.comments.concat(comments);
        this.currentPage.comments += 1;
        this.nextPageAvailable.comments = this.currentPage.comments < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more comments: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.loading.comments = false;
    },
    async fetchFavourersPage() {
      const timeoutId = window.setTimeout(() => { this.loading.favourers = true; }, 200);
      try {
        const query = {
          _id: { $in: this.post.favourers },
          $limit: this.pageSize,
          $skip: this.pageSize * this.currentPage.favourers,
          $sort: { lastActive: -1, createdAt: -1 },
          $select: ['avatar', 'name', 'lastActive', 'createdAt'],
        };

        const { data: favourers, total } = await this.$feathers.service('users').find({ query });
        this.favourers = this.favourers.concat(favourers);
        this.currentPage.favourers += 1;
        this.nextPageAvailable.favourers = this.currentPage.favourers < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more favourers: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.loading.favourers = false;
    },
    async fetchLikersPage() {
      const timeoutId = window.setTimeout(() => { this.loading.likers = true; }, 200);
      try {
        const query = {
          _id: { $in: this.post.likers },
          $limit: this.pageSize,
          $skip: this.pageSize * this.currentPage.likers,
          $sort: { lastActive: -1, createdAt: -1 },
          $select: ['avatar', 'name', 'lastActive', 'createdAt'],
        };

        const { data: likers, total } = await this.$feathers.service('users').find({ query });
        this.likers = this.likers.concat(likers);
        this.currentPage.likers += 1;
        this.nextPageAvailable.likers = this.currentPage.likers < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more likers: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.loading.likers = false;
    },
    async fetchPostsPage() {
      const timeoutId = window.setTimeout(() => { this.loading.posts = true; }, 200);
      try {
        const query = {
          ...this.listQuery,
          $limit: this.pageSize,
          $skip: this.pageSize * this.currentPage.posts + this.skipCounter.posts,
          $sort: { createdAt: -1 },
          $select: ['_id', 'author', 'title', 'blurb', 'createdAt'],
        };

        if (this.listSort === 'oldest') query.$sort = { createdAt: 1 };
        if (this.listSort === 'hearts') query.$sort = { likes: -1, createdAt: -1 };

        const { data: posts, total } = await this.$feathers.service('posts').find({ query });
        this.listPosts = this.listPosts.concat(posts);
        this.currentPage.posts += 1;
        this.nextPageAvailable.posts = this.currentPage.posts < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more posts: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.loading.posts = false;
    },
    goToUserProfile(id) {
      this.modals.favourers = false;
      this.modals.likers = false;
      this.$nextTick(() => this.$router.push({ name: 'profile', params: { id } }));
    },
    async handleBookmark(selection) {
      if (!selection || selection.index < 0 || !selection.length) return;
      try {
        const { index, length } = selection;
        const editorWrapper = this.$el.querySelector('.editor-wrapper');
        const editorRect = editorWrapper && editorWrapper.getBoundingClientRect();
        const scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        const percentage = Math.round((scrollPosition / editorRect.height) * 100);
        await this.$feathers.service('users').patch(this.$store.getters.userId, {
          bookmark: {
            index, length: Math.min(length, 100), percentage, post: this.post._id,
          },
        });
        this.$store.commit('addToast', { message: 'Bookmark set', type: 'positive' });
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not set bookmark: ${err.message}`, type: 'negative' });
      }
    },
    handleCommentEdit(id, replyId) {
      const index = this.comments.findIndex((existingComment) => existingComment._id === id);
      const replyIndex = this.comments[index].replies.findIndex((existingReply) => existingReply._id === replyId);
      this.comment = replyIndex > -1 ? this.comments[index].replies[replyIndex].content : this.comments[index].content;
      this.commentBeingEdited = this.comments[index];
      this.commentReplyBeingEdited = replyIndex > -1 ? this.comments[index].replies[replyIndex] : null;
      this.commentError = '';
      this.commentHistory = true;
      this.commentHidden = this.commentBeingEdited.hidden;
      this.commentReplyId = replyId;
      this.modals.editComment = true;
    },
    handleCommentReply(commentId, replyId) {
      if (replyId) {
        const parentComment = this.comments.find((existingComment) => existingComment._id === commentId);
        const replyComment = parentComment.replies.find((existingReply) => existingReply._id === replyId);
        this.commentBeingReplied = replyComment;
      } else {
        const comment = this.comments.find((existingComment) => existingComment._id === commentId);
        this.commentBeingReplied = comment;
      }
      this.commentParent = commentId;
      this.commentError = '';
      this.modals.addReply = true;
    },
    handleContentChanged() {
      this.isGeneratedBlurb = generateBlurb(this.$el) === this.post.blurb;
    },
    async handleListFetch() {
      if (this.listInitialized && !this.loading.posts && this.nextPageAvailable.posts) {
        await this.fetchPostsPage();
        if (this.currentIndex < 0) this.currentIndex = this.listPosts.findIndex((existingPost) => existingPost._id === this.post._id);
      }
    },
    handleQuote(quote, commentId) {
      if (quote.id) {
        // we’re quoting a comment
        const { id, text } = quote;
        this.comment = `${this.comment}\n\n> ${text.replace(/\n+/g, '\n\n> ')}`;
        if (commentId) this.handleCommentReply(commentId, id);
        else this.handleCommentReply(id);
      } else {
        const { index, length } = quote.position;
        this.comment = `${this.comment}\n\n> ${quote.text.replace(/\n+/g, '\n\n> ')} ([${index}:${length}](?selection=${index}:${length}))`;
        this.$store.commit('addToast', {
          action: () => this.$el.querySelector('#comments').scrollIntoView(),
          actionLabel: 'Show',
          message: 'The quote has been added to your comment.',
          type: 'positive',
        });
      }
    },
    handleReplyQuote(quote) {
      const { text } = quote;
      this.comment = `${this.comment}\n\n> ${text.replace(/\n+/g, '\n\n> ')}`;
    },
    handleReadingListBack() {
      if (this.$store.state.application.previousRoute && this.$store.state.application.previousRoute !== '/') this.$router.back();
      else this.$router.push({ name: 'feed' });
    },
    handleSelectClick(e) {
      if (
        e.target
        && e.target.tagName
        && e.target.tagName.toLowerCase() === 'a'
        && e.target.matches('a[href*=\'?selection=\']')
        && e.target.getAttribute
        && e.target.getAttribute('href').split('=')[1] === this.$route.query.selection
      ) {
        this.editorSelection = [];
        this.$nextTick(() => { this.editorSelection = this.$route.query.selection.split(':'); });
      }
    },
    hideAddReply() {
      this.comment = '';
      this.commentBeingEdited = null;
      this.commentParent = null;
      this.modals.addReply = false;
    },
    hideEditComment() {
      this.comment = '';
      this.commentBeingEdited = null;
      this.modals.editComment = false;
    },
    async initializeList() {
      let { listType, list } = this.$route.query;

      if (!list && !listType) {
        listType = 'tag';
        if (this.post.tags) [list] = this.post.tags;

        if (!list) {
          listType = 'user';
          list = this.post.author && this.post.author._id;
        }
      }

      if (this.isPublic) listType = 'public';

      switch (listType) {
        case 'user':
          this.listQuery = { author: list };
          if (list === this.$store.getters.userId) this.listName = 'Your Posts';
          else this.listName = `Posts by ${this.$options.filters.capitalize(this.post.author && this.post.author.name)}`;
          break;
        case 'tbd':
          this.listQuery = { toBeDiscussed: true };
          this.listName = 'To Be Discussed';
          this.listDescription = 'These posts will be discussed during the next regular meeting.\n\nTo have your pieces show up in this list, make sure they’re marked as “**To be discussed**” and you clicked on “**Going**” in the next “**Regular Meeting**”-Event.';
          break;
        case 'top':
          this.listQuery = { starRatio: { $gt: 0.25 } };
          this.listName = 'Top Content';
          this.listDescription = 'These posts have been marked as a favourite by a large percentage of their readers. They are the very best this site has to offer, as voted by **you**.\n\nSo keep giving stars to the pieces you really love. :sparkles:';
          break;
        case 'public':
          this.listQuery = { public: true };
          this.listName = 'Public Stories';
          this.listDescription = 'This is a set of posts that have been made available to the public by their respective authors. To gain access to all of them, please [request membership](/?page=terms).';
          break;
        case 'drafts':
          this.listQuery = { draft: true };
          this.listName = 'Your Drafts';
          this.listDescription = 'These posts are only visible to you until you publish them.';
          break;
        case 'favourites':
          this.listQuery = { favourers: this.$store.getters.userId };
          this.listName = 'Your Favourites';
          this.listDescription = 'These are all the posts that you gave a :star: star.';
          break;
        case 'collection':
          this.listQuery = { collections: list };
          try {
            ({ name: this.listName, description: this.listDescription } = await this.$feathers.service('collections').get(list));
            this.listName = this.$options.filters.capitalize(this.listName);
          } catch (err) {
            this.listName = 'Unnamed Collection';
            this.listDescription = `The collection could not be fetched: ${err.message}`;
          }
          break;
        default:
          this.listQuery = { tags: list };
          this.listName = this.$options.filters.capitalize(list);
          this.listDescription = `Posts tagged ‘${this.$options.filters.capitalize(list)}’.`;
      }

      this.listInitialized = true;
    },
    async likePost() {
      try {
        if (!this.hasLiked) await this.$feathers.service('posts').patch(this.post._id, { $addToSet: { likers: this.$store.getters.userId } });
        else await this.$feathers.service('posts').patch(this.post._id, { $pull: { likers: this.$store.getters.userId } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong: ${err.message}`, type: 'negative' });
      }
    },
    async markAsDiscussed() {
      try {
        await this.$feathers.service('posts').patch(this.post._id, { toBeDiscussed: false });
        this.$store.commit('addToast', { message: 'This post has been marked as discussed', type: 'positive' });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong: ${err.message}`, type: 'negative' });
      }
    },
    async markAsRead() {
      const editorWrapper = this.$el.querySelector('.editor-wrapper');
      const editorRect = editorWrapper && editorWrapper.getBoundingClientRect();
      const { userId } = this.$store.getters;

      if (!editorWrapper || editorRect.bottom > window.innerHeight) {
        // end of article hasn’t been reached yet, schedule another timeout
        this.readTimeout = window.setTimeout(this.markAsRead, 1000);
        return;
      }

      try {
        if (!userId || this.post.readers.includes(userId)) {
          this.$feathers.service('posts').patch(this.post._id, { $inc: { reads: 1 } });
        } else {
          this.$feathers.service('posts').patch(this.post._id, { $addToSet: { readers: userId } });
        }
      } catch (err) {
        // swallow it, it doesn’t matter
      } finally {
        this.readTimeout = null;
      }
    },
    async nextPost() {
      if (this.currentIndex === this.listPosts.length - 1) await this.fetchPostsPage();
      const newPost = this.listPosts[this.currentIndex + 1];
      this.$router.replace({ params: { id: newPost._id }, query: { ...this.$route.query } });
    },
    previousPost() {
      const newPost = this.listPosts[this.currentIndex - 1];
      this.$router.replace({ params: { id: newPost._id }, query: { ...this.$route.query } });
    },
    async publishPost() {
      if (!this.post.draft) return;
      try {
        await this.$feathers.service('posts').patch(this.post._id, { draft: false });
        this.$store.commit('addToast', { message: `Post ‘${this.post.title}’ was published`, type: 'positive' });
      } catch (err) {
        this.$store.commit('addToast', { message: `Something went wrong: ${err.message}`, type: 'negative' });
      }
    },
    removeComment(comment) {
      const index = this.comments.findIndex((existingComment) => existingComment._id === comment._id);
      if (index > -1) {
        this.comments.splice(index, 1);
        this.skipCounter.comments -= 1;
      }
    },
    restoreCachedComment() {
      try {
        const comment = window.localStorage.getItem(`comment-${this.$route.params.id}`);
        if (comment) this.comment = comment;
      } catch (err) {
        // ignore it, not that important
      }
    },
    async saveComment() {
      if (this.comment.length === 0) return;
      const changedComment = this.comment;
      const originalComment = this.commentReplyBeingEdited || this.commentBeingEdited;
      const commentId = this.commentBeingEdited._id;
      const change = { content: changedComment };

      if (changedComment === originalComment.content && this.commentHidden === originalComment.hidden) return;

      if (this.commentHistory) change.originalContent = originalComment.content;
      else change.originalContent = null;
      if (this.post.toBeDiscussed && !this.commentReplyId) change.hidden = this.commentHidden;

      try {
        if (!this.commentReplyId) await this.$feathers.service('comments').patch(commentId, change);
        else await this.$feathers.service('comments').patch(commentId, { $set: { 'replies.$.content': changedComment, 'replies.$.originalContent': this.commentHistory ? originalComment.content : null } }, { query: { 'replies._id': this.commentReplyId } });
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => {
            this.comment = changedComment;
            this.commentBeingEdited = commentId;
            this.commentOriginal = originalComment;
            this.modals.editComment = true;
          },
          actionLabel: 'Try again',
          message: `Could not save comment: ${err.message}`,
          type: 'negative',
        });
      }
    },
    setUpReadTimer() {
      if (this.isOwner || this.$route.query.commentId) return;
      const wpms = 400 / 60 / 1000; // 400 wpm in miliseconds
      let readingTimeMs = Math.ceil(this.wordCount / wpms);
      if (this.$route.query.percentage) readingTimeMs *= 1 - this.$route.query.percentage / 100;
      if (!Number.isNaN(readingTimeMs)) this.readTimeout = window.setTimeout(this.markAsRead, readingTimeMs);
    },
    showCollections() {
      this.modals.collections = true;
      if (this.collections.length === 0) this.fetchCollectionsPage();
    },
    showFavourers() {
      this.modals.favourers = true;
      if (this.favourers.length === 0) this.fetchFavourersPage();
    },
    showLikers() {
      this.modals.likers = true;
      if (this.likers.length === 0) this.fetchLikersPage();
    },
    async toggleCollection(id) {
      try {
        if (this.post.collections.includes(id)) await this.$feathers.service('posts').patch(this.post._id, { $pull: { collections: id } });
        else await this.$feathers.service('posts').patch(this.post._id, { $addToSet: { collections: id } });
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not toggle collection: ${err.message}`, type: 'negative' });
      }
    },
    async toggleCommentLike(commentId, comment, replyId) {
      try {
        if (!replyId) {
          if (comment.likers.includes(this.$store.getters.userId)) await this.$feathers.service('comments').patch(commentId, { $pull: { likers: this.$store.getters.userId } });
          else await this.$feathers.service('comments').patch(commentId, { $addToSet: { likers: this.$store.getters.userId } });
        } else {
          const reply = comment.replies.find((existingReply) => existingReply._id === replyId);
          if (reply.likers.includes(this.$store.getters.userId)) await this.$feathers.service('comments').patch(commentId, { $pull: { 'replies.$.likers': this.$store.getters.userId } }, { query: { 'replies._id': replyId } });
          else await this.$feathers.service('comments').patch(commentId, { $addToSet: { 'replies.$.likers': this.$store.getters.userId } }, { query: { 'replies._id': replyId } });
        }
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not like comment: ${err.message}`, type: 'negative' });
      }
    },
    async togglePromptAward() {
      const awardsBackup = { ...this.awards };
      try {
        if (!this.hasPromptAward) this.awards.prompt = this.post._id;
        else this.awards.prompt = '';
        await this.$feathers.service('info').patch('awards', { content: Object.values(this.awards).join(',') });
      } catch (err) {
        this.awards = awardsBackup;
        this.$store.commit('addToast', { message: `Could not award Best Use of Prompt: ${err.message}`, type: 'negative' });
      }
    },
    updateComment(comment) {
      const index = this.comments.findIndex((existingComment) => existingComment._id === comment._id);
      if (index > -1) {
        if (!(comment.author && comment.author._id)) comment.author = this.comments[index].author; // eslint-disable-line no-param-reassign
        this.comments.splice(index, 1, comment);
      } else if (!comment.hidden && comment.parentPost === this.post._id) {
        this.comments.unshift(comment);
      }
    },
    updatePost(post) {
      if (post._id !== this.post._id) return;
      const contentDelta = { ops: post.content };
      const { author } = this.post;
      this.post = { ...post, author, content: contentDelta };
    },
  },
  mixins: [handleLinkClick],
  mounted() {
    window.setTimeout(async () => { // wait until post is available
      if (!this.mobile) this.loading.posts = true;
      await this.initializeList();
      if (!this.mobile && this.listPosts.length === 0 && this.nextPageAvailable.posts) this.fetchPostsPage();
      if (this.mobile) this.handleListFetch(); // because on mobile the load more button will never be visible
      if (this.$route.query.percentage) {
        const editorRect = this.$el.querySelector('.editor-wrapper').getBoundingClientRect();
        const { percentage } = this.$route.query;
        const offset = (percentage / 100) * editorRect.height;
        window.setTimeout(() => {
          document.documentElement.scrollTop = offset;
          if (this.$route.query.selection) window.setTimeout(() => { this.editorSelection = this.$route.query.selection.split(':'); }, 500); // so the scroll can time out if it animates
        }, 200); // so the transitions can do their thing
      }
      if (this.$route.query.commentId) {
        const comment = document.getElementById(this.$route.query.commentId);
        if (comment) window.setTimeout(() => comment.scrollIntoView({ block: 'center' }), 200); // so the transitions can do their thing
      }
      document.title = `${this.post.title} – Untold Stories`;

      this.restoreCachedComment();

      // fix the action bar hiding and never reappearing on short posts
      const editorWrapper = this.$el.querySelector('.editor-wrapper');
      const editorRect = editorWrapper && editorWrapper.getBoundingClientRect();
      if (editorRect && editorRect.bottom < window.innerHeight) this.forceActionBar = true;
    });
  },
  props: {
    dark: Boolean,
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.query.selection) {
        this.$nextTick(() => { this.editorSelection = newVal.query.selection.split(':'); });
        // this.$router.replace({ query: { selection: undefined } });
      } else this.editorSelection = [];
      if (newVal.query.commentId) {
        const comment = document.getElementById(this.$route.query.commentId);
        comment.scrollIntoView({ block: 'center' }); // so the transitions can do their thing
        // if (comment) window.setTimeout(() => comment.scrollIntoView({ block: 'center' }), 200); // so the transitions can do their thing
      }
      if (newVal.params.id !== oldVal.params.id) this.restoreCachedComment();
    },
    comment(nv, ov) {
      if (ov !== nv) this.cacheComment(nv);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'

.read
  .reading-list
    position: fixed
    left: 3.5rem
    top: 0
    z-index: 3

    &.no-menu
      left: 0

  .editor-wrapper
    .action-bar.vertical
      position: sticky
      margin-left: -5.5rem
      float: left
      top: 6rem

    .date
      font-size: 0.875rem
      color: $text-secondary
      text-align: right
      margin-bottom: 1rem

      &.dark
        color: $text-secondary-dark

      &.mobile
        position: absolute
        top: 1.5rem
        right: @top

      span
        display: block

        u
          cursor: pointer

    .awards
      color: $text-secondary

      &.dark
        color: $text-secondary-dark

      .award
        display: inline-flex
        align-items: center

        .icon
          // width: 1rem
          // height: 1rem
          margin-right: 0.5rem

        > span
          margin-right: 1rem

    .title
      font-family: 'PT Serif', serif
      font-size: 2rem
      color: inherit
      font-weight: normal
      margin-bottom: 0.5rem

    .author
      font-family: 'PT Serif', serif
      font-style: italic

    .blurb
      margin-top: 1.5rem
      margin-left: 0
      opacity: 0.57

      &::before {
        background-color: currentColor
        opacity: 0.5
      }

    .editor.prose
      margin-top: 3rem
      padding-bottom: 2rem

  .stats
    margin-bottom: 2rem
    .counter-icon-button
      margin-right: 1.5rem

  .tags
    margin-bottom: 2rem

    &.dark .chip:hover
      background-color: darken($accent-primary, 5)

    &.public .chip
      pointer-events: none

    .chip
      cursor: pointer
      margin-right: 0.5rem
      margin-bottom: 0.5rem
      transition: background-color 200ms ease

      &:hover
        background-color: lighten($accent-primary, 30)

  #comments
    .input
      width: 100%
      margin-bottom: 2rem

      &.mobile >>> .editor-scroll
        max-height: 12rem

      >>> .editor-scroll
        max-height: 22.5rem

    label
      display: flex
      align-items: center
      margin-top: -0.5rem
      margin-bottom: 2rem

      .switch
        flex-shrink: 0
        margin-left: auto

    .comment
      width: 100%
      margin-bottom: 1rem

      &:last-child:not(:first-child)
        margin-bottom: 3rem

      &.flash >>> .comment-card
        animation: flash 1s ease-out
        animation-delay: 500ms

        @keyframes flash
          from
            background-color: $accent-secondary
          to
            background-color: inherit

    .button
      display: flex
      margin: 0 auto

  .modal
    .user-card:not(:last-child),
    .selectable-card:not(:last-child),
    .comment
      margin-bottom: 1rem

    .selectable-card >>> h2
      text-transform: capitalize

    .button
      display: flex
      margin: 0 auto

      &:not(:first-child)
        margin-top: 1rem

    .input
      width: 100%

      &.mobile >>> .editor-scroll
        max-height: 12rem

      >>> .editor-scroll
        max-height: 22.5rem

    label
      display: flex
      align-items: center
      margin-top: 1.5rem

      .switch
        flex-shrink: 0
        margin-left: auto
</style>

<template lang="html">
  <USView class="feed" :dark="dark" show-notifications>
    <section v-if="totalEvents > 0 || ['councilmembers', 'moderators', 'admins'].some(group => $store.state.user.groups.includes(group))" class="events">
      <header>
        <h1>Next Event</h1>
        <USChip v-show="totalEvents !== 1" :content="totalEvents ? `+${totalEvents - 1} more` : 'Add One'" :dark="dark" prominent @click="$router.push({ name: 'events' })" />
      </header>
      <USEventCard v-if="nextEvent" :dark="dark" :event="nextEvent" interactable show-attendants @click="$router.push({ name: 'events' })" />
      <p v-else>There are currently no upcoming events.</p>
    </section>
    <section v-if="hasBookmark" class="bookmark">
      <header class="heading-wrapper">
        <h1>Continue Reading</h1>
        <USIconButton :dark="dark" icon="clear" tooltip="Clear boommark" @click="clearBookmark" />
      </header>
      <USPostCard compact :dark="dark" :post="hasBookmark" @click="handleBookmark" />
    </section>
    <section v-if="!hasBookmark && fromArchive" class="bookmark">
      <h2>From the Archive</h2>
      <USPostCard compact :dark="dark" :post="fromArchive" @click="openPost($event, { params: { id: fromArchive._id } })" @mousedown="openPost($event, { params: { id: fromArchive._id } })" />
    </section>
    <section class="posts">
      <h1>Feed</h1>
      <USTabBar v-model="postTab" :dark="dark" :tabs="[{ label: 'Unread', value: 'unread' }, { label: 'To be discussed', value: 'tbd' }, { label: 'All', value: 'all' }]" />
      <transition :name="tabTransition" mode="out-in">
        <ListTransition v-if="postsWithoutSoftDeleted.length > 0 || nextPageAvailable" class="posts" :key="postTab">
          <USPostCard v-for="post in postsWithoutSoftDeleted" :dark="dark" :key="post._id" :post="post" @click="openPost($event, { params: { id: post._id }, query: { listType: postTab === 'tbd' ? 'tbd' : undefined } })" @mousedown="openPost($event, { params: { id: post._id }, query: { listType: postTab === 'tbd' ? 'tbd' : undefined } })" />
          <template v-if="initialized">
            <IntersectionGuard key="intersector" @enter="fetchPostsPage">
              <USButton v-show="nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="postsLoading" @click="fetchPostsPage">Load More</USButton>
            </IntersectionGuard>
          </template>
        </ListTransition>
        <section v-else class="empty-state" :key="`emptyState_${postTab}`">
          <TrophyIllu v-if="postTab === 'unread'" :dark="dark" />
          <p v-if="postTab === 'all'">There aren’t any posts yet.</p>
          <p v-if="postTab === 'tbd'">There are no posts that will be discussed at the next meeting yet.</p>
          <p v-if="postTab === 'unread'">You’ve done it. You’ve read every single post there is.</p>
          <USButton v-if="!$store.state.user.groups.includes('applicants') && ['all', 'tbd'].includes(postTab)" :dark="dark" icon-left="plus" @click="$router.push({ name: 'write' })">Create One</USButton>
        </section>
      </transition>
    </section>
    <FadeTransition>
      <USIconButton v-show="$store.state.application.mobile && $store.state.application.showActionBars" :dark="dark" fab icon="pencil" @click="$router.push({ name: 'write' })" />
    </FadeTransition>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';
import Store from '@/store';

import IntersectionGuard from '@/components/IntersectionGuard.vue';
import TrophyIllu from '@/components/TrophyIllu.vue';

import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

import openPost from '@/mixins/openPost';

export default {
  beforeDestroy() {
    this.$feathers.service('posts').off('created', this.addPost);
    this.$feathers.service('posts').off('patched', this.updatePost);
    this.$feathers.service('posts').off('removed', this.removePost);
  },
  async beforeRouteEnter(to, from, next) {
    const query = {
      $limit: 5,
      $sort: { createdAt: -1 },
    };

    if (to.query.tab === 'unread' || !to.query.tab) query.readers = { $ne: Store.getters.userId };
    if (to.query.tab === 'tbd') query.toBeDiscussed = true;

    try {
      const { data: posts, total } = await Feathers.service('posts').find({ query });

      const { data: events, total: eventTotal } = await Feathers.service('events').find({
        query: { $sort: { date: 1 }, $limit: 1 },
      });

      next((vm) => {
        /* eslint-disable no-param-reassign */
        [vm.nextEvent] = events;
        vm.posts = posts;
        vm.currentPage += 1;
        vm.nextPageAvailable = vm.currentPage < Math.ceil(total / vm.pageSize);
        vm.totalEvents = eventTotal;
        vm.initialized = true;
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  beforeRouteLeave(to, from, next) {
    this.initialized = false;
    next();
  },
  components: {
    IntersectionGuard,
    FadeTransition,
    ListTransition,
    TrophyIllu,
  },
  computed: {
    bookmark() {
      return this.$store.state.user.bookmark;
    },
    hasBookmark() {
      return this.$store.state.user.bookmark && this.$store.state.user.bookmark.post;
    },
    postsWithoutSoftDeleted() {
      return this.posts.filter((post) => !this.$store.getters.isSoftDeleted(post._id));
    },
    queryTab() {
      return this.$route.query.tab;
    },
  },
  async created() {
    this.$feathers.service('posts').on('created', this.addPost);
    this.$feathers.service('posts').on('patched', this.updatePost);
    this.$feathers.service('posts').on('removed', this.removePost);

    // if we win the lottery, we show a random unread post that’s at least a year old
    const lotteryTicket = Math.round(Math.random() * 3);

    if (lotteryTicket === 3) {
      try {
        const aYearAgo = Date.now() - 31536000000;
        const oldestPost = 1456959600000; // March 3rd 2016
        const randomTimestamp = Math.floor(Math.random() * (aYearAgo - oldestPost + 1)) + oldestPost;
        const { data: randomPost } = await Feathers.service('posts').find({
          query: {
            readers: { $ne: Store.getters.userId },
            createdAt: { $gte: randomTimestamp },
            $limit: 1,
            $sort: { createdAt: 1 },
          },
        });

        if (randomPost.length > 0 && randomPost[0].createdAt < aYearAgo) [this.fromArchive] = randomPost;
      } catch (err) {
        // don’t do anything, it doesn’t matter
      }
    }
  },
  data() {
    return {
      nextEvent: false,
      postTab: this.$route.query.tab || 'unread',
      currentPage: 0,
      fromArchive: null,
      initialized: false,
      nextPageAvailable: true,
      pageSize: 5,
      posts: [],
      postsLoading: false,
      skipCounter: 0,
      totalEvents: 0,
      tabTransition: 'tab-right',
    };
  },
  methods: {
    addPost(post) {
      if (this.$store.state.user) {
        if (this.$store.state.user.filteredAuthors && this.$store.state.user.filteredAuthors.length && this.$store.state.user.filteredAuthors.includes(post.author._id)) return;
        if (this.$store.state.user.filteredTags && this.$store.state.user.filteredTags.length && this.$store.state.user.filteredTags.some((tag) => post.tags.includes(tag))) return;
      }

      const currentScroll = document.documentElement.scrollTop;

      if (currentScroll < 512 && this.postTab !== 'tbd') {
        this.posts.unshift(post);
        this.skipCounter += 1;
      } else {
        this.$store.commit('addToast', {
          action: () => {
            document.documentElement.scrollTop = 0;
            this.postTab = 'unread';
          },
          actionLabel: 'Show Them',
          message: 'There are new posts',
        });
      }
    },
    clearBookmark() {
      this.$feathers.service('users').patch(this.$store.getters.userId, { bookmark: null }).catch((err) => this.$store.commit('addToast', { message: `Couldn’t unset bookmark: ${err.message}`, type: 'negative' }));
      this.$store.commit('setTooltip', null);
    },
    async fetchPostsPage() {
      const timeoutId = window.setTimeout(() => { this.postsLoading = true; }, 200);
      const query = {
        $limit: this.pageSize,
        $skip: this.pageSize * this.currentPage + this.skipCounter,
        $sort: { createdAt: -1 },
      };

      if (this.postTab === 'unread') query.readers = { $ne: this.$store.getters.userId };
      if (this.postTab === 'tbd') query.toBeDiscussed = true;
      try {
        const { data: posts, total } = await this.$feathers.service('posts').find({ query });
        this.posts = this.posts.concat(posts);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more posts: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.postsLoading = false;
    },
    handleBookmark(e) {
      this.openPost(e, { params: { id: this.bookmark.post }, query: { selection: this.bookmark.index > -1 && this.bookmark.length && `${this.bookmark.index}:${this.bookmark.length}`, percentage: this.bookmark.percentage } });
      this.clearBookmark();
    },
    removePost(post) {
      const index = this.posts.findIndex((existingPost) => post._id === existingPost._id);

      if (index > -1) this.posts.splice(index, 1);
    },
    updatePost(post) {
      const index = this.posts.findIndex((existingPost) => post._id === existingPost._id);

      if (index > -1) {
        post.author = this.posts[index].author; // eslint-disable-line no-param-reassign
        this.posts.splice(index, 1, post);
      }
    },
  },
  mixins: [openPost],
  props: {
    dark: Boolean,
  },
  watch: {
    postTab(newVal, oldVal) {
      this.posts = [];
      this.skipCounter = 0;
      this.currentPage = 0;
      if (this.queryTab !== newVal) this.$router.replace({ query: { tab: newVal } });
      this.fetchPostsPage();

      const tabWeights = {
        unread: 0,
        tbd: 1,
        all: 2,
      };

      if (tabWeights[newVal] < tabWeights[oldVal]) this.tabTransition = 'tab-left';
      else this.tabTransition = 'tab-right';
    },
    queryTab(newVal) {
      if (['unread', 'tbd', 'all'].includes(newVal) && this.postTab !== newVal) this.postTab = newVal;
      if (!newVal && this.postTab !== 'unread') this.postTab = 'unread';
    },
  },
};
</script>

<style lang="stylus" scoped>
.feed
  .events
    margin-bottom: 3rem

    header
      display: flex
      align-items: center
      margin-bottom: 1.5rem

      h1
        margin: 0
        margin-right: auto

  .bookmark
    margin-bottom: 3rem

    .heading-wrapper
      display: flex
      align-items: center
      margin-bottom: 1rem

      h1
        margin: 0
        margin-right: auto
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

      .icon-button
        margin-left: 1rem

  .posts
    .tab-bar
      margin-bottom: 1.5rem

    .button
      display: flex
      margin: 0 auto
      margin-top: 1.5rem

    .post-card:not(:last-of-type)
      margin-bottom: 1.5rem

    .empty-state
      margin-bottom: 0

      p
        margin-top: 0
        font-weight: 800
        opacity: 0.6
        text-align: center

    .tab-left-enter-active,
    .tab-left-leave-active
      transition: transform 200ms ease, opacity 200ms ease

      &.tab-left-enter
        transform: translateX(-8rem)
        opacity: 0

      &.tab-left-leave-to
        transform: translateX(8rem)
        opacity: 0

    .tab-right-enter-active,
    .tab-right-leave-active
      transition: transform 200ms ease, opacity 200ms ease

      &.tab-right-enter
        transform: translateX(8rem)
        opacity: 0

      &.tab-right-leave-to
        transform: translateX(-8rem)
        opacity: 0
</style>

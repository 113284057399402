<template lang="html">
  <article class="post-card" :class="{ dark }" tabindex="0" @click="$emit('click', $event)" @mousedown="$emit('mousedown', $event)">
    <div class="ink" />
    <h1>{{actualPost.title || 'Untitled'}}</h1>
    <span class="author">by {{actualPost.author && actualPost.author.name | capitalize }}</span>
    <p class="blurb" :class="{ compact }">{{actualPost.blurb}}</p>
    <footer>
      <span class="created">{{ actualPost.createdAt | relativeTime }}</span>
      <section class="meta">
        <USIcon v-if="actualPost.readers && actualPost.readers.includes($store.getters.userId)" icon="show" />
        <template v-if="actualPost.comments > 0">
          <USIcon icon="comment" /><span>{{actualPost.comments}}</span>
        </template>
        <template v-if="actualPost.likes > 0">
          <USIcon icon="heart" /><span>{{actualPost.likes}}</span>
        </template>
      </section>
    </footer>
  </article>
</template>

<script>
import capitalize from '@/filters/capitalize';
import relativeTime from '@/filters/relativeTime';

export default {
  async created() {
    if (typeof this.post === 'string') await this.fetchPost();
    else this.actualPost = this.post;
  },
  data() {
    return {
      actualPost: {},
    };
  },
  filters: {
    capitalize,
    relativeTime,
  },
  methods: {
    async fetchPost() {
      try {
        const post = await this.$feathers.service('posts').get(this.post, { query: { $select: ['author', 'title', 'blurb', 'createdAt', 'likes', 'comments', 'readers'] } });
        this.actualPost = post;
      } catch (err) {
        this.actualPost = {
          author: {},
          title: 'Glitched Post',
          blurb: `Something went wrong fetching the post: ${err.message}`,
          createdAt: Date.now(),
          likes: 0,
          comments: 0,
          readers: [],
        };
      }
    },
  },
  props: {
    compact: Boolean,
    dark: Boolean,
    post: [Object, String],
  },
  watch: {
    async post(newVal) {
      if (typeof newVal === 'string') await this.fetchPost();
      else this.actualPost = newVal;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.post-card
  max-width: 40rem
  border: 1px solid $interactable
  border-radius: 0.75rem
  padding: 1rem
  box-shadow: $shadow-low
  background-color: $bg
  position: relative
  overflow: hidden
  cursor: pointer
  font-family: 'PT Serif', serif
  transition: box-shadow 200ms ease, border-color 200ms ease, background-color 200ms ease

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark

    &:hover
      background-color: $elevation-primary-dark

    .author,
    footer
      color: $text-secondary-dark

  &:hover,
  &:focus
    box-shadow: $shadow-high
    border-color: $accent-primary

  &:focus .ink
    transform: translateY(-50%) scale(1.4142135624)

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    top: 50%
    transform: translateY(-50%) scale(0)
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  h1,
  .author,
  .blurb.compact
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  h1
    margin-bottom: 0
    color: inherit
    font-weight: normal

  .author
    display: block
    color: $text-secondary
    font-style: italic

  footer
    font-family: 'Nunito', 'PT Sans', sans-serif
    display: flex
    align-items: center
    justify-content: space-between
    color: $text-secondary
    white-space: nowrap

    .created
      overflow: hidden
      text-overflow: ellipsis

    .meta
      .icon
        &:not(:last-child)
          margin-right: 0.5rem

        & + .icon
          margin-left: 0.25rem

      > span
        vertical-align: middle

        &:not(:last-child)
          margin-right: 0.75rem
</style>

<template lang="html">
  <button class="user-card" :class="{ dark, icon }" type="button" @click="handleClick">
    <div class="ink" />
    <img :src="avatar || '/img/default-avatar.png'" alt="Avatar not found">
    <div class="content">
      <span class="name">{{name}}</span>
      <span>{{info}}</span>
    </div>
    <USIconButton v-if="icon" :dark="dark" :icon="icon" @click="handleAction" />
  </button>
</template>

<script>
export default {
  data() {
    return {
      actionClicked: false,
    };
  },
  methods: {
    handleAction() {
      this.actionClicked = true;
      this.$emit('action');
    },
    handleClick(e) {
      if (!this.icon || !this.actionClicked) {
        this.$emit('click', e);
      } else {
        this.actionClicked = false;
      }
    },
  },
  props: {
    avatar: String,
    dark: Boolean,
    info: String,
    name: String,
    icon: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.user-card
  display: flex
  width: 100%
  align-items: center
  padding: calc(1rem - 1px)
  background-color: $bg
  border: 1px solid $interactable
  border-radius: 0.75rem
  box-shadow: $shadow-low
  cursor: pointer
  position: relative
  overflow: hidden
  z-index: 0
  transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, color 200ms ease

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark
    color: $text-primary-dark

    span:not(.name)
      color: $text-secondary-dark

  &.icon .content
    width: calc(100% - 7rem)

  &:hover,
  &:focus
    border-color: $accent-primary
    box-shadow: $shadow-high

    &.dark
      background-color: $elevation-primary-dark

  &:focus .ink
      transform: scale(1.4142135624)

  &:active
    transform: translateY(2px)

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    z-index: -1
    transition: transform 200ms ease, background-color 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  img
    margin: 0
    margin-right: 1rem
    width: 2.5rem
    height: @width
    border-radius: 50%
    flex-shrink: 0

  .content
    width: calc(100% - 3.5rem)
    text-align: left

    span
      display: inline-block
      font-size: 0.875rem
      line-height: 0.875rem
      width: 100%
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      color: $text-secondary

      &.name
        font-size: 1rem
        line-height: 1
        color: currentColor
        text-transform: capitalize

  .icon-button
    flex-shrink: 0
    padding: calc(0.5rem - 1px)
    border-radius: 0.375rem
    box-shadow: none
    margin-left: 1rem
</style>

<template lang="html">
  <USView class="freewriting" :dark="dark" show-back>
    <h1 :class="{ faded: timerInterval && !done }">Free Writing</h1>
    <p :class="{ faded: timerInterval && !done }">Select for how long you’d like to write and click “Start”. Try not to worry about rethorical concerns, conventions, or mechanics—just let it flow.</p>
    <p v-if="!mobile" :class="{ faded: timerInterval && !done }">To help you with that, you won’t be able to delete anything you’ve typed (don’t worry, you can always edit it after you’ve saved it as a draft) and should you stop writing for more than {{timeToDestruct / 1000}} seconds, well, everything you wrote will be deleted.</p>
    <p v-else :class="{ faded: timerInterval && !done }">To help you with that, well, everything you wrote will be deleted should you stop writing for more than {{timeToDestruct / 1000}} seconds.</p>
    <FadeTransition @after-enter="timerInterval ? $refs.editor.quill.focus() : false">
      <div v-if="!timerInterval && !done" class="setup" key="setup">
        <h2>How long would you like to write for?</h2>
        <USSelectableCard v-for="time in limits" :dark="dark" icon="stopwatch" :key="time.value" :selected="timeLimit === time.value" :title="time.label" @click="timeLimit = time.value" />
        <div class="button-bar" :class="{ mobile }">
          <USButton :dark="dark" color="positive" icon-right="send" @click="startTimers">Start</USButton>
        </div>
      </div>
      <div v-else class="writing">
        <div class="meta-bar" :class="{ dark, mobile }">
          <transition>
            <div v-show="!typing && !done" class="delete-indicator" :class="{ danger: remainingTimeToDestruct / timeToDestruct < 0.5 }">
              <svg class="indicator" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="progress" d="M46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24Z" :style="{ strokeDashoffset: dashoffset }" />
              </svg>
              <USIcon icon="delete" />
            </div>
          </transition>
          <h2 class="remainingTime">{{formattedTime}}</h2>
        </div>
        <USEditor v-model="draft" :dark="dark" disable-deletion disable-tab disable-history :formats="['bold', 'blockquote', 'header', 'italic', 'linebreak', 'list', 'separator', 'strike', 'underline']" mode="delta" placeholder="Let your thoughts flow…" ref="editor" scrolling-container="html" @input="handleTypingStop" @selectionchange="handleSelectionChange" @typing-start="handleTypingStart" />
        <FadeTransition>
          <div v-if="done" class="button-bar" :class="{ mobile }">
            <USButton :dark="dark" color="danger" @click="reset">Discard and reset</USButton>
            <USButton :dark="dark" :disabled="!unsavedChanges" :loading="savingDraft" primary icon-left="draft-add" @click="saveAsDraft">Save to Private Drafts</USButton>
          </div>
        </FadeTransition>
      </div>
    </FadeTransition>
  </USView>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventUnintentionalClose);
    window.removeEventListener('keydown', this.preventSelectionReplacement, { capture: true });

    if (this.timerInterval) window.clearInterval(this.timerInterval);
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.$store.commit('addToast', {
        action: next,
        actionLabel: 'Discard Changes',
        message: 'You have unsaved changes, do you want to discard them?',
        type: 'warning',
      });
    } else next();
  },
  components: {
    FadeTransition,
  },
  computed: {
    dashoffset() {
      const pathLength = 138.25;
      const progress = this.remainingTimeToDestruct / this.timeToDestruct;
      if (progress === 1) return 0; // so the path shows up as fully drawn
      // clamp the offset between 0 and pathLength to prevent "shrinking" the path
      return pathLength - ((progress * pathLength) % pathLength);
    },
    formattedTime() {
      const minutes = `0${Math.floor(this.remainingTime / (60 * 1000))}`.slice(-2);
      const seconds = `0${Math.floor((this.remainingTime % (60 * 1000)) / 1000)}`.slice(-2);
      return `${minutes}:${seconds}`;
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    unsavedChanges() {
      return Object.keys(this.draft).length > 0;
    },
  },
  created() {
    window.addEventListener('beforeunload', this.preventUnintentionalClose);
  },
  data() {
    return {
      draft: {},
      savingDraft: false,
      selectionReplacementProtection: false,
      timeLimit: 2 * 60 * 1000, // two minutes
      timerInterval: null,
      timeToDestruct: 5 * 1000,
      remainingTimeToDestruct: null,
      done: false,
      limits: [
        {
          label: 'Two Minutes',
          value: 2 * 60 * 1000,
        },
        {
          label: 'Five Minutes',
          value: 5 * 60 * 1000,
        },
        {
          label: 'Ten Minutes',
          value: 10 * 60 * 1000,
        },
      ],
      remainingTime: 0,
      lastDate: null,
      typing: false,
    };
  },
  methods: {
    handleSelectionChange(range) {
      if ((!range.length && !this.selectionReplacementProtection) || (range.length && this.selectionReplacementProtection)) return;

      if (range.length && !this.selectionReplacementProtection) {
        window.addEventListener('keydown', this.preventSelectionReplacement, { capture: true });
        this.selectionReplacementProtection = true;
      } else {
        window.removeEventListener('keydown', this.preventSelectionReplacement, { capture: true });
        this.selectionReplacementProtection = false;
      }
    },
    handleTypingStart() {
      if (!this.typing) {
        this.typing = true;
        this.remainingTimeToDestruct = this.timeToDestruct;
      }
    },
    handleTypingStop() {
      this.typing = false;
    },
    preventSelectionReplacement(e) {
      if (e.key.length === 1 && !e.ctrlKey) e.preventDefault();
    },
    preventUnintentionalClose(e) {
      if (this.unsavedChanges) {
        this.$store.commit('addToast', {
          message: 'You have unsaved changes, save them before exiting if you don’t want to lose them.',
          type: 'warning',
          timeout: 10000,
        });
        e.preventDefault();
        e.returnValue = ''; // for chrome
      }
    },
    reset() {
      this.done = false;
      this.draft = {};
      this.lastTextLength = 0;
    },
    async saveAsDraft() {
      if (!this.draft.ops || this.draft.ops.length === 0) {
        this.$store.commit('addToast', { message: 'Could not save draft: post has no content', type: 'negative' });
        return;
      }

      this.savingDraft = true;

      let blurb = '';
      const paragraphItems = this.$el.querySelectorAll('.ql-editor p, .ql-editor li, .ql-editor blockquote');
      for (let i = 0; i < paragraphItems.length; i += 1) {
        if (paragraphItems[i].textContent) {
          const blurbCandidate = paragraphItems[i].textContent.split('\n')[0].substring(0, 255).trim();
          if (!/(\w|,)$/.test(blurbCandidate)) blurb = blurbCandidate;
          else if (blurbCandidate.endsWith(',')) blurb = blurbCandidate.substring(0, blurbCandidate.length - 1);
          else blurb = `${blurbCandidate}…`;
          break;
        }
      }

      const post = {
        blurb,
        content: this.draft.ops,
        draft: true,
        public: false,
        indentedParagraphs: false,
        readers: [this.$store.getters.userId],
        reads: 1,
        tags: ['free writing', 'writing exercise'],
        title: 'Untitled Free Writing Piece',
        toBeDiscussed: false,
      };

      try {
        const newPost = await this.$feathers.service('posts').create(post);
        this.$store.commit('addToast', {
          action: () => this.$router.replace({ name: 'read', params: { id: newPost._id }, query: { draft: true, listType: 'drafts' } }),
          actionLabel: 'Open',
          dismissable: true,
          message: 'Your piece has been added to your drafts. You can edit and publish it from there.',
          timeout: 10000,
          type: 'positive',
        });

        this.reset();
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not save post: ${err.message}`, type: 'negative' });
      }

      this.savingDraft = false;
    },
    startTimers() {
      this.lastDate = Date.now();
      this.remainingTime = this.timeLimit;
      this.remainingTimeToDestruct = this.timeToDestruct;
      this.typing = false;
      this.timerInterval = window.setInterval(() => {
        const now = Date.now();
        const delta = now - this.lastDate;
        this.remainingTime -= delta;
        this.lastDate = now;

        if (!this.typing) {
          this.remainingTimeToDestruct -= delta;

          if (this.remainingTimeToDestruct <= -1000) { // so it matches the transition
            window.clearInterval(this.timerInterval);
            this.timerInterval = null;
            this.remainingTimeToDestruct = 0;
            this.$refs.editor.quill.blur();
            this.draft = {};
            this.$refs.editor.quill.disable();
            this.done = true;
          }
        }

        if (this.remainingTime <= 0) {
          window.clearInterval(this.timerInterval);
          this.remainingTime = 0;
          this.timerInterval = null;
          this.done = true;
        }
      }, 1000);
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'inspiration', query: { page: 'freewriting' } });
    },
    unsavedChanges(nv) {
      this.$emit('update-unsaved-changes', nv);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../../styles/colors'

.freewriting
  > p,
  > h1
    transition: opacity 200ms ease

    &.faded
      opacity: 0.1

  .writing
    margin: 4rem 0
    display: flex
    flex-direction: column

    .meta-bar
      display: flex
      justify-content: space-between
      align-items: center
      min-height: 3rem
      position: sticky
      top: (60 / 16)rem
      padding: 1rem 0
      min-height: 5rem
      margin-bottom: 1rem
      background-image: linear-gradient(to top, alpha($bg, 0), $bg 1rem)
      z-index: 1

      &.dark
        background-image: linear-gradient(to top, alpha($bg-dark, 0), $bg-dark 1rem)

      &.mobile
        top: 0

      .delete-indicator
        width: 3rem
        height: @width
        position: relative
        display: flex
        align-items: center
        justify-content: center
        flex-shrink: 0
        color: $warning
        transition: color 1000ms ease

        &.danger
          color: $negative

        &.v-enter-active,
        &.v-leave-active
          transition: transform 200ms ease, opacity 200ms ease

          &.v-enter
            transform: rotate(45deg)
            opacity: 0

          &.v-leave-to
            transform: rotate(-45deg)
            opacity: 0

        .indicator
          overflow: visible
          stroke-width: 0.25rem
          stroke-linecap: round
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0

          .progress
            stroke: currentColor
            stroke-dasharray: 138.25
            transform-origin: center center
            transform: rotate(-90deg)
            transition: stroke-dashoffset 1s linear

      > h2
        margin: 0
        margin-left: auto

  .setup
    display: flex
    flex-wrap: wrap
    margin: 4rem -0.5rem

    .selectable-card
      flex: 1 1 calc(33.33% - 2rem)
      min-width: (192 / 16)rem
      margin: 0.5rem

    .button-bar,
    > h2
      flex-basis: 100%
      margin-left: 0.5rem
      margin-right: @margin-left

  .button-bar
    margin-top: 2rem
    text-align: right

    .button:not(:last-child)
      margin-right: 1rem

    &.mobile .button
      width: 100%

      &:not(:last-child)
        margin-right: 0
        margin-bottom: 1rem
</style>

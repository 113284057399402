<template lang="html">
  <section class="view">
    <header class="view-header" v-if="(showBack || showNotifications) && $store.state.application.mobile">
      <USIconButton v-if="showBack" :dark="dark" icon="arrow-left" @click="handleBack" />
      <USCounterIconButton v-if="showNotifications" :amount="notificationAmount" :dark="dark" :icon="notificationAmount > 0 ? 'bell-ringing' : 'bell'" :show-counter="false" @click="$store.dispatch('setShowNotificationBar', true)" />
    </header>
    <slot />
  </section>
</template>

<script>
export default {
  computed: {
    notificationAmount() {
      return this.$store.state.application.notificationAmount;
    },
  },
  methods: {
    handleBack() {
      if (this.backAction) this.backAction();
      else if (this.$store.state.application.previousRoute && (this.$store.state.application.previousRoute !== '/' || !this.userId)) this.$router.back();
      else this.$router.push({ name: 'feed' });
    },
  },
  props: {
    backAction: Function,
    dark: Boolean,
    showBack: Boolean,
    showNotifications: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
.view
  padding: 6rem 2rem
  max-width: 44rem
  margin: 0 auto
  position: relative

  .view-header
    position: absolute
    top: 1rem
    left: 1rem
    right: 1rem
    display: flex

    .counter-icon-button
      margin-left: auto

  .action-bar.vertical
    position: sticky
    margin-left: -5.5rem
    float: left
    top: 6rem

  .icon-button.fab
    position: fixed
    bottom: 4.5rem;
    bottom: calc(4.5rem + env(safe-area-inset-bottom, 0))
    right: 1rem

    &.fade-enter-active,
    &.fade-leave-active
      transition: transform 200ms ease !important

      &.fade-enter,
      &.fade-leave-to
        opacity: 1
        transform: scale(0)

</style>

<template lang="html">
  <USView class="forum" :dark="dark" show-back show-notifications>
    <FadeTransition>
      <USActionBar v-if="!mobile && $store.state.application.showActionBars" :actions="[{ action: () => this.showCreate = true, icon: 'add-forum', tooltip: 'Add a new thread' }]" :dark="dark" vertical />
    </FadeTransition>
    <template v-if="pinned.length > 0">
      <h1>Pinned Threads</h1>
      <ListTransition class="pinned" :class="{ desktop: !mobile }">
        <USThreadCard v-for="thread in pinnedWithoutSoftDeleted" :author="thread.author" :class="{ unread: !thread.readers.includes($store.getters. userId) }" :dark="dark" :key="thread._id" :title="thread.title" :updated="thread.updatedAt || thread.createdAt" @click="$router.push({ name: 'thread', params: { id: thread._id } }).catch(e => e)" />
      </ListTransition>
    </template>
    <h1>General Threads</h1>
    <USTabBar v-model="threadTab" :dark="dark" :tabs="[{ label: 'Unread', value: 'unread' }, { label: 'Read', value: 'read' }]" />
    <transition :name="tabTransition" mode="out-in">
      <ListTransition v-if="threads.length > 0 || nextPageAvailable" :key="threadTab">
        <USThreadCard v-for="thread in threadsWithoutSoftDeleted" :author="thread.author" :dark="dark" :key="thread._id" :title="thread.title" :updated="thread.updatedAt || thread.createdAt" @click="$router.push({ name: 'thread', params: { id: thread._id } })" />
        <template v-if="initialized">
          <IntersectionGuard key="intersector" @enter="fetchThreadsPage">
            <USButton v-show="nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="threadsLoading" @click="fetchThreadsPage">Load More</USButton>
          </IntersectionGuard>
        </template>
        <USButton v-show="threadTab === 'unread' && !nextPageAvailable" :dark="dark" icon-left="clear" key="clearButton" @click="clearUnread">Mark all as read</USButton>
      </ListTransition>
      <section v-else class="empty-state" :key="`emptyState_${threadTab}`">
        <p>There aren’t any {{threadTab === 'unread' ? 'unread' : 'read'}} forum threads at the moment.</p>
        <USButton :dark="dark" icon-left="plus" @click="showCreate = true">Create One</USButton>
      </section>
    </transition>
    <FadeTransition>
      <USIconButton v-show="mobile && $store.state.application.showActionBars" :dark="dark" fab icon="add-forum" @click="showCreate = true" />
    </FadeTransition>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: createThread, disabled: formErrors, icon: 'send', label: 'save',
      }]"
      :dark="dark"
      title="Create New Thread"
      :visible="showCreate"
      @close="showCreate = false">
      <USInput v-model="subject" autofocus :dark="dark" :error="errors.subject" icon="title" label="Subject" :max-len="64" @blur="validate('subject')" />
      <USInput v-model="message" :class="{ mobile }" :dark="dark" :error="errors.message" :formats="['blockquote', 'bold', 'header', 'image', 'indent', 'italic', 'linebreak', 'link', 'list', 'strike']" icon="description" label="Message" :max-len="4096" multiline @blur="validate('message')" />
      <label v-if="$store.state.user.groups.includes('members')">
        Visible to Applicants
        <USSwitch v-model="visibleToApplicants" :dark="dark" />
      </label>
      <label v-if="isPrivileged">
        Pin this thread
        <USSwitch v-model="pin" :dark="dark" />
      </label>
      <label v-if="isPrivileged">
        Locked
        <USSwitch v-model="locked" :dark="dark" />
      </label>
      <label v-if="isPrivileged">
        Use “Untold Stories” as author
        <USSwitch v-model="usAuthor" :dark="dark" />
      </label>
      <label v-if="isPrivileged" class="restricted">
        Restricted
        <USSwitch v-model="enableRestricted" :dark="dark" />
      </label>
      <FadeTransition>
        <USChipInput v-show="enableRestricted" v-model="restricted" :dark="dark" field-to-search="name" icon="group" label="Group Names" :max-len="8" model="groups" @blur="validate('restricted')" />
      </FadeTransition>
    </USModal>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';
import Store from '@/store';
import IntersectionGuard from '@/components/IntersectionGuard.vue';
import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

export default {
  beforeDestroy() {
    this.$feathers.service('threads').off('created', this.addThread);
    this.$feathers.service('threads').off('patched', this.updateThread);
    this.$feathers.service('threads').off('removed', this.removeThread);
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: pinned } = await Feathers.service('threads').find({
        query: {
          pinned: true,
          $limit: 50,
          $skip: 0,
          $sort: { updatedAt: -1, createdAt: -1 },
        },
      });

      const query = {
        pinned: { $ne: true },
        readers: { $ne: Store.getters.userId },
        $limit: 15,
        $sort: { updatedAt: -1, createdAt: -1 },
      };

      if (to.query.tab === 'read') query.readers = Store.getters.userId;
      const { data: threads, total } = await Feathers.service('threads').find({ query });

      pinned.forEach((thread) => {
        if (thread.usAuthor) thread.author.avatar = '/img/us-avatar.png'; // eslint-disable-line no-param-reassign
      });
      threads.forEach((thread) => {
        if (thread.usAuthor) thread.author.avatar = '/img/us-avatar.png'; // eslint-disable-line no-param-reassign
      });
      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.pinned = pinned;
        vm.threads = threads;
        vm.currentPage += 1;
        vm.nextPageAvailable = vm.currentPage < Math.ceil(total / vm.pageSize);
        vm.initialized = true;
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.initialized = false;
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    const { data: pinned } = await Feathers.service('threads').find({
      query: {
        pinned: true,
        $limit: 50,
        $skip: 0,
        $sort: { updatedAt: -1, createdAt: -1 },
      },
    });
    pinned.forEach((thread) => {
      if (thread.usAuthor) thread.author.avatar = '/img/us-avatar.png'; // eslint-disable-line no-param-reassign
    });
    this.pinned = pinned;
    await this.fetchThreadsPage();
    this.initialized = true;
    next();
  },
  components: {
    IntersectionGuard,
    FadeTransition,
    ListTransition,
  },
  computed: {
    formErrors() {
      const errors = Object.values(this.errors);
      return errors.some((err) => err !== '');
    },
    isPrivileged() {
      return ['moderators', 'admins'].some((group) => this.$store.state.user.groups.includes(group));
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
    pinnedWithoutSoftDeleted() {
      return this.pinned.filter((thread) => !this.$store.getters.isSoftDeleted(thread._id));
    },
    threadsWithoutSoftDeleted() {
      return this.threads.filter((thread) => !this.$store.getters.isSoftDeleted(thread._id));
    },
    queryTab() {
      return this.$route.query.tab;
    },
  },
  created() {
    this.$feathers.service('threads').on('created', this.addThread);
    this.$feathers.service('threads').on('patched', this.updateThread);
    this.$feathers.service('threads').on('removed', this.removeThread);
  },
  data() {
    return {
      currentPage: 0,
      enableRestricted: false,
      errors: {
        subject: '',
        message: '',
        restricted: '',
      },
      initialized: false,
      locked: false,
      message: '',
      nextPageAvailable: true,
      pageSize: 15,
      pin: false,
      pinned: [],
      restricted: [],
      showCreate: false,
      skipCounter: 0,
      subject: '',
      tabTransition: 'tab-right',
      threads: [],
      threadsLoading: false,
      threadTab: this.$route.query.tab || 'unread',
      usAuthor: false,
      visibleToApplicants: true,
    };
  },
  methods: {
    addThread(thread) {
      if (thread.usAuthor) thread.author.avatar = '/img/us-avatar.png'; // eslint-disable-line no-param-reassign

      if ((this.threadTab === 'unread' || thread.author._id === this.$store.getters.userId) && !thread.pinned) {
        this.threads.unshift(thread);
        this.skipCounter += 1;
      } else if (thread.pinned) {
        this.pinned.unshift(thread);
      }
    },
    async clearUnread() {
      try {
        await this.$feathers.service('threads').patch(null, { $addToSet: { readers: this.$store.getters.userId } }, { query: { readers: { $ne: this.$store.getters.userId } } });
        this.threads = [];
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not mark as read: ${err.message}`, type: 'negative' });
      }
    },
    async createThread() {
      this.validate('subject');
      this.validate('message');
      this.validate('restricted');

      if (this.formErrors) return;

      try {
        const newThread = {
          message: this.message,
          public: this.visibleToApplicants,
          title: this.subject,
        };

        if (!this.$store.state.user.groups.includes('applicants')) newThread.public = this.visibleToApplicants;
        if (this.isPrivileged) {
          newThread.locked = this.locked;
          newThread.pinned = this.pin;
          newThread.restricted = this.restricted;
          newThread.usAuthor = this.usAuthor;
        }

        if (newThread.restricted) newThread.restricted = newThread.restricted.map((group) => group.value);

        await this.$feathers.service('threads').create(newThread);

        this.locked = false;
        this.message = '';
        this.pin = false;
        this.visibleToApplicants = true;
        this.restricted = [];
        this.enableRestricted = false;
        this.subject = '';
        this.usAuthor = false;
        this.$nextTick(() => {
          this.errors = {
            subject: '',
            message: '',
            restricted: '',
          };
        });
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => { this.showCreate = true; },
          actionLabel: 'Try again',
          message: `Could not create thread: ${err.message}`,
          timeout: 10000,
          type: 'negative',
        });
      }
    },
    async fetchThreadsPage() {
      const timeoutId = window.setTimeout(() => { this.threadsLoading = true; }, 200);
      const query = {
        pinned: { $ne: true },
        $limit: this.pageSize,
        $skip: this.pageSize * this.currentPage + this.skipCounter,
        $sort: { updatedAt: -1, createdAt: -1 },
      };

      if (this.threadTab === 'unread') query.readers = { $ne: this.$store.getters.userId };
      else query.readers = this.$store.getters.userId;

      try {
        const { data: threads, total } = await this.$feathers.service('threads').find({ query });
        threads.forEach((thread) => {
          if (thread.usAuthor) thread.author.avatar = '/img/us-avatar.png'; // eslint-disable-line no-param-reassign
        });
        this.threads = this.threads.concat(threads);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more threads: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.threadsLoading = false;
    },
    removeThread(thread) {
      const index = this.threads.findIndex((existingThread) => thread._id === existingThread._id);

      if (index > -1) this.threads.splice(index, 1);
    },
    updateThread(thread) {
      const index = this.threads.findIndex((existingThread) => thread._id === existingThread._id);

      if (index > -1) {
        if (this.threadTab === 'unread' && thread.readers.includes(this.$store.getters.userId)) this.threads.splice(index, 1);
        else {
          thread.author = this.threads[index].author; // eslint-disable-line no-param-reassign
          this.threads.splice(index, 1, thread);
        }
      }
    },
    validate(field) {
      let error = '';

      switch (field) {
        case 'message':
          if (!this.message) error = 'Message is required';
          if (this.message.length > 4096) error = 'Message is too long';
          this.errors.message = error;
          break;
        case 'restricted':
          if (this.enableRestricted && this.restricted.length > 8) error = 'Too many groups';
          this.errors.restricted = error;
          break;
        case 'subject':
          if (!this.subject) error = 'Subject is required';
          if (this.subject.length > 64) error = 'Subject is too long';
          this.errors.subject = error;
          break;
        default:
      }
    },
  },
  mounted() {
    this.$store.commit('setShowForumNotificationDot', false);
    // this.$nextTick(() => { this.initialized = true; });
  },
  props: {
    dark: Boolean,
  },
  watch: {
    threadTab(newVal) {
      this.threads = [];
      this.skipCounter = 0;
      this.currentPage = 0;
      if (this.queryTab !== newVal) this.$router.replace({ query: { tab: newVal } });
      if (newVal === 'read') this.tabTransition = 'tab-right';
      else this.tabTransition = 'tab-left';
    },
    queryTab(newVal) {
      if (['unread', 'read'].includes(newVal) && this.threadTab !== newVal) this.threadTab = newVal;
      if (!newVal && this.threadTab !== 'unread') this.threadTab = 'unread';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'

.forum
  .tab-bar
    margin-bottom: 1.5rem

  .thread-card
    margin-bottom: 1rem

  .button
    display: flex
    margin: 0 auto

  .empty-state
    margin-bottom: 0

    p
      margin-top: 0
      font-weight: 800
      opacity: 0.6
      text-align: center

  .tab-left-enter-active,
  .tab-left-leave-active
    transition: transform 200ms ease, opacity 200ms ease

    &.tab-left-enter
      transform: translateX(-8rem)
      opacity: 0

    &.tab-left-leave-to
      transform: translateX(8rem)
      opacity: 0

  .tab-right-enter-active,
  .tab-right-leave-active
    transition: transform 200ms ease, opacity 200ms ease

    &.tab-right-enter
      transform: translateX(8rem)
      opacity: 0

    &.tab-right-leave-to
      transform: translateX(-8rem)
      opacity: 0

  .pinned
    margin-bottom: 3rem

    &.desktop
      margin-bottom: 6rem

    .unread
      >>> .title
        font-weight: bold

      &::after
        content: ''
        display: block
        width: 0.5rem
        height: @width
        position: absolute
        top: 0.875rem
        left: 2.875rem
        background-color: $accent-secondary
        border-radius: 50%
        border: 2px solid $bg

      &.dark::after
        border-color: $bg-dark

  .modal
    .input,
    .chip-input
    .select-box
      width: 100%

    label
      display: flex
      margin-bottom: 1rem

      .switch
        margin-left: auto

    .input
      margin-bottom: 1rem

      &.mobile >>> .editor-scroll
        max-height: 12rem

      >>> .editor-scroll
        max-height: 22.5rem

    .input + label
      margin-top: 1.5rem
</style>

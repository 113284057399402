<template lang="html">
  <USView class="user" :dark="dark" show-notifications>
    <template v-if="mobile">
      <svg width="17.125rem" height="8.9375rem" viewBox="0 0 274 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M247 0H0V55.5C0 40.8645 11.8645 29 26.5 29C41.1355 29 53 40.8645 53 55.5V56V57V65C53 87.0914 70.9086 105 93 105H94C116.091 105 134 87.0914 134 65V57V56.5C134 42.4167 145.417 31 159.5 31C173.583 31 185 42.4167 185 56.5V57V73.5C185 82.6127 192.387 90 201.5 90H202.5C211.613 90 219 82.6127 219 73.5V57V49.5C219 34.3122 231.312 22 246.5 22C246.667 22 246.834 22.0015 247 22.0045V22C249.955 22 252.881 21.431 255.61 20.3254C258.34 19.2197 260.821 17.5992 262.91 15.5564C264.999 13.5135 266.657 11.0882 267.787 8.41904C268.918 5.74987 269.5 2.88908 269.5 0H247ZM202 138C211.389 138 219 130.613 219 121.5C219 112.387 211.389 105 202 105C192.611 105 185 112.387 185 121.5C185 130.613 192.611 138 202 138Z" :fill="$store.state.user.color || '#B100CE'" />
        </g>
        <defs>
          <filter id="filter0_d" x="-4" y="-3" width="277.5" height="146" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
      <img class="avatar" :src="$store.state.user.avatar || '/img/default-avatar.png'" alt="no avatar found">
      <USButton v-for="option in options" :dark="dark" :icon-left="option.icon" :key="option.value" @click="goTo(option.value)">{{ option.label }}</USButton>
      <USButton :dark="dark" icon-right="sign-out" primary @click="logout">Logout</USButton>
    </template>
    <template v-else>
      <USSidebar :dark="dark" collapse-button>
        <svg width="17.125rem" height="8.9375rem" viewBox="0 0 274 143" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M247 0H0V55.5C0 40.8645 11.8645 29 26.5 29C41.1355 29 53 40.8645 53 55.5V56V57V65C53 87.0914 70.9086 105 93 105H94C116.091 105 134 87.0914 134 65V57V56.5C134 42.4167 145.417 31 159.5 31C173.583 31 185 42.4167 185 56.5V57V73.5C185 82.6127 192.387 90 201.5 90H202.5C211.613 90 219 82.6127 219 73.5V57V49.5C219 34.3122 231.312 22 246.5 22C246.667 22 246.834 22.0015 247 22.0045V22C249.955 22 252.881 21.431 255.61 20.3254C258.34 19.2197 260.821 17.5992 262.91 15.5564C264.999 13.5135 266.657 11.0882 267.787 8.41904C268.918 5.74987 269.5 2.88908 269.5 0H247ZM202 138C211.389 138 219 130.613 219 121.5C219 112.387 211.389 105 202 105C192.611 105 185 112.387 185 121.5C185 130.613 192.611 138 202 138Z" :fill="$store.state.user.color || '#B100CE'" />
          </g>
          <defs>
            <filter id="filter0_d" x="-4" y="-3" width="277.5" height="146" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </svg>
        <img class="avatar" :src="$store.state.user.avatar || '/img/default-avatar.png'" alt="no avatar found">
        <USOptionList v-model="currentPage" :dark="dark" :options="options" />
        <USButton :dark="dark" icon-right="sign-out" primary @click="logout">Logout</USButton>
      </USSidebar>
      <FadeTransition @before-enter="$root.$emit('triggerScroll')">
        <component v-if="viewComponent" :is="viewComponent" :dark="dark" @show-changelog="$emit('show-changelog')" />
      </FadeTransition>
    </template>
  </USView>
</template>

<script>
import Drafts from '@/views/Drafts.vue';
import Favourites from '@/views/Favourites.vue';
import Profile from '@/views/Profile.vue';
import Settings from '@/views/Settings.vue';

import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  beforeDestroy() {
    if (!this.mobile) this.$store.commit('setShowSidebar', false);
  },
  components: {
    Drafts,
    Favourites,
    Profile,
    Settings,
    FadeTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
    options() {
      const options = [
        { icon: 'draft', label: 'Private Drafts', value: 'drafts' },
        { icon: 'star', label: 'Favourite Posts', value: 'favourites' },
        { icon: 'user', label: 'Profile', value: 'profile' },
        { icon: 'settings', label: 'Settings', value: 'settings' },
      ];
      if (this.mobile && ['councilmembers', 'moderators', 'admins'].some((group) => this.$store.state.user.groups.includes(group))) {
        options.push({ icon: 'lock-open', label: 'Advanced Options', value: 'advanced-options' });
      }
      return options;
    },
    page() {
      return this.$route.query.page;
    },
    viewComponent() {
      if (!this.$route.query) return '';
      const { page } = this.$route.query;
      switch (page) {
        case 'drafts':
          return 'Drafts';
        case 'favourites':
          return 'Favourites';
        case 'profile':
          return 'Profile';
        case 'settings':
          return 'Settings';
        default:
          return '';
      }
    },
  },
  created() {
    if (!this.mobile) this.$store.commit('setShowSidebar', true);
    if (!this.mobile && !this.currentPage) {
      this.$router.replace({ name: 'user', query: { page: this.options[0].value } });
      this.$nextTick(() => { this.currentPage = this.options[0].value; });
    }
  },
  data() {
    return {
      currentPage: this.$route.query.page || '',
    };
  },
  methods: {
    async goTo(name) {
      try {
        if (name === 'profile') await this.$router.push({ name, params: { id: this.$store.getters.userId } });
        else await this.$router.push({ name });
      } catch (err) {
        // swallow it, it’s a permission denied or duplicated navigation
      }
    },
    async logout() {
      await this.$feathers.logout();
      this.$router.push({ name: 'home' });
      window.setTimeout(() => {
        this.$store.commit('addToast', { message: 'You’re now logged out. See you again soon!' });
        this.$store.commit('resetUser');
      }, 200);
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    currentPage(newVal) {
      if (this.mobile) return;
      if (this.page !== newVal) this.$router.push({ query: { page: newVal } });
    },
    page(newVal) {
      if (this.currentPage !== newVal) this.currentPage = newVal;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/shadows'

.user
  > .button
    width: 100%
    margin-bottom: 1rem

    &.primary
      margin-top: 1rem

  .sidebar
    position: fixed
    left: 3.5rem
    top: 0
    z-index: 3
    display: flex
    flex-direction: column

    .avatar
      margin-left: 0.3125rem
      flex-shrink: 0

    .option-list
      margin: 0 -2rem

    .button
      width: 100%
      margin-top: auto
      flex-shrink: 0

  .view
    padding: 0

  svg
    position: absolute
    top: 0
    left: 0

  .avatar
    width: 5rem
    height: @width
    border-radius: 50%
    box-shadow: $shadow-low
    margin: 0
    margin-left: 1.3125rem
    margin-top: 2rem
    margin-bottom: 2.5rem
</style>

<template lang="html">
  <svg class="trophy-illu" :class="{ dark }" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="filled" d="M88 102H168V158C168 175.673 153.673 190 136 190H120C102.327 190 88 175.673 88 158V102Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <rect class="filled" x="80" y="100" width="96" height="12" rx="4" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled" fill-rule="evenodd" clip-rule="evenodd" d="M72.186 122C67.665 122 64 125.665 64 130.186C64 143.338 74.8479 154 88 154V148H87.7778C77.9594 148 70 140.041 70 130.222C70 128.995 70.9949 128 72.2222 128H88V122H72.186Z" />
    <path d="M88 154V155C88.5523 155 89 154.552 89 154H88ZM88 148H89C89 147.448 88.5523 147 88 147V148ZM88 128V129C88.5523 129 89 128.552 89 128H88ZM88 122H89C89 121.448 88.5523 121 88 121V122ZM65 130.186C65 126.217 68.2173 123 72.186 123V121C67.1127 121 63 125.113 63 130.186H65ZM88 153C75.3901 153 65 142.776 65 130.186H63C63 143.901 74.3058 155 88 155V153ZM87 148V154H89V148H87ZM88 147H87.7778V149H88V147ZM87.7778 147C78.5117 147 71 139.488 71 130.222H69C69 140.593 77.4071 149 87.7778 149V147ZM71 130.222C71 129.547 71.5472 129 72.2222 129V127C70.4426 127 69 128.443 69 130.222H71ZM72.2222 129H88V127H72.2222V129ZM87 122V128H89V122H87ZM72.186 123H88V121H72.186V123Z" fill="#20A2F7" />
    <path class="filled" fill-rule="evenodd" clip-rule="evenodd" d="M183.814 122C188.335 122 192 125.665 192 130.186C192 143.338 181.152 154 168 154V148H168.222C178.041 148 186 140.041 186 130.222C186 128.995 185.005 128 183.778 128H168V122H183.814Z" />
    <path d="M168 154H167C167 154.552 167.448 155 168 155V154ZM168 148V147C167.448 147 167 147.448 167 148H168ZM168 128H167C167 128.552 167.448 129 168 129V128ZM168 122V121C167.448 121 167 121.448 167 122H168ZM193 130.186C193 125.113 188.887 121 183.814 121V123C187.783 123 191 126.217 191 130.186H193ZM168 155C181.694 155 193 143.901 193 130.186H191C191 142.776 180.61 153 168 153V155ZM167 148V154H169V148H167ZM168 149H168.222V147H168V149ZM168.222 149C178.593 149 187 140.593 187 130.222H185C185 139.488 177.488 147 168.222 147V149ZM187 130.222C187 128.443 185.557 127 183.778 127V129C184.453 129 185 129.547 185 130.222H187ZM183.778 127H168V129H183.778V127ZM167 122V128H169V122H167ZM183.814 121H168V123H183.814V121Z" fill="#20A2F7" />
    <rect class="filled" x="122" y="190" width="12" height="16" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled" d="M116 210C116 207.791 117.791 206 120 206H136C138.209 206 140 207.791 140 210V214H116V210Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <rect class="filled" x="104" y="214" width="48" height="8" rx="2" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled" d="M107 156L127.785 168L119.785 181.856L113.392 168.928L99 169.856L107 156Z" stroke="#B100CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled" d="M148.785 156L128 168L136 181.856L142.392 168.928L156.785 169.856L148.785 156Z" stroke="#B100CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled" d="M138.68 123.105C136.965 120.136 133.168 119.118 130.198 120.833L128 122.102L125.802 120.833C122.832 119.118 119.035 120.136 117.32 123.105L116.051 125.304H113.513C110.084 125.304 107.304 128.084 107.304 131.513V134.051L105.105 135.32C102.136 137.035 101.118 140.832 102.833 143.802L104.102 146L102.833 148.198C101.118 151.168 102.136 154.965 105.106 156.68L107.304 157.949V160.487C107.304 163.916 110.084 166.696 113.513 166.696H116.051L117.32 168.895C119.035 171.864 122.832 172.882 125.802 171.167L128 169.898L130.198 171.167C133.168 172.882 136.965 171.864 138.68 168.895L139.949 166.696H142.487C145.916 166.696 148.696 163.916 148.696 160.487V157.949L150.894 156.68C153.864 154.965 154.882 151.168 153.167 148.198L151.898 146L153.167 143.802C154.882 140.832 153.864 137.035 150.895 135.32L148.696 134.051V131.513C148.696 128.084 145.916 125.304 142.487 125.304H139.949L138.68 123.105Z" stroke="#B100CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled" d="M136.215 128.389C134.896 126.104 131.975 125.322 129.691 126.641L128 127.617L126.309 126.641C124.025 125.322 121.104 126.104 119.785 128.389L118.809 130.08H116.856C114.218 130.08 112.08 132.218 112.08 134.856V136.808L110.389 137.785C108.104 139.104 107.322 142.025 108.641 144.309L109.617 146L108.641 147.691C107.322 149.975 108.105 152.896 110.389 154.215L112.08 155.192V157.144C112.08 159.782 114.218 161.92 116.856 161.92H118.808L119.785 163.611C121.104 165.896 124.025 166.678 126.309 165.359L128 164.383L129.691 165.359C131.975 166.678 134.896 165.896 136.215 163.611L137.192 161.92H139.144C141.782 161.92 143.92 159.782 143.92 157.144V155.191L145.611 154.215C147.895 152.896 148.678 149.975 147.359 147.691L146.383 146L147.359 144.309C148.678 142.025 147.896 139.104 145.611 137.785L143.92 136.809V134.856C143.92 132.218 141.782 130.08 139.144 130.08H137.191L136.215 128.389Z" stroke="#B100CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <g class="eye">
      <path d="M128 140C121 140 119 146 119 146C119 146 121 152 128 152C135 152 137 146 137 146C137 146 135 140 128 140Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M119 146C119 146 121 140 128 140C135 140 137 146 137 146C137 146 135 152 128 152C121 152 119 146 119 146ZM126 146C126 147.105 126.895 148 128 148C129.105 148 130 147.105 130 146C130 144.895 129.105 144 128 144C126.895 144 126 144.895 126 146Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <path d="M69 222H159M163 222H178M182 222H196M65 222H63M59 222H58" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled star one" d="M149 35L152.241 43.7589L161 47L152.241 50.2411L149 59L145.759 50.2411L137 47L145.759 43.7589L149 35Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled star two" d="M34 134L37.2411 142.759L46 146L37.2411 149.241L34 158L30.7589 149.241L22 146L30.7589 142.759L34 134Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled star three" d="M208 72L211.241 80.7589L220 84L211.241 87.2411L208 96L204.759 87.2411L196 84L204.759 80.7589L208 72Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled star four" d="M202 166L204.161 171.839L210 174L204.161 176.161L202 182L199.839 176.161L194 174L199.839 171.839L202 166Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path class="filled star five" d="M62 66L64.1607 71.8393L70 74L64.1607 76.1607L62 82L59.8393 76.1607L54 74L59.8393 71.8393L62 66Z" stroke="#20A2F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'

.trophy-illu
  display: block
  width: 100%
  max-width: 16rem
  margin: 0 auto

  &.dark
    .filled
      fill: $bg-dark

  .filled
    fill: $bg

  .eye
    transform-origin: center
    animation: blink 6s infinite

    @keyframes blink
      0%, 25%
        transform: none
      26.25%
        transform: translateY(14px) scaleY(0.1)
      27.5%, 65%
        transform: none
      66.25%
        transform: translateY(14px) scaleY(0.1)
      67.5%, 100%
        transform: none

  .star
    transform-origin: center
    opacity: 0
    animation: twinkle 2.5s ease-out infinite
    transform: scale(0.8) rotate(-5deg)

    @keyframes twinkle
      0%
        transform: scale(0.8) rotate(-5deg)
        opacity: 0
      50%
        opacity: 1
      100%
        transform: scale(1.1) rotate(5deg)
        opacity: 0

    &.one
      animation-delay: 100ms

    &.two
      animation-delay: 600ms

    &.three
      animation-delay: 1100ms

    &.four
      animation-delay: 1600ms

    &.five
      animation-delay: 2100ms
</style>

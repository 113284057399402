<template lang="html">
  <div
    class="input"
    :class="{
      clearable, dark, dirty, disabled, error: showAsError, focussed, icon, multiline, sendable,
    }"
    @click="focus">
    <div class="ink" />
    <CountTransition direction="down">
      <USIcon v-if="icon" :icon="showAsError ? 'error' : icon" :key="showAsError ? 'error' : icon" />
    </CountTransition>
    <div v-if="multiline" class="editor-scroll" :id="editorScrollId">
      <USEditor :dark="dark" :disabled="disabled" disable-tab :formats="formats" mode="markdown" placeholder="" ref="editor" :scrolling-container="`#${editorScrollId}`" :value="value" @blur="handleBlur" @focus="handleFocus" @input="$emit('input', $event)" @keyup.native.ctrl.enter="delayedSend" @selectionchange="handleSelectionChange" />
    </div>
    <input v-else autocomplete="off" :disabled="disabled" :placeholder="focussed && placeholder" ref="input" :type="type" :value="value" @blur="handleBlur" @focus="handleFocus" @input="$emit('input', $event.target.value)" @keyup.enter="sendable && !showAsError ? $emit('send') : null">
    <label>{{combinedLabel}}</label>
    <FadeTransition>
      <USIconButton v-if="actionButton" :dark="dark" :disabled="!dirty || Boolean(showAsError) || disabled" :icon="actionButton" :loading="sendLoading || loading" ref="actionButton" @click="clearable ? $emit('input', '') : $emit('send')" />
    </FadeTransition>
  </div>
</template>

<script>
import CountTransition from '@/transitions/CountTransition.vue';
import FadeTransition from '@/transitions/FadeTransition.vue';

import generateUUID from '@/scripts/generateUUID';

export default {
  components: {
    CountTransition,
    FadeTransition,
  },
  computed: {
    actionButton() {
      if (this.clearable && this.dirty) return 'cross';
      if (this.sendable) return 'send';
      return false;
    },
    combinedLabel() {
      if (this.error) return this.error;
      if (!this.maxLen || !this.dirty) return this.label;
      return `${this.label} (${this.value.length}/${this.maxLen})`;
    },
    dirty() {
      return (this.value && this.value.length > 0) || (typeof this.value === 'number');
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
    showAsError() {
      return (!this.focussed && this.error) || (this.maxLen && this.value.length > this.maxLen);
    },
  },
  data() {
    return {
      editorScrollId: `_${generateUUID()}`,
      focussed: false,
      scrollingParent: false,
      sendLoading: false,
    };
  },
  methods: {
    blur() {
      if (this.multiline) this.$refs.editor.quill.blur();
      else this.$rfs.input.blur();
    },
    delayedSend() {
      if (this.sendable && !this.showAsError) {
        this.$refs.editor.quill.blur();
        this.sendLoading = true;
        window.setTimeout(() => { this.$emit('send'); this.sendLoading = false; }, 600); // so the debounce has time to fire
      }
    },
    focus(e) {
      if (this.sendable && e && (this.$refs.actionButton.$el.contains(e.target) || this.$refs.actionButton.$el === e.target)) return;
      if (this.$refs.input) this.$refs.input.focus();
      if (this.$refs.editor && (!e || !this.$refs.editor.$el.contains(e.target))) this.$refs.editor.$el.click();
    },
    handleBlur(e) {
      this.focussed = false;
      this.$emit('blur', e);
    },
    handleFocus(e) {
      this.focussed = true;
      this.$emit('focus', e);
    },
    handleSelectionChange() {
      if (!this.focussed) return;
      const containerRect = this.$el.getBoundingClientRect();
      const parent = this.$el.parentNode;
      if (this.scrollingParent && containerRect.bottom > parent.offsetHeight - 4 * this.remBase) {
        parent.scrollTo(0, parent.scrollTop + (containerRect.bottom - parent.offsetHeight) + 12 * this.remBase);
      } else if (containerRect.bottom > window.innerHeight - 8 * this.remBase) window.scrollTo(0, Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + (containerRect.bottom - window.innerHeight) + 12 * this.remBase);
    },
  },
  mounted() {
    if (this.autofocus) this.$nextTick(this.focus);
    const parentComputedStyle = window.getComputedStyle(this.$el.parentNode);
    this.scrollingParent = parentComputedStyle.overflowY && !parentComputedStyle.overflowY.includes('hidden') && !parentComputedStyle.overflowY.includes('visible');
  },
  props: {
    autofocus: Boolean,
    clearable: Boolean,
    dark: Boolean,
    disabled: Boolean,
    error: String,
    formats: {
      type: Array,
      default() {
        return [
          'blockquote',
          'bold',
          'header',
          'image',
          'indent',
          'italic',
          'linebreak',
          'link',
          'list',
          'strike',
        ];
      },
    },
    icon: String,
    label: {
      type: String,
      default: 'Type something',
    },
    loading: Boolean,
    maxLen: Number,
    multiline: Boolean,
    placeholder: String,
    scrollingContainer: HTMLElement,
    sendable: Boolean,
    type: {
      type: String,
      default: 'text',
      validator(v) {
        return ['text', 'number', 'password', 'search', 'email'].includes(v);
      },
    },
    value: [String, Number],
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.input
  border: 1px solid $interactable
  box-shadow: $shadow-low
  border-radius: 0.75rem
  display: inline-flex
  vertical-align: middle
  align-items: center
  padding: calc(1rem - 1px)
  cursor: text
  background-color: $bg
  position: relative
  max-width: 100%
  overflow: hidden
  width: 16rem
  transition: box-shadow 200ms ease, border-color 200ms ease

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark
    color: $text-primary-dark

    label
      color: $text-secondary-dark

    input::placeholder
      color: $text-secondary-dark

    // .editor-scroll
    //   background-color: $bg-dark // uncomment if the scrollbars appear on other operating systems and aren’t in the right color

  &:hover,
  &.focussed
    box-shadow: $shadow-high
    border-color: $accent-primary

  &.focussed
    .ink
      transform: scale(1.4142135624)

    // .editor-scroll
    //   background-color: mix($accent-primary, $bg, 10)
    //
    // &.dark .editor-scroll
    //   background-color: mix($accent-primary, $bg-dark, 10)

  &.focussed,
  &.dirty
    input
      transform: translateY(0.625rem)
    .editor-scroll
      top: 0.625rem
    label
      transform: translateY(-0.625rem) scale(0.875)

  &.clearable,
  &.sendable
    width: 14.75rem

    input
      margin-right: 2.5rem

    .editor-scroll
      margin-right: 1.5rem

    label
      right: 3.5rem

  &.icon
    width: 15.625rem

    input
      margin-left: 0.875rem

    .editor-scroll
      margin-left: -0.125rem

    label
      left: 3.375rem

  &.multiline
    .icon-button
      top: unset
      bottom: calc(0.375rem - 1px)

  &.error
    color: $negative

    &:hover,
    &.focussed
      border-color: $negative

    .ink
      background-color: alpha($negative, 0.1)

    // &.focussed .editor-scroll
    //   background-color: mix($negative, $bg, 10)
    //
    // &.dark.focussed .editor-scroll
    //   background-color: mix($negative, $bg-dark, 10)

    label
      color: $negative

  &.disabled
    border-color: $interactable-disabled
    box-shadow: none
    pointer-events: none
    color: $text-disabled

    &.dark
      border-color: $interactable-disabled-dark
      color: $text-disabled-dark

      label
        color: $text-disabled-dark

    label
      color: $text-disabled

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  .icon
    vertical-align: top
    align-self: flex-start

  .editor-scroll
    width: calc(100% + 2rem)
    min-height: 1.5rem
    max-height: 7rem
    overflow-y: auto
    padding: 0 1rem
    margin: 0 -1rem
    position: relative
    background-color: transparent
    scroll-behavior: smooth
    scrollbar-width: none // hide bar in firefox

    &::-webkit-scrollbar
      width: 0px

  .editor
    width: 100%
    background-color: transparent
    background-clip: content-box
    overflow: hidden

  input
    background-color: transparent
    border: none
    text-shadow: $text-shadow
    height: 1.5rem
    margin: 0
    padding: 0
    text-overflow: ellipsis
    color: inherit
    min-width: 0
    width: 100%
    appearance: none
    user-select: auto

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration
      appearance: none
      display: none
      margin: 0

    &[type="number"]
      -moz-appearance: textfield

    &::placeholder
      color: $text-secondary

  label
    pointer-events: none
    color: $text-secondary
    position: absolute
    left: calc(1rem - 1px)
    top: @left
    right: @left
    height: 1.5rem
    line-height: @height
    transform-origin: top left
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    transition: transform 200ms ease

  .icon-button
    position: absolute
    top: calc(0.375rem - 1px)
    right: @top
    bottom: @top
    width: 2.75rem
    height: @width
    border-radius: 0.375rem
    padding: calc(0.375rem - 1px)
    box-shadow: none
</style>

<template lang="html">
  <button :aria-label="`Switch ${active ? 'on' : 'off'}`" class="switch" :class="{ active, dark }" :disabled="disabled" type="button" @click="$emit('input', !active)">
    <div class="bubble" />
  </button>
</template>

<script>
export default {
  data() {
    return {
      active: this.value,
    };
  },
  props: {
    dark: Boolean,
    disabled: Boolean,
    value: Boolean,
  },
  watch: {
    value(newVal) {
      this.active = newVal;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.switch
  width: 2.5rem
  height: 1.5rem
  padding: 0.125rem
  border: 1px solid $interactable
  border-radius: 0.75rem
  box-shadow: $shadow-low
  background-color: $bg
  cursor: pointer
  transition: box-shadow 200ms ease, background-color 200ms ease, border-color 200ms ease

  &.dark
    border-color: $interactable-dark
    background-color: $bg-dark

    &:disabled
      border-color: $interactable-disabled-dark

      &.active
        background-color: $text-disabled-dark

        .bubble
          background-color: $bg-dark

      .bubble
        background-color: $text-disabled-dark

  &:hover,
  &:focus
    box-shadow: $shadow-high
    border-color: $accent-primary

  &.active
    background-color: $accent-primary
    border-color: @background-color

    .bubble
      background-color: $bg
      border-color: $interactable
      transform: translateX(1rem)

  &:disabled
    pointer-events: none
    border-color: $interactable-disabled
    box-shadow: none

    &.active
      background-color: $text-disabled
      background-clip: padding-box

      .bubble
        background-color: $bg

    .bubble
      box-shadow: none
      border-color: transparent
      background-color: $text-disabled

  .bubble
    width: 1.125rem
    height: @width
    border-radius: 50%
    border: 1px solid $accent-primary
    background-color: $accent-primary
    box-shadow: $shadow-low
    transition: background-color 200ms ease, transform 200ms ease, border-color 200ms ease
</style>

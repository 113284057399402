import { Date as SugarDate } from 'sugar-date';

export default (date, shortForm) => {
  const d = SugarDate.create(date);
  if (shortForm) {
    return SugarDate.relative(d)
      .replace(/second[s]?/g, 's')
      .replace(/minute[s]?/g, 'min')
      .replace(/hour[s]?/g, 'h')
      .replace(/day[s]?/g, 'd')
      .replace(/week[s]?/g, 'w')
      .replace(/month[s]?/g, 'm')
      .replace(/year[s]?/g, 'y')
      .replace(' ', '');
  }
  return SugarDate.relative(d);
};

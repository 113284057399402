<template lang="html">
  <USView v-show="initialized" class="profile" :dark="dark" show-back>
    <FadeTransition>
      <USActionBar v-show="$store.state.application.showActionBars && (isOwner || isModOrAdmin || isCouncilmember)" :actions="actions" :dark="dark" :vertical="!mobile" />
    </FadeTransition>
    <header :class="{ mobile, 'no-description': !description }">
      <img class="avatar" :src="avatar || '/img/default-avatar.png'" alt="no avatar found">
      <section>
        <h1>{{name | capitalize}}</h1>
        <span v-if="title" class="title">{{title}}</span>
      </section>
    </header>
    <article v-show="description" class="description" :class="{ mobile }" v-html="$options.filters.markdown(description)" />
    <div class="chip-wrapper">
      <h1 v-if="!isOwner">Posts by {{name | firstName}}</h1>
      <h1 v-else>Your Posts</h1>
      <USChip :content="`${postsTotal} Post${postsTotal === 1 ? '' : 's'}`" :dark="dark" prominent />
    </div>
    <ListTransition>
      <template v-if="posts.length > 0 || nextPageAvailable.posts">
        <USPostCard v-for="post in posts" :dark="dark" :key="post._id" :post="post" @click="openPost($event, { params: { id: post._id }, query: { list: post.author._id, listType: 'user' } })" @mousedown="openPost($event, { params: { id: post._id }, query: { list: post.author._id, listType: 'user' } })" />
        <USButton v-show="initialized && nextPageAvailable.posts" :dark="dark" key="loadMoreButton" :loading="postsLoading" @click="fetchPostsPage">Load More</USButton>
      </template>
      <section v-else class="empty-state" key="emptyState">
        <p v-if="isOwner">You haven’t published any posts yet.</p>
        <p v-else>{{ name | firstName }} hasn’t published any posts yet.</p>
        <USButton v-if="isOwner && !$store.state.user.groups.includes('applicants')" :dark="dark" icon-left="plus" @click="$router.push({ name: 'write' })">Write One</USButton>
      </section>
    </ListTransition>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: saveProfile, disabled: formErrors, icon: 'send', label: 'save',
      }]"
      :dark="dark"
      title="Edit Profile"
      :visible="modals.editProfile"
      @close="modals.editProfile = false">
      <h2>Avatar</h2>
      <section class="avatar" :class="{ desktop: !mobile }">
        <USColorPicker v-model="color" :dark="dark" label="Avatar Colour" />
        <section class="text-color">
          <span>Letter Color:</span>
          <USSelectBox v-model="forceTextColor" :dark="dark" :options="[{ label: 'Auto', value: 'auto' }, { label: 'Light', value: 'light' }, { label: 'Dark', value: 'dark' }]" />
        </section>
      </section>
      <h2>Info</h2>
      <USInput v-model="name" :dark="dark" :error="errors.name" icon="user" label="Name or Pseudonym" :max-len="32" @blur="validate('name')" />
      <USInput v-model="description" :dark="dark" :error="errors.description" :formats="['bold', 'italic', 'linebreak', 'link', 'list', 'strike']" icon="description" label="Description (optional)" :max-len="1024" multiline @blur="validate('description')" />
    </USModal>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: saveTitle, disabled: formErrors, icon: 'send', label: 'save',
      }]"
      :dark="dark"
      :title="title ? 'Edit Title' : 'Set Title'"
      :visible="modals.editTitle"
      @close="modals.editTitle = false">
      <USInput v-model="title" clearable :dark="dark" :error="errors.title" icon="title" label="Title" :max-len="256" @blur="validate('title')" />
    </USModal>
    <USModal :actions="null" :dark="dark" title="Manage Groups" :visible="modals.manageGroups" @close="modals.manageGroups = false">
      <ListTransition>
        <USSelectableCard v-for="group in groups" :content="`${groupMembers[group._id] || '0'} members`" :dark="dark" icon="group" :key="group._id" :selected="userGroups.includes(group._id)" :title="group.name" @click="toggleGroup(group._id)" />
        <USButton v-if="nextPageAvailable.groups" :dark="dark" key="loadMoreButton" :loading="groupsLoading" @click="fetchGroupsPage">Load More</USButton>
      </ListTransition>
    </USModal>
    <USModal :actions="[{ icon: 'cross', label: 'cancel' }, { action: deleteAccount, icon: 'delete', label: 'delete user' }]" :dark="dark" title="Delete User" :visible="modals.deleteUser" @close="modals.deleteUser = false">
      <p>You’re about to <strong>permanently</strong> delete this user from Untold Stories. This will <strong>remove all their posts</strong> and cause their other interactions on the website to appear as “Deleted User”.</p>
      <p><strong>Please keep in mind that this step cannot be undone and should only happen as a last resort to handle a dangerous or harmful user!</strong></p>
    </USModal>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';
import generateAvatar from '@/scripts/generateAvatar';

import capitalize from '@/filters/capitalize';
import firstName from '@/filters/firstName';
import markdown from '@/filters/markdown';

import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

import openPost from '@/mixins/openPost';

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      let user;
      if (!(to.name === 'user' && to.query.page === 'profile')) {
        user = await Feathers.service('users').get(to.params.id, { query: { $select: ['_id', 'avatar', 'color', 'description', 'groups', 'name', 'title'] } });
      }
      const { data: posts, total } = await Feathers.service('posts').find({
        query: {
          author: to.params.id,
          $limit: 15,
          $sort: { createdAt: -1 },
        },
      });
      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.avatar = user.avatar || '';
        vm.color = user.color || '';
        vm.currentPage.posts += 1;
        vm.description = user.description || '';
        vm.initialized = true;
        vm.name = vm.$options.filters.capitalize(user.name) || '';
        vm.posts = posts;
        vm.postsTotal = total;
        vm.nextPageAvailable.posts = vm.currentPage.posts < Math.ceil(total / vm.pageSize);
        vm.title = user.title || '';
        vm.userGroups = user.groups;
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      else if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  components: {
    FadeTransition,
    ListTransition,
  },
  computed: {
    actions() {
      const actions = [];
      if (this.isOwner) actions.push({ action: () => this.showModal('editProfile'), icon: 'pencil', tooltip: 'Edit Profile' });
      if (this.isCouncilmember || this.isModOrAdmin) actions.push({ action: () => this.showModal('editTitle'), icon: 'title', tooltip: 'Set/Edit Title' });
      if (this.isModOrAdmin) {
        actions.push({ action: () => this.showModal('manageGroups'), icon: 'group', tooltip: 'Manage Groups' });
        if (!this.isProfileSettings) actions.push({ action: () => this.showModal('deleteUser'), icon: 'delete', tooltip: 'Delete User' });
      }
      return actions;
    },
    formErrors() {
      const errors = Object.values(this.errors);
      return errors.some((err) => err !== '');
    },
    isCouncilmember() {
      return this.$store.state.user.groups.includes('councilmembers');
    },
    isModOrAdmin() {
      return ['moderators', 'admins'].some((group) => this.$store.state.user.groups.includes(group));
    },
    isOwner() {
      if (this.isProfileSettings) return true;
      return this.$route.params.id === this.$store.getters.userId;
    },
    isProfileSettings() {
      if (this.$route.name === 'user' && this.$route.query.page === 'profile') return true;
      return false;
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  async created() {
    if (this.isProfileSettings) {
      await this.fetchPostsPage();
      this.initialized = true;
    }
  },
  data() {
    return {
      avatar: this.$store.state.user.avatar || '',
      color: this.$store.state.user.color || '',
      currentPage: {
        groups: 0,
        posts: 0,
      },
      description: this.$store.state.user.description || '',
      errors: {
        description: '',
        name: '',
        title: '',
      },
      forceTextColor: 'auto',
      groupMembers: {},
      groups: [],
      groupsLoading: false,
      initialized: false,
      modals: {
        deleteUser: false,
        editProfile: false,
        editTitle: false,
        manageGroups: false,
      },
      name: this.$options.filters.capitalize(this.$store.state.user.name) || '',
      nextPageAvailable: {
        groups: true,
        posts: true,
      },
      pageSize: 15,
      posts: [],
      postsLoading: false,
      postsTotal: 0,
      title: this.$store.state.user.title || '',
      userGroups: this.$store.state.user.groups || [],
    };
  },
  filters: {
    capitalize,
    firstName,
    markdown,
  },
  methods: {
    async deleteAccount() {
      try {
        await this.$feathers.service('users').remove(this.isProfileSettings ? this.$store.state.userId : this.$route.params.id);
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => this.showModal('deleteUser'),
          actionLabel: 'Try again',
          message: `Could not delete account: ${err.message}`,
          type: 'negative',
        });
      }
    },
    async fetchGroupsPage() {
      const timeoutId = window.setTimeout(() => { this.groupsLoading = true; }, 200);
      try {
        const { data: groups, total } = await this.$feathers.service('groups').find({
          query: {
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage.groups,
            $sort: { name: 1 },
          },
        });

        for (let i = 0; i < groups.length; i += 1) {
          const group = groups[i];
          ({ total: this.groupMembers[group._id] } = await this.$feathers.service('users').find({ query: { groups: group._id, $limit: 0 } })); // eslint-disable-line no-await-in-loop
        }

        this.groups = this.groups.concat(groups);
        this.currentPage.groups += 1;
        this.nextPageAvailable.groups = this.currentPage.groups < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more groups: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.groupsLoading = false;
    },
    async fetchPostsPage() {
      const timeoutId = window.setTimeout(() => { this.postsLoading = true; }, 200);
      try {
        const { data: posts, total } = await this.$feathers.service('posts').find({
          query: {
            author: this.isProfileSettings ? this.$store.getters.userId : this.$route.params.id,
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage.posts,
            $sort: { createdAt: -1 },
          },
        });
        this.posts = this.posts.concat(posts);
        this.currentPage.posts += 1;
        this.nextPageAvailable.posts = this.currentPage.posts < Math.ceil(total / this.pageSize);
        this.postsTotal = total;
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more posts: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.postsLoading = false;
    },
    async saveProfile() {
      this.validate('name');
      this.validate('description');

      if (this.errors.title) this.errors.title = '';

      if (this.formErrors) {
        this.showModal('editProfile');
        return;
      }

      let initials = this.name.trim().slice(0, 2).toUpperCase();
      if (this.name.trim().includes(' ')) initials = this.name.split(' ')[0][0].toUpperCase() + this.name.split(' ')[1][0].toUpperCase();
      const avatar = generateAvatar(initials, this.color, this.forceTextColor);

      try {
        ({
          avatar: this.avatar, color: this.color, description: this.description, name: this.name,
        } = await this.$feathers.service('users').patch(this.$store.getters.userId, {
          avatar,
          color: this.color,
          description: this.description,
          name: this.name,
        }));
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => this.showModal('editProfile'),
          actionLabel: 'Try again',
          message: `Could not save profile: ${err.message}`,
          type: 'negative',
        });
      }
    },
    async saveTitle() {
      this.validate('title');

      if (this.errors.name) this.errors.name = '';
      if (this.errors.description) this.errors.description = '';

      if (this.formErrors) {
        this.showModal('editTitle');
        return;
      }

      try {
        ({ title: this.title } = await this.$feathers.service('users').patch(this.isProfileSettings ? this.$store.getters.userId : this.$route.params.id, { title: this.title }));
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => this.showModal('editTitle'),
          actionLabel: 'Try again',
          message: `Could not save title: ${err.message}`,
          type: 'negative',
        });
      }
    },
    async showModal(modal) {
      this.errors = {
        description: '',
        name: '',
        title: '',
      };

      if (modal === 'manageGroups' && this.groups.length === 0) {
        await this.fetchGroupsPage();
      }

      this.modals[modal] = true;
    },
    async toggleGroup(id) {
      try {
        const index = this.userGroups.indexOf(id);
        if (index > -1) {
          await this.$feathers.service('users').patch(this.isProfileSettings ? this.$store.getters.userId : this.$route.params.id, { $pull: { groups: id } });
          this.userGroups.splice(index, 1);
          this.groupMembers[id] -= 1;
        } else {
          await this.$feathers.service('users').patch(this.isProfileSettings ? this.$store.getters.userId : this.$route.params.id, { $addToSet: { groups: id } });
          this.userGroups.push(id);
          this.groupMembers[id] += 1;
        }
      } catch (err) {
        this.$store.commit('addToast', { message: `Could toggle group: ${err.message}`, type: 'negative' });
      }
    },
    validate(field) {
      let error = '';

      switch (field) {
        case 'description':
          if (this.description.length > 1024) error = 'Description is too long';
          this.errors.description = error;
          break;
        case 'name':
          if (!this.name || !this.name.trim()) error = 'Name is required';
          if (this.name.length < 2) error = 'Name is too short';
          if (this.name.length > 32) error = 'Name is too long';
          this.errors.name = error;
          break;
        case 'title':
          if (this.title.length > 256) error = 'Title is too long';
          this.errors.title = error;
          break;
        default:
      }
    },
  },
  mixins: [openPost],
  props: {
    dark: Boolean,
  },
  watch: {
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'user', query: { page: 'profile' } });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/shadows'

.profile
  > header
    display: flex
    align-items: center
    margin-bottom: 1.5rem

    &.mobile
      flex-direction: column

      .avatar
        margin: 0
        margin-bottom: 1rem

      > section
        text-align: center

    &.no-description
      margin-bottom: 3rem

    .avatar
      width: 5rem
      height: @width
      border-radius: 50%
      box-shadow: $shadow-low
      margin: 0
      margin-right: 2rem

    h1
      margin-bottom: 0
      color: currentColor

    .title
      font-family: 'PT Serif', serif
      font-style: italic

  .description
    margin-bottom: 6rem

    &.mobile
      margin-bottom: 3rem

  .chip-wrapper
    display: flex
    align-items: center
    margin-bottom: 1.5rem

    h1
      margin-bottom: 0
      margin-right: auto

    .chip
      flex-shrink: 0

  .button
    display: flex
    margin: 0 auto
    margin-top: 1.5rem

  .post-card:not(:last-of-type)
    margin-bottom: 1.5rem

  .empty-state
    margin-bottom: 0

    p
      font-weight: 800
      opacity: 0.6
      text-align: center

  .modal
    .chip-input
      width: 100%

    .input
      width: 100%

      &:not(:last-of-type)
        margin-bottom: 1rem

    .selectable-card
      &:not(:last-child)
        margin-bottom: 1rem

      >>> h2
        text-transform: capitalize

    .avatar
      display: flex
      flex-wrap: wrap
      align-items: center
      margin-bottom: 2rem

      &.desktop
        .color-picker
          width: calc(50% - 1rem)
          margin-right: 2rem
          margin-bottom: 0

        .text-color
          width: calc(50% - 1rem)

      .color-picker
        width: 100%
        margin-bottom: 1rem

      .text-color
        width: 100%
        display: flex
        align-items: center

        .select-box
          margin-left: auto
          flex-shrink: 0
</style>

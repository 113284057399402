<template lang="html">
  <transition name="tooltip">
    <div v-show="visible && !hidden" class="tooltip" :style="{ transform }">
      <USChip class="tooltip-chip" :content="content" no-transition />
      <span class="fitter">{{content}}</span>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    hidden() { // HACK: this hides the tooltip if it’s untransformed (because the target is invalid or not visible)
      return this.transform === 'translate(0, 0)' || this.transform === 'translate(0px, 0px)';
    },
    remBase() {
      return this.$store.state.application.remBase;
    },
  },
  data() {
    return {
      targetElement: null,
      transform: 'translate(0, 0)',
    };
  },
  methods: {
    update() {
      if (!this.targetElement) {
        if (this.target && typeof target === 'string') this.targetElement = document.querySelector(this.target) || this.$el.parentElement;
        else if (typeof this.target !== 'undefined') this.targetElement = this.target;
        else this.targetElement = this.$el.parentElement;
      }

      const targetRect = this.targetElement.getBoundingClientRect();
      const { width: ownWidth, height: ownHeight } = this.$el.getBoundingClientRect();
      const margin = 0.5 * this.remBase;
      const forceSide = this.forceSide || (targetRect.left + targetRect.width / 2) - ownWidth / 2 < 0;
      let x = '0';
      let y = '0';
      if (!forceSide && targetRect.bottom + margin + ownHeight < window.innerHeight) {
        x = `calc(${Math.round(targetRect.left + targetRect.width / 2)}px - 50%)`;
        y = `${targetRect.bottom + margin}px`;
      } else if (!forceSide && targetRect.top - margin - ownHeight > 0) {
        x = `calc(${Math.round(targetRect.left + targetRect.width / 2)}px - 50%)`;
        y = `${targetRect.top - margin - ownHeight}px`;
      } else if (targetRect.right + margin + ownWidth < window.innerWidth) {
        x = `${targetRect.right + margin}px`;
        y = `calc(${targetRect.top + targetRect.height / 2}px - 50%)`;
      } else {
        x = `${targetRect.left - margin - ownWidth}px`;
        y = `calc(${targetRect.top + targetRect.height / 2}px - 50%)`;
      }
      this.transform = `translate(${x}, ${y})`;
    },
  },
  mounted() {
    if (this.visible) this.update();
  },
  props: {
    content: String,
    forceSide: Boolean,
    target: [String, HTMLElement],
    visible: Boolean,
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.targetElement = null;
        this.$nextTick(this.update);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.tooltip
  pointer-events: none
  position: fixed
  top: 0
  left: 0
  z-index: 9

  &.tooltip-enter-active,
  &.tooltip-leave-active
    transition: opacity 200ms ease

    &.tooltip-enter,
    &.tooltip-leave-to
      opacity: 0

  >>> .tooltip-chip
    box-shadow: $shadow-high
    background-color: $elevation-secondary-dark
    color: $text-primary-dark
    font-size: 1rem
    cursor: default
    margin: 0
    position: absolute
    width: 100%

  .fitter
    padding: 0.125rem 0.75rem
    font-weight: 800
    font-size: 1rem
    text-transform: capitalize
    visibility: hidden
</style>

/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import Feathers from '@/feathersApp';
import urlBase64ToUint8Array from '@/scripts/urlBase64ToUint8Array';

function lockBody(value) {
  // lock the scroll of the body
  if (value) {
    document.documentElement.style.setProperty('overflow', 'hidden');
    document.body.style.setProperty('overflow', 'hidden');
  } else {
    document.documentElement.style.removeProperty('overflow');
    document.body.style.removeProperty('overflow');
  }
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    application: {
      darkTheme: (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || false,
      forceHiddenMenu: true,
      installPrompt: null,
      loading: false,
      loadingTimeout: null,
      mobile: true,
      modalVisible: false,
      notificationAmount: 0,
      previousRoute: null,
      reauthAttemptMade: false,
      remBase: 16,
      serviceWorker: null,
      showActionBars: true,
      showForumNotificationDot: false,
      showNotificationBar: false,
      showSidebar: false,
      softDeleted: [],
      toasts: [],
      tooltip: null,
      tooltipTimeout: null,
    },
    user: {
      _id: '',
      avatar: '',
      bookmark: null,
      color: '',
      createdAt: 0,
      darkThemePreference: 'auto',
      email: '',
      filteredAuthors: [],
      filteredTags: [],
      groups: [],
      isVerified: false,
      lastActive: 0,
      name: '',
    },
  },
  getters: {
    isModerator(state) {
      return state.user.groups.includes('moderators');
    },
    isSoftDeleted(state) {
      return (id) => state.application.softDeleted.includes(id);
    },
    userId(state) {
      return state.user._id;
    },
  },
  mutations: {
    addToast(state, toast) {
      if (!toast.id) toast.id = `_${Math.random().toString(36).substr(2, 9)}${state.application.toasts.length}`;
      state.application.toasts.push(toast);
      if (state.application.toasts.length > 4) state.application.toasts.shift();
    },
    addToSoftDelete(state, id) {
      if (!state.application.softDeleted.includes(id)) state.application.softDeleted.push(id);
    },
    deleteToast(state, id) {
      const index = state.application.toasts.findIndex((toast) => toast.id === id);
      if (index > -1) state.application.toasts.splice(index, 1);
    },
    removeFromSoftDelete(state, id) {
      const index = state.application.softDeleted.indexOf(id);
      if (index > -1) state.application.softDeleted.splice(index, 1);
    },
    resetUser(state) {
      state.user = {
        _id: '',
        avatar: '',
        bookmark: null,
        color: '',
        createdAt: 0,
        darkThemePreference: 'auto',
        email: '',
        groups: [],
        isVerified: false,
        lastActive: 0,
        name: '',
      };
    },
    setApplicationLoading(state, value) {
      state.application.loading = value;
    },
    setBodyLock(state, value) {
      lockBody(value);
    },
    setDarkTheme(state, value) {
      state.application.darkTheme = value;
    },
    setForceHiddenMenu(state, value) {
      state.application.forceHiddenMenu = value;
    },
    setInstallPrompt(state, value) {
      state.application.installPrompt = value;
    },
    setLoadingTimeout(state, value) {
      state.application.loadingTimeout = value;
    },
    setMobile(state, value) {
      state.application.mobile = value;
    },
    setModalVisible(state, value) {
      state.application.modalVisible = value;
      lockBody(value);
    },
    setNotificationAmount(state, value) {
      state.application.notificationAmount = value;
    },
    setPreviousRoute(state, value) {
      state.application.previousRoute = value;
    },
    setReAuthAttemptMade(state, value) {
      state.application.reauthAttemptMade = value;
    },
    setRemBase(state, value) {
      const currentValue = state.application.remBase;
      state.application.remBase = value;
      window.localStorage.setItem('remBase', value);
      if (currentValue !== 16 || value !== 16) document.documentElement.style.setProperty('font-size', `${value}px`);
    },
    setServiceWorkerRegistration(state, value) {
      state.application.serviceWorker = value;
    },
    setShowActionBars(state, value) {
      state.application.showActionBars = value;
    },
    setShowForumNotificationDot(state, value) {
      state.application.showForumNotificationDot = value;
    },
    setShowNotificationBar(state, value) {
      state.application.showNotificationBar = value;
      lockBody(value);
    },
    setShowSidebar(state, value) {
      state.application.showSidebar = value;
    },
    setTooltip(state, tooltip) {
      if (tooltip === null && state.application.tooltipTimeout) {
        window.clearTimeout(state.application.tooltipTimeout);
        state.application.tooltipTimeout = null;
      }
      state.application.tooltip = tooltip;
    },
    setTooltipTimeout(state, timeout) {
      state.application.tooltipTimeout = timeout;
    },
    setUser(state, user) {
      // user might be incomplete, only set fields that are defined
      const userFields = Object.keys(user);

      userFields.forEach((field) => {
        state.user[field] = user[field];
      });

      switch (user.darkThemePreference) {
        case 'auto':
          state.application.darkTheme = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || false;
          break;
        case 'light':
          state.application.darkTheme = false;
          break;
        case 'dark':
          state.application.darkTheme = true;
          break;
        default:
      }
    },
  },
  actions: {
    setShowNotificationBar({ commit, state }, value) {
      commit('setShowNotificationBar', value);

      if (value === true && window.localStorage && window.localStorage.getItem('notificationsPrompted') !== 'true' && state.application.serviceWorker) {
        commit('addToast', {
          action: async () => {
            let permission;
            if (Notification && (Notification.permission === 'default' || !Notification.permission)) permission = await Notification.requestPermission();
            else if (Notification) permission = Notification.permission;

            if (permission === 'granted') {
              try {
                const registration = state.application.serviceWorker;
                const subscription = await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_PUSH_PUBLIC_KEY),
                });
                await Feathers.service('push-subscriptions').create({ notificationsFor: ['commentReplies', 'comments', 'favourites', 'likes', 'forum', 'forumReplies', 'newEvents', 'writingPrompts'], ...JSON.parse(JSON.stringify(subscription)) }); // so it’s an object again
                commit('addToast', { message: 'Great! You should now receive push notifications on your device. You can adjust which ones in the settings', type: 'positive' });
              } catch (err) {
                commit('addToast', { message: `Could not enable push notifications: ${err.message}`, timeout: 5000, type: 'negative' });
              }
              commit('addToast', { message: 'Great! You should now receive push notifications on your device. You can adjust which ones in the settings', type: 'positive' });
            }
            if (permission === 'default') commit('addToast', { message: 'If you change your mind at a later date, you can enable push notifications from the settings' });
            if (permission === 'denied') commit('addToast', { message: 'You have permanently blocked notifications from this website. If you want to change your mind, you’ll have to unblock Untold Stories from your browser’s settings', timeout: 5000, type: 'negative' });
          },
          actionLabel: 'Enable',
          message: 'Would you like to enable notifications on your device even if the website isn’t open? You can always adjust your choice later in the settings',
          timeout: -1,
        });
        window.localStorage.setItem('notificationsPrompted', true);
      }
    },
    setTooltip({ commit, state }, tooltip) {
      if (state.application.tooltipTimeout) window.clearTimeout(state.application.tooltipTimeout);
      if (state.application.tooltip) commit('setTooltip', null);

      const timeout = tooltip.timeout >= 0 ? tooltip.timeout : 1000;

      const timeoutId = window.setTimeout(() => {
        commit('setTooltip', tooltip);
        commit('setTooltipTimeout', null);
      }, timeout);

      commit('setTooltipTimeout', timeoutId);
    },
    startApplicationLoading({ commit, state }) {
      if (state.application.loadingTimeout) {
        window.clearTimeout(state.application.loadingTimeout);
        commit('setLoadingTimeout', null);
      }
      const timeoutId = window.setTimeout(() => commit('setApplicationLoading', true), 1000);
      commit('setLoadingTimeout', timeoutId);
    },
    stopApplicationLoading({ commit, state }) {
      if (state.application.loadingTimeout) {
        window.clearTimeout(state.application.loadingTimeout);
        commit('setLoadingTimeout', null);
      }
      commit('setApplicationLoading', false);
    },
  },
});

<template lang="html">
  <div class="tab-bar" :class="{ dark }">
    <USButton v-for="(tab, index) in tabs" :class="{ active: value === tab.value }" :dark="dark" :disabled="tab.disabled" :key="index" @click="$emit('input', tab.value)">{{tab.label || tab.value}}</USButton>
  </div>
</template>

<script>
export default {
  props: {
    dark: Boolean,
    tabs: {
      type: Array,
      required: true,
    },
    value: [String, Number],
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.tab-bar
  display: flex
  background-color: $bg
  padding: 0.375rem
  border: 1px solid $interactable
  border-radius: 0.75rem
  box-shadow: $shadow-low
  transition: box-shadow 200ms ease, background-color 200ms ease

  &:hover
    box-shadow: $shadow-high

    &.dark
      background-color: $elevation-primary-dark

  &.dark
    background-color: $bg-dark
    border-color: $interactable-dark

    .button:disabled.active
      border-color: $interactable-disabled-dark

      >>> .ink
        background-color: $interactable-disabled-dark

  .button
    width: 100%
    border-radius: 0.375rem
    border-color: transparent
    padding: calc(0.375rem - 1px) calc(1rem - 1px)
    box-shadow: none

    &:not(:last-child)
      margin-right: 0.375rem

    &:hover,
    &:focus
      border-color: $accent-primary

    &:disabled
      border-color: transparent

      &.active
        border-color: $interactable-disabled
        font-weight: normal
        box-shadow: none

        >>> .ink
          background-color: $interactable-disabled

    &.active
      border-color: $accent-primary
      box-shadow: $shadow-low
      color: $text-primary-dark
      font-weight: 800

      >>> .ink
        background-color: $accent-primary
        transform: scale(1.4142135624)

      &:focus >>> .ink
        background-color: darken($accent-primary, 10)
</style>

<template lang="html">
  <button
    :aria-label="`Button ${icon}`"
    class="icon-button"
    :class="[color, {
      active, dark, fab, faved: icon === 'star' && active, liked: icon === 'heart' && active,
    }]"
    :disabled="disabled || loading"
    type="button"
    @click="handleClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave">
    <div class="ink" />
    <CountTransition>
      <USIcon :icon="icon" :key="icon" />
    </CountTransition>
    <FadeTransition>
      <div v-if="loading" class="loader">
        <USSpinner />
      </div>
    </FadeTransition>
  </button>
</template>

<script>
import CountTransition from '@/transitions/CountTransition.vue';
import FadeTransition from '@/transitions/FadeTransition.vue';

import tooltipFunctions from '@/mixins/tooltipFunctions';

export default {
  components: {
    CountTransition,
    FadeTransition,
  },
  computed: {
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
      if (this.tooltip) this.hideTooltip();
    },
    handleMouseEnter() {
      if (!this.mobile && this.tooltip) this.showTooltip(this.$el, this.tooltip);
    },
    handleMouseLeave() {
      if (!this.mobile && this.tooltip) this.hideTooltip();
    },
  },
  mixins: [tooltipFunctions],
  props: {
    active: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    fab: Boolean,
    icon: String,
    loading: Boolean,
    tooltip: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.icon-button
  cursor: pointer
  padding: calc(0.75rem - 1px)
  border: 1px solid transparent
  border-radius: 0.75rem
  background-color: transparent
  color: $text-primary
  line-height: 1.5rem
  position: relative
  overflow: hidden
  z-index: 0
  transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease, border-color 200ms ease

  &.dark
    color: $text-primary-dark

    .loader
      background-color: $bg-dark

  &.danger
    color: $negative

    .ink
      background-color: alpha($negative, 0.1)

    &.active .ink
      background-color: $negative

  &.positive
    color: $positive

    .ink
      background-color: alpha($positive, 0.1)

    &.active .ink
      background-color: $positive

  &.warning
    color: $warning

    .ink
      background-color: alpha( $warning, 0.1)

    &.active .ink
      background-color: $warning

  &.active
    color: $text-primary-dark

    &:hover,
    &:focus
      color: $text-primary-dark
      border-color: darken($accent-primary, 10)

    .ink
      background-color: $accent-primary

  &.fab
    padding: calc(1rem - 1px)
    border-color: $accent-primary
    background-color: $accent-primary
    color: $text-primary-dark
    font-weight: 800
    box-shadow: $shadow-low

    .ink
      margin-top: calc(-50% + 1.75rem - 1px)
      background-color: darken($accent-primary, 10)

  &:hover,
  &:focus
    border-color: $accent-primary
    box-shadow: $shadow-high
    color: $accent-primary

    &.fab
      color: $text-primary-dark

    &.danger
      color: $negative
      border-color: @color

    &.positive
      color: $positive
      border-color: @color

    &.warning
      color: $warning
      border-color: @color

  &:focus,
  &.active
    .ink
      transform: scale(1.4142135624)

  &.faved
    .ink
      transform: scale(0)

    .icon
      fill: $warning
      stroke: @fill !important

  &.liked
    .ink
      transform: scale(0)

    .icon
      fill: $negative
      stroke: @fill !important

  &:active
    transform: translateY(2px)

  &:disabled
    pointer-events: none
    color: $text-disabled
    box-shadow: none

    &.fab
      background-color: $interactable-disabled
      border-color: @background-color
      font-weight: normal
      background-clip: padding-box

    &.dark
      color: $text-disabled-dark

      &.fab
        background-color: $interactable-disabled-dark
        border-color: @background-color
        font-weight: normal

  .ink
    display: inline-block
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    top: 0
    left: 0
    vertical-align: middle
    transform: scale(0)
    z-index: -1
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

  .icon
    vertical-align: top

  .loader
    background-color: $bg
    position: absolute
    top: 0
    left: @top
    right: @top
    bottom: @top
    display: flex
    align-items: center
    justify-content: center
</style>

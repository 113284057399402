<template lang="html">
  <USView class="writing-prompts" :dark="dark" show-back>
    <FadeTransition>
      <USLoader v-show="loading" :class="{ dark }" />
    </FadeTransition>
    <FadeTransition>
      <USActionBar
        v-show="$store.state.application.showActionBars"
        :actions="[{
          action: () => showAddPrompt = true, color: 'positive', icon: 'plus', tooltip: 'Add new prompt',
        }]"
        :dark="dark"
        :vertical="!mobile" />
    </FadeTransition>
    <h1>Writing Prompts</h1>
    <p>Let yourself be inspired by this growing collection of unique writing prompts and challenges!</p>
    <p>If you find a prompt you like, tapping on one will take you straight to the editor screen and prefill the necessary tags for you.</p>
    <p>When discussing pieces created using a prompt during a meeting, there’s a chance one might be awarded the “Best Use of Prompt” medal and to have it prominently featured on the explore page.</p>
    <ListTransition>
      <template v-if="prompts.length > 0 || nextPageAvailable">
        <USPromptCard v-for="(prompt, index) in prompts" :created-at="prompt.createdAt" :description="prompt.description" :dark="dark" :enableActions="isPrivileged" :key="prompt._id" :tag="prompt.tag" :title="prompt.title" @delete="deletePrompt(index)" @edit="handleEditPrompt(index)" @prompt-click="$router.push({ name: 'write', query: { prompt: prompt.tag } })" />
        <USButton v-show="initialized && nextPageAvailable" :dark="dark" key="loadMoreButton" :loading="promptsLoading" @click="fetchPromptsPage">Load More</USButton>
      </template>
      <section v-else class="empty-state" key="emptyState">
        <p>Currently there are no prompts.</p>
        <USButton v-if="isPrivileged" color="positive" :dark="dark" icon-left="plus" @click="showAddPrompt = true">Add One</USButton>
      </section>
    </ListTransition>
    <USModal
      :actions="[{ icon: 'cross', label: 'cancel' }, {
        action: savePrompt, disabled: formErrors, icon: promptId ? 'send' : 'plus', label: promptId ? 'Save' : 'Add',
      }]"
      :dark="dark"
      :title="promptId ? 'Edit Prompt' : 'Create Prompt'"
      :visible="showAddPrompt"
      @close="showAddPrompt = false">
      <USInput v-model="activePrompt.title" :dark="dark" :error="errors.title" icon="title" label="Title" :max-len="32" @blur="validate('title')" />
      <USInput v-model="activePrompt.description" :dark="dark" :error="errors.description" :formats="['bold', 'italic', 'linebreak', 'link', 'list', 'strike']" icon="description" label="Description" :max-len="1024" multiline @blur="validate('description')" />
      <USInput v-model="activePrompt.tag" :dark="dark" :error="errors.tag" icon="hashtag" label="Tag" :max-len="32" @blur="validate('tag')" />
    </USModal>
  </USView>
</template>

<script>
import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

export default {
  components: {
    FadeTransition,
    ListTransition,
  },
  computed: {
    formErrors() {
      const errors = Object.values(this.errors);
      return errors.some((err) => err !== '');
    },
    isPrivileged() {
      return ['councilmembers', 'moderators', 'admins'].some((group) => this.$store.state.user.groups.includes(group));
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  async created() {
    await this.fetchPromptsPage();

    this.initialized = true;
  },
  data() {
    return {
      activePrompt: {
        title: '',
        description: '',
        tag: '',
      },
      prompts: [],
      currentPage: 0,
      errors: {
        title: '',
        description: '',
        tag: '',
      },
      initialized: false,
      loading: false,
      nextPageAvailable: true,
      pageSize: 15,
      promptId: '',
      promptsLoading: false,
      showAddPrompt: false,
      skipCounter: 0,
    };
  },
  methods: {
    async deletePrompt(index) {
      const promptToDelete = this.prompts[index];
      const timeout = 5000;
      const timeoutId = window.setTimeout(async () => {
        try {
          await this.$feathers.service('prompts').remove(promptToDelete._id);
          this.skipCounter -= 1;
        } catch (err) {
          this.prompts.splice(index, 0, promptToDelete);
          this.$store.commit('addToast', { message: `Could not remove the prompt: ${err.message}`, type: 'negative' });
        }
      }, timeout);

      this.prompts.splice(index, 1);
      this.$store.commit('addToast', {
        action: () => {
          window.clearTimeout(timeoutId);
          this.prompts.splice(index, 0, promptToDelete);
        },
        actionLabel: 'Undo',
        message: `Prompt '${promptToDelete.title}' was deleted`,
        timeout: timeout - 200, // for safety
        type: 'warning',
      });
    },
    async fetchPromptsPage() {
      const timeoutId = window.setTimeout(() => { this.promptsLoading = true; }, 200);
      try {
        const { data: prompts, total } = await this.$feathers.service('prompts').find({
          query: {
            $limit: this.pageSize,
            $skip: this.pageSize * this.currentPage + this.skipCounter,
            $sort: { createdAt: -1 },
          },
        });

        this.prompts = this.prompts.concat(prompts);
        this.currentPage += 1;
        this.nextPageAvailable = this.currentPage < Math.ceil(total / this.pageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more prompts: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.promptsLoading = false;
    },
    handleEditPrompt(index) {
      const promptToEdit = this.prompts[index];
      this.activePrompt.title = promptToEdit.title;
      this.activePrompt.description = promptToEdit.description;
      this.activePrompt.tag = promptToEdit.tag;
      this.promptId = promptToEdit._id;
      this.showAddPrompt = true;
    },
    async savePrompt() {
      this.validate('title');
      this.validate('tag');
      this.validate('description');

      if (this.formErrors) return;

      try {
        if (!this.promptId) {
          const newPrompt = await this.$feathers.service('prompts').create({
            description: this.activePrompt.description,
            tag: this.activePrompt.tag,
            title: this.activePrompt.title,
          });
          this.prompts.unshift(newPrompt);
          this.skipCounter += 1;
        } else {
          const updatedPrompt = await this.$feathers.service('prompts').patch(this.promptId, {
            description: this.activePrompt.description,
            tag: this.activePrompt.tag,
            title: this.activePrompt.title,
          });
          const promptIndex = this.prompts.findIndex((prompt) => prompt._id === updatedPrompt._id);
          this.prompts.splice(promptIndex, 1, updatedPrompt);
          this.promptId = '';
        }
        this.activePrompt = {
          description: '',
          tag: '',
          title: '',
        };
      } catch (err) {
        this.$store.commit('addToast', {
          action: () => { this.showAddPrompt = true; },
          actionLabel: 'Try again',
          message: `Could not create prompt: ${err.message}`,
          type: 'negative',
        });
      }
    },
    validate(field) {
      let error = '';

      switch (field) {
        case 'description':
          if (!this.activePrompt.description) error = 'Description is required';
          if (this.activePrompt.description.length > 1024) error = 'Description is too long';
          this.errors.description = error;
          break;
        case 'title':
          if (!this.activePrompt.title) error = 'Title is required';
          if (this.activePrompt.title.length > 32) error = 'Title is too long';
          this.errors.title = error;
          break;
        case 'tag':
          if (!this.activePrompt.tag) error = 'Tag is required';
          if (this.activePrompt.tag.length > 32) error = 'Tag is too long';
          this.errors.tag = error;
          break;
        default:
      }
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    mobile(newVal) {
      if (!newVal) this.$router.replace({ name: 'inspiration', query: { page: 'writing-prompts' } });
    },
  },
};
</script>

<style lang="stylus" scoped>
.writing-prompts
  > p:last-of-type
    margin-bottom: 3rem

  .empty-state
    margin-bottom: 0

    p
      font-weight: 800
      opacity: 0.6
      text-align: center

  .button
    display: flex
    margin: 0 auto

    &:not(:first-child)
      margin-top: 1rem

  .prompt-card:not(:last-of-type)
    margin-bottom: 1rem

  .modal
    .input
      width: 100%

      &:not(:last-of-type)
        margin-bottom: 1rem

      >>> .editor-scroll
        max-height: 12rem
        overscroll-behavior: contain
</style>

<script>
export default {
  beforeDestroy() {
    this.observer.disconnect();
  },
  created() {
    this.observer = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.$emit('leave', [entries[0]]);
      } else {
        this.$emit('enter', [entries[0]]);
      }

      this.$emit('change', [entries[0]]);
    }, { root: this.root, rootMargin: this.rootMargin, threshold: this.threshold });
  },
  mounted() {
    this.$nextTick(() => this.observer.observe(this.$slots.default[0].elm));
  },
  props: {
    root: {
      type: HTMLElement,
      default: () => null,
    },
    rootMargin: {
      type: String,
      default: () => '0px 0px 100px 0px',
    },
    threshold: {
      type: [Number, Array],
      default: 0,
    },
  },
  render() {
    return this.$slots.default ? this.$slots.default[0] : null;
  },
};
</script>

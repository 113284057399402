<template lang="html">
  <button class="thread-card" :class="{ dark }" type="button" @click="$emit('click', $event)">
    <div class="ink" />
    <img :src="author.avatar || '/img/default-avatar.png'" alt="no avatar found">
    <span class="title">{{title}}</span>
    <span>{{shortenedTime}}</span>
  </button>
</template>

<script>
import { Date as SugarDate } from 'sugar-date';

export default {
  computed: {
    shortenedTime() {
      const d = new SugarDate(this.updated);
      const relativeTime = d.relative().replace(' ago', '');

      return relativeTime
        .replace(/second[s]?/g, 's')
        .replace(/minute[s]?/g, 'min')
        .replace(/hour[s]?/g, 'h')
        .replace(/day[s]?/g, 'd')
        .replace(/week[s]?/g, 'w')
        .replace(/month[s]?/g, 'm')
        .replace(/year[s]?/g, 'y')
        .replace(' ', '');
    },
  },
  props: {
    author: {
      type: Object,
      default() {
        return {};
      },
    },
    dark: Boolean,
    title: String,
    updated: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.thread-card
  display: flex;
  align-items: center
  cursor: pointer
  width: 100%
  padding: calc(1rem - 1px)
  border: 1px solid $interactable
  border-radius: 0.75rem
  background-color: transparent
  color: inherit
  line-height: 1.5rem
  box-shadow: $shadow-low
  text-shadow: $text-shadow
  position: relative
  overflow: hidden
  z-index: 0
  vertical-align: top
  transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease, border-color 200ms ease

  &.dark
    border-color: $interactable-dark

    span
      color: $text-secondary-dark

  &:hover,
  &:focus
    border-color: $accent-primary
    box-shadow: $shadow-high
    color: $accent-primary

  &:focus .ink
    transform: scale(1.4142135624)

  &:active
    transform: translateY(2px)

  img
    margin: 0
    flex-shrink: 0
    border-radius: 50%
    width: 2.5rem
    height: @width

  span
    display: block
    flex-shrink: 0
    white-space: nowrap
    margin-left: 1rem
    color: $text-secondary

    &.title
      flex-shrink: 1
      overflow: hidden
      text-overflow: ellipsis
      margin-right: auto
      color: inherit

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    transform: scale(0)
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%

</style>

<template lang="html">
  <USView class="write" :dark="dark" show-back>
    <FadeTransition>
      <USActionBar v-show="$store.state.application.showActionBars && (!mobile || !editorHasFocus)" :actions="[{ action: () => save(true), icon: 'draft-add', tooltip: 'Save as Draft' }, { action: save, icon: 'send', tooltip: 'Publish' }]" :dark="dark" :vertical="!mobile" />
    </FadeTransition>
    <header class="date" :class="{ dark, mobile }">
      <span>{{ Date.now() | customDate }}</span>
      <span>{{wordCount | readingTime }} read</span>
    </header>
    <section v-show="prompt.tag && mobile" class="prompt">
      <h2>Writing Prompt</h2>
      <USPromptCard :createdAt="prompt.createdAt" :dark="dark" :description="prompt.description" :tag="prompt.tag" :title="prompt.title" @prompt-click="$router.push({ name: 'writing-prompts' })" />
    </section>
    <input v-model="title" autocomplete="off" class="title" :class="{ dark }" placeholder="Untitled" type="text">
    <span class="author">by {{ $store.state.user.name | capitalize }}</span>
    <USEditor v-model="content" :dark="dark" :indented-paragraphs="indentedParagraphs" mode="delta" placeholder="Once upon a time…" scrolling-container="html" :selection="editorSelection" @blur="editorHasFocus = false" @focus="editorHasFocus = true" />
    <USSidebar v-if="!mobile" :dark="dark" collapse-button>
      <section v-show="prompt.tag" class="prompt">
        <h2>Writing Prompt</h2>
        <USPromptCard :createdAt="prompt.createdAt" :dark="dark" :description="prompt.description" :tag="prompt.tag" :title="prompt.title" @prompt-click="$router.push({ name: 'writing-prompts' })" />
      </section>
      <section class="post-settings" :class="{ shaded }">
        <div v-if="showComments && shaded" class="hide-shadow" :class="dark" />
        <h2>Post Settings</h2>
        <USChipInput v-model="tags" allow-not-suggested :dark="dark" icon="hashtag" label="Post Tags" :max-len="8" :model="genres" />
        <label>
          To Be Discussed
          <USSwitch v-model="toBeDiscussed" :dark="dark" />
        </label>
        <label>
          Public
          <USSwitch v-model="isPublic" :dark="dark" />
        </label>
        <label v-if="$route.query.edit">
          Version History
          <USSwitch v-model="history" :dark="dark" />
        </label>
        <label>
          Indented paragraphs
          <USSwitch v-model="indentedParagraphs" :dark="dark" />
        </label>
        <label>
          Custom Blurb
          <USSwitch v-model="customBlurb" :dark="dark" />
        </label>
        <FadeTransition>
          <USInput v-show="customBlurb" v-model="blurb" clearable :dark="dark" :formats="[]" icon="description" label="Post Blurb" :max-len="256" multiline />
        </FadeTransition>
        <label v-if="$route.query.edit">
          Show Comments
          <USSwitch v-model="showComments" :dark="dark" />
        </label>
      </section>
      <FadeTransition>
        <ListTransition v-show="showComments" class="comments" @scroll.native.passive="checkShading">
          <section v-for="comment in commentsWithoutSoftDeleted" class="comment-thread" :key="`thread_${comment._id}`" @click.capture="handleSelectClick">
            <USComment :author="comment.author" compact :content="comment.content" :created="comment.createdAt" :dark="dark" :id="comment._id" :interactable="false" :key="comment._id" :likes="comment.likers.length" :original-content="comment.originalContent" :quotable="false" :unpublished="comment.hidden" />
            <USComment v-for="reply in comment.replies" v-show="!$store.getters.isSoftDeleted(reply._id)" :author="reply.author" compact :content="reply.content" :created="reply.createdAt" :dark="dark" :id="reply._id" :interactable="false" :key="reply._id" :likes="reply.likers.length" :original-content="reply.originalContent" :quotable="false" reply />
          </section>
          <section v-if="!commentsLoading && comments.length < 1 && !commentsAvailable" class="empty-state" key="empty-state">
            <p>Sorry, this post doesn’t have any comments yet.</p>
          </section>
          <USButton v-show="commentsAvailable" :dark="dark" key="loadMoreButton" :loading="commentsLoading" @click="fetchCommentsPage">Load More</USButton>
        </ListTransition>
      </FadeTransition>
    </USSidebar>
    <template v-else>
      <h2>Post Settings</h2>
      <USChipInput v-model="tags" allow-not-suggested :dark="dark" icon="hashtag" label="Post Tags" :max-len="8" :model="genres" />
      <label>
        To Be Discussed
        <USSwitch v-model="toBeDiscussed" :dark="dark" />
      </label>
      <label>
        Public
        <USSwitch v-model="isPublic" :dark="dark" />
      </label>
      <label v-if="$route.query.edit">
        Version History
        <USSwitch v-model="history" :dark="dark" />
      </label>
      <label>
        Indented paragraphs
        <USSwitch v-model="indentedParagraphs" :dark="dark" />
      </label>
      <label>
        Custom Blurb
        <USSwitch v-model="customBlurb" :dark="dark" />
      </label>
      <FadeTransition>
        <USInput v-show="customBlurb" v-model="blurb" clearable :dark="dark" :formats="[]" icon="description" label="Post Blurb" :max-len="256" multiline />
      </FadeTransition>
      <label v-if="$route.query.edit">
        Show Comments
        <USSwitch v-model="showComments" :dark="dark" />
      </label>
      <FadeTransition>
        <ListTransition v-show="showComments" class="comments mobile">
          <section v-for="comment in commentsWithoutSoftDeleted" class="comment-thread" :key="`thread_${comment._id}`" @click.capture="handleSelectClick">
            <USComment :author="comment.author" compact :content="comment.content" :created="comment.createdAt" :dark="dark" :id="comment._id" :interactable="false" :key="comment._id" :likes="comment.likers.length" :original-content="comment.originalContent" :quotable="false" :unpublished="comment.hidden" />
            <USComment v-for="reply in comment.replies" v-show="!$store.getters.isSoftDeleted(reply._id)" :author="reply.author" compact :content="reply.content" :created="reply.createdAt" :dark="dark" :id="reply._id" :interactable="false" :key="reply._id" :likes="reply.likers.length" :original-content="reply.originalContent" :quotable="false" reply />
          </section>
          <section v-if="!commentsLoading && comments.length < 1 && !commentsAvailable" class="empty-state" key="empty-state">
            <p>Sorry, this post doesn’t have any comments yet.</p>
          </section>
          <USButton v-show="commentsAvailable" :dark="dark" key="loadMoreButton" :loading="commentsLoading" @click="fetchCommentsPage">Load More</USButton>
        </ListTransition>
      </FadeTransition>
    </template>
  </USView>
</template>

<script>
import Feathers from '@/feathersApp';
import Store from '@/store';

import capitalize from '@/filters/capitalize';
import customDate from '@/filters/customDate';
import readingTime from '@/filters/readingTime';

import FadeTransition from '@/transitions/FadeTransition.vue';
import ListTransition from '@/transitions/ListTransition.vue';

import generateBlurb from '@/scripts/generateBlurb';

export default {
  beforeDestroy() {
    if (!this.mobile) this.$store.commit('setShowSidebar', false);

    window.removeEventListener('beforeunload', this.preventUnintentionalClose);
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { content: genreString } = await Feathers.service('info').get('genres');
      let prompt;
      let post;

      if (to.query.prompt) {
        ({ data: prompt } = await Feathers.service('prompts').find({ query: { tag: to.query.prompt, $limit: 1 } }));
        [prompt] = prompt;
      }
      if (to.query.edit) {
        post = await Feathers.service('posts').get(to.query.edit, { query: { draft: to.query.draft } });
        if (post.author._id !== Store.getters.userId) {
          Store.commit('addToast', { message: 'You don’t have permission to edit this post', type: 'negative' });
          next(false);
          return;
        }
      }

      next((vm) => {
        /* eslint-disable no-param-reassign */
        vm.genres = genreString.split(',');
        if (prompt && !post) {
          vm.prompt = prompt;
          vm.tags.push({ value: prompt.tag });
          vm.tags.push({ value: 'writing prompt' });
        }
        if (post) {
          vm.blurb = post.blurb;
          vm.content = { ops: post.content };
          vm.customBlurb = true;
          vm.indentedParagraphs = post.indentedParagraphs;
          vm.isPublic = post.public;
          vm.originalContent = post.originalContent.length > 0 ? post.originalContent : post.content;
          vm.tags = post.tags;
          vm.title = post.title;
          vm.toBeDiscussed = post.toBeDiscussed;
          vm.originalTBD = post.toBeDiscussed;
        }
        /* eslint-enable no-param-reassign */
      });
    } catch (err) {
      if (err.code === 404) next({ name: 'not-found' });
      else if (err.code === 408) next({ name: 'timeout', query: { retry: to.fullPath } });
      else next(new Error(err.message));
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
    if (this.forceNavigation) {
      next();
      return;
    }
    if (this.wordCount > 0 || this.title || this.tags.length > 0 || (this.customBlurb && this.blurb)) {
      this.$store.commit('addToast', {
        action: next,
        actionLabel: 'Discard Changes',
        message: 'You have unsaved changes, do you want to discard them?',
        type: 'warning',
      });
    } else next();
  },
  components: {
    FadeTransition,
    ListTransition,
  },
  computed: {
    commentsWithoutSoftDeleted() {
      return this.comments.filter((comment) => !this.$store.getters.isSoftDeleted(comment._id));
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  created() {
    if (!this.mobile) this.$store.commit('setShowSidebar', true);

    window.addEventListener('beforeunload', this.preventUnintentionalClose);
  },
  data() {
    return {
      blurb: '',
      content: {},
      comments: [],
      commentsLoading: false,
      commentsAvailable: true,
      commentsPageSize: 5,
      commentsCurrentPage: 0,
      customBlurb: false,
      editorHasFocus: false,
      editorSelection: [],
      firstEditorFocus: true,
      forceNavigation: false,
      genres: [],
      history: true,
      indentedParagraphs: false,
      isPublic: false,
      originalContent: [],
      originalTBD: null,
      prompt: {},
      shaded: false,
      showComments: false,
      tags: [],
      timeoutId: null,
      title: '',
      toBeDiscussed: true,
      wordCount: 0,
    };
  },
  filters: {
    capitalize,
    customDate,
    readingTime,
  },
  methods: {
    checkShading(e) {
      if (e.target.scrollTop > 0) this.shaded = true;
      else this.shaded = false;
    },
    async fetchCommentsPage() {
      if (!this.$route.query.edit) return;

      const timeoutId = window.setTimeout(() => { this.commentsLoading = true; }, 200);
      try {
        const query = {
          parentPost: this.$route.query.edit,
          $limit: this.commentsPageSize,
          $skip: this.commentsPageSize * this.commentsCurrentPage,
          $sort: { createdAt: -1 },
        };

        const { data: comments, total } = await this.$feathers.service('comments').find({ query });
        this.comments = this.comments.concat(comments);
        this.commentsCurrentPage += 1;
        this.commentsAvailable = this.commentsCurrentPage < Math.ceil(total / this.commentsPageSize);
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not fetch more comments: ${err.message}`, type: 'negative' });
      }
      window.clearTimeout(timeoutId);
      this.commentsLoading = false;
    },
    handleSelectClick(e) {
      e.preventDefault(); // don’t append to query
      if (
        e.target
        && e.target.tagName
        && e.target.tagName.toLowerCase() === 'a'
        && e.target.matches('a[href*=\'?selection=\']')
      ) {
        const selection = e.target.getAttribute('href').split('=')[1];
        this.editorSelection = [];
        this.$nextTick(() => { this.editorSelection = selection.split(':'); });
      }
    },
    preventUnintentionalClose(e) {
      if (this.forceNavigation) return;
      if (this.wordCount > 0 || this.title || this.tags.length > 0 || (this.customBlurb && this.blurb)) {
        this.$store.commit('addToast', {
          message: 'You have unsaved changes, save them before exiting if you don’t want to lose them.',
          type: 'warning',
          timeout: 10000,
        });
        e.preventDefault();
        e.returnValue = ''; // for chrome
      }
    },
    async save(draft) {
      if (!this.content.ops || this.content.ops.length === 0) {
        this.$store.commit('addToast', { message: 'Could not save post: post has no content', type: 'negative' });
        return;
      }
      this.setBlurb();

      const valid = this.validate();
      if (!valid) return;

      const tagsToSave = this.tags.map((tag) => {
        if (typeof tag === 'string') return tag;
        return tag.value;
      });

      const post = {
        blurb: this.blurb,
        content: this.content.ops,
        tags: tagsToSave,
        title: this.title || 'Untitled',
        readers: [this.$store.getters.userId],
        reads: 1,
      };

      if (this.isPublic) post.public = true;
      else post.public = false;
      if (this.indentedParagraphs) post.indentedParagraphs = true;
      else post.indentedParagraphs = false;
      if (this.toBeDiscussed) post.toBeDiscussed = true;
      if (this.$route.query.edit && this.toBeDiscussed !== this.originalTBD) post.toBeDiscussed = this.toBeDiscussed;
      if (draft) post.draft = true;
      else post.draft = false;
      if (this.history && this.originalContent.length > 0) post.originalContent = this.originalContent;
      if (!this.history && this.originalContent.length > 0) post.originalContent = [];

      try {
        let newPost;
        if (this.$route.query.edit) newPost = await this.$feathers.service('posts').patch(this.$route.query.edit, post);
        else newPost = await this.$feathers.service('posts').create(post);
        this.forceNavigation = true;
        const query = { draft };
        if (draft) query.listType = 'drafts';
        else {
          query.listType = 'user';
          query.list = this.$store.getters.userId;
        }
        this.$router.replace({ name: 'read', params: { id: newPost._id }, query });
      } catch (err) {
        this.$store.commit('addToast', { message: `Could not save post: ${err.message}`, type: 'negative' });
      }
    },
    setBlurb() {
      if (this.customBlurb && this.blurb) return;
      this.blurb = generateBlurb(this.$el);
    },
    validate() {
      if (this.customBlurb && this.blurb.length > 256) {
        this.$store.commit('addToast', { message: 'Could not save post: blurb is too long', type: 'negative' });
        return false;
      }

      if (!this.blurb) {
        this.$store.commit('addToast', { message: 'Could not save post: it has no blurb', type: 'negative' });
        return false;
      }

      if (this.title.length > 128) {
        this.$store.commit('addToast', { message: 'Could not save post: title is too long', type: 'negative' });
        return false;
      }

      if (this.tags.length > 8) {
        this.$store.commit('addToast', { message: 'Could not save post: too many tags', type: 'negative' });
        return false;
      }
      return true;
    },
  },
  props: {
    dark: Boolean,
  },
  watch: {
    content(newVal) {
      const textContent = newVal.ops.map((op) => op.insert).join();
      if (textContent === '\n') this.wordCount = 0;
      else this.wordCount = textContent.split(/\s+|-+|–+|—+/g).length - 1; // there’s always an empty string at the end because ops ends with a \n

      // this.wordCount = this.$refs.editor.quill.getText().split(/\s+|-+|–+|—+/g).length; // maybe an alternative if we ever have performance issues

      if (!this.title) {
        const header = this.$el.querySelector('.ql-editor h1');
        if (header && header.textContent) this.title = header.textContent;
      }
    },
    editorHasFocus(newVal) {
      if (newVal && this.firstEditorFocus) {
        this.firstEditorFocus = false;
        if (!this.$route.query.prompt) {
          this.timeoutId = window.setTimeout(() => {
            this.timeoutId = null;
            if (this.wordCount > 0 || this.title) return;
            this.$store.commit('addToast', {
              action: () => this.$router.push({ name: 'writing-prompts' }),
              actionLabel: 'Browse',
              message: 'Unsure what to write? Pick a prompt!',
              timeout: 10000,
            });
          }, 5000);
        }
      }
    },
    showComments(newVal) {
      if (newVal && this.comments.length < 1 && this.commentsAvailable) this.fetchCommentsPage();
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.write
  .date
    font-size: 0.875rem
    color: $text-secondary
    text-align: right

    &.dark
      color: $text-secondary-dark

    &.mobile
      position: absolute
      top: 1.5rem
      right: @top

    span
      display: block

  .prompt
    margin-bottom: 3rem

  .title
    color: inherit
    font-family: 'PT Serif', serif
    font-size: 2rem
    background-color: transparent
    border: none
    width: 100%
    margin-bottom: 0.5rem
    line-height: @font-size
    padding: 0
    user-select: text

    &::placeholder
      color: $text-secondary

    &.dark::placeholder
      color: $text-secondary-dark

  .author
    font-family: 'PT Serif', serif
    font-style: italic

  .editor
    margin-top: 3rem

  .sidebar
    position: fixed
    left: 3.5rem
    top: 0
    z-index: 3
    display: flex
    flex-direction: column

    .post-settings
      margin: 0 -2rem
      padding: 0 2rem
      position: relative
      z-index: 1

      &.shaded
        box-shadow: $shadow-high
        border-bottom-left-radius: 0.75rem
        border-bottom-right-radius: 0.75rem

      .hide-shadow
        background-color: $bg
        height: 0.5rem
        position: absolute
        width: 100%
        left: 0
        top: -0.5rem

        &.dark
          background-color: $bg-dark

      .comments
        overflow-y: auto
        margin: 0 -2rem -2rem -2rem
        padding: 2rem

  .comments

    &.mobile
      padding-top: 1rem

      .comment-thread
        &:not(:first-child)
          margin-top: 2rem

    .empty-state
      font-weight: 800
      opacity: 0.6
      text-align: center

    .comment-thread
      &:not(:first-child)
        margin-top: 3rem

      .comment
        padding-left: 0

        &:not(.reply).compact >>> .comment-card
          border-top-left-radius: (3 / 16)rem
          border-top-right-radius: (12 / 16)rem

        &.reply
          margin-top: 1rem

    .button
      display: flex
      margin: 0 auto

  .input.icon,
  .chip-input.icon
    margin-bottom: 1.5rem
    width: 100%

  label
    display: flex
    align-items: center
    margin-bottom: 1rem

    .switch
      flex-shrink: 0
      margin-left: auto
</style>

<template lang="html">
  <section
    class="comment"
    :class="{
      compact, dark, reply, static: !interactable, unpublished,
    }"
    :id="id"
    @mouseleave="handleMouseLeave">
    <img v-if="!compact" :src="author.avatar || '/img/default-avatar.png'" alt="no avatar found">
    <div class="comment-card" @mouseenter="handleMouseEnter" @click="handleClick">
      <USEditor :dark="dark" disabled mode="markdown" :original-content="originalContent" :quotable="quotable" :show-changes="showChanges" :value="content" @click.native="handleLinkClick" @quote="handleQuote" />
      <footer>
        <section>
          <img v-if="compact" :src="author.avatar || '/img/default-avatar.png'" alt="no avatar found">
          <span>{{author.name | firstName}}, {{created | relativeTime(true)}}</span>
          <span v-if="originalContent" class="edited" @click="showChanges = !showChanges">, <u>{{ showChanges ? 'changes' : 'edited'}}</u></span>
          <span v-if="unpublished">, private</span>
        </section>
        <section v-if="likes > 0" class="likes">
          <USIcon icon="heart" />
          <span>{{likes}}</span>
        </section>
      </footer>
    </div>
    <FadeTransition>
      <USActionBar v-show="!mobile && showActions" :actions="commentActions" :dark="dark" vertical @mouseenter.native="clearTimeout" />
    </FadeTransition>
    <USModal :actions="null" :dark="dark" :visible="mobile && showActions" @close="showActions = false">
      <USOptionList :dark="dark" :options="commentActions" />
    </USModal>
  </section>
</template>

<script>
import firstName from '@/filters/firstName';
import relativeTime from '@/filters/relativeTime';

import handleLinkClick from '@/mixins/handleLinkClick';

import FadeTransition from '@/transitions/FadeTransition.vue';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    commentActions() {
      if (this.$store.getters.userId !== this.author._id) {
        if (this.forum) {
          return [
            {
              action: this.handleDelete, icon: 'delete', label: 'Delete this comment', tooltip: 'Delete this comment', value: '',
            },
          ];
        }
        const actions = [
          {
            action: this.handleLike, active: this.likedByCurrentUser, icon: 'heart', label: this.likedByCurrentUser ? 'Unlike this comment' : 'Like this comment', tooltip: this.likedByCurrentUser ? 'Unlike this comment' : 'Like this comment', value: '',
          },
          {
            action: this.handleReply, icon: 'reply-comment', label: 'Add a reply', tooltip: 'Add a reply', value: '',
          },
        ];
        if (this.$store.getters.isModerator) {
          actions.push(
            {
              action: this.handleDelete, icon: 'delete', label: 'Delete this comment', tooltip: 'Delete this comment', value: '',
            },
          );
        }
        return actions;
      }
      return [
        {
          action: this.handleEdit, icon: 'pencil', label: 'Edit this comment', tooltip: 'Edit this comment', value: '',
        },
        {
          action: this.handleDelete, icon: 'delete', label: 'Delete this comment', tooltip: 'Delete this comment', value: '',
        },
      ];
    },
    mobile() {
      return this.$store.state.application.mobile;
    },
  },
  data() {
    return {
      showActions: false,
      showChanges: false,
      timeout: null,
    };
  },
  filters: {
    firstName,
    relativeTime,
  },
  methods: {
    clearTimeout() {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    },
    handleClick(e) {
      if (!this.interactable) return;
      if (this.mobile && !e.target.parentElement.classList.contains('edited') && !e.currentTarget.querySelector('.action-bar').contains(e.target) && e.target.tagName.toLowerCase() !== 'a') this.showActions = true;
    },
    handleDelete() {
      this.showActions = false;
      this.$emit('delete', this.id);
    },
    handleEdit() {
      this.showActions = false;
      this.$nextTick(() => this.$emit('edit', this.id));
    },
    handleLike() {
      this.showActions = false;
      this.$emit('like', this.id);
    },
    handleMouseEnter() {
      if (this.mobile || !this.interactable) return;
      if (this.timeout) this.clearTimeout();
      this.showActions = true;
    },
    handleMouseLeave() {
      if (this.mobile) return;
      if (!this.timeout) {
        this.timeout = window.setTimeout(() => {
          this.showActions = false;
        }, 200);
      }
    },
    handleQuote(quote) {
      this.$emit('quote', { id: this.id, ...quote });
    },
    handleReply() {
      this.showActions = false;
      this.$nextTick(() => this.$emit('reply', this.id));
    },
  },
  mixins: [handleLinkClick],
  props: {
    author: {
      type: Object,
      default() {
        return {};
      },
    },
    compact: Boolean,
    content: String,
    created: Number,
    dark: Boolean,
    forum: Boolean,
    id: String,
    interactable: {
      type: Boolean,
      default: true,
    },
    likes: {
      type: Number,
      default: 0,
    },
    likedByCurrentUser: Boolean,
    originalContent: String,
    quotable: {
      type: Boolean,
      default: true,
    },
    reply: Boolean,
    unpublished: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.comment
  display: flex
  max-width: 40rem
  position: relative

  &.dark
    .comment-card
      border-color: $interactable-dark

      footer
        color: $text-secondary-dark

  &.unpublished
    img
      opacity: 0.5

    .comment-card
      border-style: dashed
      box-shadow: none

  &.compact
    padding-left: 3.5rem

    .comment-card
      border-top-left-radius: 0.75rem
      border-top-right-radius: 0.1875rem

  &.reply > img
    width: 2rem
    height: @width
    margin-right: 1.25rem
    margin-left: 0.25rem

  &.static
    .comment-card
      box-shadow: none
      border-color: $interactable-disabled

      &:hover
        box-shadow: none

    &.dark .comment-card
      border-color: $interactable-disabled-dark

  img
    border-radius: 50%
    width: 2.5rem
    height: @width
    box-shadow: $shadow-low
    margin: 0
    margin-right: 1rem
    flex-shrink: 0

  .comment-card
    width: 100%
    border-radius: 0.75rem
    border-top-left-radius: 0.1875rem
    border: 1px solid $interactable
    box-shadow: $shadow-low
    padding: 1rem
    transition: box-shadow 200ms ease

    &:hover
      box-shadow: $shadow-high

    footer
      margin-top: 1rem
      display: flex
      color: $text-secondary

      section
        white-space: nowrap
        display: flex

        img
          display: inline-block
          margin-right: 0.5rem
          width: 1.5rem
          height: @width
          box-shadow: none
          vertical-align: middle

        span
          vertical-align: middle
          display: block
          overflow: hidden
          text-overflow: ellipsis

          &.edited
            cursor: pointer

            &:hover
              color: $accent-primary

        &.likes
          margin-left: auto
          margin-right: 0
          width: auto

          .icon
            margin-right: 0.5rem

  .action-bar
    position: absolute
    right: -1rem
    translate: 100% 0
    top: 0

    &.vertical:not(.mobile)
      max-height: unset
</style>
